import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormFeedback } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { sendMailNotificaton } from "../../store/Global/actions";
import { MAIL_SENT } from "../Dashboard/Partials/initialData";
import { useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import PreviewFormat2 from "../InstantRate/Preview/PreviewFormat2";
import PreviewFormat1 from "../InstantRate/Preview/PreviewFormat1";

const EmailSenderPopUp = ({ modal, onCloseClick, quotationData }) => {
    const [previewFormat, setPreviewFormat] = useState('1');
    const [customerInfo, setCustomerInfo] = useState();
    const [editorState, setEditorState] = useState();
    const { status_master } = useSelector((state) => state.globalReducer);
    const { login_user_data } = useSelector((state) => state?.Login);
    const { customer_data } = useSelector((state) => state?.customer);
    const isSingleData = true;
    const dispatch = useDispatch();
    const { searchForm } = useSelector((state) => state?.instantRate);

    const quoteSelectedTenantdata = useSelector((state) => state.instantRate.quote_selected_tenantdata);
    const customerOptions = customer_data?.content?.map(customer => ({
        value: customer.id,
        label: customer.contactEmail,
        email: customer.contactEmail,
        version: customer.version,
        id: customer.id,
    })) || [];

    const validationSchema = Yup.object().shape({
        from: Yup.string().required("From is required"),
        to: Yup.array().min(1, "Select at least one recipient"),
        cc: Yup.array(),
        subject: Yup.string().required("Subject is required"),
        message: Yup.string().required("Message is required"),
        attachments: Yup.array().of(Yup.mixed()),
    });

    const formik = useFormik({
        initialValues: {
            from: "",
            to: [],
            cc: [],
            subject: "",
            message: "",
            attachments: [],
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();

            const emailDataBlob = new Blob([JSON.stringify({
                from: "lokeshk@tarifftales.com",
                to: values.to,
                cc: values.cc,
                subject: values.subject,
                message: values.message,
                summaryId: quotationData?.id,
                statusId: status_master?.find(item => item.statusCode === MAIL_SENT)?.id
            })], { type: "application/json" });
            formData.append("emailData", emailDataBlob);

            values.attachments.forEach(file => {
                formData.append("attachments", file);
            });

            dispatch(sendMailNotificaton(formData));
            onCloseClick();
            resetForm();
        },
    });

    useEffect(() => {
        formik.setValues({
            from: login_user_data?.email,
            to: [],
            cc: [],
            subject: "",
            message: "",
            attachments: [],
        })

        const generatePdfAttachment = async () => {
            const componentToCapture = document.getElementById('component-to-capture');
            const pdf = new jsPDF('p', 'mm', 'a4');

            // Wait for the PDF generation
            const canvas = await html2canvas(componentToCapture);
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 210, 297);

            // Convert PDF to Blob and set it as a default attachment
            const pdfBlob = pdf.output('blob');
            const pdfFile = new File([pdfBlob], "component.pdf", { type: "application/pdf" });
            formik.setFieldValue("attachments", [...formik.values.attachments, pdfFile]);
        };

        generatePdfAttachment();
    }, [quoteSelectedTenantdata,customerInfo]);

    useEffect(() => {
        let data = customer_data && customer_data?.content?.find(obj => obj.id === searchForm?.customerName?.value);
        if (data) {
            setCustomerInfo(data);
        } else {
            setCustomerInfo({ name: quoteSelectedTenantdata?.contactName, address: "" })
        }
    }, [customer_data]);


    return (
        <Modal isOpen={modal} role="dialog" centered={true} toggle={onCloseClick} >
            <div className="modal-content">
                <ModalHeader toggle={onCloseClick}>New Message</ModalHeader>
                <ModalBody>
                    <form onSubmit={formik.handleSubmit}>
                        {/* From Field */}
                        <div className="mb-3">
                            <label className="form-label">From</label>
                            <Input
                                name="from"
                                type="text"
                                className="form-control"
                                placeholder="from"
                                value={formik.values.from}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                readOnly={true}
                            />
                            {formik.errors.from && formik.touched.from && (
                                <FormFeedback className="d-block">{formik.errors.from}</FormFeedback>
                            )}
                        </div>

                        {/* To Field (Multi-select) */}
                        <div className="mb-3">
                            <label>To</label>
                            <Select
                                isMulti
                                name="to"
                                options={customerOptions}
                                value={formik.values.to}
                                onChange={(selected) => formik.setFieldValue("to", selected)}
                                classNamePrefix="select2-selection form-select"
                            />
                            {formik.errors.to && formik.touched.to && (
                                <FormFeedback className="d-block">{formik.errors.to}</FormFeedback>
                            )}
                        </div>

                        {/* Cc Field (Multi-select) */}
                        <div className="mb-3">
                            <label>Cc</label>
                            <Select
                                isMulti
                                name="cc"
                                options={customerOptions}
                                value={formik.values.cc}
                                onChange={(selected) => formik.setFieldValue("cc", selected)}
                                classNamePrefix="select2-selection form-select"
                            />
                        </div>

                        {/* Subject Field */}
                        <div className="mb-3">
                            <label>Subject</label>
                            <Input
                                name="subject"
                                type="text"
                                className="form-control"
                                placeholder="Subject"
                                value={formik.values.subject}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.subject && formik.touched.subject && (
                                <FormFeedback className="d-block">{formik.errors.subject}</FormFeedback>
                            )}
                        </div>

                        {/* Message Field */}
                        <div className="mb-3">
                            <label>Message</label>
                            <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(state) => {
                                    setEditorState(state);
                                    formik.setFieldValue("message", state.getCurrentContent().getPlainText());
                                }}
                            />
                            {formik.errors.message && formik.touched.message && (
                                <FormFeedback className="d-block">{formik.errors.message}</FormFeedback>
                            )}
                        </div>

                        {/* Attachments Field */}
                        <div className="mb-3">
                            <label>Attachments</label>
                            <Input
                                type="file"
                                multiple
                                onChange={(event) => {
                                    const files = Array.from(event.target.files);
                                    formik.setFieldValue("attachments", files);
                                }}
                            />
                            <div className="mt-2">
                                {formik.values.attachments && formik.values.attachments.length > 0 && (
                                    <ul>
                                        {formik.values.attachments.map((file, index) => (
                                            <li key={index}>{file.name}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        <ModalFooter>
                            <Button color="secondary" onClick={onCloseClick}>
                                Close
                            </Button>
                            <Button type="submit" color="primary">
                                Send <i className="fab fa-telegram-plane ms-1"></i>
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalBody>
            </div>

            <div id="component-to-capture" className="modal-body preview_modal_wrap" >
                {previewFormat === '2' ? <PreviewFormat2 customerInfo={customerInfo} isSingleData={isSingleData} /> : <PreviewFormat1 customerInfo={customerInfo} isSingleData={isSingleData} />}
            </div>
        </Modal>

    );
};

export default EmailSenderPopUp;
