
export const dashboardSteps = [

]

export const addUserSteps = [
    { element: "#firstName", intro: "First name is required for create user!" },
    { element: "#email", intro: "Email address is required for user login!" },
    { element: "#roles", intro: "Roles you given this user !" },
    { element: "#password", intro: "Password is required for user login!" },
    { element: "#confirmpassword", intro: "Confirm your password need to verify your password!" },
    { element: "#submitAddUser", hint: "Finally click here for create new user", hintPosition: "top-middle" }
]


export const instantRateSteps = [

]


export const inquiriesListSteps = [

]

export const requestForRatesSteps = [

]

export const marketPlaceInquiriesSteps = [

]

export const quotationsListSteps = [

]

export const quotationApprovalSteps = [

]

export const fclFreightSteps = [

]

export const fclPortLocalChargesSteps = [

]

export const fclInlandChargesSteps = [

]

export const masterWaybillSteps = [

]

export const consoleSteps = [

]

export const airportLocalChargesStep = [

]

export const airlineChargesSteps = [

]

export const compareRatesSteps = [

]

export const customerListSteps = [

]

export const customerRatesSteps = [

]

export const marketingSteps = [

]

export const companySettingSteps = [

]

export const marketPlaceConfigSteps = [

]

export const integrationsConfigSteps = [

]

export const rolesConfigSteps = [

]

export const surchargeConfigSteps = [

]

export const vendorConfigSteps = [

]

export const carrierConfigSteps = [

]
