import {
    getSubscriberDetailsData,
    getSubscriberSummaryData,
    postSubscriberData, postTenantsStatusUpdateData
} from "../../helpers/services/NetworkService";
import {showErrorToast, showSuccessToast} from "../../components/Common/CustomToast";
import {
    all,
    call,
    fork,
    put,
    takeLatest,
    takeEvery
} from "redux-saga/effects";
import {
    GET_SUBSCRIBER_DATA,
    GET_SUBSCRIBER_DATA_FAIL,
    GET_SUBSCRIBER_DATA_SUCCESS,
    GET_SUBSCRIBER_DETAILS_DATA,
    GET_SUBSCRIBER_DETAILS_DATA_FAIL,
    GET_SUBSCRIBER_DETAILS_DATA_LOADER,
    GET_SUBSCRIBER_DETAILS_DATA_SUCCESS,
    GET_SUBSCRIBER_GROUP_DATA,
    GET_SUBSCRIBER_GROUP_DATA_FAIL,
    GET_SUBSCRIBER_GROUP_DATA_SUCCESS,
    GET_SUBSCRIBER_IMAGE_DATA,
    GET_SUBSCRIBER_IMAGE_DATA_FAIL,
    GET_SUBSCRIBER_IMAGE_DATA_SUCCESS,
    GET_TENANTS_DETAILS_DATA_BY_ID,
    GET_TENANTS_DETAILS_DATA_BY_ID_ERROR,
    GET_TENANTS_DETAILS_DATA_BY_ID_SUCCESS,
    POST_SUBSCRIBER_DATA,
    POST_SUBSCRIBER_DATA_FAIL,
    POST_SUBSCRIBER_DATA_LOADER_STOP,
    POST_SUBSCRIBER_DATA_SUCCESS,
    POST_TENANTS_STATUS_UPDATE_DATA,
    POST_TENANTS_STATUS_UPDATE_DATA_FAIL,
    POST_TENANTS_STATUS_UPDATE_DATA_SUCCESS
} from "./actionType";
import axios from "axios";
import {Get_File_URL} from "../../helpers/url_helper";
import {CompanyAllDetails} from "../../helpers/services/AuthService";

function* postSubscriberServer(dataObj) {
    try{
        const response = yield call(postSubscriberData, dataObj.payload);
        yield put({ type: POST_SUBSCRIBER_DATA_SUCCESS, payload: response });
        yield put({ type: POST_SUBSCRIBER_DATA_LOADER_STOP, payload: false });
        showSuccessToast("Subscriber create successfully.");
    } catch (error) {
        yield put({ type: POST_SUBSCRIBER_DATA_LOADER_STOP, payload: false });
        console.error(error);
        showErrorToast(error.message);
        yield put({type: POST_SUBSCRIBER_DATA_FAIL, payload: error});
    }
}

function* getSubscriptionDetailsData({ payload }) {
    yield put({ type: GET_SUBSCRIBER_DETAILS_DATA_LOADER, payload: true });
    try{
        const response = yield call(getSubscriberDetailsData, payload);
        yield put({ type: GET_SUBSCRIBER_DETAILS_DATA_SUCCESS, payload: response });
        yield put({ type: GET_SUBSCRIBER_DETAILS_DATA_LOADER, payload: false });
    } catch (error) {
        console.error(error);
        showErrorToast(error.message);
        yield put({ type: GET_SUBSCRIBER_DETAILS_DATA_FAIL, payload: error});
        yield put({ type: GET_SUBSCRIBER_DETAILS_DATA_LOADER, payload: false });
    }
}

function* getSubscriberSummaryServerData({ payload }) {
    try{
        const response = yield call(getSubscriberSummaryData, payload);
        yield put({ type: GET_SUBSCRIBER_GROUP_DATA_SUCCESS, payload: response });
    } catch (error) {
        showErrorToast(error.message);
        yield put({type: GET_SUBSCRIBER_GROUP_DATA_FAIL, payload: error});
    }
}

function* getSubscriberProfileImage({ payload }) {
    try{
        let imageData = payload;
        const base64Encoded = window.btoa(imageData);
        const response = `${axios.defaults.baseURL}${Get_File_URL}${base64Encoded}`;
        yield put({ type: GET_SUBSCRIBER_IMAGE_DATA_SUCCESS, payload: response });
    } catch (e) {
        showErrorToast(error.message);
        yield put({type: GET_SUBSCRIBER_IMAGE_DATA_FAIL, payload: error});
    }
}

function* getTenantsInfoData({ payload }) {
    try {
        const response = yield call(CompanyAllDetails, payload);
        // logo
        let imageData = response ? response.logoPath : undefined;
        const base64Encoded = window.btoa(imageData);
        // const resImageData = yield call(GetFileSer, base64Encoded);

        if (response !== undefined) {
            response.logo = `${axios.defaults.baseURL}${Get_File_URL}${base64Encoded}`;
        }

        yield put({ type: GET_TENANTS_DETAILS_DATA_BY_ID_SUCCESS, payload: { ...response } });
        // yield put({ type: GET_ALL_COMPANY_SETTINGS_SUCCESS, payload: { ...response, imageData: resImageData } });

    } catch (error) {
        console.log(error, "saga getAllCompanySettings api error");
        yield put({ type: GET_TENANTS_DETAILS_DATA_BY_ID_ERROR , payload: error });
    }
}

function* postTenantsStatusUpdate({ payload }) {
    try{
        const response = yield call(postTenantsStatusUpdateData, payload);
        yield put({ type: POST_TENANTS_STATUS_UPDATE_DATA_SUCCESS, payload: response });
        showSuccessToast(response);
    } catch (error) {
        console.log(error, "saga postTenantsStatusUpdateStatusUpdate api error");
        showErrorToast(error.message);
        yield put({ type: POST_TENANTS_STATUS_UPDATE_DATA_FAIL, payload: error });
    }
}


export function* watchSubscriber() {
    yield takeEvery(POST_SUBSCRIBER_DATA, postSubscriberServer);
    yield takeEvery(GET_SUBSCRIBER_GROUP_DATA, getSubscriberSummaryServerData);
    yield takeEvery(GET_SUBSCRIBER_IMAGE_DATA, getSubscriberProfileImage);
    yield takeEvery(GET_SUBSCRIBER_DETAILS_DATA, getSubscriptionDetailsData);
    yield takeEvery(GET_TENANTS_DETAILS_DATA_BY_ID, getTenantsInfoData);
    yield takeEvery(POST_TENANTS_STATUS_UPDATE_DATA, postTenantsStatusUpdate);
}

function* subscriberSagas() {
    yield all([fork(watchSubscriber)]);
}

export default subscriberSagas;