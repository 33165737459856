import React, {useEffect, useState} from "react";
import {FormFeedback, Input, Label} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {getCustomersPincodeData, getCustomersStateData} from "../../../store/Parties/actions";

const DeliveryAddressForm = ({deliveryAddressFormik}) => {
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);
    const { parties_city_details, parties_state_details, parties_pincode_details } = useSelector(
        (state) => state?.parties
    );

    useEffect(() => {
        if (parties_state_details && parties_state_details?.content?.length > 0) {
            deliveryAddressFormik.setFieldValue("state", parties_state_details?.content[0]?.stateName)
        }

    }, [parties_state_details, parties_pincode_details]);

    useEffect(() => {
        const cityData = parties_city_details?.content?.find((city) => city.cityName === deliveryAddressFormik.values.city);
        if (!!cityData) {
            dispatch(getCustomersStateData({ cityId: cityData.id }));
            dispatch(getCustomersPincodeData({ cityId: cityData.id }));
        }
    }, []);

    return (
        <>
            <div className="container mt-4">
                <div className="d-flex w-100 justify-content-between">
                    <div className="d-flex align-items-center">
                        <h6 className="mb-3" style={{flex: 1}}>Delivery Address</h6>
                        <div className="square-switch" style={{marginLeft: "10px"}}>
                            <Input type="checkbox"
                                   id="square-switch3"
                                   name="deliveryAddressNeeded"
                                   switch="none"
                                   role="switch"
                                   checked={isChecked}
                                   onClick={() => {
                                       deliveryAddressFormik.setFieldValue("deliveryAddressNeeded", !isChecked);
                                       deliveryAddressFormik.setTouched({ deliveryAddressNeeded: true });
                                       // if (!isChecked) {
                                       //     deliveryAddressFormik.resetForm();
                                       // }
                                       setIsChecked(!isChecked);
                                   }}
                            />
                            <Label htmlFor="square-switch3" data-on-label="Yes"
                                   data-off-label="No"></Label>
                        </div>
                    </div>
                </div>
                <div className="collapse-container"
                     style={{
                         maxHeight: isChecked ? "500px" : "0",
                         overflow: "hidden",
                         transition: "max-height 0.5s ease-in-out",
                     }}>
                    <div className="row mt-4">
                        {/* Address Input */}
                        <div className="col-12 mb-3">
                            <label className="form-label">Address <span className="text-danger">*</span></label>
                            <Input type="text" className="form-control" placeholder="Enter address"
                                   name="deliveryAddress"
                                   value={deliveryAddressFormik?.values.deliveryAddress || ""}
                                   onChange={deliveryAddressFormik.handleChange}
                                   onBlur={deliveryAddressFormik.handleBlur}
                                   invalid={
                                       deliveryAddressFormik.touched.deliveryAddress &&
                                       deliveryAddressFormik.errors.deliveryAddress
                                           ? true
                                           : false
                                   }/>
                            {deliveryAddressFormik.touched.deliveryAddress &&
                            deliveryAddressFormik.errors.deliveryAddress ? (
                                <FormFeedback>{deliveryAddressFormik.errors.deliveryAddress}</FormFeedback>
                            ) : null}
                        </div>

                        {/* State and City Inputs */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">State <span className="text-danger">*</span></label>
                            <Input
                                type="text"
                                name="state"
                                list="stateList"
                                value={deliveryAddressFormik?.values?.state || ""}
                                onChange={deliveryAddressFormik.handleChange}
                                onBlur={deliveryAddressFormik.handleBlur}
                                className="form-control"
                                placeholder=""
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">City <span className="text-danger">*</span></label>
                            <Input
                                type="text"
                                name="city"
                                list="cityList"
                                value={deliveryAddressFormik?.values?.city || ""}
                                onChange={(e) => {
                                    deliveryAddressFormik.handleChange(e);
                                    deliveryAddressFormik.setFieldValue('state', '');
                                    deliveryAddressFormik.setFieldValue('zipcode', '');
                                    const cityData = parties_city_details?.content?.find((city) => city.cityName === e.target.value);
                                    if (cityData) {
                                        dispatch(getCustomersStateData({ cityId: cityData.id }));
                                        dispatch(getCustomersPincodeData({ cityId: cityData.id }));
                                    }
                                }
                                }
                                className="form-control"
                                placeholder=""
                                onBlur={deliveryAddressFormik.handleBlur}
                                invalid={deliveryAddressFormik.touched.city && deliveryAddressFormik.errors.city ? true : false}
                            />
                            {deliveryAddressFormik.touched.city && deliveryAddressFormik.errors.city ? (
                                <FormFeedback>{deliveryAddressFormik.errors.city}</FormFeedback>
                            ) : null}
                            <datalist id="cityList">
                                {parties_city_details &&
                                    parties_city_details?.content?.map((item, i) => (
                                        <option key={i} value={item.cityName} />
                                    ))}
                            </datalist>
                        </div>

                        {/* Postal Code Input */}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Postal Code <span className="text-danger">*</span></label>
                            <Input
                                type="text"
                                name="zipcode"
                                list="zipcodeList"
                                value={deliveryAddressFormik?.values?.zipcode || ""}
                                onChange={deliveryAddressFormik.handleChange}
                                className="form-control"
                                placeholder=""
                            />
                            <datalist id="zipcodeList">
                                {parties_pincode_details &&
                                    parties_pincode_details?.content?.map((item, i) => (
                                        <option key={i} value={item.pin} />
                                    ))}
                            </datalist>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default DeliveryAddressForm;