import {
    GET_SUBSCRIPTION_DATA_FAIL,
    GET_SUBSCRIPTION_DATA_SUCCESS,
    GET_SUBSCRIPTION_SELECT_DATA_SUCCESS,
    POST_SUBSCRIPTION_DATA_SUCCESS, SUBSCRIPTION_INITIALIZE, SUBSCRIPTION_LOADER_START, SUBSCRIPTION_LOADER_STOP
} from "./actiontype";

const INITIAL_STATE = {
    subscriptions_data: {},
    subscriptionResponse: {},
    subscriptionSelectDataList: [],
    subscriptionsLoader: ''
}

const subscriptionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUBSCRIPTION_INITIALIZE:
            return {
                ...state,
                subscriptions_data: {},
                subscriptionsLoader: '',
                subscriptionResponse: {},
                subscriptionSelectDataList: [],
            }
        case GET_SUBSCRIPTION_DATA_SUCCESS :
            let subscriptionsData = [];
            action.payload.content.map(item => {
                subscriptionsData.push({...item, noOfModules: item.subscriptionModuleMaps.length });
            })
            action.payload = {...action.payload, content : subscriptionsData};
            return {
                ...state,
                subscriptions_data: action.payload,
            };
        case GET_SUBSCRIPTION_SELECT_DATA_SUCCESS:
            let subscriptionsSelectDataOption = [];
            action.payload.content.map(item => {
                const tempData = {
                    value: item.id,
                    label: item.subscriptionName
                }
                subscriptionsSelectDataOption.push(tempData);
            });
            return {
                ...state,
                subscriptionSelectDataList: subscriptionsSelectDataOption,
            }
        case GET_SUBSCRIPTION_DATA_FAIL :
            return {
                ...state,
            };
        case POST_SUBSCRIPTION_DATA_SUCCESS:
            console.log("POST_SUBSCRIPTION_DATA_SUCCESS", action);
            return {
                ...state,
                subscriptionResponse: action.payload,
            };
        case SUBSCRIPTION_LOADER_START:
            return {
                ...state,
                subscriptionsLoader: true
            }
        case SUBSCRIPTION_LOADER_STOP:
            return {
                ...state,
                subscriptionsLoader: false
            }
        default:
            return state;
    }
}

export default subscriptionReducer;