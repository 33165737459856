export const UPDATE_INSTANT_RATE_SWAP = 'UPDATE_INSTANT_RATE_SWAP'
export const UPDATE_VALUE_BLANK = 'UPDATE_VALUE_BLANK'
export const UPDATE_SEARCH_INSTANT_RATE_DATA = 'UPDATE_SEARCH_INSTANT_RATE_DATA'
export const UPDATE_SEARCH_INSTANT_RATE_DATE = 'UPDATE_SEARCH_INSTANT_RATE_DATE'


export const ADD_OBJECT_INSTANT_SEARCH = 'ADD_OBJECT_INSTANT_SEARCH'
export const REMOVE_OBJECT_INSTANT_SEARCH = 'REMOVE_OBJECT_INSTANT_SEARCH'
export const BLANK_INSTANT_SEARCH = 'BLANK_INSTANT_SEARCH'
export const GET_INSTANT_SEARCH_JSON = 'GET_INSTANT_SEARCH_JSON'


// actionTypes.js
export const GET_INSTANT_RATE_LOCATION = "GET_INSTANT_RATE_LOCATION";
export const GET_INSTANT_RATE_LOCATION_SUCCESS = "GET_INSTANT_RATE_LOCATION_SUCCESS";
export const GET_INSTANT_RATE_LOCATION_FAILURE = "GET_INSTANT_RATE_LOCATION_FAILURE";
export const GET_AIR_LOCATION_TYPE = "GET_AIR_LOCATION_TYPE";
export const GET_AIR_LOCATION_TYPE_SUCCESS = "GET_AIR_LOCATION_TYPE_SUCCESS";


export const GET_ALL_INCOTERM ="GET_ALL_INCOTERM"
export const GET_ALL_INCOTERM_SUCCESS ="GET_ALL_INCOTERM_SUCCESS";


// post
export const POST_INSTANT_SEARCH_DATA_TYPE = 'POST_INSTANT_SEARCH_DATA_TYPE'
export const FILTER_INSTANT_SEARCH_DATA_TYPE = 'FILTER_INSTANT_SEARCH_DATA_TYPE'
export const GET_INSTANT_SEARCH_RESULT_TYPE = 'GET_INSTANT_SEARCH_RESULT_TYPE'
export const GET_INSTANT_SEARCH_RESULT_ID = 'GET_INSTANT_SEARCH_RESULT_ID'
export const GET_DEFAULT_MARGIN = 'GET_DEFAULT_MARGIN'
export const POST_INSTANT_SEARCH_LOADER = 'POST_INSTANT_SEARCH_LOADER'
export const GET_INSTANT_RATE_BY_JSON_RESPONCE="GET_INSTANT_RATE_BY_JSON_RESPONCE"

// Search Result
export const UPDATE_QUOTATION_RESULT_DETAILS = 'UPDATE_QUOTATION_RESULT_DETAILS'
export const QUOTATION_RESULT_SELECTED = 'QUOTATION_RESULT_SELECTED'
export const QUOTATION_RESULT_SELECTED_TENANT = 'QUOTATION_RESULT_SELECTED_TENANT'
export const QUOTATION_RESULT_SELECTED_SUCCESS = 'QUOTATION_RESULT_SELECTED_SUCCESS'


export const QUOTATION_RESULT_SELECTED_BLANK = 'QUOTATION_RESULT_SELECTED_BLANK'
export const QUOTATION_RESULT_UPDATE = 'QUOTATION_RESULT_UPDATE'
export const CONFIRM_PREVIEW_DATA = 'CONFIRM_PREVIEW_DATA'
export const SEARCH_RESULT_FILTER_UPDATE = 'SEARCH_RESULT_FILTER_UPDATE'
export const CLEAR_SEARCH_RESULT_FILTER = 'CLEAR_SEARCH_RESULT_FILTER'
export const QUOTATION_RESULT_REMARK_UPDATE = 'QUOTATION_RESULT_REMARK_UPDATE'


// Air SEARCH
export const INSTANT_RATE_ACTIVE_TAB_TYPE= 'INSTANT_RATE_ACTIVE_TAB_TYPE'

export const GET_INSTANT_AIR_SEARCH_RESULT_DETAILS= 'GET_INSTANT_AIR_SEARCH_RESULT_DETAILS'

export const POST_INSTANT_AIR_SEARCH_DATA='GET_INSTANT_AIR_SEARCH_DATA'
export const POST_INSTANT_AIR_SEARCH_DATA_SUCCESS='GET_INSTANT_AIR_SEARCH_DATA_SUCCESS'

export const FILTER_INSTANT_RATE= 'FILTER_INSTANT_RATE'

export const INSTANT_AIR_CHECKED_DATA_UPDATE = "INSTANT_AIR_CHECKED_DATA_UPDATE"
