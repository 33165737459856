import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_EMAIL_VERIFY,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_OTP_VERIFY,
  FORGET_PASSWORD_PASSWORD_CHANGE,
  FORGET_PASSWORD_SUCCESS,
} from "./actionTypes"
import {REGISTER_EMAIL_VERIFIED} from "../register/actionTypes";

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history },
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}


export const forgetEmailVerified = email => {
  return {
    type: FORGET_PASSWORD_EMAIL_VERIFY,
    payload: email,
  }
}

export const otpVerifiedApiCall = (otpData) => {
  return {
    type: FORGET_PASSWORD_OTP_VERIFY,
    payload: otpData,
  }
}

export const passwordChangeRequest = (payload) => {
  return {
    type: FORGET_PASSWORD_PASSWORD_CHANGE,
    payload: payload,
  }
}
