// Breadcrumbs
export const customersBreadcrumb = [
  {
    label: 'Customers',
    link: '/#',
    active: true
  }
]
export const carrierMasterBreadcrumb = [
  {
    label: 'Carrier Master',
    link: '/#',
    active: true
  }
]
export const addCustomersBreadcrumb = [
  {
    label: 'Customers',
    link: '/#',
    active: false
  },
  {
    label: 'Add Customers',
    link: '/#',
    active: true
  }
]
export const vendorsBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Vendors',
    link: '/#',
    active: true
  }
]
export const addVendorsBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Vendors',
    link: '/#',
    active: false
  },
  {
    label: 'Add Vendors',
    link: '/#',
    active: true
  }
]

export const addCarrierBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Carrier',
    link: '/#',
    active: false
  },
  {
    label: 'Add Carrier',
    link: '/#',
    active: true
  }
]

export const usersBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Users',
    link: '/#',
    active: true
  }
]
export const RolesBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Roles',
    link: '/#',
    active: true
  }
]

export const LeadGenarationBreadcrumb = [
  {
    label: 'Rate Broadcast',
    link: '/#',
    active: true
  }
]

export const SubscriberBreadcrumb = [
  {
    label: 'Subscriber',
    link: '/#',
    active: true
  }
]

export const SubscribersDetailsBreadcrumb = [
  {
    label: 'Subscriber',
    link: '/#',
    active: false
  },
  {
    label: 'Subscriber Details',
    link: '/#',
    active: true
  }
]

export const SubscriptionBreadcrumb = [
  {
    label: 'Subscriptions',
    link: '/#',
    active: true
  }
]

export const AddSubscriptionBreadcrumb = [
  {
    label: 'Subscriptions',
    link: '/#',
    active: false
  },
  {
    label: 'Add New Subscription',
    link: '/#',
    active: true
  }
]

export const EditSubscriptionBreadcrumb = [
  {
    label: 'Subscriptions',
    link: '/#',
    active: false
  },
  {
    label: 'Edit Subscription',
    link: '/#',
    active: true
  }
]

export const addRoleBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Users',
    link: '/#',
    active: false
  },
  {
    label: 'Add Role',
    link: '/#',
    active: true
  }
]

export const editRoleBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Users',
    link: '/#',
    active: false
  },
  {
    label: 'Edit Role',
    link: '/#',
    active: true
  }
]
export const addUserBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Users',
    link: '/#',
    active: false
  },
  {
    label: 'Add Users',
    link: '/#',
    active: true
  }
]
export const EditUserBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Users',
    link: '/#',
    active: false
  },
  {
    label: 'Edit Users',
    link: '/#',
    active: true
  }
]
export const companySettingsBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Company Details',
    link: '/#',
    active: true
  }
]
export const addCompanyBreadcrumb = [
  {
    label: 'Subscriber',
    link: '/#',
    active: false
  },
  {
    label: 'Subscriber List',
    link: '/#',
    active: false
  },
  {
    label: 'Create Company Details',
    link: '/#',
    active: true
  }
]

export const instantRateBookingBreadcrumb = [
  {
    label: 'Instant Rate',
    link: '/#',
    active: false
  },
  {
    label: 'Booking',
    link: '/#',
    active: true
  }
]

export const editCompanyBreadcrumb = [
  {
    label: 'Subscriber',
    link: '/#',
    active: false
  },
  {
    label: 'Subscriber List',
    link: '/#',
    active: false
  },
  {
    label: 'Edit Company Details',
    link: '/#',
    active: true
  }
]

export const marketPlaceConfigBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Market Place Config',
    link: '/#',
    active: true
  }
];


export const addSurchargeSettingsBreadcrumb = [
  {
    label: 'Settings',
    link: '/#',
    active: false
  },
  {
    label: 'Surcharge Master',
    link: '/#',
    active: false
  },
  {
    label: 'Add Surcharge',
    link: '/#',
    active: true
  }
]

// ------------ Customers --------------
export const PartiesCustomersData = [
  {
    id: 1,
    customerCode: "123908AB",
    customerName: "Apex Export Pvt Ltd",
    customerType: "Agent",
    title: "Mr",
    contactName: "Ajay",
    contactNo: "+91 9800012345",
    address: "12, Golden plazza",
    email: "a@gmail.com",
    city: "Banglore",
    state: "Kolkata",
    country: "India",
    zipcode: "123456",
    website: "www.test.in",
    lastTransaction: "4",
    createdOn: "Oct 17, 2023",
    department: "test",
    designation: "test",
    salesEmployee: "test",
    keyAccountManager: "test",
    is_active: true,

    CINnumber: "12345",
    GSTnumber: "24ABCD1234",
    PANnumber: "PAN66AB",
    entityType: "test",
    industryType: "test",

    customerDetails: "test details 123",
    contacts: "12341234",
    documents: "pancard",
    rates: "1000",
    discounts: "10%",
    invoiceSettings: "test",
    invoices: "test invoice",
    communications: "test"
  },
  {
    id: 2,
    customerCode: "123918AB",
    customerName: "Balaji Enterprice",
    customerType: "",
    title: "Mr",
    contactName: "Hitesh",
    contactNo: "+91 9800022345",
    address: "13, Green plazza",
    email: "h@gmail.com",
    city: "Delhi",
    state: "Delhi",
    country: "India",
    zipcode: "123456",
    website: "www.test.in",
    lastTransaction: "5",
    createdOn: "Oct 17, 2023",
    department: "test",
    designation: "test",
    salesEmployee: "test",
    keyAccountManager: "test",
    is_active: true,

    CINnumber: "12345",
    GSTnumber: "24ABCD1234",
    PANnumber: "PAN66AB",
    entityType: "test",
    industryType: "test",

    customerDetails: "test details 123",
    contacts: "12341234",
    documents: "pancard",
    rates: "2000",
    discounts: "10%",
    invoiceSettings: "test",
    invoices: "test invoice",
    communications: "test"
  },
  {
    id: 3,
    customerCode: "123928AB",
    customerName: "House of Tea Exports",
    customerType: "",
    title: "Mr",
    contactName: "Mahendra",
    contactNo: "+91 9800032345",
    address: "22, Silver plazza",
    email: "m@gmail.com",
    city: "Jakarta",
    state: "Jakarta",
    country: "Indonesia",
    zipcode: "123456",
    website: "www.test.in",
    lastTransaction: "6",
    createdOn: "Oct 17, 2023",
    department: "test",
    designation: "test",
    salesEmployee: "test",
    keyAccountManager: "test",
    is_active: false,

    CINnumber: "12345",
    GSTnumber: "24ABCD1234",
    PANnumber: "PAN66AB",
    entityType: "test",
    industryType: "test",

    customerDetails: "test details 123",
    contacts: "12341234",
    documents: "pancard",
    rates: "3000",
    discounts: "10%",
    invoiceSettings: "test",
    invoices: "test invoice",
    communications: "test"
  },
  {
    id: 4,
    customerCode: "123938AB",
    customerName: "Raj Fruits Exports",
    customerType: "",
    title: "Mr",
    contactName: "Mahes",
    contactNo: "+91 9800042345",
    address: "32, Grey plazza",
    email: "m1@gmail.com",
    city: "Dhaka",
    state: "Dhaka",
    country: "Bangladesh",
    zipcode: "123456",
    website: "www.test.in",
    lastTransaction: "6",
    createdOn: "Oct 17, 2023",
    department: "test",
    designation: "test",
    salesEmployee: "test",
    keyAccountManager: "test",
    is_active: true,

    CINnumber: "12345",
    GSTnumber: "24ABCD1234",
    PANnumber: "PAN66AB",
    entityType: "test",
    industryType: "test",

    customerDetails: "test details 123",
    contacts: "12341234",
    documents: "pancard",
    rates: "4000",
    discounts: "10%",
    invoiceSettings: "test",
    invoices: "test invoice",
    communications: "test"
  },

];
export const PartiesVendorsData = [
  {
    id: 1,
    VendorCode: "123908AB",
    VendorName: "Online network",
    VendorType: "Carrier",
    ServiceType: "Ocean",
    title: "Mr",
    contactName: "Rajeev",
    opCode: "+91",
    contactNo: "9800045678",
    address: "12, Golden plazza",
    email: "r1@gmail.com",
    city: "Chennai",
    state: "Tamil Nadu",
    country: "India",
    zipcode: "123456",
    website: "www.test.in",
    lastTransaction: "4",
    createdOn: "Jul 17, 2023",
    department: "test",
    designation: "test",
    is_active: true,

    vendorType: "Carrier",
    transportMode: "Ocean",
    CINnumber: "12345",
    GSTnumber: "24ABCD1234",
    PANnumber: "PAN66AB",
    entityType: "test",
    industryType: "test",

    documentType: "Pancard"

    // customerDetails:"test details 123",
    // contacts:"12341234",
    // documents:"pancard",
    // rates:"1000",
    // discounts:"10%",
    // invoiceSettings:"test",
    // invoices:"test invoice",
    // communications:"test"
  },
  {
    id: 2,
    VendorCode: "123908AB",
    VendorName: "OOCL",
    VendorType: "Carrier",
    ServiceType: "Ocean",
    title: "Mr",
    contactName: "Nair",
    opCode: "+91",
    contactNo: "9800055678",
    address: "12, Viz plazza",
    email: "n@gmail.com",
    city: "Chennai",
    state: "Tamil Nadu",
    country: "India",
    zipcode: "123456",
    website: "www.test.in",
    lastTransaction: "5",
    createdOn: "Jul 17, 2023",
    department: "test",
    designation: "test",
    is_active: true,

    vendorType: "Carrier",
    transportMode: "Ocean",
    CINnumber: "12345",
    GSTnumber: "24ABCD1234",
    PANnumber: "PAN66AB",
    entityType: "test",
    industryType: "test",

    documentType: "Pancard"

    // customerDetails:"test details 123",
    // contacts:"12341234",
    // documents:"pancard",
    // rates:"2000",
    // discounts:"10%",
    // invoiceSettings:"test",
    // invoices:"test invoice",
    // communications:"test"
  },
  {
    id: 3,
    VendorCode: "423908AB",
    VendorName: "ZIM",
    VendorType: "Carrier",
    ServiceType: "Ocean",
    title: "Ms",
    contactName: "Usha",
    opCode: "+91",
    contactNo: "9800065678",
    address: "12, Blue plazza",
    email: "u@gmail.com",
    city: "Chennai",
    state: "Tamil Nadu",
    country: "India",
    zipcode: "123456",
    website: "www.test.in",
    lastTransaction: "6",
    createdOn: "Jul 17, 2023",
    department: "test",
    designation: "test",
    is_active: false,

    vendorType: "Carrier",
    transportMode: "Ocean",
    CINnumber: "12345",
    GSTnumber: "24ABCD1234",
    PANnumber: "PAN66AB",
    entityType: "test",
    industryType: "test",

    documentType: "Pancard"

    // customerDetails:"test details 123",
    // contacts:"12341234",
    // documents:"pancard",
    // rates:"3000",
    // discounts:"10%",
    // invoiceSettings:"test",
    // invoices:"test invoice",
    // communications:"test"
  },

];

const optionCustomerDocumentType = [
  { label: 'PAN', value: 'PAN' },
  { label: 'AADHAAR', value: 'AADHAAR' },
  { label: 'TAN', value: 'TAN' },
  { label: 'COMPANY INCORPORATION CERTIFICATE', value: 'COMPANY_INCORPORATION_CERTIFICATE' },
  { label: 'GST CERTIFICATE', value: 'GST_CERTIFICATE' },
  { label: 'AOA', value: 'AOA' },
  { label: 'MOA', value: 'MOA' },
  { label: 'IMPORT EXPORT CERTIFICATE', value: 'IMPORT_EXPORT_CERTIFICATE' },
]

export {
  optionCustomerDocumentType
}