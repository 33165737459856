import React, {useEffect, useState} from "react";
import TopBreadcrumbs from "../Surcharge/TopBreadcrumbs";
import {addCompanyBreadcrumb} from "../../../common/data/parties";
import {Card, CardBody, Container, FormFeedback, Input, Row} from "reactstrap";
import SimpleBar from "simplebar-react";
import FileUpload from "../FileUpload";
import {useFormik} from "formik";
import { useLoadingBar } from "../../../components/Common/ProgressBar";
import { useMobileView } from "../../../components/Common/CommonLogic";
import Layout from "../../../store/layout/reducer";
import {useSelector} from "react-redux";

const Integrations = () => {
    const isMobile = useMobileView();
    const { start, complete, decrease, increase } = useLoadingBar({ height: 3, });
    const [active, setActive] = useState("emailConfigDetails");
    const [postmarkChecked, setPostMarkChecked] = useState(false);
    const [postMarkFieldShown, setPostMarkFieldShown] = useState(false);
    const [mailchimpChecked, setMailChimpChecked] = useState(false);
    const [mailChimpFieldShown, setMailChimpFieldShown] = useState(false);

    const [libormiChecked, setLibormiChecked] = useState(false);
    const [libormiFieldShown, setLibormiFieldShown] = useState(false);

    const [bhashSmsChecked, setBhashSmsChecked] = useState(false);
    const [bhashSmsFieldShown, setBhashSmsFieldShown] = useState(false);
    const { isAccessMarketPlaceAccess } = useSelector((state) => state?.Layout);

    const onUploadChange = (file) => {
        //subscriberCreateFormik.setFieldValue("image", file);
    };

    useEffect(() => {
        //console.log("isAccessMarketPlaceAccess ", isAccessMarketPlaceAccess);
    }, [isAccessMarketPlaceAccess]);

    const onHandleConfig = (configData) => {
        setActive(configData);
    }

    const onHandleEmailSwitch = (prefix) => {
        switch (prefix) {
            case "postmarkEmail":
                setPostMarkChecked(!postmarkChecked);
                setPostMarkFieldShown(!postmarkChecked);
                break;
            case "mailChimpEmail":
                setMailChimpChecked(!mailchimpChecked)
                setMailChimpFieldShown(!mailchimpChecked);
                break;
            case 'libormiConfig':
                setLibormiChecked(!libormiChecked);
                setLibormiFieldShown(!libormiFieldShown);
                setBhashSmsFieldShown(false);
                setBhashSmsChecked(false);
                break;
            case 'bhashSmsConfig':
                setBhashSmsChecked(!bhashSmsChecked);
                setBhashSmsFieldShown(!bhashSmsChecked);
                setLibormiFieldShown(false);
                setLibormiChecked(false);
                break;
            default:
                return;
        }
    }

    const emailIntegrationsFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            postmarksChecked: postmarkChecked,
            postMarkAccountToken: "",
            postMarkServerToken: "",
            mailChimpChecked: mailchimpChecked,
            mailChimpApiToken: "",
            mailChimpServerPrefix: "",
        },
        onSubmit: (values) => {
            console.log(values);
        }
    })


    return (
        <>
            <div className="page-content settings_wrapper">
                <TopBreadcrumbs breadcrumbs={addCompanyBreadcrumb}/>
                <Container fluid>
                    <button type="button" className="btn border mb-3" onClick={() => {
                        navigate(-1);
                    }}> Back
                    </button>
                    <Row>
                        <div className="col-12 col-md-2">
                            <Card className="h-100">
                                <SimpleBar style={{maxHeight: "100%"}}>
                                    <div id="sidebar-menu" className="settings_sidebar">
                                        <ul className="metismenu list-unstyled" id="side-menu">
                                            <li>
                                                <span>
                                                    <a
                                                        href="#emailConfigDetails"
                                                        onClick={() => {
                                                            onHandleConfig("emailConfigDetails")
                                                        }}
                                                        className={active === "emailConfigDetails" ? "active" : ""}
                                                    >
                                                        Email
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <a
                                                        href="#whatsappConfigDetails"
                                                        onClick={() => {
                                                            onHandleConfig("whatsappConfigDetails")
                                                        }}
                                                        className={active === "whatsappConfigDetails" ? "active" : ""}
                                                    >
                                                        WhatsApp
                                                    </a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </SimpleBar>
                            </Card>
                        </div>

                        {/* ------------------- */}
                        <div className="col-12 col-md-10">
                            <div style={{height: "auto"}}>
                                {/* Comapany details  */}
                                {
                                    active === "emailConfigDetails" &&
                                    <Card id="emailConfigDetails" className="my-4 mb-auto">
                                        <CardBody>
                                            <Card id="postmark">
                                                <CardBody>
                                                    <div className="row mt-4 justify-content-center">
                                                        <div className="col-12 col-md-8 mb-4">
                                                            <div className={isMobile ? 'row justify-content-center' : 'row justify-content-start'}>
                                                                <div className="d-flex image-placeholder bg-light text-muted justify-content-center">
                                                                    <img src={"https://cdn.prod.website-files.com/5f15081919fdf673994ab5fd/63ed91102c07a790c287d1f4_Postmark%20Logo.svg"} alt={"postmarklogo"} />
                                                                </div>
                                                                <div className="d-flex col-12 col-md-3 mb-3 align-items-end justify-content-center">
                                                                    <h2 className="mb-0">Postmark</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 mb-4 align-content-center">
                                                            <div className="d-flex align-items-end justify-content-end">
                                                                <div className="form-check form-switch toggle-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={postmarkChecked || false}
                                                                        onClick={() => onHandleEmailSwitch("postmarkEmail")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {postMarkFieldShown &&
                                                        <>
                                                            <div className="row mt-4 align-items-baseline justify-content-center">
                                                                <div className="col-12 col-md-2 mb-0 align-content-center">
                                                                    <label htmlFor="serverApiToken" className="form-label">Account Token</label>
                                                                </div>
                                                                <div className="col-12 col-md-8 mb-0 align-content-center">
                                                                    <input type="text" id="serverApiToken"
                                                                           name="postMarkAccountToken"
                                                                           value={emailIntegrationsFormik?.values?.postMarkAccountToken || ""}
                                                                           onChange={emailIntegrationsFormik.handleChange}
                                                                           onBlur={emailIntegrationsFormik.handleBlur}
                                                                           className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4 align-items-baseline justify-content-center">
                                                                <div className="col-12 col-md-2 mb-0 align-content-center">
                                                                    <label htmlFor="serverApiToken" className="form-label">Server Token</label>
                                                                </div>
                                                                <div className="col-12 col-md-8 mb-0 align-content-center">
                                                                    <input type="text" id="serverApiToken"
                                                                           name="postMarkServerToken"
                                                                           value={emailIntegrationsFormik?.values?.postMarkServerToken || ""}
                                                                           onChange={emailIntegrationsFormik.handleChange}
                                                                           onBlur={emailIntegrationsFormik.handleBlur}
                                                                           className="form-control" />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </CardBody>
                                            </Card>
                                            <Card id="mailchimp">
                                                <CardBody>
                                                    <div className="row mt-4 justify-content-center">
                                                        <div className="col-12 col-md-8 mb-4">
                                                            <div className={isMobile ? 'row justify-content-center' : 'row justify-content-start'}>
                                                                <div className="d-flex image-placeholder bg-light text-muted justify-content-center">
                                                                    <img src={"https://static-00.iconduck.com/assets.00/mailchimp-icon-2048x2048-7tjwbg42.png"} alt={"mailchinmlogo"} />
                                                                </div>
                                                                <div className="d-flex col-12 col-md-3 mb-3 align-items-end justify-content-center">
                                                                    <h2 className="mb-0">MailChimp</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 mb-4 align-content-center">
                                                            <div className="d-flex align-items-end justify-content-end">
                                                                <div className="form-check form-switch toggle-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={mailchimpChecked || false}
                                                                        onClick={() => onHandleEmailSwitch("mailChimpEmail")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {mailChimpFieldShown &&
                                                        <>
                                                            <div className="row mt-4 align-items-baseline justify-content-center">
                                                                <div className="col-12 col-md-2 mb-0 align-content-center">
                                                                    <label htmlFor="mailChimpApiToken" className="form-label">API
                                                                        Key</label>
                                                                </div>
                                                                <div className="col-12 col-md-8 mb-0 align-content-center">
                                                                    <input type="text" id="mailChimpApiToken"
                                                                           name="mailChimpApiToken"
                                                                           value={emailIntegrationsFormik.values.mailChimpApiToken || ""}
                                                                           onChange={emailIntegrationsFormik.handleChange}
                                                                           onBlur={emailIntegrationsFormik.handleBlur}
                                                                           className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4 align-items-baseline justify-content-center">
                                                                <div className="col-12 col-md-2 mb-0 align-content-center">
                                                                    <label htmlFor="mailChimpServerPrefix" className="form-label">Server
                                                                        Prefix</label>
                                                                </div>
                                                                <div className="col-12 col-md-8 mb-0 align-content-center">
                                                                    <input type="text" id="mailChimpServerPrefix"
                                                                           name="mailChimpServerPrefix"
                                                                           value={emailIntegrationsFormik.values.mailChimpServerPrefix || ""}
                                                                           onChange={emailIntegrationsFormik.handleChange}
                                                                           onBlur={emailIntegrationsFormik.handleBlur}
                                                                           className="form-control" />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </CardBody>
                                            </Card>

                                            <div style={{height: "20px"}}></div>
                                            <div className="mt-4 text-center">
                                                <div className="row">
                                                    <div className="d-flex justify-content-center">
                                                        <div className="mb-3 mx-3 d-flex justify-content-end">
                                                            <button
                                                                onClick={() => emailIntegrationsFormik.handleSubmit()}
                                                                type="submit"
                                                                className=" btn btn-primary">
                                                                Save
                                                            </button>
                                                        </div>
                                                        <div className="mb-3 mx-3 d-flex justify-content-end">
                                                            <button
                                                                onClick={() => emailIntegrationsFormik.resetForm()}
                                                                className=" btn btn-primary">
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                }

                                {/* Bussiness Type */}
                                {
                                    active === "whatsappConfigDetails" &&
                                    <Card id="whatsappConfigDetails" className="my-4 mb-auto">
                                        <CardBody>
                                            <Card id="libormi">
                                                <CardBody>
                                                    <div className="row mt-4 justify-content-center">
                                                        <div className="col-12 col-md-8 mb-4">
                                                            <div className={isMobile ? 'row justify-content-center' : 'row justify-content-start'}>
                                                                <div className="d-flex image-placeholder bg-light text-muted justify-content-center">
                                                                    <img src={"https://www.libromi.com/favicon.png"} alt={"libormilogo"} style={{borderRadius: '100%'}} />
                                                                </div>
                                                                <div className="d-flex col-12 col-md-3 mb-3 align-items-end justify-content-center">
                                                                    <h2 className="mb-0">Libormi</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 mb-4 align-content-center">
                                                            <div className="d-flex align-items-end justify-content-end">
                                                                <div className="form-check form-switch toggle-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={libormiChecked}
                                                                        onChange={(e) => {
                                                                            onHandleEmailSwitch("libormiConfig");
                                                                        }}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {libormiFieldShown &&
                                                        <>
                                                            <div className="row mt-4 align-items-baseline justify-content-center">
                                                                <div className="col-12 col-md-2 mb-0 align-content-center">
                                                                    <label htmlFor="website" className="form-label">Website</label>
                                                                </div>
                                                                <div className="col-12 col-md-8 mb-0 align-content-center">
                                                                    <input type="text" id="website" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4 align-items-baseline justify-content-center">
                                                                <div className="col-12 col-md-2 mb-0 align-content-center">
                                                                    <label htmlFor="metabusinesssuite" className="form-label">Meta Business Suite </label>
                                                                </div>
                                                                <div className="col-12 col-md-8 mb-0 align-content-center">
                                                                    <input type="text" id="metabusinesssuite" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4 align-items-baseline justify-content-center">
                                                                <div className="col-12 col-md-2 mb-0 align-content-center">
                                                                    <label htmlFor="phonenumber" className="form-label">Phone Number</label>
                                                                </div>
                                                                <div className="col-12 col-md-8 mb-0 align-content-center">
                                                                    <input type="text" id="phonenumber" placeholder={"Without using whatsapp number"} className="form-control" />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </CardBody>
                                            </Card>
                                            <Card id="bhashsms">
                                                <CardBody>
                                                    <div className="row mt-4 justify-content-center">
                                                        <div className="col-12 col-md-8 mb-4">
                                                            <div className={isMobile ? 'row justify-content-center' : 'row justify-content-start'}>
                                                                <div className="d-flex image-placeholder bg-light text-muted justify-content-center">
                                                                    <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdIe4Bxmfo1GDQUO-GD9XdaixqVj5OuBHDVw&s"} alt={"bhashsmslogo"} style={{borderRadius: '100%'}} />
                                                                </div>
                                                                <div className="d-flex col-12 col-md-3 mb-3 align-items-end justify-content-center">
                                                                    <h2 className="mb-0">Bhash Sms</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 mb-4 align-content-center">
                                                            <div className="d-flex align-items-end justify-content-end">
                                                                <div className="form-check form-switch toggle-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={bhashSmsChecked}
                                                                        onChange={(e) => {
                                                                            onHandleEmailSwitch("bhashSmsConfig");
                                                                        }}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {bhashSmsFieldShown &&
                                                        <>
                                                            <div className="row mt-4 align-items-baseline justify-content-center">
                                                                <div className="col-12 col-md-2 mb-0 align-content-center">
                                                                    <label htmlFor="apiToken" className="form-label">Server API
                                                                        Token</label>
                                                                </div>
                                                                <div className="col-12 col-md-8 mb-0 align-content-center">
                                                                    <input type="text" id="apiToken" className="form-control" />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </CardBody>
                                            </Card>
                                            <div style={{height: "20px"}}></div>
                                            <div className="mt-4 text-center">
                                                <div className="row">
                                                    <div className="d-flex justify-content-center">
                                                        <div className="mb-3 mx-3 d-flex justify-content-end">
                                                            <button
                                                                //onClick={subscriberCreateFormik.handleSubmit}
                                                                type="submit"
                                                                className=" btn btn-primary">
                                                                Save
                                                            </button>
                                                        </div>
                                                        <div className="mb-3 mx-3 d-flex justify-content-end">
                                                            <button
                                                                //onClick={() => subscriberCreateFormik.resetForm()}
                                                                className=" btn btn-primary">
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                }


                                <div style={{height: "200px"}}></div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Integrations;
