import {get, post} from "../api_helper";
import * as url from "../url_helper";
import {
    SUBSCRIBER_DETAIL_END_POINT,
    SUBSCRIBER_SUMMARY_END_POINT,
    TENANTS_STATUS_UPDATE_END_POINT
} from "../url_helper";



export const postSubscriptionData = payLoad => {
    return post(url.SUBSCRIPTION_END_POINT, payLoad, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
};

export const postSubscriberData = payLoad => {
    return post(url.SUBSCRIBER_END_POINT, payLoad, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}

export const getSubscriptionSummaryData = (data) => get(url.SUBSCRIPTION_END_POINT + data);

export const getSubscriptionAllData = () => get(url.SUBSCRIPTION_END_POINT);

export const getSubscriberServerData = () => get(url.SUBSCRIBER_END_POINT);

export const getSubscriberSummaryData = (data) => get(url.SUBSCRIBER_SUMMARY_END_POINT + data);

export const getSubscriberDetailsData = (data) => get(url.SUBSCRIBER_DETAIL_END_POINT + data);

export const postTenantsStatusUpdateData = (data) => post(url.TENANTS_STATUS_UPDATE_END_POINT + data, "");