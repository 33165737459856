import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
    Card,
    CardBody,
    Container,
    FormFeedback,
    Input,
    Row
} from "reactstrap";
import SimpleBar from "simplebar-react";
import {
    emailType,
    entityType,
    industryType,
    placeOfSupply
} from "../../common/data/settings";
import { isAnyValueEmpty } from "../../components/Common/CommonLogic";
import {
    getBusinessData,
    getCompanyCityData, getCompanyCountryData,
    getCompanyDetailsData, getCompanyPincodeData, getCompanyStateData,
    getTaxDetailsData,
    getTenantInfoData,
    postSettingsCompanyDetailsAction,
    saveCompanyMailConfigurationDetails
} from "../../store/Settings/actions";
import ModalAddGST from "./Modal/ModalAddGST";
import CompanyDetailsForm from "./partials/CompanyDetailsForm";
import { comapanySchema } from "./schema";
import SettingsTaxDetails from "./partials/SettingsTaxDetails";
import { GET_STATE_ALL_TYPE } from "../../store/Global/actiontype";
import TopBreadcrumbs from "./Surcharge/TopBreadcrumbs";
import { companySettingsBreadcrumb } from "../../common/data/parties";
import {companySchema, emailConfigSchema} from "./schema/companySchema";

const Settings = () => {
    const [gstModal, setGstModal] = useState(false);
    const [modalAlldata, setModalAllData] = useState([]);
    const [active, setActive] = useState("comapanyDetails");
    const stateAllData = useSelector((state) => state?.globalReducer?.stateAllData);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("FCL");
    const [showPassword, setShowPassword] = useState(false);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const {
        settings_companydetails_data,
        settings_companyCity_data,
        tenant_info,
        settings_companyState_data,
        settings_companyCountry_data,
        settings_companyPincode_data,
    } = useSelector((state) => state?.settings);

    useEffect(() => {
        dispatch(getCompanyCityData());
        dispatch(getTenantInfoData());
        dispatch({ type: GET_STATE_ALL_TYPE });
    }, []);

    useEffect(() => {
        if (tenant_info !== undefined) {
            console.log(tenant_info, "tenant_info");
            if (tenant_info?.city?.id !== undefined) {
                dispatch(getCompanyStateData({ cityId: tenant_info?.city?.id }));
                dispatch(getCompanyCountryData({ cityId: tenant_info?.city?.id }));
                dispatch(getCompanyPincodeData({ cityId: tenant_info?.city?.id }));
            }
        }
    }, [tenant_info]);

    const companyDetailsFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            image: "",
            firstName : tenant_info !== undefined && tenant_info.firstName || "",
            lastName : tenant_info !== undefined && tenant_info.lastName || "",
            companyName: tenant_info !== undefined && tenant_info?.name || "",
            contactNumber: tenant_info !== undefined && tenant_info?.contactNumber || "",
            email: tenant_info !== undefined && tenant_info?.email || "",
            companyAddress: tenant_info !== undefined && tenant_info?.address || "",
            city: tenant_info !== undefined && tenant_info?.city?.cityName || "",
            state: tenant_info !== undefined && tenant_info?.state?.stateName || "",
            zipcode: tenant_info !== undefined && tenant_info?.pinCode?.pin || "",
            country: tenant_info !== undefined && tenant_info?.country?.countryName || "",
        },
        validationSchema: companySchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: async ({ image, ...value }) => {
            console.log("Hit Company Settings Submitted");
            const pincodeData = settings_companyPincode_data?.content?.find((pinCodeEntry) => pinCodeEntry.pin === value.zipcode) || [];
            const cityData = settings_companyCity_data?.content?.find((city) => city.cityName === value.city) || [];
            const stateData = settings_companyState_data?.content?.find((state) => state.stateName === value.state) || [];
            const countryData = settings_companyCountry_data?.content?.find((con) => con.countryName === value.country) || [];

            const projectUATRequestDTO = {
                ...(tenant_info && {
                    id: tenant_info?.id,
                    version: tenant_info?.version
                }),
                name: value.companyName || "",
                address: value.companyAddress || null,
                ...(pincodeData?.length !== 0 && {
                    pinCode: {
                        version: pincodeData.version,
                        id: pincodeData.id,
                    },
                }),
                ...(cityData?.length !== 0 && {
                    city: {
                        version: cityData.version,
                        id: cityData.id,
                    },
                }),
                ...(stateData?.length !== 0 && {
                    state: {
                        version: stateData.version,
                        id: stateData.id,
                    },
                }),
                ...(countryData?.length !== 0 && {
                    country: {
                        version: countryData.version,
                        id: countryData.id,
                    },
                }),
                logo: "",
                logoPath: image?.path || '',
                contactName: value.companyName || null,
                contactNumber: value.contactNumber || null,
                email: value.email || null,
                firstName: value.firstName,
                lastName: value.lastName,
            };

            //console.log("finaly Company  payload:-", image, projectUATRequestDTO);
            const formData = new FormData();
            formData.append("file", image);
            const jsonBlob = new Blob([JSON.stringify(projectUATRequestDTO)], {
                type: "application/json",
            });
            formData.append("tenant", jsonBlob);
            dispatch(postSettingsCompanyDetailsAction(formData));
        },
    });
    const cstDetailsHandler = (data) => {
        setModalAllData((prev) => [...prev, data]);
    };

    const taxDetailsFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            pan: tenant_info !== undefined && tenant_info?.pan || "",
            cin: tenant_info !== undefined && tenant_info?.cin || "",
            transporterId: tenant_info !== undefined && tenant_info?.transporterId || "",
            no: tenant_info !== undefined && tenant_info?.tenantGSTS?.[0]?.no || "",
            placeOfService: tenant_info !== undefined && tenant_info?.tenantGSTS?.[0]?.placeOfService || "",
            moreGstNumbers: tenant_info !== undefined && tenant_info?.tenantGSTS?.slice(1)?.map((item) => ({
                no: item?.no,
                placeOfService: item?.placeOfService,
                address: item?.address,
                city: item?.city,
                state: item?.state,
                country: item?.country,
                pinCode: item?.pinCode,
            })) || [],
        },
        onSubmit: (values) => {
            let singleVal = values?.no !== "" && values?.placeOfService !== "" && [{ no: values?.no, placeOfService: values?.placeOfService }] || [];
            let mergeArray = [...values?.moreGstNumbers, ...modalAlldata, ...singleVal];

            const payload = {
                id: tenant_info !== undefined && tenant_info?.id || null,
                version: tenant_info !== undefined && tenant_info?.version || null,
                cin: values.cin || null,
                pan: values.pan || null,
                transporterId: values.transporterId || null,
                tenantGSTS: mergeArray?.length !== 0 && mergeArray || [],
            };

            console.log(payload, "payload");

            dispatch(getTaxDetailsData(payload));
        },
    });

    useEffect(() => {
        mailConfigDetailsFormik.setValues({
            emailId: tenant_info?.tenantEmailDetails?.[0]?.emailId || "",
            password: tenant_info?.tenantEmailDetails?.[0]?.password || "",
            smtpDetails: tenant_info?.tenantEmailDetails?.[0]?.smtpDetails || "",
            smtpPort: tenant_info?.tenantEmailDetails?.[0]?.smtpPort || "",
            emailType: tenant_info?.tenantEmailDetails?.[0]?.emailType || "",
        })
    }, [tenant_info]);

    const mailConfigDetailsFormik = useFormik({
        initialValues: {
            emailId: "",
            password: "",
            smtpDetails: "",
            smtpPort: "",
            emailType: "",
        },
        validationSchema: emailConfigSchema,
        onSubmit: (values) => {
            let emailTypeExists = false;
            const updatedTenantEmailDetails = tenant_info?.tenantEmailDetails?.map((data) => {
                if (data?.emailType === values.emailType) {
                    emailTypeExists = true;
                    return {
                        emailId: values.emailId,
                        password: values.password,
                        smtpDetails: values.smtpDetails,
                        smtpPort: values.smtpPort,
                        emailType: values.emailType,
                    };
                }
                return data;
            }) || [];
            if (!emailTypeExists) {
                updatedTenantEmailDetails.push({
                    emailId: values.emailId,
                    password: values.password,
                    smtpDetails: values.smtpDetails,
                    smtpPort: values.smtpPort,
                    emailType: values.emailType,
                });
            }

            const payload = {
                id: tenant_info?.id || '',
                version: tenant_info?.version || '',
                tenantEmailDetails: updatedTenantEmailDetails,
            };

            dispatch(saveCompanyMailConfigurationDetails(payload));
        },
    });

    const onChangeEmailType = (type) => {
        let filterData = tenant_info?.tenantEmailDetails?.find(data => data?.emailType == type)
        mailConfigDetailsFormik.setValues({
            emailId: filterData?.emailId || "",
            password: filterData?.password || "",
            smtpDetails: filterData?.smtpDetails || "",
            smtpPort: filterData?.smtpPort || "",
            emailType: type || "",
        })
    }

    const onCloseClick = () => {
        setGstModal(false);
    };

    const bussinessTypeFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            industryType: tenant_info !== undefined ? tenant_info?.industryType : industryType.find((item) => item.value === tenant_info?.industryType).value,
            entityType: tenant_info?.entityType ?? entityType.find((item) => item.value === tenant_info?.entityType)?.value ?? "PRIVATE_LTD",
        },
        onSubmit: (value) => {
            const projectUATRequestDTO = {
                id: tenant_info !== undefined && tenant_info?.id || null,
                version: tenant_info !== undefined && tenant_info?.version || null,
                entityType: value.entityType || null,
                industryType: value.industryType || null,
            };
            //console.log(projectUATRequestDTO , "projectUATRequestDTO");
            dispatch(getBusinessData(projectUATRequestDTO));
        },
    });

    return (
        <>
            <div className="page-content settings_wrapper">
                <TopBreadcrumbs breadcrumbs={companySettingsBreadcrumb} />
                <Container fluid>
                    <Row>
                        <div className="col-12 col-md-2">
                            <Card className="h-100">
                                <SimpleBar style={{ maxHeight: "100%" }}>
                                    <div id="sidebar-menu" className="settings_sidebar">
                                        <ul className="metismenu list-unstyled" id="side-menu">
                                            <li>
                                                <span>
                                                    <a
                                                        href="#comapanyDetails"
                                                        onClick={() => { setActive("comapanyDetails"); }}
                                                        className={active === "comapanyDetails" ? "active" : ""}
                                                    >
                                                        Company Details
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <a
                                                        href="#taxDetails"
                                                        onClick={() => { setActive("taxDetails"); }}
                                                        className={active === "taxDetails" ? "active" : ""}
                                                    >
                                                        Tax Details
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <a
                                                        href="#bussinessType"
                                                        onClick={() => { setActive("bussinessType"); }}
                                                        className={active === "bussinessType" ? "active" : ""}
                                                    >
                                                        Bussiness Type
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <a
                                                        href="#emailConfiguration"
                                                        onClick={() => { setActive("emailConfiguration"); }}
                                                        className={active === "emailConfiguration" ? "active" : ""}
                                                    >
                                                        Email Configuration
                                                    </a>
                                                </span>
                                            </li>
                                            {/* ------disabled options--------- */}
                                            <li>
                                                <span className="opacity-50">
                                                    <a>Security</a>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="opacity-50">
                                                    <a>Branding</a>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="opacity-50">
                                                    <a>Invoice Themes</a>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="opacity-50">
                                                    <a>Delete Account</a>
                                                </span>
                                            </li>
                                            {/* ------disabled options--------- */}
                                        </ul>
                                    </div>
                                </SimpleBar>
                            </Card>
                        </div>

                        {/* ------------------- */}
                        <div className="col-12 col-md-10">
                            <Card className="">
                                <PerfectScrollbar className="p-4" style={{ height: "802px" }}>
                                    {/* Comapany details  */}
                                    <CompanyDetailsForm companyDetailsFormik={companyDetailsFormik} />

                                    {/* Tax Details  */}
                                    <SettingsTaxDetails taxDetailsFormik={taxDetailsFormik} setGstModal={setGstModal} />

                                    {/* Bussiness Type */}
                                    <Card id="bussinessType" className="my-4 mb-auto">
                                        <CardBody>
                                            <div>
                                                <h5>Bussiness Type</h5>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-12 col-md-6 mb-4">
                                                    <label className="form-label">Industry Type<span className='required_star'>*</span></label>
                                                    <Select
                                                        value={industryType ? industryType.find((option) => option.value === bussinessTypeFormik?.values?.industryType) : ""}
                                                        name="industryType"
                                                        onChange={(e) => {
                                                            bussinessTypeFormik.setFieldValue("industryType", e.value);
                                                        }}
                                                        options={industryType}
                                                        placeholder={"Enter Industry Type"}
                                                        classNamePrefix="select2-selection form-select"
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 mb-4">
                                                    <label className="form-label">Entity Type<span className='required_star'>*</span></label>
                                                    <Select
                                                        value={entityType ? entityType.find((option) => option.value === bussinessTypeFormik?.values?.entityType) : ""}
                                                        name="entityType"
                                                        onChange={(e) => {
                                                            bussinessTypeFormik.setFieldValue("entityType", e.value);
                                                        }}
                                                        options={entityType}
                                                        placeholder={"Enter Entity Type"}
                                                        classNamePrefix="select2-selection form-select"
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="d-flex justify-content-center">
                                                    <div className="mb-3 mx-3 d-flex justify-content-end">
                                                        <button
                                                            onClick={bussinessTypeFormik.handleSubmit}
                                                            className=" btn btn-primary"
                                                            disabled={isAnyValueEmpty(
                                                                bussinessTypeFormik.values
                                                            )}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                    <div className="mb-3 mx-3 d-flex justify-content-end">
                                                        <button
                                                            onClick={() => bussinessTypeFormik.resetForm()}
                                                            className=" btn btn-primary">
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card id="emailConfiguration" className="my-4 mb-auto">
                                        <CardBody>
                                            <div>
                                                <h5>Email Configuration</h5>
                                            </div>
                                            <div className="col-12 col-md-12 mt-4">
                                                <label className="form-label">Email Type<span className='required_star'>*</span></label>
                                                <Select
                                                    value={emailType.find((option) => option.value === mailConfigDetailsFormik.values.emailType)}
                                                    name="emailType"
                                                    onChange={(option) => onChangeEmailType(option.value)}
                                                    options={emailType}
                                                    placeholder={"Email Type"}
                                                    classNamePrefix="select2-selection form-select"
                                                    onBlur={mailConfigDetailsFormik.handleBlur}
                                                />
                                                {mailConfigDetailsFormik.touched.emailType && mailConfigDetailsFormik.errors.emailType ? (
                                                    <FormFeedback type="invalid">{mailConfigDetailsFormik.errors.emailType}</FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-12 col-md-6 mb-3">
                                                    <label className="form-label">Email Id<span className='required_star'>*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email Id"
                                                        name="emailId"
                                                        value={mailConfigDetailsFormik.values.emailId}
                                                        onChange={mailConfigDetailsFormik.handleChange}
                                                        onBlur={mailConfigDetailsFormik.handleBlur}
                                                    />
                                                    {mailConfigDetailsFormik.touched.emailId && mailConfigDetailsFormik.errors.emailId ? (
                                                        <FormFeedback type="invalid">{mailConfigDetailsFormik.errors.emailId}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="col-12 col-md-6 mb-3 ">
                                                    <label className="form-label">Password<span className='required_star'>*</span></label>
                                                    <div className="input-group">
                                                        <input
                                                            type={showPassword ? "text" : "password"}
                                                            className="form-control"
                                                            placeholder="Password"
                                                            name="password"
                                                            value={mailConfigDetailsFormik.values.password}
                                                            onChange={mailConfigDetailsFormik.handleChange}
                                                            onBlur={mailConfigDetailsFormik.handleBlur}
                                                        />
                                                        <button onClick={() => setShowPassword(!showPassword)} className={`btn btn-light shadow-none ms-0 ${showPassword ? 'show' : ''}`} type="button" id="password-addon"> <i className="bi bi-eye"></i> </button>
                                                        {mailConfigDetailsFormik.touched.password && mailConfigDetailsFormik.errors.password ? (
                                                            <FormFeedback type="invalid">{mailConfigDetailsFormik.errors.password}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 col-md-6 mb-3">
                                                    <label className="form-label">Smtp Server Details<span className='required_star'>*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Server Details"
                                                        name="smtpDetails"
                                                        value={mailConfigDetailsFormik.values.smtpDetails}
                                                        onChange={mailConfigDetailsFormik.handleChange}
                                                        onBlur={mailConfigDetailsFormik.handleBlur}
                                                    />
                                                    {mailConfigDetailsFormik.touched.smtpDetails && mailConfigDetailsFormik.errors.smtpDetails ? (
                                                        <FormFeedback type="invalid">{mailConfigDetailsFormik.errors.smtpDetails}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="col-12 col-md-6 mb-3">
                                                    <label className="form-label">Smtp Port<span className='required_star'>*</span></label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Port"
                                                        name="smtpPort"
                                                        value={mailConfigDetailsFormik.values.smtpPort}
                                                        onChange={mailConfigDetailsFormik.handleChange}
                                                        onBlur={mailConfigDetailsFormik.handleBlur}
                                                    />
                                                    {mailConfigDetailsFormik.touched.smtpPort && mailConfigDetailsFormik.errors.smtpPort ? (
                                                        <FormFeedback type="invalid">{mailConfigDetailsFormik.errors.smtpPort}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="d-flex justify-content-center mt-4">
                                                    <div className="mb-3 mx-3 d-flex justify-content-end">
                                                        <button type="submit" onClick={mailConfigDetailsFormik.handleSubmit} className="btn btn-primary">
                                                            Save
                                                        </button>
                                                    </div>
                                                    <div className="mb-3 mx-3 d-flex justify-content-end">
                                                        <button type="button" className="btn btn-secondary" onClick={mailConfigDetailsFormik.handleReset}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>

                                    <div style={{ height: "525px" }}></div>
                                </PerfectScrollbar>
                            </Card>
                        </div>
                    </Row>
                    <ModalAddGST
                        modal={gstModal}
                        onSubmitHandler={cstDetailsHandler}
                        onCloseClick={onCloseClick}
                    />
                </Container>
            </div>
        </>
    );
};

export default Settings;
