export const SUBSCRIBER_INITIALIZE = "SUBSCRIBER_INITIALIZE";

export const POST_SUBSCRIBER_DATA="POST_SUBSCRIBER_DATA";
export const POST_SUBSCRIBER_DATA_SUCCESS = 'POST_SUBSCRIBER_DATA_SUCCESS';
export const POST_SUBSCRIBER_DATA_FAIL = 'POST_SUBSCRIBER_DATA_FAIL';
export const POST_SUBSCRIBER_DATA_LOADER_START = "POST_SUBSCRIBER_DATA_LOADER_START"
export const POST_SUBSCRIBER_DATA_LOADER_STOP = "POST_SUBSCRIBER_DATA_LOADER_STOP"

export const GET_SUBSCRIBER_DATA="GET_SUBSCRIBER_DATA";
export const GET_SUBSCRIBER_DATA_SUCCESS = 'GET_SUBSCRIBER_DATA_SUCCESS';
export const GET_SUBSCRIBER_DATA_FAIL = 'GET_SUBSCRIBER_DATA_FAIL';

export const GET_SUBSCRIBER_GROUP_DATA="GET_SUBSCRIBER_GROUP_DATA";
export const GET_SUBSCRIBER_GROUP_DATA_SUCCESS = 'GET_SUBSCRIBER_GROUP_DATA_SUCCESS';
export const GET_SUBSCRIBER_GROUP_DATA_FAIL = 'GET_SUBSCRIBER_GROUP_DATA_FAIL';

export const GET_SUBSCRIBER_DETAILS_DATA= "GET_SUBSCRIBER_DETAILS_DATA_DATA";
export const GET_SUBSCRIBER_DETAILS_DATA_LOADER = "GET_SUBSCRIBER_DETAILS_DATA_LOADER";
export const GET_SUBSCRIBER_DETAILS_DATA_SUCCESS = 'GET_SUBSCRIBER_DETAILS_DATA_SUCCESS';
export const GET_SUBSCRIBER_DETAILS_DATA_FAIL = 'GET_SUBSCRIBER_DETAILS_DATA_FAIL';

export const GET_SUBSCRIBER_IMAGE_DATA = "GET_SUBSCRIBER_IMAGE_DATA";
export const GET_SUBSCRIBER_IMAGE_DATA_SUCCESS = "GET_SUBSCRIBER_IMAGE_DATA_SUCCESS";
export const GET_SUBSCRIBER_IMAGE_DATA_FAIL = "GET_SUBSCRIBER_IMAGE_DATA_FAIL";

export const GET_TENANTS_DETAILS_DATA_BY_ID= "GET_TENANTS_DETAILS_DATA_BY_ID";
export const GET_TENANTS_DETAILS_DATA_BY_ID_SUCCESS= "GET_TENANTS_DETAILS_DATA_BY_ID_SUCCESS";
export const GET_TENANTS_DETAILS_DATA_BY_ID_ERROR= "GET_TENANTS_DETAILS_DATA_BY_ID_ERROR";

export const POST_TENANTS_STATUS_UPDATE_DATA = "POST_TENANTS_STATUS_UPDATE_DATA";
export const POST_TENANTS_STATUS_UPDATE_DATA_SUCCESS = "POST_TENANTS_STATUS_UPDATE_DATA_SUCCESS";
export const POST_TENANTS_STATUS_UPDATE_DATA_FAIL = 'POST_TENANTS_STATUS_UPDATE_DATA_FAIL';