import React, {useEffect, useState} from "react";
import TopBreadcrumbs from "../Settings/Surcharge/TopBreadcrumbs";
import {addCompanyBreadcrumb} from "../../common/data/parties";
import {Card, CardBody, Container, FormFeedback, Input, Row} from "reactstrap";
import SimpleBar from "simplebar-react";
import Select from "react-select";
import {country_list, entityType, industryType, state_list} from "../../common/data/settings";
import {generateRandomPassword} from "../../components/Common/CommonLogic";
import {useDispatch, useSelector} from "react-redux";
import {
    getCompanyCountryData, getCompanyPincodeData, getCompanyStateData,
} from "../../store/Settings/actions";
import {useFormik} from "formik";

import {createCompanySchema, updateCompanySchema} from "../Settings/schema/companySchema";
import FileUpload from "../Settings/FileUpload";
import {GET_SUBSCRIPTION_SELECT_DATA} from "../../store/Subscriptions/actiontype";
import {postSubscriberData} from "../../store/Subscribers/actions";
import {useNavigate} from "react-router-dom";
import {DomesticsTabName, FclTabName, InternationTabName, LclTabName} from "../Dashboard/Partials/initialData";
import {buyersLocationsList} from "../../common/data/common";
import { useLoadingBar } from "../../components/Common/ProgressBar";
import { POST_SUBSCRIBER_DATA_LOADER_START, SUBSCRIBER_INITIALIZE } from "../../store/Subscribers/actionType";

const AddSubscriber = () => {
    const { start, complete, decrease, increase } = useLoadingBar({ height: 3, });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState("comapanyDetails");
    const { country_master } = useSelector(state => state?.globalReducer);
    const { subscriptionSelectDataList } = useSelector((state) => state.subscriptionsReducer);
    const { settings_companyCity_data, settings_companyState_data,tenant_info, settings_companyCountry_data, settings_companyPincode_data, } = useSelector((state) => state?.settings);
    const [showPassword, setShowPassword] = useState(false);
    const [subscriptionsValue, setSubscriptionsValue] = useState({});
    const [fclChecked, setFclChecked] = useState(false);
    const [lclChecked, setLclChecked] = useState(false);
    const [iAFChecked, setIafChecked] = useState(false);
    const [dAFChecked, setDAFChecked] = useState(false);
    const [tenantMarketPlaces, setTenantMarketPlaces] = useState([]);
    const [buyersLocations, setBuyersLocations] = useState({value: "", label: "--Select Location--"});
    const [selectedCountryOptions, setSelectedCountryOptions] = useState([]);
    const { progressBarLoader } = useSelector((state) => state.subscriberReducer);

    useEffect(() => {
        if (settings_companyState_data && settings_companyState_data?.content?.length > 0) {
            subscriberCreateFormik.setFieldValue("state", settings_companyState_data?.content[0]?.stateName || "")
        }
        if (settings_companyCountry_data && settings_companyCountry_data?.content?.length > 0) {
            subscriberCreateFormik.setFieldValue("country", settings_companyCountry_data?.content[0]?.countryName || "")
        }

        dispatch({ type:  GET_SUBSCRIPTION_SELECT_DATA});

        setSubscriptionsValue({
            value: "Select Subscriptions",
            label: "Select Subscriptions"
        });
    }, [settings_companyState_data, settings_companyCountry_data]);


    // Company Profile Section Start
    const onUploadChange = (file) => {
        subscriberCreateFormik.setFieldValue("image", file);
    };

    const generatePassword = () => {
        const autoPassword = generateRandomPassword(10);
        subscriberCreateFormik.setFieldValue("password", autoPassword);
        subscriberCreateFormik.setFieldValue("passwordConfirmation", autoPassword);
    }

    const subscriberCreateFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            image: "",
            companyName: "",
            firstName: "",
            lastName:  "",
            contactNumber: "",
            email: "",
            password:  "",
            passwordConfirmation:  "",
            industryType: industryType.find((item) => item.value === 'AGENT').value,
            entityType: entityType.find((item) => item.value === 'PRIVATE_LTD').value,
            subscriptionId : "",
            city: "",
            state: "",
            zipcode: "",
            country: "",
            fclChecked: false,
            lclChecked: false,
            airFChecked: false,
            domFChecked: false,
        },
        validationSchema: createCompanySchema,
        onSubmit: async ({ image, ...value }) => {
            dispatch({ type: POST_SUBSCRIBER_DATA_LOADER_START});
            setLoading(true);
            let geoLocations = [];

            selectedCountryOptions.map((item) => {
                geoLocations.push({
                    countryId: item.id,
                    cityId: 0,
                    buyersLocationType: value["buyersLocations"] || ""
                })
            });
            const cityData = settings_companyCity_data?.content?.find((city) => city.cityName === value.city) || [];
            const stateData = settings_companyState_data?.content?.find((state) => state.stateName === value.state) || [];
            const countryData = settings_companyCountry_data?.content?.find((con) => con.countryName === value.country) || [];
            const pinCodeData = settings_companyPincode_data?.content?.find((pinCodeEntry) => pinCodeEntry.pin === value.zipcode) || [];
            const tenant_data = {
                ...(pinCodeData?.length !== 0 && {
                    pinCode: {
                        version: pinCodeData.version,
                        id: pinCodeData.id,
                    },
                }),
                ...(cityData?.length !== 0 && {
                    city: {
                        version: cityData.version,
                        id: cityData.id,
                    },
                }),
                ...(stateData?.length !== 0 && {
                    state: {
                        version: stateData.version,
                        id: stateData.id,
                    },
                }),
                ...(countryData?.length !== 0 && {
                    country: {
                        version: countryData.version,
                        id: countryData.id,
                    },
                }),
                name: value.companyName || "",
                firstName: value.firstName || null,
                lastName: value.lastName || null,
                logo: "",
                logoPath: image?.path || '',
                contactName: value.companyName || null,
                contactNumber: value.contactNumber || null,
                email: value.email || null,
                industryType: value.industryType || null,
                entityType: value.entityType || null,
                subscriptionId: value.subscriptionId || null,
                tenantMarketPlaces: tenantMarketPlaces,
                tenantMarketPlaceVisibilityGeolocations: geoLocations
            }



            const tenantDTO = {
                firstName: value.firstName || null,
                lastName: value.lastName || null,
                email: value.email || null,
                password: value.password || null,
                tenant: tenant_data,
                id: "",
                version: "0",
            };

            //console.log("finally Company  payload:-", image, tenantDTO);
            const formData = new FormData();
            formData.append("file", image);
            const jsonBlob = new Blob([JSON.stringify(tenantDTO)], {
                type: "application/json",
            });
            formData.append("tenant", jsonBlob);
            dispatch(postSubscriberData(formData));
            /**/
        },
    });

    useEffect(() => {
        if (progressBarLoader === true) {
            start();
        }
        if (progressBarLoader === false) {
            dispatch({ type: SUBSCRIBER_INITIALIZE });
            navigate('/network/subscriber');
            setTimeout(() => {
                complete();
            }, 1500);
        }

    }, [progressBarLoader])

    const addOrUpdateItem = (newItem) => {
        setTenantMarketPlaces(prevItems => {
            const index = prevItems.findIndex(item => item.serviceType === newItem.serviceType);
            if (index === -1) {
                // Item doesn't exist, add it to the array
                return [...prevItems, newItem];
            } else {
                // Item exists, update it
                const updatedItems = [...prevItems];
                updatedItems[index] = newItem;
                return updatedItems;
            }
        });
    };

    return(
        <>
            <div className="page-content settings_wrapper">
                <TopBreadcrumbs breadcrumbs={addCompanyBreadcrumb}/>
                <Container fluid>
                    <button type="button" className="btn border mb-3" onClick={() => {
                        navigate(-1);
                    }}> Back
                    </button>
                    <Row>
                        <div className="col-12 col-md-2">
                            <Card className="h-100">
                                <SimpleBar style={{maxHeight: "100%"}}>
                                    <div id="sidebar-menu" className="settings_sidebar">
                                        <ul className="metismenu list-unstyled" id="side-menu">
                                            <li>
                                                <span>
                                                    <a
                                                        href="#comapanyDetails"
                                                        onClick={() => {
                                                            setActive("comapanyDetails");
                                                        }}
                                                        className={active === "comapanyDetails" ? "active" : ""}
                                                    >
                                                        Company Details
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <a
                                                        href="#bussinessType"
                                                        onClick={() => {
                                                            setActive("bussinessType");
                                                        }}
                                                        className={active === "bussinessType" ? "active" : ""}
                                                    >
                                                        Bussiness Type
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <a
                                                        href="#subscription"
                                                        onClick={() => {
                                                            setActive("subscription");
                                                        }}
                                                        className={active === "subscription" ? "active" : ""}
                                                    >
                                                        Subscription
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <a
                                                        href="#marketplace"
                                                        onClick={() => {
                                                            setActive("marketplace");
                                                        }}
                                                        className={active === "marketplace" ? "active" : ""}
                                                    >
                                                        Market Place
                                                    </a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </SimpleBar>
                            </Card>
                        </div>

                        {/* ------------------- */}
                        <div className="col-12 col-md-10">
                            <div style={{height: "auto"}}>
                                {/* Comapany details  */}
                                <Card id="comapanyDetails" className="mb-4">
                                    <CardBody>
                                        <div>
                                            <h5>Company Details</h5>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12 col-md-4 mb-4">
                                                <label className="form-label">Image</label>
                                                <FileUpload
                                                    iconName="img"
                                                    name="image"
                                                    onUpload={onUploadChange}
                                                    src={subscriberCreateFormik?.values?.image || ""}
                                                    className="form-control"
                                                />
                                            </div>

                                            <div className="col-12 col-md-8 mb-4">
                                                <label className="form-label">Company Name<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="companyName"
                                                    value={subscriberCreateFormik?.values?.companyName || ""}
                                                    onChange={subscriberCreateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                {subscriberCreateFormik.touched.companyName &&
                                                subscriberCreateFormik.errors.companyName ? (
                                                    <FormFeedback type="invalid">
                                                        {subscriberCreateFormik.errors.companyName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">First Name<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="firstName"
                                                    value={subscriberCreateFormik?.values?.firstName || ""}
                                                    onChange={subscriberCreateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                {subscriberCreateFormik.touched.firstName &&
                                                subscriberCreateFormik.errors.firstName ? (
                                                    <FormFeedback type="invalid">
                                                        {subscriberCreateFormik.errors.firstName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Last Name<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="lastName"
                                                    value={subscriberCreateFormik?.values?.lastName || ""}
                                                    onChange={subscriberCreateFormik.handleChange}
                                                    onBlur={subscriberCreateFormik.handleBlur}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                {subscriberCreateFormik.touched.lastName &&
                                                subscriberCreateFormik.errors.lastName ? (
                                                    <FormFeedback type="invalid">
                                                        {subscriberCreateFormik.errors.lastName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Contact Number<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="contactNumber"
                                                    value={subscriberCreateFormik?.values?.contactNumber || ""}
                                                    onChange={subscriberCreateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                {subscriberCreateFormik.touched.contactNumber &&
                                                subscriberCreateFormik.errors.contactNumber ? (
                                                    <FormFeedback type="invalid">
                                                        {subscriberCreateFormik.errors.contactNumber}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Email id<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="email"
                                                    value={subscriberCreateFormik?.values?.email || ""}
                                                    onChange={subscriberCreateFormik.handleChange}
                                                    onBlur={subscriberCreateFormik.handleBlur}
                                                    className="form-control"
                                                    invalid={
                                                        subscriberCreateFormik.touched.email &&
                                                        subscriberCreateFormik.errors.email
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder=""
                                                />
                                                {subscriberCreateFormik.touched.email &&
                                                subscriberCreateFormik.errors.email ? (
                                                    <FormFeedback type="invalid">
                                                        {subscriberCreateFormik.errors.email}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Password<span
                                                    className='required_star'>*</span></label>
                                                <div className="input-group">
                                                    <Input
                                                        type={showPassword ? "text" : "password"}
                                                        name="password"
                                                        value={subscriberCreateFormik?.values?.password || ""}
                                                        onChange={subscriberCreateFormik.handleChange}
                                                        onBlur={subscriberCreateFormik.handleBlur}
                                                        className="form-control"
                                                        placeholder="Password"
                                                    />
                                                    <button onClick={() => setShowPassword(!showPassword)}
                                                            className={`btn btn-light shadow-none ms-0 ${showPassword ? 'show' : ''}`}
                                                            type="button" id="password-addon"><i
                                                        className="far fa-eye"></i>
                                                    </button>
                                                    {subscriberCreateFormik.touched.password &&
                                                    subscriberCreateFormik.errors.password ? (
                                                        <FormFeedback type="invalid">
                                                            {subscriberCreateFormik.errors.password}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Confirm Password<span
                                                    className='required_star'>*</span></label>
                                                <div className="input-group">
                                                    <Input
                                                        type={"password"}
                                                        name="passwordConfirmation"
                                                        value={subscriberCreateFormik?.values?.passwordConfirmation || ""}
                                                        onChange={subscriberCreateFormik.handleChange}
                                                        onBlur={subscriberCreateFormik.handleBlur}
                                                        className="form-control"
                                                        placeholder="ConfirmPassword"
                                                    />
                                                    <button type="button" onClick={() => generatePassword()}
                                                            className="btn btn-primary btn-sm waves-effect waves-light">
                                                        Generate password
                                                    </button>
                                                </div>

                                                {subscriberCreateFormik.touched.passwordConfirmation &&
                                                subscriberCreateFormik.errors.passwordConfirmation ? (
                                                    <FormFeedback type="invalid">
                                                        {subscriberCreateFormik.errors.passwordConfirmation}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">City<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="city"
                                                    list="cityList"
                                                    value={subscriberCreateFormik?.values?.city || ""}
                                                    onChange={(e) => {
                                                        subscriberCreateFormik.handleChange(e);
                                                        subscriberCreateFormik.setFieldValue('state', '');
                                                        subscriberCreateFormik.setFieldValue('country', '');
                                                        subscriberCreateFormik.setFieldValue('zipcode', '');
                                                        const cityData = settings_companyCity_data?.content?.find((city) => city.cityName === e.target.value);
                                                        if (cityData) {
                                                            dispatch(getCompanyStateData({cityId: cityData.id}));
                                                            dispatch(getCompanyCountryData({cityId: cityData.id}));
                                                            dispatch(getCompanyPincodeData({cityId: cityData.id}));
                                                        }
                                                    }}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <datalist id="cityList">
                                                    {settings_companyCity_data &&
                                                        settings_companyCity_data?.content?.map((item, i) => (
                                                            <option key={i} value={item.cityName}/>
                                                        ))}
                                                </datalist>
                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">State</label>
                                                <Input
                                                    type="text"
                                                    name="state"
                                                    list="stateList"
                                                    value={subscriberCreateFormik?.values?.state || ''}
                                                    onChange={subscriberCreateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <datalist id="stateList">
                                                    {state_list &&
                                                        state_list.map((item, i) => <option key={i} value={item}/>)}
                                                </datalist>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Zipcode</label>
                                                <Input
                                                    type="text"
                                                    name="zipcode"
                                                    list="zipcodeList"
                                                    value={subscriberCreateFormik?.values?.zipcode || ""}
                                                    onChange={subscriberCreateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <datalist id="zipcodeList">
                                                    {settings_companyPincode_data &&
                                                        settings_companyPincode_data?.content?.map((item, i) => (
                                                            <option key={i} value={item.pin}/>
                                                        ))}
                                                </datalist>
                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Country<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="country"
                                                    list="countryList"
                                                    value={subscriberCreateFormik?.values?.country || ""}
                                                    onChange={subscriberCreateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <datalist id="countryList">
                                                    {country_list &&
                                                        country_list.map((item, i) => (
                                                            <option key={i} value={item}/>
                                                        ))}
                                                </datalist>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                {/* Bussiness Type */}
                                <Card id="bussinessType" className="my-4 mb-auto">
                                    <CardBody>
                                        <div>
                                            <h5>Bussiness Type</h5>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Industry Type<span
                                                    className='required_star'>*</span></label>
                                                <Select
                                                    value={industryType ? industryType.find((option) => option.value === subscriberCreateFormik?.values?.industryType) : ""}
                                                    name="industryType"
                                                    onChange={(e) => {
                                                        subscriberCreateFormik.setFieldValue(industryType, e.value);
                                                    }}
                                                    options={industryType}
                                                    placeholder={"Enter Industry Type"}
                                                    classNamePrefix="select2-selection form-select"
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Entity Type<span
                                                    className='required_star'>*</span></label>
                                                <Select
                                                    value={entityType ? entityType.find((option) => option.value === subscriberCreateFormik?.values?.entityType) : ""}
                                                    name="entityType"
                                                    onChange={(e) => {
                                                        subscriberCreateFormik.setFieldValue(entityType, e.value);
                                                    }}
                                                    options={entityType}
                                                    placeholder={"Enter Entity Type"}
                                                    classNamePrefix="select2-selection form-select"
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                {/* Subscription Type */}
                                <Card id="subscription" className="my-4 mb-auto">
                                    <CardBody>
                                        <div>
                                            <h5>Subscription</h5>
                                        </div>
                                        <div className="col-12 col-md-12 mt-4">
                                            <label className="form-label">Subscriptions Type<span
                                                className='required_star'>*</span></label>
                                            <Select
                                                value={subscriptionsValue}
                                                options={subscriptionSelectDataList}
                                                name="subscriptionId"
                                                onChange={(e) => {
                                                    setSubscriptionsValue(e);
                                                    subscriberCreateFormik.setFieldValue("subscriptionId", e.value);
                                                }}
                                                placeholder={"Select Subscription Type"}
                                                classNamePrefix="select2-selection form-select"
                                            />
                                            {subscriberCreateFormik.touched.subscriptionId &&
                                            subscriberCreateFormik.errors.subscriptionId ? (
                                                <FormFeedback type="invalid">
                                                    {subscriberCreateFormik.errors.subscriptionId}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        {/*<div className="row">
                                                <div className="d-flex justify-content-center">
                                                    <div className="mb-3 mx-3 d-flex justify-content-end">
                                                        <button
                                                            onClick={subscriberCreateFormik.handleSubmit}
                                                            type="submit"
                                                            className=" btn btn-primary"
                                                            disabled={isAnyValueEmpty(subscriberCreateFormik?.values, ["image", "companyAddress", "state", "zipcode", "country"])}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                    <div className="mb-3 mx-3 d-flex justify-content-end">
                                                        <button
                                                            onClick={() => subscriberCreateFormik.resetForm()}
                                                            className=" btn btn-primary"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>*/}
                                    </CardBody>
                                </Card>

                                <Card id="marketplace" className="my-4 mb-auto">
                                    <CardBody>
                                        <div>
                                            <h5>Market Place</h5>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="card p-4">
                                                <h6 className="mb-3">Services Offered in Marketplace</h6>

                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            Full Container Load (FCL)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="fclCheckbox"
                                                                defaultChecked={fclChecked}
                                                                onChange={(e) => {
                                                                    setFclChecked(e.target.checked);
                                                                    addOrUpdateItem({
                                                                        serviceType: FclTabName,
                                                                        access: e.target.checked
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            Less Then Container (LCL)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="lclCheckbox"
                                                                defaultChecked={lclChecked}
                                                                onChange={(e) => {
                                                                    setLclChecked(e.target.checked);
                                                                    addOrUpdateItem({
                                                                        serviceType: LclTabName,
                                                                        access: e.target.checked
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            AIR Freight (International)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="iafCheckbox"
                                                                defaultChecked={iAFChecked}
                                                                onChange={(e) => {
                                                                    setIafChecked(e.target.checked);
                                                                    addOrUpdateItem({
                                                                        serviceType: InternationTabName,
                                                                        access: e.target.checked
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            AIR Freight (Domestics)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="dafCheckbox"
                                                                defaultChecked={dAFChecked}
                                                                onChange={(e) => {
                                                                    setDAFChecked(e.target.checked);
                                                                    addOrUpdateItem({
                                                                        serviceType: DomesticsTabName,
                                                                        access: e.target.checked
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="card p-4 shadow-sm">
                                                <h6 className="mb-3">Who Can See Your Rates in Marketplace</h6>


                                                <div className="form-check form-switch mb-3">
                                                    <div className="form-check form-switch form-switch-md mb-3"
                                                         dir="ltr">
                                                        <label className="form-check-label" htmlFor="displayNameToggle">Display
                                                            My
                                                            Name Against My Rates</label>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customSwitchsizelg"
                                                            defaultChecked={subscriberCreateFormik.values.isNameVisible || false}
                                                            onChange={(e) => {
                                                                subscriberCreateFormik.setFieldValue("isNameVisible", e.target.checked);
                                                            }}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label className="form-label">Buyers Locations</label>
                                                        <Select
                                                            value={buyersLocations}
                                                            name={"buyersLocations"}
                                                            placeholder="AnyWhere"
                                                            onChange={(e) => {
                                                                setBuyersLocations(e);
                                                                subscriberCreateFormik.setFieldValue("buyersLocations", e.value);
                                                            }}
                                                            options={buyersLocationsList || []}
                                                            classNamePrefix="select2-selection form-select"
                                                        />
                                                    </div>
                                                    {
                                                        buyersLocations.value !== "anywhere" && buyersLocations.value !== "" &&
                                                        <>
                                                            <div className="col-md-4">
                                                                <label className="form-label">Country</label>
                                                                <Select
                                                                    isMulti
                                                                    value={selectedCountryOptions}
                                                                    onChange={(e) => {
                                                                        setSelectedCountryOptions(e);
                                                                    }}
                                                                    options={country_master}
                                                                    placeholder="Select Country"
                                                                />
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div style={{height: "20px"}}></div>
                                <div className="mt-4 text-center">
                                    <div className="row">
                                        <div className="d-flex justify-content-center">
                                            <div className="mb-3 mx-3 d-flex justify-content-end">
                                                <button
                                                    onClick={subscriberCreateFormik.handleSubmit}
                                                    type="submit"
                                                    className=" btn btn-primary">
                                                    Save
                                                </button>
                                            </div>
                                            <div className="mb-3 mx-3 d-flex justify-content-end">
                                                <button
                                                    onClick={() => subscriberCreateFormik.resetForm()}
                                                    className=" btn btn-primary">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{height: "200px"}}></div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
}


export default AddSubscriber;
