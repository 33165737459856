// ----------------Customers  -----------
export const GET_CUSTOMERS_TABLE_DATA = "GET_CUSTOMERS_TABLE_DATA";
export const GET_CUSTOMERS_TABLE_DATA_SUCCESS =
  "GET_CUSTOMERS_TABLE_DATA_SUCCESS";
export const GET_CUSTOMERS_TABLE_DATA_FAIL = "GET_CUSTOMERS_TABLE_DATA_FAIL";
export const UPDATE_CUSTOMER_SWITCH = "UPDATE_CUSTOMER_SWITCH";

// ----------------Customers  -----------
export const GET_VENDORS_TABLE_DATA = "GET_VENDORS_TABLE_DATA";
export const GET_VENDORS_TABLE_DATA_SUCCESS = "GET_VENDORS_TABLE_DATA_SUCCESS";
export const GET_VENDORS_TABLE_DATA_FAIL = "GET_VENDORS_TABLE_DATA_FAIL";
export const UPDATE_VENDOR_SWITCH = "UPDATE_VENDOR_SWITCH";

// All Basic deatils API
export const GET_PARTIES_CUSTOMERS_DETAILS = "GET_PARTIES_CUSTOMERS_DETAILS";
export const GET_PARTIES_CUSTOMERS_DETAILS_SUCCESS =
  "GET_PARTIES_CUSTOMERS_DETAILS_SUCCESS";
export const GET_PARTIES_CUSTOMERS_DETAILS_FAIL =
  "GET_PARTIES_CUSTOMERS_DETAILS_FAIL";

// all get city API
export const GET_PARTIES_COMPANY_CITY_DATA = "GET_PARTIES_COMPANY_CITY_DATA";
export const GET_PARTIES_COMPANY_CITY_DATA_SUCCESS =
  "GET_PARTIES_COMPANY_CITY_DATA_SUCCESS";
export const GET_PARTIES_COMPANY_CITY_DATA_FAIL =
  "GET_PARTIES_COMPANY_CITY_DATA_FAIL";

// all get state API
export const GET_PARTIES_COMPANY_STATE_DATA = "GET_PARTIES_COMPANY_STATE_DATA";
export const GET_PARTIES_COMPANY_STATE_DATA_SUCCESS =
  "GET_PARTIES_COMPANY_STATE_DATA_SUCCESS";
export const GET_PARTIES_COMPANY_STATE_DATA_FAIL =
  "GET_PARTIES_COMPANY_STATE_DATA_FAIL";

// all get country API
export const GET_PARTIES_COMPANY_COUNTRY_DATA =
  "GET_PARTIES_COMPANY_COUNTRY_DATA";
export const GET_PARTIES_COMPANY_COUNTRY_DATA_SUCCESS =
  "GET_PARTIES_COMPANY_COUNTRY_DATA_SUCCESS";
export const GET_PARTIES_COMPANY_COUNTRY_DATA_FAIL =
  "GET_PARTIES_COMPANY_COUNTRY_DATA_FAIL";

// all get pincode API
export const GET_PARTIES_COMPANY_PINCODE_DATA =
  "GET_PARTIES_COMPANY_PINCODE_DATA";
export const GET_PARTIES_COMPANY_PINCODE_DATA_SUCCESS =
  "GET_PARTIES_COMPANY_PINCODE_DATA_SUCCESS";
export const GET_PARTIES_COMPANY_PINCODE_DATA_FAIL =
  "GET_PARTIES_COMPANY_PINCODE_DATA_FAIL";

// all get api for customer
export const GET_PARTIES_ALL_DETAILS = "GET_PARTIES_ALL_DETAILS";
export const GET_PARTIES_ALL_DETAILS_SUCCESS =
  "GET_PARTIES_ALL_DETAILS_SUCCESS";
export const GET_PARTIES_ALL_DETAILS_FAIL = "GET_PARTIES_ALL_DETAILS_FAIL";

// All get employee deatisl for customers
export const GET_PARTIES_CUSTOMER_EMPLOYEE_DETAILS =
  "GET_PARTIES_CUSTOMER_EMPLOYEE_DETAILS";
export const GET_PARTIES_CUSTOMER_EMPLOYEE_DETAILS_SUCCESS =
  "GET_PARTIES_CUSTOMER_EMPLOYEE_DETAILS_SUCCESS";
export const GET_PARTIES_CUSTOMER_EMPLOYEE_DETAILS_FAIL =
  "GET_PARTIES_CUSTOMER_EMPLOYEE_DETAILS_FAIL";
