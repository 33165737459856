import React, { useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, } from "reactstrap";

const TabTwoContacts = ({ viewData }) => {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };
  return (
    <div>
      <div className="table_view_data_wrap">
        <Accordion flush open={open} toggle={toggle} className="main_accordion">
          {/* Contacts */}
          <AccordionItem className="freigth_details_wrap">
            <AccordionHeader targetId={`contacts${viewData?.id}`}>
              <h3 className="sub_modal_title">Contacts</h3>
            </AccordionHeader>
            <AccordionBody accordionId={`contacts${viewData?.id}`}>
              {viewData?.contacts?.map((data, index) => (
                <div className="view_data_wrap d-flex align-items-start" key={index}>
                  <div className="left_freight_details">
                    <div className="details">
                      <span className="title">Contact Name:</span>
                      <span className="data">
                        {data?.contactName || "-"}
                      </span>
                    </div>
                    <div className="details">
                      <span className="title">Phone Number:</span>
                      <span className="data">
                        {data?.contactNo || "-"}
                      </span>
                    </div>
                    <div className="details">
                      <span className="title">Email Id:</span>
                      <span className="data">{data?.contactEmail || "-"}</span>
                    </div>
                    <div className="details">
                      <span className="title">Department:</span>
                      <span className="data">{data?.department?.split("_").join(" ") || "-"}</span>
                    </div>
                    <div className="details">
                      <span className="title">Designation:</span>
                      <span className="data">{data?.designation?.split("_").join(" ") || "-"}</span>
                    </div>
                  </div>
                </div>
              ))}
              {viewData?.contacts?.length === 0 && <b>No Data Found !</b>}
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default TabTwoContacts;
