import React, {createContext} from "react";
import {addUserSteps} from "./TourSteps";


export const TourContext = createContext({
    onStartTour: (pageName) => {} // Function accepting pagename
});

const TourGuide = ({ children }) => {

    const tourData = {
        AddUserDetails: addUserSteps,
        dashboard: [
            { element: "#dashboardStats", intro: "Here are your stats!" },
            { element: "#dashboardSettings", intro: "Customize your settings here!" }
        ]
    };

    const onStartTour = (pageName) => {
        console.log("Starting tour for:", pageName);
        if (!tourData[pageName]) {
            console.warn(`No tour steps found for: ${pageName}`);
            return;
        }

        //window.introJs().setOptions({ steps: tourSteps[pageName] }).start();
        const intro = window.introJs();
        const steps = tourData[pageName].filter(step => step.intro); // Extract only tour steps
        const hints = tourData[pageName].filter(step => step.hint); // Extract only hints

        if (hints.length > 0) {
            intro.addHints(hints);
            intro.showHints(); // 🔥 Force hints to appear
        }

        intro.setOptions({ steps }).start();

        setTimeout(() => {

        }, 500);
    }

    return (
        <TourContext.Provider value={{ onStartTour }}>
            {children}
        </TourContext.Provider>
    );
}


export default TourGuide;
