import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import {
  REGISTER_CREATE_SELF_NEW_ACCOUNT,
  REGISTER_CREATE_SELF_NEW_ACCOUNT_SUCCESS,
  REGISTER_EMAIL_VERIFIED,
  REGISTER_EMAIL_VERIFIED_SUCCESS, REGISTER_OTP_VERIFIED, REGISTER_OTP_VERIFIED_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_FAILED
} from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper"
import {showErrorToast, showSuccessToast} from "../../../components/Common/CustomToast";
import {
  accountOnBoardingApi,
  setEmailVerificationsApi,
  setOtpVerificationApi
} from "../../../helpers/services/AuthService";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.registerUser,
        user.email,
        user.password
      )
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtRegister, "/post-jwt-register", user)
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeRegister, user)
      yield put(registerUserSuccessful(response))
    }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

function* registerEmailVerified({ payload }) {
  try{
    const response = yield call(setEmailVerificationsApi, payload);
    showSuccessToast(response);
    yield put({ type: REGISTER_EMAIL_VERIFIED_SUCCESS, payload: true });
  } catch (error) {
    showErrorToast(error?.response?.data?.message);
  }
}

function* registerOtpVerified({ payload }) {
  try{
    console.log(payload, "in Saga");
    const response = yield call(setOtpVerificationApi, payload);
    showSuccessToast(response);
    yield put({ type: REGISTER_OTP_VERIFIED_SUCCESS, payload: true });
  } catch (error) {
    showErrorToast(error?.message);
  }
}

function* accountOnBoarding({ payload }) {
  try{
    const response = yield call(accountOnBoardingApi, payload);
    console.log(response, "create self onboarding");
    yield put({type: REGISTER_CREATE_SELF_NEW_ACCOUNT_SUCCESS, payload: true });
  } catch (error) {
    showErrorToast(error?.message);
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(REGISTER_EMAIL_VERIFIED, registerEmailVerified)
  yield takeEvery(REGISTER_OTP_VERIFIED, registerOtpVerified)
  yield takeEvery(REGISTER_CREATE_SELF_NEW_ACCOUNT, accountOnBoarding)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
