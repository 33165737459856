import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormFeedback } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { sendMailNotificaton } from "../../store/Global/actions";
import { MAIL_SENT } from "../Dashboard/Partials/initialData";
import { getVendorCarrierDataAction } from "../../store/Parties/Vendor/action";
import { ContentState, EditorState } from "draft-js";

const EmailSendtoCarrierPopUp = ({ modal, onCloseClick, quotationData }) => {
    const { status_master } = useSelector((state) => state.globalReducer);
    const { vendor_carrier_data, vendor_loader } = useSelector((state) => state?.vendor);
    const { customer_data } = useSelector((state) => state?.customer);
    const dispatch = useDispatch();
    const { login_user_data } = useSelector((state) => state?.Login);

    const customerOptions = vendor_carrier_data?.content?.filter(data=>data?.tenantVendor!==null)?.map(customer => ({
        value: customer?.id,
        label: customer?.tenantVendor?.contactEmail,
        email: customer?.tenantVendor?.contactEmail,
        version: customer?.version,
        id: customer?.tenantVendor?.id,
    })) || [];

  
    const defaultMessage = quotationData ? `
        Inquiry Details:
        - Inquiry Date: ${quotationData?.inquiryDate}
        - Rate Type: ${quotationData?.rateType}
        - Status: ${quotationData?.status}
        - Customer Name: ${quotationData?.customerName}
        - Customer Contact No: ${quotationData?.customerContactNo}
        - Origin: ${quotationData?.origin}
        - Destination: ${quotationData?.destination}
        - Cargo Date: ${quotationData?.cargoDate}
        - Cargo Type: ${quotationData?.cargoType}
        - Cargo Value: ${quotationData?.cargoValue}
        - Container Types: ${quotationData?.containerTypes}
        - Weight: ${quotationData?.weight}
        - Sales Employee: ${quotationData?.salesEmp}
        - Status Code: ${quotationData?.statusCode}
    ` : '';

    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(ContentState.createFromText(defaultMessage))
    );

    const formik = useFormik({
        initialValues: {
            from: login_user_data?.email,
            to: [],
            cc: [],
            subject: "",
            message: defaultMessage,
            attachments: [],
        },
        enableReinitialize: true,  
        validationSchema: Yup.object({
            from: Yup.string().required("From is required"),
            to: Yup.array().min(1, "Select at least one recipient"),
            cc: Yup.array(),
            subject: Yup.string().required("Subject is required"),
            message: Yup.string().required("Message is required"),
            attachments: Yup.array().of(Yup.mixed()),
        }),
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            const emailDataBlob = new Blob([JSON.stringify({
                from: "lokeshk@tarifftales.com",
                to: values.to,
                cc: values.cc,
                subject: values.subject,
                message:  values.message,
                inquiryId: quotationData?.inquiryId,
                statusId: status_master?.find((item) => item?.statusCode === MAIL_SENT)?.id
            })], { type: "application/json" });
            formData.append("emailData", emailDataBlob);
            values.attachments.forEach(file => {
                formData.append("attachments", file);
            });
            dispatch(sendMailNotificaton(formData));
            onCloseClick();
            resetForm();
        },
    });

    useEffect(() => {
        dispatch(getVendorCarrierDataAction(''));
    }, [dispatch]);

    useEffect(() => {
        if (quotationData) {
            const updatedMessage = defaultMessage;
            setEditorState(EditorState.createWithContent(ContentState.createFromText(updatedMessage)));
            formik.setFieldValue("message", updatedMessage);
        }
    }, [quotationData]);

    return (
        <Modal isOpen={modal} role="dialog" centered={true} toggle={onCloseClick}>
            <div className="modal-content">
                <ModalHeader toggle={onCloseClick}>New Message For Carrier</ModalHeader>
                <ModalBody>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">From</label>
                            <Input
                                name="from"
                                type="text"
                                className="form-control"
                                placeholder="from"
                                value={formik.values.from}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                readOnly={true}
                            />
                            {formik.errors.from && formik.touched.from && (
                                <FormFeedback className="d-block">{formik.errors.from}</FormFeedback>
                            )}
                        </div>

                        <div className="mb-3">
                            <label>To</label>
                            <Select
                                isMulti
                                name="to"
                                options={customerOptions}
                                value={formik.values.to}
                                onChange={(selected) => formik.setFieldValue("to", selected)}
                                classNamePrefix="select2-selection form-select"
                            />
                            {formik.errors.to && formik.touched.to && (
                                <FormFeedback className="d-block">{formik.errors.to}</FormFeedback>
                            )}
                        </div>

                        <div className="mb-3">
                            <label>Cc</label>
                            <Select
                                isMulti
                                name="cc"
                                options={customerOptions}
                                value={formik.values.cc}
                                onChange={(selected) => formik.setFieldValue("cc", selected)}
                                classNamePrefix="select2-selection form-select"
                            />
                        </div>

                        <div className="mb-3">
                            <label>Subject</label>
                            <Input
                                name="subject"
                                type="text"
                                className="form-control"
                                placeholder="Subject"
                                value={formik.values.subject}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.subject && formik.touched.subject && (
                                <FormFeedback className="d-block">{formik.errors.subject}</FormFeedback>
                            )}
                        </div>

                        <div className="mb-3">
                            <label>Message</label>
                            <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                editorState={editorState}
                                onEditorStateChange={(state) => {
                                    setEditorState(state);
                                    formik.setFieldValue("message", state.getCurrentContent().getPlainText());
                                }}
                            />
                            {formik.errors.message && formik.touched.message && (
                                <FormFeedback className="d-block">{formik.errors.message}</FormFeedback>
                            )}
                        </div>
                
                        <div className="mb-3">
                            <label>Attachments</label>
                            <Input
                                type="file"
                                multiple
                                onChange={(event) => {
                                    const files = Array.from(event.target.files);
                                    formik.setFieldValue("attachments", files);
                                }}
                            />
                            <div className="mt-2">
                                {formik.values.attachments && formik.values.attachments.length > 0 && (
                                    <ul>
                                        {formik.values.attachments.map((file, index) => (
                                            <li key={index}>{file.name}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onCloseClick}>Close</Button>
                    <Button type="submit" onClick={formik.handleSubmit} color="primary">Send <i className="fab fa-telegram-plane ms-1"></i></Button>
                </ModalFooter>
            </div>
        </Modal>
    );
};

export default EmailSendtoCarrierPopUp;
