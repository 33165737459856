// ---------------------- breadcrumbs --------------------------------------
const fclBreadcrumb = [
    {
        label: 'Procurement',
        link: '/#',
        active: false
    },
    {
        label: 'Ocean Freight',
        link: '/#',
        active: false
    },
    {
        label: 'FCL(Full Container Load)',
        link: '/#',
        active: true
    },
]
const lclBreadcrumb = [
    {
        label: 'Procurement',
        link: '/#',
        active: false
    },
    {
        label: 'Ocean Freight',
        link: '/#',
        active: false
    },
    {
        // label: 'LCL(Less than Container Load)',
        label: 'Ocean Surcharges',
        link: '/#',
        active: true
    },
]
const portLocalBreadcrumb = [
    {
        label: 'Procurement',
        link: '/#',
        active: false
    },
    {
        label: 'Ocean Freight',
        link: '/#',
        active: false
    },
    {
        label: 'Port & Local Charges',
        link: '/#',
        active: true
    },
]
const airPortLocalBreadcrumb = [   
    {
        label: 'Procurement',
        link: '/#',
        active: false
    },
    {
        label: 'Air Freight',
        link: '/#',
        active: false
    },
    {
        label: 'Air Port & Local Charges',
        link: '/#',
        active: true
    },
]
const airLineChargesBreadcrumb = [   
    {
        label: 'Procurement',
        link: '/#',
        active: false
    },
    {
        label: 'Air Freight',
        link: '/#',
        active: false
    },
    {
        label: 'Airline Charges',
        link: '/#',
        active: true
    },
]
const waybillBreadcrumb = [
    {
        label: 'Procurement',
        link: '/#',
        active: false
    },
    {
        label: 'Air Freight',
        link: '/#',
        active: false
    },
    {
        label: 'Master Waybill',
        link: '/#',
        active: true
    },
]
const consoleBreadcrumb = [
    {
        label: 'Procurement',
        link: '/#',
        active: false
    },
    {
        label: 'Air Freight',
        link: '/#',
        active: false
    },
    {
        label: 'Console',
        link: '/#',
        active: true
    },
]
const inLandBreadcrumb = [
    {
        label: 'Procurement',
        link: '/#',
        active: false
    },
    {
        label: 'Ocean Freight',
        link: '/#',
        active: false
    },
    {
        label: 'FCL Inland Charges',
        link: '/#',
        active: true
    },
]

const fclSurchargeBreadcrumb = [
    {
        label: 'Settings',
        link: '/#',
        active: false
    },
    {
        label: 'Surcharge Master',
        link: '/#',
        active: true
    }
]
const instantRateBreadcrumb = [    
    {
        label: 'Instant Rate',
        link: '/#',
        active: true
    }
]
// ---------------------- breadcrumbs --------------------------------------

// --------------------- rate ----------------------------------------------
const fclRateData = [
    {
        id: 1,
        title: 'Total Active Ports',
        rate: '12',
        compare_rate: '12',
        rate_type: 'down'
    },
    {
        id: 2,
        title: 'Rate Density',
        rate: '31',
        compare_rate: '24',
        rate_type: 'up'
    },
    {
        id: 3,
        title: 'Avg Conversion',
        rate: '13',
        compare_rate: '12',
        rate_type: 'down'
    },
]
const lclRateData = [
    {
        id: 1,
        title: 'Total Active Ports',
        rate: '64',
        compare_rate: '05',
        rate_type: 'up'
    },
    {
        id: 2,
        title: 'Rate Density',
        rate: '40',
        compare_rate: '08',
        rate_type: 'down'
    },
    {
        id: 3,
        title: 'Avg Conversion',
        rate: '32',
        compare_rate: '65',
        rate_type: 'up'
    },
]
const waybillRateData = [
    {
        id: 1,
        title: 'Total Active Ports',
        rate: '15',
        compare_rate: '18',
        rate_type: 'down'
    },
    {
        id: 2,
        title: 'Rate Density',
        rate: '32',
        compare_rate: '20',
        rate_type: 'up'
    },
    {
        id: 3,
        title: 'Avg Conversion',
        rate: '24',
        compare_rate: '11',
        rate_type: 'up'
    },
]
const consoleRateData = [
    {
        id: 1,
        title: 'Total Active Ports',
        rate: '98',
        compare_rate: '32',
        rate_type: 'up'
    },
    {
        id: 2,
        title: 'Rate Density',
        rate: '56',
        compare_rate: '21',
        rate_type: 'up'
    },
    {
        id: 3,
        title: 'Avg Conversion',
        rate: '78',
        compare_rate: '18',
        rate_type: 'down'
    },
]
const inLandRateData = [
    {
        id: 1,
        title: 'Total Serviceable Cities',
        rate: '56',
        compare_rate: '12',
        rate_type: 'down'
    },
    {
        id: 2,
        title: 'Rate Density',
        rate: '12',
        compare_rate: '15',
        rate_type: 'down'
    },
    {
        id: 3,
        title: 'Avg Conversion',
        rate: '63',
        compare_rate: '05',
        rate_type: 'up'
    },
]
const fclSurchargeRateData = [
    {
        id: 1,
        title: 'Total Active Ports',
        rate: '12',
        compare_rate: '12',
        rate_type: 'down'
    },
    {
        id: 2,
        title: 'Rate Density',
        rate: '31',
        compare_rate: '24',
        rate_type: 'up'
    },
    {
        id: 3,
        title: 'Avg Conversion',
        rate: '13',
        compare_rate: '12',
        rate_type: 'down'
    },
]

// --------------------- procurement ----------------------------------------------
const fclTableData = [
    {
        id: 1,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'BDDAC',
        via_port: '',
        currency: 'USD',
        fre_gp: '1878',
        fre_gp2: '2956',
        fre_hq: '2956',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 2,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'CNNGB',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '30',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 3,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'CNSHA',
        via_port: '',
        currency: 'USD',
        fre_gp: '5',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '25',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 4,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'KHPNH',
        via_port: '',
        currency: 'USD',
        fre_gp: '278',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 5,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'KHKOS',
        via_port: '',
        currency: 'USD',
        fre_gp: '228',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 6,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'HKHKG',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '20',
        fre_hq: '20',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 7,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDBLW',
        via_port: '',
        currency: 'USD',
        fre_gp: '105',
        fre_gp2: '70',
        fre_hq: '70',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 8,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDJKT',
        via_port: '',
        currency: 'USD',
        fre_gp: '55',
        fre_gp2: '31',
        fre_hq: '31',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '30',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 9,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDPLM',
        via_port: '',
        currency: 'USD',
        fre_gp: '110',
        fre_gp2: '80',
        fre_hq: '80',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '31',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 10,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDPNK',
        via_port: '',
        currency: 'USD',
        fre_gp: '28',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 11,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDSRG',
        via_port: '',
        currency: 'USD',
        fre_gp: '85',
        fre_gp2: '6',
        fre_hq: '6',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 12,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDSUB',
        via_port: '',
        currency: 'USD',
        fre_gp: '120',
        fre_gp2: '95',
        fre_hq: '95',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 13,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'BDDAC',
        via_port: '',
        currency: 'USD',
        fre_gp: '1878',
        fre_gp2: '2956',
        fre_hq: '2956',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 14,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'CNNGB',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 15,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'CNSHA',
        via_port: '',
        currency: 'USD',
        fre_gp: '5',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 16,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'KHPNH',
        via_port: '',
        currency: 'USD',
        fre_gp: '278',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '30',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 17,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'KHKOS',
        via_port: '',
        currency: 'USD',
        fre_gp: '228',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '25',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 18,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'HKHKG',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '20',
        fre_hq: '20',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 19,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDBLW',
        via_port: '',
        currency: 'USD',
        fre_gp: '105',
        fre_gp2: '70',
        fre_hq: '70',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 20,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDJKT',
        via_port: '',
        currency: 'USD',
        fre_gp: '55',
        fre_gp2: '31',
        fre_hq: '31',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 21,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDPLM',
        via_port: '',
        currency: 'USD',
        fre_gp: '110',
        fre_gp2: '80',
        fre_hq: '80',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 22,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDPNK',
        via_port: '',
        currency: 'USD',
        fre_gp: '28',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '30',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 23,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDSRG',
        via_port: '',
        currency: 'USD',
        fre_gp: '85',
        fre_gp2: '6',
        fre_hq: '6',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '25',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 24,
        charge_id: 'OCT2523001',
        carrier_name: 'ONELINE',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDSUB',
        via_port: '',
        currency: 'USD',
        fre_gp: '120',
        fre_gp2: '95',
        fre_hq: '95',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'preffered',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 25,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'BDDAC',
        via_port: '',
        currency: 'USD',
        fre_gp: '2956',
        fre_gp2: '3956',
        fre_hq: '3956',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '20',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 26,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'CNNGB',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '25',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 27,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'CNSHA',
        via_port: '',
        currency: 'USD',
        fre_gp: '5',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 28,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'KHPNH',
        via_port: '',
        currency: 'USD',
        fre_gp: '278',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 29,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'KHKOS',
        via_port: '',
        currency: 'USD',
        fre_gp: '228',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 30,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'HKHKG',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '20',
        fre_hq: '20',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 31,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDBLW',
        via_port: '',
        currency: 'USD',
        fre_gp: '105',
        fre_gp2: '70',
        fre_hq: '70',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 32,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDJKT',
        via_port: '',
        currency: 'USD',
        fre_gp: '55',
        fre_gp2: '31',
        fre_hq: '31',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '30',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 33,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDPLM',
        via_port: '',
        currency: 'USD',
        fre_gp: '110',
        fre_gp2: '80',
        fre_hq: '80',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '25',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 34,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDPNK',
        via_port: '',
        currency: 'USD',
        fre_gp: '28',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 35,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDSRG',
        via_port: '',
        currency: 'USD',
        fre_gp: '85',
        fre_gp2: '6',
        fre_hq: '6',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 36,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDSUB',
        via_port: '',
        currency: 'USD',
        fre_gp: '120',
        fre_gp2: '95',
        fre_hq: '95',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 37,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'BDDAC',
        via_port: '',
        currency: 'USD',
        fre_gp: '2956',
        fre_gp2: '3956',
        fre_hq: '3956',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '20',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 38,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'CNNGB',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '25',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 39,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'CNSHA',
        via_port: '',
        currency: 'USD',
        fre_gp: '5',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 40,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'KHPNH',
        via_port: '',
        currency: 'USD',
        fre_gp: '278',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 41,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'KHKOS',
        via_port: '',
        currency: 'USD',
        fre_gp: '228',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 42,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'HKHKG',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '20',
        fre_hq: '20',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 43,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDBLW',
        via_port: '',
        currency: 'USD',
        fre_gp: '105',
        fre_gp2: '70',
        fre_hq: '70',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 44,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDJKT',
        via_port: '',
        currency: 'USD',
        fre_gp: '55',
        fre_gp2: '31',
        fre_hq: '31',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '30',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 45,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDPLM',
        via_port: '',
        currency: 'USD',
        fre_gp: '110',
        fre_gp2: '80',
        fre_hq: '80',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '25',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 46,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDPNK',
        via_port: '',
        currency: 'USD',
        fre_gp: '28',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 47,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDSRG',
        via_port: '',
        currency: 'USD',
        fre_gp: '85',
        fre_gp2: '6',
        fre_hq: '6',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 48,
        charge_id: 'OCT2523002',
        carrier_name: 'ZIM',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDSUB',
        via_port: '',
        currency: 'USD',
        fre_gp: '120',
        fre_gp2: '95',
        fre_hq: '95',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'faster',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 49,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'BDDAC',
        via_port: '',
        currency: 'USD',
        fre_gp: '900',
        fre_gp2: '1956',
        fre_hq: '1956',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '40',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 50,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'CNNGB',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '25',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 51,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'CNSHA',
        via_port: '',
        currency: 'USD',
        fre_gp: '5',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 52,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'KHPNH',
        via_port: '',
        currency: 'USD',
        fre_gp: '278',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 53,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'KHKOS',
        via_port: '',
        currency: 'USD',
        fre_gp: '228',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 54,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'HKHKG',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '20',
        fre_hq: '20',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 55,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDBLW',
        via_port: '',
        currency: 'USD',
        fre_gp: '105',
        fre_gp2: '70',
        fre_hq: '70',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 56,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDJKT',
        via_port: '',
        currency: 'USD',
        fre_gp: '55',
        fre_gp2: '31',
        fre_hq: '31',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '30',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 57,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDPLM',
        via_port: '',
        currency: 'USD',
        fre_gp: '110',
        fre_gp2: '80',
        fre_hq: '80',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '25',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 58,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDPNK',
        via_port: '',
        currency: 'USD',
        fre_gp: '28',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 59,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDSRG',
        via_port: '',
        currency: 'USD',
        fre_gp: '85',
        fre_gp2: '6',
        fre_hq: '6',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 60,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INMAA',
        dest_port: 'IDSUB',
        via_port: '',
        currency: 'USD',
        fre_gp: '120',
        fre_gp2: '95',
        fre_hq: '95',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 61,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'BDDAC',
        via_port: '',
        currency: 'USD',
        fre_gp: '900',
        fre_gp2: '1956',
        fre_hq: '1956',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '40',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 62,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'CNNGB',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '25',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 63,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'CNSHA',
        via_port: '',
        currency: 'USD',
        fre_gp: '5',
        fre_gp2: '10',
        fre_hq: '10',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 64,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'KHPNH',
        via_port: '',
        currency: 'USD',
        fre_gp: '278',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 65,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'KHKOS',
        via_port: '',
        currency: 'USD',
        fre_gp: '228',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 66,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'HKHKG',
        via_port: '',
        currency: 'USD',
        fre_gp: '10',
        fre_gp2: '20',
        fre_hq: '20',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: '',
        payment_term: '',
        charge_gp: '',
        charge_gp2: '',
        charge_currency: 'USD',
    },
    {
        id: 67,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDBLW',
        via_port: '',
        currency: 'USD',
        fre_gp: '105',
        fre_gp2: '70',
        fre_hq: '70',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 68,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDJKT',
        via_port: '',
        currency: 'USD',
        fre_gp: '55',
        fre_gp2: '31',
        fre_hq: '31',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '30',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 69,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDPLM',
        via_port: '',
        currency: 'USD',
        fre_gp: '110',
        fre_gp2: '80',
        fre_hq: '80',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '25',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 70,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDPNK',
        via_port: '',
        currency: 'USD',
        fre_gp: '28',
        fre_gp2: '156',
        fre_hq: '156',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 71,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDSRG',
        via_port: '',
        currency: 'USD',
        fre_gp: '85',
        fre_gp2: '6',
        fre_hq: '6',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_gp: '22',
        charge_gp2: '44',
        charge_currency: 'USD',
    },
    {
        id: 72,
        charge_id: 'OCT2523003',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        cargo_type: 'General',
        cargo_class: '',
        commodity: '',
        org_port: 'INKTP',
        dest_port: 'IDSUB',
        via_port: '',
        currency: 'USD',
        fre_gp: '120',
        fre_gp2: '95',
        fre_hq: '95',
        fre_hq2: '',
        fre_rf: '',
        fre_rf2: '',
        transit_time: '28',
        org_detention_free: '4',
        dest_detention_free: '4',
        quote_type: 'cheaper',
        subject_to_charges: '',
        valid_from: '16 Sep 2023',
        valid_till: '30 Dec 2023',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        rate_type: 'SPOT',
        rate_source: 'WEBSITE',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '29 Oct 2023',
        created_by: '29 Oct 2023',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        charge_currency: 'USD',
        charge_gp: '22',
        charge_gp2: '44',
        charge_hc: '44',
    },
]

const lclTableData = [
    {
        id: 1,
        charge_id: '123456ADA',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_port: 'Dhaka',
        dest_port: 'Mumbai',
        via_port: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_min_charge: '700',
        fre_currency: 'USD',
        fre_rate: '100',
        rate_basis: 'Ton/CBM',
        charge_basis: 'Per CMB',
        charge_mincharge: '10',
        charge_rate: '10',
        charge_ratio: '1:1000',
        charge_currency: 'USD'
    },
    {
        id: 2,
        charge_id: '123456ADB',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_port: 'Dhaka',
        dest_port: 'Mumbai',
        via_port: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_min_charge: '700',
        fre_currency: 'USD',
        fre_rate: '100',
        rate_basis: 'Ton/CBM',
        charge_basis: 'Per CMB',
        charge_mincharge: '10',
        charge_rate: '10',
        charge_ratio: '1:1000',
        charge_currency: 'USD'
    },
    {
        id: 3,
        charge_id: '123456ADC',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_port: 'Dhaka',
        dest_port: 'Mumbai',
        via_port: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_min_charge: '700',
        fre_currency: 'USD',
        fre_rate: '100',
        rate_basis: 'Ton/CBM',
        charge_basis: 'Per CMB',
        charge_mincharge: '10',
        charge_rate: '10',
        charge_ratio: '1:1000',
        charge_currency: 'USD'
    },
    {
        id: 4,
        charge_id: '123456ADD',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_port: 'Dhaka',
        dest_port: 'Mumbai',
        via_port: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_min_charge: '700',
        fre_currency: 'USD',
        fre_rate: '100',
        rate_basis: 'Ton/CBM',
        charge_basis: 'Per CMB',
        charge_mincharge: '10',
        charge_rate: '10',
        charge_ratio: '1:1000',
        charge_currency: 'USD'
    },
    {
        id: 5,
        charge_id: '123456ADE',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_port: 'Dhaka',
        dest_port: 'Mumbai',
        via_port: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_min_charge: '700',
        fre_currency: 'USD',
        fre_rate: '100',
        rate_basis: 'Ton/CBM',
        charge_basis: 'Per CMB',
        charge_mincharge: '10',
        charge_rate: '10',
        charge_ratio: '1:1000',
        charge_currency: 'USD'
    },
    {
        id: 6,
        charge_id: '123456ADF',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_port: 'Dhaka',
        dest_port: 'Mumbai',
        via_port: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_min_charge: '700',
        fre_currency: 'USD',
        fre_rate: '100',
        rate_basis: 'Ton/CBM',
        charge_basis: 'Per CMB',
        charge_mincharge: '10',
        charge_rate: '10',
        charge_ratio: '1:1000',
        charge_currency: 'USD'
    },
    {
        id: 7,
        charge_id: '123456ADG',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_port: 'Dhaka',
        dest_port: 'Mumbai',
        via_port: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_min_charge: '700',
        fre_currency: 'USD',
        fre_rate: '100',
        rate_basis: 'Ton/CBM',
        charge_basis: 'Per CMB',
        charge_mincharge: '10',
        charge_rate: '10',
        charge_ratio: '1:1000',
        charge_currency: 'USD'
    },
    {
        id: 8,
        charge_id: '123456ADH',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_port: 'Dhaka',
        dest_port: 'Mumbai',
        via_port: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_min_charge: '700',
        fre_currency: 'USD',
        fre_rate: '100',
        rate_basis: 'Ton/CBM',
        charge_basis: 'Per CMB',
        charge_mincharge: '10',
        charge_rate: '10',
        charge_ratio: '1:1000',
        charge_currency: 'USD'
    },
    {
        id: 9,
        charge_id: '123456ADI',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_port: 'Dhaka',
        dest_port: 'Mumbai',
        via_port: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_min_charge: '700',
        fre_currency: 'USD',
        fre_rate: '100',
        rate_basis: 'Ton/CBM',
        charge_basis: 'Per CMB',
        charge_mincharge: '10',
        charge_rate: '10',
        charge_ratio: '1:1000',
        charge_currency: 'USD'
    },
    {
        id: 10,
        charge_id: '123456ADJ',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_port: 'Dhaka',
        dest_port: 'Mumbai',
        via_port: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_min_charge: '700',
        fre_currency: 'USD',
        fre_rate: '100',
        rate_basis: 'Ton/CBM',
        charge_basis: 'Per CMB',
        charge_mincharge: '10',
        charge_rate: '10',
        charge_ratio: '1:1000',
        charge_currency: 'USD'
    },
]

export const plChargesTableData = [
    {
        id: 1,
        uniq_id: 'port_1',
        surcharge_id: 'OCTOL251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "INMAA",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'ONELINE',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        chargeCode: "OTHC",
        chargeBasis: "Per Container",
        calculationType: "Flat",
        slabBasis: "",
        currency: "INR",
        minValue: "1",
        surchargeValue: [
            {
                chargeCode: "OTHC",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "General",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "6800"
                    },
                    {
                        cargoType: "General",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "10100"
                    },
                    {
                        cargoType: "General",
                        containerType: "40HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "10100"
                    },
                    {
                        cargoType: "General",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "14500"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "16800"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "24500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "9000"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "13500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "13500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "20000"
                    },
                    {
                        cargoType: "SPL Equipment",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "9000"
                    },
                    {
                        cargoType: "SPL Equipment",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "13500"
                    },
                ]
            },
        ]
    },
    {
        id: 2,
        uniq_id: 'port_2',
        surcharge_id: 'OCTOL251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "INKTP",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'ONELINE',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        chargeCode: "THC",
        chargeBasis: "Per Shipment",
        calculationType: "Flat",
        slabBasis: "",
        currency: "INR",
        minValue: "1",
        surchargeValue: [
            {
                chargeCode: "OTHC",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "General",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "6800"
                    },
                    {
                        cargoType: "General",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "10100"
                    },
                    {
                        cargoType: "General",
                        containerType: "40HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "10100"
                    },
                    {
                        cargoType: "General",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "14500"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "16800"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "24500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "9000"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "13500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "13500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "20000"
                    },
                    {
                        cargoType: "SPL Equipment",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "9000"
                    },
                    {
                        cargoType: "SPL Equipment",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "13500"
                    },
                ]
            },
        ]
    },
    {
        id: 3,
        uniq_id: 'port_3',
        surcharge_id: 'OCTOO251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "INMAA",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'OOCL',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        chargeCode: "THC",
        chargeBasis: "Per Container	",
        calculationType: "Flat",
        slabBasis: "",
        currency: "INR",
        minValue: "1",
        surchargeValue: [
            {
                chargeCode: "OTHC",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "General",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "6000"
                    },
                    {
                        cargoType: "General",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "8500"
                    },
                    {
                        cargoType: "General",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "14300"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "15000"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "21500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "7700"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "11000"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "19600"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "20500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "29500"
                    },
                ]
            },
        ]
    },
    {
        id: 4,
        uniq_id: 'port_4',
        surcharge_id: 'OCTOO251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "INKTP",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'OOCL',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        chargeCode: "THC",
        chargeBasis: "Per Shipment",
        calculationType: "Flat",
        slabBasis: "",
        currency: "INR",
        minValue: "1",
        surchargeValue: [
            {
                chargeCode: "OTHC",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "General",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "6000"
                    },
                    {
                        cargoType: "General",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "8500"
                    },
                    {
                        cargoType: "General",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "14300"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "15000"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "21500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "7700"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "11000"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "19600"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "20500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "29500"
                    },
                ]
            },
        ]
    },
    {
        id: 5,
        uniq_id: 'port_5',
        surcharge_id: 'OCTZM251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "INMAA",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'ZIM',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        chargeCode: "THC",
        chargeBasis: "Per Shipment",
        calculationType: "Flat",
        slabBasis: "",
        currency: "INR",
        minValue: "1",
        surchargeValue: [
            {
                chargeCode: "OTHC",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "0",
                tax: "18",
                addTerms: {
                    paymentTerm: "Prepaid",
                    isStandard: "Standard Charge",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "General",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "5575"
                    },
                    {
                        cargoType: "General",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "8325"
                    },
                    {
                        cargoType: "General",
                        containerType: "40HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "8325"
                    },
                    {
                        cargoType: "General",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "10700"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "14975"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "21875"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "7500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "10900"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "10900"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "14000"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "20500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "25950"
                    },
                ]
            }
        ]
    },
    {
        id: 6,
        uniq_id: 'port_6',
        surcharge_id: 'OCTZM251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "INKTP",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'ZIM',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        chargeCode: "THC",
        chargeBasis: "Per Shipment",
        calculationType: "Flat",
        slabBasis: "",
        currency: "INR",
        minValue: "1",
        surchargeValue: [
            {
                chargeCode: "OTHC",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "0",
                tax: "18",
                addTerms: {
                    paymentTerm: "Prepaid",
                    isStandard: "Standard Charge",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "General",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "5575"
                    },
                    {
                        cargoType: "General",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "8325"
                    },
                    {
                        cargoType: "General",
                        containerType: "40HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "8325"
                    },
                    {
                        cargoType: "General",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "10700"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "14975"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "21875"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "7500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "10900"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "10900"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "14000"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "20500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "25950"
                    },
                ]
            }
        ]
    },
    {
        id: 7,
        uniq_id: 'port_7',
        surcharge_id: 'OCTOL251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "INMAA",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'ONELINE',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        chargeCode: "THC",
        chargeBasis: "Per Shipment",
        calculationType: "Flat",
        slabBasis: "",
        currency: "INR",
        minValue: "1",
        surchargeValue: [
            {
                chargeCode: "BL FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "5700"
                    },
                ]
            },
            {
                chargeCode: "CERTIFICATE FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "5700"
                    },
                ]
            },
            {
                chargeCode: "EMPTY CONTAINER LIFT FEE",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "General",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "340"
                    },
                    {
                        cargoType: "General",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "510"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "340"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "510"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "340"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "510"
                    },
                ]
            },
        ]
    },
    {
        id: 8,
        uniq_id: 'port_8',
        surcharge_id: 'OCTOL251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "INKTP",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'ONELINE',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        chargeCode: "THC",
        chargeBasis: "Per Shipment",
        calculationType: "Flat",
        slabBasis: "",
        currency: "INR",
        minValue: "1",
        surchargeValue: [
            {
                chargeCode: "BL FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "5700"
                    },
                ]
            },
            {
                chargeCode: "CERTIFICATE FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "5700"
                    },
                ]
            },
            {
                chargeCode: "EMPTY CONTAINER LIFT FEE",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "General",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "340"
                    },
                    {
                        cargoType: "General",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "510"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "340"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "510"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "340"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "510"
                    },
                ]
            },
        ]
    },
    {
        id: 9,
        uniq_id: 'port_9',
        surcharge_id: 'OCTOO251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "INMAA",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'OOCL',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        chargeCode: "THC",
        chargeBasis: "Per Shipment",
        calculationType: "Flat",
        slabBasis: "",
        currency: "INR",
        minValue: "1",
        surchargeValue: [
            {
                chargeCode: "BL FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "3500"
                    },
                ]
            },
            {
                chargeCode: "CERTIFICATE FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "750"
                    },
                ]
            },
            {
                chargeCode: "SEAL FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "USD",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "5"
                    },
                ]
            },
            {
                chargeCode: "GATE IN FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "170"
                    },
                ]
            },
            {
                chargeCode: "EMPTY CONTAINER LIFT FEE",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "General",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "1000"
                    },
                    {
                        cargoType: "General",
                        containerType: "2000",
                        fromSlab: "",
                        toSlab: "",
                        rate: "510"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "1300"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "2600"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "1500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "3000"
                    },
                ]
            },
        ]
    },
    {
        id: 10,
        uniq_id: 'port_10',
        surcharge_id: 'OCTOO251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "INKTP",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'OOCL',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        chargeCode: "THC",
        chargeBasis: "Per Shipment",
        calculationType: "Flat",
        slabBasis: "",
        currency: "INR",
        minValue: "1",
        surchargeValue: [
            {
                chargeCode: "BL FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "3500"
                    },
                ]
            },
            {
                chargeCode: "CERTIFICATE FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "750"
                    },
                ]
            },
            {
                chargeCode: "SEAL FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "USD",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "5"
                    },
                ]
            },
            {
                chargeCode: "GATE IN FEE",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "170"
                    },
                ]
            },
            {
                chargeCode: "EMPTY CONTAINER LIFT FEE",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "INR",
                minValue: "",
                tax: "18",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "General",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "1000"
                    },
                    {
                        cargoType: "General",
                        containerType: "2000",
                        fromSlab: "",
                        toSlab: "",
                        rate: "510"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "1300"
                    },
                    {
                        cargoType: "Refrigerated",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "2600"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "1500"
                    },
                    {
                        cargoType: "Hazardous",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "3000"
                    },
                ]
            },
        ]
    },
    {
        id: 11,
        uniq_id: 'port_11',
        surcharge_id: 'OCTZM251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "INMAA",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'ZIM',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: []
    },
    {
        id: 12,
        uniq_id: 'port_12',
        surcharge_id: 'OCTZM251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "INKTP",
        terminals: '',
        movement_type: 'Export',
        carrier_name: 'ZIM',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: []
    },
    {
        id: 13,
        uniq_id: 'port_13',
        surcharge_id: 'OCTOL251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "BDDAC",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'ONELINE',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: []
    },
    {
        id: 14,
        uniq_id: 'port_14',
        surcharge_id: 'OCTOL251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "IDSUB",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'ONELINE',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: [
            {
                chargeCode: "DTHC",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "USD",
                minValue: "",
                tax: "",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "95"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "145"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "185"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "145"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "230"
                    },
                ]
            },
        ]
    },
    {
        id: 15,
        uniq_id: 'port_15',
        surcharge_id: 'OCTOO251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "BDDAC",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'OOCL',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: []
    },
    {
        id: 16,
        uniq_id: 'port_16',
        surcharge_id: 'OCTOO251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "IDSUB",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'OOCL',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: [
            {
                chargeCode: "DTHC",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "USD",
                minValue: "",
                tax: "",
                addTerms: {
                    paymentTerm: "Prepaid",
                    isStandard: "Standard",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "100"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "135"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "190"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "155"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "250"
                    },
                ]
            },
        ]
    },
    {
        id: 17,
        uniq_id: 'port_17',
        surcharge_id: 'OCTZM251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "BDDAC",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'ZIM',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: []
    },
    {
        id: 18,
        uniq_id: 'port_18',
        surcharge_id: 'OCTZM251023001',
        surcharge_category: 'PORT SURCHARGE',
        port_name: "IDSUB",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'ZIM',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: [
            {
                chargeCode: "DTHC",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "USD",
                minValue: "",
                tax: "",
                addTerms: {
                    paymentTerm: "Prepaid",
                    isStandard: "Standard Charge",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "20GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "102"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "40GP",
                        fromSlab: "",
                        toSlab: "",
                        rate: "140"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "45HQ",
                        fromSlab: "",
                        toSlab: "",
                        rate: "180"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "20RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "140"
                    },
                    {
                        cargoType: "ALL",
                        containerType: "40RF",
                        fromSlab: "",
                        toSlab: "",
                        rate: "220"
                    },
                ]
            }
        ]
    },
    {
        id: 19,
        uniq_id: 'port_19',
        surcharge_id: 'OCTOL251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "BDDAC",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'ONELINE',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: [
            {
                chargeCode: "DOC",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "BDT  ",
                minValue: "",
                tax: "",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "1500"
                    },
                ]
            },
            {
                chargeCode: "Cleaning Charges",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "BDT",
                minValue: "",
                tax: "",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "500"
                    },
                ]
            },
        ]
    },
    {
        id: 20,
        uniq_id: 'port_20',
        surcharge_id: 'OCTOL251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "IDSUB",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'ONELINE',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: [
            {
                chargeCode: "DOC",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "IDR",
                minValue: "",
                tax: "",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "100000"
                    },
                ]
            },
            {
                chargeCode: "MPC",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "IDR",
                minValue: "",
                tax: "",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "300000"
                    },
                ]
            },
            {
                chargeCode: "CIC",
                chargeBasis: "Per Container",
                calculationType: "Flat",
                slabBasis: "",
                currency: "IDR",
                minValue: "",
                tax: "",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "100000"
                    },
                ]
            },
        ]
    },
    {
        id: 21,
        uniq_id: 'port_21',
        surcharge_id: 'OCTOO251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "BDDAC",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'OOCL',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: [
            {
                chargeCode: "DOC",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "BDT  ",
                minValue: "",
                tax: "",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "1500"
                    },
                ]
            },
            {
                chargeCode: "Cleaning Charges",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "BDT",
                minValue: "",
                tax: "",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "500"
                    },
                ]
            },
        ]
    },
    {
        id: 22,
        uniq_id: 'port_22',
        surcharge_id: 'OCTOO251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "IDSUB",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'OOCL',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: [
            {
                chargeCode: "DOC",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "IDR",
                minValue: "",
                tax: "",
                addTerms: {
                    paymentTerm: "Prepaid",
                    isStandard: "Standard",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "120000"
                    }
                ]
            },
            {
                chargeCode: "MPC",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "IDR",
                minValue: "",
                tax: "",
                addTerms: {
                    paymentTerm: "Prepaid",
                    isStandard: "Standard",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "300000"
                    }
                ]
            },
            {
                chargeCode: "CIC",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "IDR",
                minValue: "",
                tax: "",
                addTerms: {
                    paymentTerm: "Prepaid",
                    isStandard: "Standard",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "120000"
                    }
                ]
            },
        ]
    },
    {
        id: 23,
        uniq_id: 'port_23',
        surcharge_id: 'OCTZM251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "BDDAC",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'ZIM',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: [
            {
                chargeCode: "DOC",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "BDT  ",
                minValue: "",
                tax: "",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "1500"
                    },
                ]
            },
            {
                chargeCode: "Cleaning Charges",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "BDT",
                minValue: "",
                tax: "",
                addTerms: {
                    isStandard: "Standard",
                    paymentTerm: "Prepaid",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "500"
                    },
                ]
            },
        ]
    },
    {
        id: 24,
        uniq_id: 'port_24',
        surcharge_id: 'OCTZM251023001',
        surcharge_category: 'LOCAL SURCHARGE',
        port_name: "IDSUB",
        terminals: '',
        movement_type: 'Import',
        carrier_name: 'ZIM',
        vendor_name: '',
        valid_from: '25th OCT 2023',
        valid_till: '31st DEC 2023',
        surchargeValue: [
            {
                chargeCode: "DOC",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "IDR",
                minValue: "",
                tax: "",
                addTerms: {
                    paymentTerm: "Prepaid",
                    isStandard: "Standard",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "110000"
                    }
                ]
            },
            {
                chargeCode: "MPC",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "IDR",
                minValue: "",
                tax: "",
                addTerms: {
                    paymentTerm: "Prepaid",
                    isStandard: "Standard",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "300000"
                    }
                ]
            },
            {
                chargeCode: "CIC",
                chargeBasis: "Per BL",
                calculationType: "Flat",
                slabBasis: "",
                currency: "IDR",
                minValue: "",
                tax: "",
                addTerms: {
                    paymentTerm: "Prepaid",
                    isStandard: "Standard",
                    incoTerm: [],
                    commodity: [],
                    transportMode: []
                },
                subBox: [
                    {
                        cargoType: "ALL",
                        containerType: "ALL",
                        fromSlab: "",
                        toSlab: "",
                        rate: "110000"
                    }
                ]
            },
        ]
    },
]

const waybillTableData = [
    {
        id: 1,
        charge_id: '123456ADA',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 2,
        charge_id: '123456ADB',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 3,
        charge_id: '123456ADC',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 4,
        charge_id: '123456ADD',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 5,
        charge_id: '123456ADE',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 6,
        charge_id: '123456ADF',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 7,
        charge_id: '123456ADG',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 8,
        charge_id: '123456ADH',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 9,
        charge_id: '123456ADI',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 10,
        charge_id: '123456ADJ',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
]
const consoleTableData = [
    {
        id: 1,
        charge_id: '123456ADA',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 2,
        charge_id: '123456ADB',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 3,
        charge_id: '123456ADC',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 4,
        charge_id: '123456ADD',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 5,
        charge_id: '123456ADE',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 6,
        charge_id: '123456ADF',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 7,
        charge_id: '123456ADG',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 8,
        charge_id: '123456ADH',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 9,
        charge_id: '123456ADI',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
    {
        id: 10,
        charge_id: '123456ADJ',
        carrier_name: 'OOCL',
        is_active: true,
        vendor_name: '',
        org_airport: 'Dhaka',
        dest_airport: 'Mumbai',
        via_airport: 'Chennai',
        valid_from: '17 Jun 2023',
        detention_free: '4',
        valid_till: '17 Jun 2023',
        transit_time: '24',
        cargo_type: 'General',
        cargo_class: 'Class 1',
        product: 'Class 1',
        validity_application: 'Vessel Selling',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        rate_type: 'SPOT',
        rate_source: 'Carrier Website',
        commodity: '',
        charge_name: 'OBS',
        payment_term: 'Prepaid',
        fre_gp: '700',
        fre_gp2: '700',
        fre_hq: '700',
        fre_hq2: '700',
        fre_rf: '700',
        fre_rf2: '700',
    },
]
const inLandTableData = [
    {
        id: 1,
        charge_id: '211123001',
        is_active: true,
        charge_type: 'ICD to Port',
        carrier_name: 'Oneline Network',
        vendor_name: '',
        transport_mode: 'Rail',
        origin: 'BANGALORE',
        destination: 'CHENNAI',
        charge_basis: 'Per Container',
        valid_from: '17 Jun 2023',
        valid_till: '31-Mar-23',
        transit_time: '1',
        detention_free: '4',
        slab: '0Mt - 10MT',
        amount: '24,000',
        currency: 'INR',
        cargo_type: 'General',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        freight_charge: [
            {
                container_type: '20 GP',
                cargo_type: 'General',
                cargo_wt_min: '1',
                cargo_wt_max: '7.7',
                rate: '24150'
            },
            {
                container_type: '20 GP',
                cargo_type: 'General',
                cargo_wt_min: '7.7',
                cargo_wt_max: '17.7',
                rate: '26000'
            },
            {
                container_type: '20 GP',
                cargo_type: 'General',
                cargo_wt_min: '17.7',
                cargo_wt_max: '23.7',
                rate: '29100'
            },
            {
                container_type: '20 GP',
                cargo_type: 'General',
                cargo_wt_min: '23.7',
                cargo_wt_max: '27.7',
                rate: '32200'
            },
            {
                container_type: '20 GP',
                cargo_type: 'General',
                cargo_wt_min: '27.7',
                cargo_wt_max: '28.7',
                rate: '32200'
            },
            {
                container_type: '20 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '1',
                cargo_wt_max: '7.7',
                rate: '31395'
            },
            {
                container_type: '20 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '7.7',
                cargo_wt_max: '17.7',
                rate: '33800'
            },
            {
                container_type: '20 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '17.7',
                cargo_wt_max: '23.7',
                rate: '37830'
            },
            {
                container_type: '20 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '23.7',
                cargo_wt_max: '27.7',
                rate: '41860'
            },
            {
                container_type: '20 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '27.7',
                cargo_wt_max: '28.7',
                rate: '41860'
            },
            {
                container_type: '40 GP',
                cargo_type: 'General',
                cargo_wt_min: '1',
                cargo_wt_max: '15.4',
                rate: '39300'
            },
            {
                container_type: '40 GP',
                cargo_type: 'General',
                cargo_wt_min: '15.4',
                cargo_wt_max: '22.4',
                rate: '40900'
            },
            {
                container_type: '40 GP',
                cargo_type: 'General',
                cargo_wt_min: '22.4',
                cargo_wt_max: '26.4',
                rate: '41900'
            },
            {
                container_type: '40 GP',
                cargo_type: 'General',
                cargo_wt_min: '26.4',
                cargo_wt_max: '27.4',
                rate: '43900'
            },
            {
                container_type: '40 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '1',
                cargo_wt_max: '15.4',
                rate: '51090'
            },
            {
                container_type: '40 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '15.4',
                cargo_wt_max: '22.4',
                rate: '53170'
            },
            {
                container_type: '40 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '22.4',
                cargo_wt_max: '26.4',
                rate: '54470'
            },
            {
                container_type: '40 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '26.4',
                cargo_wt_max: '27.4',
                rate: '57070'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'General',
                cargo_wt_min: '1',
                cargo_wt_max: '15.4',
                rate: '39300'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'General',
                cargo_wt_min: '15.4',
                cargo_wt_max: '22.4',
                rate: '40900'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'General',
                cargo_wt_min: '22.4',
                cargo_wt_max: '26.4',
                rate: '41900'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'General',
                cargo_wt_min: '26.4',
                cargo_wt_max: '27.4',
                rate: '43900'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'Hazardous',
                cargo_wt_min: '1',
                cargo_wt_max: '15.4',
                rate: '51090'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'Hazardous',
                cargo_wt_min: '15.4',
                cargo_wt_max: '22.4',
                rate: '53170'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'Hazardous',
                cargo_wt_min: '22.4',
                cargo_wt_max: '26.4',
                rate: '54470'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'Hazardous',
                cargo_wt_min: '26.4',
                cargo_wt_max: '27.4',
                rate: '57070'
            },
        ],
        surcharges: [
            {
                name: 'FSC',
                charge_basis: 'Freight',
                calculation_type: 'percentage',
                rate: '18',
                tax: '18',
                currency: 'INR'
            },
            {
                name: 'OWT Surcharge',
                charge_basis: 'Cargo Weight (MT)',
                calculation_type: 'percentage',
                rate: '30',
                tax: '18',
                currency: 'INR'
            }
        ]
    },
    {
        id: 2,
        charge_id: '211123001',
        is_active: true,
        charge_type: 'ICD to Port',
        carrier_name: 'Oneline Network',
        vendor_name: '',
        transport_mode: 'Rail',
        origin: 'BANGALORE',
        destination: 'KATTUPALLI',
        charge_basis: 'Per Container',
        valid_from: '17 Jun 2023',
        valid_till: '31-Mar-23',
        transit_time: '1',
        detention_free: '4',
        slab: '0Mt - 10MT',
        amount: '24,000',
        currency: 'INR',
        cargo_type: 'General',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        freight_charge: [
            {
                container_type: '20 GP',
                cargo_type: 'General',
                cargo_wt_min: '1',
                cargo_wt_max: '7.7',
                rate: '24150'
            },
            {
                container_type: '20 GP',
                cargo_type: 'General',
                cargo_wt_min: '7.7',
                cargo_wt_max: '17.7',
                rate: '26000'
            },
            {
                container_type: '20 GP',
                cargo_type: 'General',
                cargo_wt_min: '17.7',
                cargo_wt_max: '23.7',
                rate: '29100'
            },
            {
                container_type: '20 GP',
                cargo_type: 'General',
                cargo_wt_min: '23.7',
                cargo_wt_max: '27.7',
                rate: '32200'
            },
            {
                container_type: '20 GP',
                cargo_type: 'General',
                cargo_wt_min: '27.7',
                cargo_wt_max: '28.7',
                rate: '32200'
            },
            {
                container_type: '20 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '1',
                cargo_wt_max: '7.7',
                rate: '31395'
            },
            {
                container_type: '20 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '7.7',
                cargo_wt_max: '17.7',
                rate: '33800'
            },
            {
                container_type: '20 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '17.7',
                cargo_wt_max: '23.7',
                rate: '37830'
            },
            {
                container_type: '20 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '23.7',
                cargo_wt_max: '27.7',
                rate: '41860'
            },
            {
                container_type: '20 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '27.7',
                cargo_wt_max: '28.7',
                rate: '41860'
            },
            {
                container_type: '40 GP',
                cargo_type: 'General',
                cargo_wt_min: '1',
                cargo_wt_max: '15.4',
                rate: '39300'
            },
            {
                container_type: '40 GP',
                cargo_type: 'General',
                cargo_wt_min: '15.4',
                cargo_wt_max: '22.4',
                rate: '40900'
            },
            {
                container_type: '40 GP',
                cargo_type: 'General',
                cargo_wt_min: '22.4',
                cargo_wt_max: '26.4',
                rate: '41900'
            },
            {
                container_type: '40 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '1',
                cargo_wt_max: '15.4',
                rate: '51090'
            },
            {
                container_type: '40 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '15.4',
                cargo_wt_max: '22.4',
                rate: '53170'
            },
            {
                container_type: '40 GP',
                cargo_type: 'Hazardous',
                cargo_wt_min: '22.4',
                cargo_wt_max: '26.4',
                rate: '54470'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'General',
                cargo_wt_min: '1',
                cargo_wt_max: '15.4',
                rate: '39300'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'General',
                cargo_wt_min: '15.4',
                cargo_wt_max: '22.4',
                rate: '40900'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'General',
                cargo_wt_min: '22.4',
                cargo_wt_max: '26.4',
                rate: '41900'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'Hazardous',
                cargo_wt_min: '1',
                cargo_wt_max: '15.4',
                rate: '51090'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'Hazardous',
                cargo_wt_min: '15.4',
                cargo_wt_max: '22.4',
                rate: '53170'
            },
            {
                container_type: '40 HQ',
                cargo_type: 'Hazardous',
                cargo_wt_min: '22.4',
                cargo_wt_max: '26.4',
                rate: '54470'
            },
        ],
        surcharges: [
            {
                name: 'FSC',
                charge_basis: 'Freight',
                calculation_type: 'percentage',
                rate: '18',
                tax: '18',
                currency: 'INR'
            },
            {
                name: 'OWT Surcharge',
                charge_basis: 'Cargo Weight (MT)',
                calculation_type: 'percentage',
                rate: '30',
                tax: '18',
                currency: 'INR'
            }
        ]
    },
    {
        id: 3,
        charge_id: '211123002',
        is_active: true,
        charge_type: 'ICD to Port',
        carrier_name: 'ZIM',
        vendor_name: '',
        transport_mode: 'Rail',
        origin: 'BANGALORE',
        destination: 'CHENNAI',
        charge_basis: 'Per Container',
        valid_from: '17 Jun 2023',
        valid_till: '31-Mar-23',
        transit_time: '1',
        detention_free: '4',
        slab: '0Mt - 10MT',
        amount: '24,000',
        currency: 'INR',
        cargo_type: 'General',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        surcharges: [
            {
                name: 'FSC',
                charge_basis: 'Freight',
                calculation_type: 'percentage',
                rate: '18',
                tax: '18',
                currency: 'INR'
            },
            {
                name: 'OWT Surcharge',
                charge_basis: 'Cargo Weight (MT)',
                calculation_type: 'percentage',
                rate: '30',
                tax: '18',
                currency: 'INR'
            }
        ]
    },
    {
        id: 4,
        charge_id: '211123002',
        is_active: true,
        charge_type: 'ICD to Port',
        carrier_name: 'ZIM',
        vendor_name: '',
        transport_mode: 'Rail',
        origin: 'BANGALORE',
        destination: 'KATTUPALLI',
        charge_basis: 'Per Container',
        valid_from: '17 Jun 2023',
        valid_till: '31-Mar-23',
        transit_time: '1',
        detention_free: '4',
        slab: '0Mt - 10MT',
        amount: '24,000',
        currency: 'INR',
        cargo_type: 'General',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        surcharges: [
            {
                name: 'FSC',
                charge_basis: 'Freight',
                calculation_type: 'percentage',
                rate: '18',
                tax: '18',
                currency: 'INR'
            },
            {
                name: 'OWT Surcharge',
                charge_basis: 'Cargo Weight (MT)',
                calculation_type: 'percentage',
                rate: '30',
                tax: '18',
                currency: 'INR'
            }
        ]
    },
    {
        id: 5,
        charge_id: '211123003',
        is_active: true,
        charge_type: 'ICD to Port',
        carrier_name: 'OOCL',
        vendor_name: '',
        transport_mode: 'Rail',
        origin: 'BANGALORE',
        destination: 'CHENNAI',
        charge_basis: 'Per Container',
        valid_from: '17 Jun 2023',
        valid_till: '31-Mar-23',
        transit_time: '1',
        detention_free: '4',
        slab: '0Mt - 10MT',
        amount: '24,000',
        currency: 'INR',
        cargo_type: 'General',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        surcharges: [
            {
                name: 'FSC',
                charge_basis: 'Freight',
                calculation_type: 'percentage',
                rate: '18',
                tax: '18',
                currency: 'INR'
            },
            {
                name: 'OWT Surcharge',
                charge_basis: 'Cargo Weight (MT)',
                calculation_type: 'percentage',
                rate: '30',
                tax: '18',
                currency: 'INR'
            }
        ]
    },
    {
        id: 6,
        charge_id: '211123003',
        is_active: true,
        charge_type: 'ICD to Port',
        carrier_name: 'OOCL',
        vendor_name: '',
        transport_mode: 'Rail',
        origin: 'BANGALORE',
        destination: 'KATTUPALLI',
        charge_basis: 'Per Container',
        valid_from: '17 Jun 2023',
        valid_till: '31-Mar-23',
        transit_time: '1',
        detention_free: '4',
        slab: '0Mt - 10MT',
        amount: '24,000',
        currency: 'INR',
        cargo_type: 'General',
        last_update: '01 Jan 2023',
        last_update_by: 'Michelle Brewer',
        created_on: '01 Jan 2023',
        created_by: '01 Jan 2023',
        surcharges: [
            {
                name: 'FSC',
                charge_basis: 'Freight',
                calculation_type: 'percentage',
                rate: '18',
                tax: '18',
                currency: 'INR'
            },
            {
                name: 'OWT Surcharge',
                charge_basis: 'Cargo Weight (MT)',
                calculation_type: 'percentage',
                rate: '30',
                tax: '18',
                currency: 'INR'
            }
        ]
    },
]

export const airDummyData = [
    {
        bookingMode: "Master AWB",
        origin: "BOM",
        destination: "BLR",
        cargomode: "GCR",
        cargoType: "GENERAL",
        weight: "100",
        l1Details: {
            l1fr: "32.36",
            l1charges: "8",
            l1total: "40.36"
        },
        l2Details: {
            l2fr: "33.86",
            l2charges: "8.00",
            l2total: "41.86"
        },
        l3Details: {
            l3fr: "35.36",
            l3charges: "8.00",
            l3total: "43.36"
        },
        l4Details: {
            l4fr: "36.86",
            l4charges: "8.00",
            l4total: "44.86"
        },
        l5Details: {
            l5fr: "38.36",
            l5charges: "8.00",
            l5total: "46.36"
        },
    },
    {
        bookingMode: "CONSOL",
        origin: "BOM",
        destination: "BLR",
        cargomode: "GCR",
        cargoType: "GENERAL",
        weight: "100",
        l1Details: {
            l1fr: "40.00",
            l1charges: "1.50",
            l1total: "41.50"
        },
        l2Details: {
            l2fr: "41.50",
            l2charges: "1.50",
            l2total: "43.00"
        },
        l3Details: {
            l3fr: "43.00",
            l3charges: "1.50",
            l3total: "44.50"
        },
        l4Details: {
            l4fr: "44.50",
            l4charges: "1.50",
            l4total: "46.00"
        },
        l5Details: {
            l5fr: "46.00",
            l5charges: "1.50",
            l5total: "47.50"
        },
    },
    {
        bookingMode: "CONSOL",
        origin: "BOM",
        destination: "MAA",
        cargomode: "GCR",
        cargoType: "GENERAL",
        weight: "100",
        l1Details: {
            l1fr: "37.36",
            l1charges: "8.00",
            l1total: "45.36"
        },
        l2Details: {
            l2fr: "38.86",
            l2charges: "8.00",
            l2total: "46.86"
        },
        l3Details: {
            l3fr: "40.36",
            l3charges: "8.00",
            l3total: "48.36"
        },
        l4Details: {
            l4fr: "41.86",
            l4charges: "8.00",
            l4total: "49.86"
        },
        l5Details: {
            l5fr: "43.36",
            l5charges: "8.00",
            l5total: "51.36"
        },
    },
    {
        bookingMode: "Master AWB",
        origin: "BOM",
        destination: "MAA",
        cargomode: "GCR",
        cargoType: "GENERAL",
        weight: "100",
        l1Details: {
            l1fr: "40.00",
            l1charges: "1.50",
            l1total: "41.50"
        },
        l2Details: {
            l2fr: "41.50",
            l2charges: "1.50",
            l2total: "43.00"
        },
        l3Details: {
            l3fr: "43.00",
            l3charges: "1.50",
            l3total: "44.50"
        },
        l4Details: {
            l4fr: "44.50",
            l4charges: "1.50",
            l4total: "46.00"
        },
        l5Details: {
            l5fr: "46.00",
            l5charges: "1.50",
            l5total: "47.50"
        },
    },
    {
        bookingMode: "Master AWB",
        origin: "BOM",
        destination: "CCU",
        cargomode: "GCR",
        cargoType: "GENERAL",
        weight: "100",
        l1Details: {
            l1fr: "32.36",
            l1charges: "8",
            l1total: "40.36"
        },
        l2Details: {
            l2fr: "33.86",
            l2charges: "8.00",
            l2total: "41.86"
        },
        l3Details: {
            l3fr: "35.36",
            l3charges: "8.00",
            l3total: "43.36"
        },
        l4Details: {
            l4fr: "36.86",
            l4charges: "8.00",
            l4total: "44.86"
        },
        l5Details: {
            l5fr: "38.36",
            l5charges: "8.00",
            l5total: "46.36"
        },
    },
    {
        bookingMode: "CONSOL",
        origin: "BOM",
        destination: "CCU",
        cargomode: "GCR",
        cargoType: "GENERAL",
        weight: "100",
        l1Details: {
            l1fr: "40.00",
            l1charges: "1.50",
            l1total: "41.50"
        },
        l2Details: {
            l2fr: "41.50",
            l2charges: "1.50",
            l2total: "43.00"
        },
        l3Details: {
            l3fr: "43.00",
            l3charges: "1.50",
            l3total: "44.50"
        },
        l4Details: {
            l4fr: "44.50",
            l4charges: "1.50",
            l4total: "46.00"
        },
        l5Details: {
            l5fr: "46.00",
            l5charges: "1.50",
            l5total: "47.50"
        },
    },
    {
        bookingMode: "Master AWB",
        origin: "BOM",
        destination: "DEL",
        cargomode: "GCR",
        cargoType: "GENERAL",
        weight: "100",
        l1Details: {
            l1fr: "32.36",
            l1charges: "8",
            l1total: "40.36"
        },
        l2Details: {
            l2fr: "33.86",
            l2charges: "8.00",
            l2total: "41.86"
        },
        l3Details: {
            l3fr: "35.36",
            l3charges: "8.00",
            l3total: "43.36"
        },
        l4Details: {
            l4fr: "36.86",
            l4charges: "8.00",
            l4total: "44.86"
        },
        l5Details: {
            l5fr: "38.36",
            l5charges: "8.00",
            l5total: "46.36"
        },
    },
    {
        bookingMode: "CONSOL",
        origin: "BOM",
        destination: "DEL",
        cargomode: "GCR",
        cargoType: "GENERAL",
        weight: "100",
        l1Details: {
            l1fr: "40.00",
            l1charges: "1.50",
            l1total: "41.50"
        },
        l2Details: {
            l2fr: "41.50",
            l2charges: "1.50",
            l2total: "43.00"
        },
        l3Details: {
            l3fr: "43.00",
            l3charges: "1.50",
            l3total: "44.50"
        },
        l4Details: {
            l4fr: "44.50",
            l4charges: "1.50",
            l4total: "46.00"
        },
        l5Details: {
            l5fr: "46.00",
            l5charges: "1.50",
            l5total: "47.50"
        },
    },
    {
        bookingMode: "CONSOL",
        origin: "BOM",
        destination: "HYD",
        cargomode: "GCR",
        cargoType: "GENERAL",
        weight: "100",
        l1Details: {
            l1fr: "32.36",
            l1charges: "8",
            l1total: "40.36"
        },
        l2Details: {
            l2fr: "33.86",
            l2charges: "8.00",
            l2total: "41.86"
        },
        l3Details: {
            l3fr: "35.36",
            l3charges: "8.00",
            l3total: "43.36"
        },
        l4Details: {
            l4fr: "36.86",
            l4charges: "8.00",
            l4total: "44.86"
        },
        l5Details: {
            l5fr: "38.36",
            l5charges: "8.00",
            l5total: "46.36"
        },
    },
    {
        bookingMode: "Master AWB",
        origin: "BOM",
        destination: "HYD",
        cargomode: "GCR",
        cargoType: "GENERAL",
        weight: "100",
        l1Details: {
            l1fr: "40.00",
            l1charges: "1.50",
            l1total: "41.50"
        },
        l2Details: {
            l2fr: "41.50",
            l2charges: "1.50",
            l2total: "43.00"
        },
        l3Details: {
            l3fr: "43.00",
            l3charges: "1.50",
            l3total: "44.50"
        },
        l4Details: {
            l4fr: "44.50",
            l4charges: "1.50",
            l4total: "46.00"
        },
        l5Details: {
            l5fr: "46.00",
            l5charges: "1.50",
            l5total: "47.50"
        },
    },
]

// export const compareListData = [
//     {
//         "bookingMode": "Console",
//         "origin": "CHENNAI",
//         "orgCode": "MAA",
//         "destination": "DELHI",
//         "destCode": "DEL",
//         "cargoMode": "GRC",
//         "itemLimit": 3,
//         "items": [
//             {
//                 "weight": 100,
//                 "airLines": "OOCL",
//                 "vendorName": null,
//                 "totalPerKg": 49.5,
//                 "othersPerKg": 0.0,
//                 "freightPerkg": 49.5
//             },
//             {
//                 "weight": 100,
//                 "airLines": "OOCL",
//                 "vendorName": null,
//                 "totalPerKg": 49.5,
//                 "othersPerKg": 0.0,
//                 "freightPerkg": 49.5
//             },
//             {
//                 "weight": 100,
//                 "airLines": "OOCL",
//                 "vendorName": null,
//                 "totalPerKg": 25.5,
//                 "othersPerKg": 0.0,
//                 "freightPerkg": 25.5
//             }
//         ]
//     },
//     {
//         "bookingMode": "Console",
//         "origin": "CHENNAI",
//         "orgCode": "MAA",
//         "destination": "BENGALURU",
//         "destCode": "BLR",
//         "cargoMode": "LEAN",
//         "itemLimit": 3,
//         "items": [
//             {
//                 "weight": 100,
//                 "airLines": "OOCL",
//                 "vendorName": null,
//                 "totalPerKg": 30.0,
//                 "othersPerKg": 0.0,
//                 "freightPerkg": 30.0
//             },
//             {
//                 "weight": 100,
//                 "airLines": "OOCL",
//                 "vendorName": null,
//                 "totalPerKg": 29.5,
//                 "othersPerKg": 0.0,
//                 "freightPerkg": 29.5
//             },
//             {
//                 "weight": 100,
//                 "airLines": "OOCL",
//                 "vendorName": null,
//                 "totalPerKg": 25.0,
//                 "othersPerKg": 0.0,
//                 "freightPerkg": 25.0
//             }
//         ]
//     },
//     {
//         "bookingMode": "Console",
//         "origin": "CHENNAI",
//         "orgCode": "MAA",
//         "destination": "KOLKATA",
//         "destCode": "CCU",
//         "cargoMode": "LEAN",
//         "itemLimit": 3,
//         "items": [
//             {
//                 "weight": 100,
//                 "airLines": "OOCL",
//                 "vendorName": "Indigo Airlines",
//                 "totalPerKg": 41.5,
//                 "othersPerKg": 0.0,
//                 "freightPerkg": 41.5
//             },
//             {
//                 "weight": 100,
//                 "airLines": "OOCL",
//                 "vendorName": "Indigo Airlines",
//                 "totalPerKg": 36.5,
//                 "othersPerKg": 0.0,
//                 "freightPerkg": 36.5
//             },
//             {
//                 "weight": 100,
//                 "airLines": "OOCL",
//                 "vendorName": "Air Asia",
//                 "totalPerKg": 36.5,
//                 "othersPerKg": 0.0,
//                 "freightPerkg": 36.5
//             }
//         ]
//     },
//     {
//         "bookingMode": "MAWB",
//         "origin": "CHENNAI",
//         "orgCode": "MAA",
//         "destination": "DELHI",
//         "destCode": "DEL",
//         "cargoMode": "GRC",
//         "itemLimit": 3,
//         "items": [
//             {
//                 "weight": 100,
//                 "airLines": "Air Asia",
//                 "vendorName": null,
//                 "totalPerKg": 30.0,
//                 "othersPerKg": 0.0,
//                 "freightPerkg": 30.0
//             }
//         ]
//     },
//     {
//         "bookingMode": "MAWB",
//         "origin": "CHENNAI",
//         "orgCode": "MAA",
//         "destination": "KOLKATA",
//         "destCode": "CCU",
//         "cargoMode": "LEAN",
//         "itemLimit": 3,
//         "items": [
//             {
//                 "weight": 100,
//                 "airLines": "Air Asia",
//                 "vendorName": null,
//                 "totalPerKg": 25.0,
//                 "othersPerKg": 0.0,
//                 "freightPerkg": 25.0
//             }
//         ]
//     }
// ]

export const comparetableData = [
    {
        "bookingMode": "Console",
        "origin": "CHENNAI",
        "originCode": "MAA",
        "destination": "DELHI",
        "destinationCode": "DEL",
        "cargoMode": "GRC",
        "weight": 1000,
        "uniqueHash": "93bbe107a8804b1225a2448ecdb74b2e",
        "items": [
            {
                "freightAmount": 25.5,
                "totalAmount": 25.5,
                "otherPerKg": 0.0,
                "airLines": "OOCL",
                "vendorName": null,
                "uniqueHash": "435409a52f5de35b76a47f9275633d0d"
            },
            {
                "freightAmount": 30.0,
                "totalAmount": 30.0,
                "otherPerKg": 0.0,
                "airLines": "OOCL",
                "vendorName": null,
                "uniqueHash": "6a59048ead7c1dab889916a750b464ea"
            },
        ]
    },
    {
        "bookingMode": "Console",
        "origin": "CHENNAI",
        "originCode": "MAA",
        "destination": "DELHI",
        "destinationCode": "DEL",
        "cargoMode": "GRC",
        "weight": 1000,
        "uniqueHash": "93bbe107a8804b1225a2448ecdb74b2e",
        "items": [
            {
                "freightAmount": 25.5,
                "totalAmount": 25.5,
                "otherPerKg": 0.0,
                "airLines": "OOCL",
                "vendorName": null,
                "uniqueHash": "435409a52f5de35b76a47f9275633d0d"
            },
            {
                "freightAmount": 30.0,
                "totalAmount": 30.0,
                "otherPerKg": 0.0,
                "airLines": "OOCL",
                "vendorName": null,
                "uniqueHash": "6a59048ead7c1dab889916a750b464ea"
            },
            {
                "freightAmount": 32.5,
                "totalAmount": 32.5,
                "otherPerKg": 0.0,
                "airLines": "OOCL",
                "vendorName": null,
                "uniqueHash": "7d4954c0160550cc2212270ea615b8f9"
            }
        ]
    },
]

// --------------------------- upload form options ---------------------------------

const optionStatus = [
    { label: "ACTIVE", value: "ACTIVE" },
    { label: "INACTIVE", value: "INACTIVE" },
]
const optionRateType = [
    { label: "SPOT", value: "SPOT" },
    { label: "CONTRACT", value: "CONTRACT" },
    { label: "TARIFF", value: "TARIFF" }
]
const optionRateSource = [
    { label: "EMAIL", value: "EMAIL" },
    { label: "WEBSITE", value: "WEBSITE" },
    { label: "SALES TEAM", value: "SALES_TEAM" }
]
const optionVendorType = [
    { label: "ALL", value: "ALL" },
    { label: "CARRIER", value: "CARRIER" },
    { label: "NVOCC", value: "NVOCC" },
    { label: "AGENT", value: "OVERSEAS_AGENT" },
    { label: "CHA", value: "CHA" },
    { label: "TRANSPORTER", value: "TRANSPORTER" },
    { label: "CO LOADER", value: "CO_LOADER" },
    { label: "LOCAL AGENT", value: "LOCAL_AGENT" },
    { label: "MANPOWER AGENT", value: "MANPOWER_AGENT" },
]
const optionVendorName = []
const optionCarrierName = [
    { label: "ONELINE", value: "oneline" },
    { label: "ZIM", value: "zim" },
    { label: "OOCL", value: "oocl" },
]
const optionMovementType = [
    { label: "IMPORT", value: "IMPORT" },
    { label: "EXPORT", value: "EXPORT" },
]
const optionBookingType = [
    { label: "MAWB", value: "MAWB" },
    { label: "CONSOLE", value: "CONSOLE" },
    { label: "ALL", value: "ALL" },
]

const internationalAirBookingType = [
    { label: "MAWB", value: "MAWB" },
]

const optionValidityApp = [
    { label: "SAILING DATE", value: "SAILING_DATE" },
    { label: "CARGO HANDOVER DATE", value: "CARGO_HANDOVER_DATE" }
]
const optionSurchargesName = [
    { label: "OBS - One Bunker Surchage", value: "obs" },
    { label: "EIS - Equipment Imbalance Surcharge", value: "eis" },
    { label: "WRC - War Risk Surcharge", value: "wrc" },
    { label: "OCR - Origin Receiving Charges", value: "ocr" },
    { label: "ADDON - Additional Charge", value: "addon" },
    { label: "LSF - Low Sulphur Surcharge", value: "lsf" },
    { label: "ARD - Import Haulage Charge", value: "ard" },
    { label: "Add New", value: "Add New" },
]
const optionMultiDestination = [
    { value: 'selectAll', label: 'Select All' },
    { label: "BDDAC - DHAKA", value: "BDDAC(DHAKA)" },
    { label: "CNNGB - NINGBO", value: "CNNGB" },
    { label: "CNSHA - SHANGHAI", value: "CNSHA" },
    { label: "KHPNH - PHNOM PENH", value: "KHPNH" },
    { label: "KHKOS - SIHANOUKVILLE", value: "KHKOS" },
    { label: "HKHKG - HONG KONG", value: "HKHKG" },
    { label: "IDBLW - BELAWAN", value: "IDBLW" },
    { label: "IDJKT - JAKARTA", value: "IDJKT" },
    { label: "IDPLM - PALEMBANG", value: "IDPLM" },
    { label: "IDPNK - PONTIANAK", value: "IDPNK" },
    { label: "IDSRG - SEMARANG", value: "IDSRG" },
    { label: "IDSUB - SURABAYA", value: "IDSUB" },
]
const optionDestPort = [
    { label: "BDDAC", value: "BDDAC" },
    { label: "CNNGB", value: "CNNGB" },
    { label: "CNSHA", value: "CNSHA" },
    { label: "KHPNH", value: "KHPNH" },
    { label: "KHKOS", value: "KHKOS" },
    { label: "HKHKG", value: "HKHKG" },
    { label: "IDBLW", value: "IDBLW" },
    { label: "IDJKT", value: "IDJKT" },
    { label: "IDPLM", value: "IDPLM" },
    { label: "IDPNK", value: "IDPNK" },
    { label: "IDSRG", value: "IDSRG" },
    { label: "IDSUB", value: "IDSUB" },
]
const optionOrgPort = [
    { label: 'INMAA', value: 'INMAA' },
    { label: 'INKTP', value: 'INKTP' },
]
const optionPortlocalOrgPort = [
    { label: 'INMAA', value: 'INMAA' },
    { label: 'INKTP', value: 'INKTP' },
    { label: 'BDDAC', value: 'BDDAC' },
    { label: 'IDSUB', value: 'IDSUB' },
]
const optionPaymentType = [
    { label: "TO COLLECT", value: "TO_COLLECT" },
    { label: "PREPAID", value: "PREPAID" },
]
const optcurrency = [
    { label: "INR", value: "INR" },
    { label: "USD", value: "USD" },
    { label: "Rp", value: "Rp" },
    { label: "Yuan", value: "Yuan" },
    { label: "BDT", value: "BDT" },
    { label: "HKD", value: "HKD" },
];
const optionCargoType = [
    { label: "General", value: "general" },
    { label: "Refer", value: "Refer" },
    { label: "Haz", value: "Haz" },
]
const optionChargeBasis = [
    { label: "Cargo Weight (MT)", value: "cargo_weight_mt" },
    { label: "Freight", value: "freight" },
    { label: "Per Container", value: "per_container" },
    { label: "Per BL", value: "per_bill" },
    { label: "Per Shipment", value: "per_shipment" },
    { label: "Per TEU", value: "per_teu" },
    { label: "Per Day/Per Container", value: "per_day_container" },
    { label: "Per House BL", value: "per_house" },
    { label: "Per Day", value: "per_day" },
    { label: "Per Ton", value: "per_ton" },
    { label: "Per Ton/Per Container", value: "per_ton_container" },
    { label: "Per CBM", value: "per_cbm" },
];
const optionCalculationType = [
    { label: "FLAT", value: "FLAT" },
    { label: "Slab", value: "SLAB" },
    { label: "Percentage", value: "PERCENTAGE" },
];
const optionContainerType = [
    { label: "20 GP", value: "20_gp" },
    { label: "40 GP", value: "40_gp" },
    { label: "40 HQ", value: "40_hq" },
    { label: "45 HQ", value: "45_hq" },
    { label: "20 RF", value: "20_rf" },
    { label: "40 RF", value: "40_rf" },
];
const optionServiceType = [
    { label: "OCEAN", value: "OCEAN" },
    { label: "AIR", value: "AIR" },
];
const optionIsStandard = [
    { label: "Standard Charge", value: "standard_charge" },
    { label: "Incidental", value: "incidental" },
];

const optionAirline = [
    {value: "Air Asia", label: "Air Asia"},
    {value: "Air India", label: "Air India"},
    {value: "Indigo", label: "Indigo"},
]
const optionBookingMode = [
    {value: "master_AWB", label: "Master AWB"},
    {value: "CONSOL", label: "CONSOL"},
]
const optionMode = [
    { label: "Prime", value: "prime" },
    { label: "General", value: "general" },
    { label: "Lean", value: "lean" },
]

export {
    fclBreadcrumb, fclRateData, fclTableData, lclBreadcrumb, portLocalBreadcrumb, lclRateData, lclTableData,
    optionRateType, optionRateSource, optionVendorType, optionVendorName, optionCarrierName, optionValidityApp, optionSurchargesName, optionMultiDestination, optionPaymentType,
    waybillBreadcrumb, waybillRateData, waybillTableData, consoleBreadcrumb, consoleRateData, consoleTableData, inLandBreadcrumb, inLandRateData, inLandTableData, fclSurchargeBreadcrumb, fclSurchargeRateData,
    optcurrency, optionOrgPort, optionDestPort, optionCargoType, optionMovementType, optionPortlocalOrgPort,
    optionChargeBasis, optionCalculationType, optionContainerType, optionServiceType, optionIsStandard,instantRateBreadcrumb,airPortLocalBreadcrumb,

    optionAirline,optionBookingMode, optionMode, optionStatus,airLineChargesBreadcrumb,optionBookingType, internationalAirBookingType
};