export const GET_MARKETING_TABLE_DATA = 'GET_MARKETING_TABLE_DATA';
export const GET_MARKETING_TABLE_DATA_SUCCESS="GET_MARKETING_TABLE_DATA_SUCCESS";

export const GET_MARKETING_TABLE_DATA_LODER="GET_MARKETING_TABLE_DATA_LODER";

export const POST_MARKETING_DATA='POST_MARKETING_DATA'


export const GET_RULE_HISTORY_DATA = 'GET_RULE_HISTORY_DATA';
export const GET_RULE_HISTORY_DATA_SUCCESS="GET_RULE_HISTORY_DATA_SUCCESS";

export const GET_POSTMARK_VERIFIED_EMAILS = "GET_POSTMARK_VERIFIED_EMAILS";
export const GET_POSTMARK_VERIFIED_EMAILS_SUCCESS = "GET_POSTMARK_VERIFIED_EMAILS_SUCCESS";
export const GET_POSTMARK_VERIFIED_EMAILS_FAILS = "GET_POSTMARK_VERIFIED_EMAILS_FAILS";
