export const SUBSCRIPTION_INITIALIZE = "SUBSCRIPTION_INITIALIZE";

export const GET_SUBSCRIPTION_DATA = 'GET_SUBSCRIPTION_DATA';
export const GET_SUBSCRIPTION_DATA_SUCCESS = 'GET_SUBSCRIPTION_DATA_SUCCESS';
export const GET_SUBSCRIPTION_DATA_FAIL = 'GET_SUBSCRIPTION_DATA_FAIL';

export const GET_SUBSCRIPTION_SELECT_DATA = 'GET_SUBSCRIPTION_SELECT_DATA';
export const GET_SUBSCRIPTION_SELECT_DATA_SUCCESS = 'GET_SUBSCRIPTION_SELECT_DATA_SUCCESS';

export const POST_SUBSCRIPTION_DATA="POST_SUBSCRIPTION_DATA";
export const POST_SUBSCRIPTION_DATA_SUCCESS = 'POST_SUBSCRIPTION_DATA_SUCCESS';
export const POST_SUBSCRIPTION_DATA_FAIL = 'POST_SUBSCRIPTION_DATA_FAIL';

export const SUBSCRIPTION_LOADER_START = "SUBSCRIPTION_LOADER_START"
export const SUBSCRIPTION_LOADER_STOP = "SUBSCRIPTION_LOADER_STOP"
