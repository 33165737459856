import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  FORGET_PASSWORD_EMAIL_VERIFY,
  FORGET_PASSWORD_EMAIL_VERIFY_SUCCESS,
  FORGET_PASSWORD_OTP_VERIFY,
  FORGET_PASSWORD_OTP_VERIFY_SUCCESS,
  FORGET_PASSWORD_PASSWORD_CHANGE,
  FORGET_PASSWORD_PASSWORD_CHANGE_SUCCESS,
} from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper"
import {
  forgetPasswordApi,
  setEmailVerificationsApi,
  setOtpVerificationApi
} from "../../../helpers/services/AuthService";
import {showErrorToast, showSuccessToast} from "../../../components/Common/CustomToast";
import {REGISTER_EMAIL_VERIFIED_SUCCESS, REGISTER_OTP_VERIFIED_SUCCESS} from "../register/actionTypes";

const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.forgetPassword, user.email)
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtForgetPwd, "/jwt-forget-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else {
      const response = yield call(postFakeForgetPwd, "/fake-forget-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* registerEmailVerified({ payload }) {
  try{
    const response = yield call(setEmailVerificationsApi, payload);
    showSuccessToast(response);
    yield put({ type: FORGET_PASSWORD_EMAIL_VERIFY_SUCCESS, payload: true });
  } catch (error) {
    showErrorToast(error?.response?.data?.message);
  }
}

function* registerOtpVerified({ payload }) {
  try{
    console.log(payload, "in Saga");
    const response = yield call(setOtpVerificationApi, payload);
    showSuccessToast(response);
    yield put({ type: FORGET_PASSWORD_OTP_VERIFY_SUCCESS, payload: true });
  } catch (error) {
    showErrorToast(error?.response?.data?.message);
  }
}

function* forgetPasswordApiCall({ payload }) {
  try{
    console.log(payload, "in Saga");
    const response = yield call(forgetPasswordApi, payload);
    showSuccessToast(response);
    yield put({ type: FORGET_PASSWORD_PASSWORD_CHANGE_SUCCESS, payload: true });
  } catch (error) {
    showErrorToast(error?.response?.data?.message);
  }
}


export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD_EMAIL_VERIFY, registerEmailVerified)
  yield takeEvery(FORGET_PASSWORD_OTP_VERIFY, registerOtpVerified)
  yield takeEvery(FORGET_PASSWORD_PASSWORD_CHANGE, forgetPasswordApiCall);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
