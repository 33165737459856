import { all, call, fork, put, takeLatest } from "redux-saga/effects"
import {
    getMarketingDataSer,
    getPostmarkVerifiedEmails,
    getRuleHistoryDataSer,
    postMarketingDataSer
} from "../../helpers/services/MarketingService"
import {
    GET_MARKETING_TABLE_DATA,
    GET_MARKETING_TABLE_DATA_LODER,
    GET_MARKETING_TABLE_DATA_SUCCESS, GET_POSTMARK_VERIFIED_EMAILS,
    GET_POSTMARK_VERIFIED_EMAILS_FAILS, GET_POSTMARK_VERIFIED_EMAILS_SUCCESS,
    GET_RULE_HISTORY_DATA,
    GET_RULE_HISTORY_DATA_SUCCESS,
    POST_MARKETING_DATA
} from "./actiontype"
import { showErrorToast, showSuccessToast } from "../../components/Common/CustomToast";

function* saveMarketingData({ payload }) {
    yield put({ type: GET_MARKETING_TABLE_DATA_LODER, payload: true });
    try {
        const response = yield call(postMarketingDataSer, payload)
        console.log(response, "reponse into postTenantLocationTypes");
        yield put({ type: GET_MARKETING_TABLE_DATA_LODER, payload: false });
        showSuccessToast("Updated successfully");
    } catch (error) {
        showErrorToast(error?.response?.data?.message);
        yield put({ type: GET_MARKETING_TABLE_DATA_LODER, payload: false });
        console.log(error, "saga saveMarketingData api error");
    }
}

function* fetchMarketingViewData({ payload }) {
    yield put({ type: GET_MARKETING_TABLE_DATA_LODER, payload: true });
    try {
        const response = yield call(getMarketingDataSer, payload);
        yield put({ type: GET_MARKETING_TABLE_DATA_LODER, payload: false });
        yield put({ type: GET_MARKETING_TABLE_DATA_SUCCESS, payload: response })
    } catch (error) {
        yield put({ type: GET_MARKETING_TABLE_DATA_LODER, payload: false });
        console.log("error", error);
    }
}
function* fetchRuleHistoryData({ payload }) {
    yield put({ type: GET_MARKETING_TABLE_DATA_LODER, payload: true });
    try {
        const response = yield call(getRuleHistoryDataSer, payload);
        yield put({ type: GET_MARKETING_TABLE_DATA_LODER, payload: false });
        yield put({ type: GET_RULE_HISTORY_DATA_SUCCESS, payload: response })
    } catch (error) {
        yield put({ type: GET_MARKETING_TABLE_DATA_LODER, payload: false });
        console.log("error", error);
    }
}

function* fetchPostmarkVerifiedEmails() {
    try{
        const response = yield call(getPostmarkVerifiedEmails);
        yield put({ type: GET_POSTMARK_VERIFIED_EMAILS_SUCCESS, payload: response });
    } catch (error) {
        yield put({ type: GET_POSTMARK_VERIFIED_EMAILS_FAILS });
        console.log(error);
    }
}


export function* watchGetMarketingData() {
    yield takeLatest(POST_MARKETING_DATA, saveMarketingData)
    yield takeLatest(GET_MARKETING_TABLE_DATA, fetchMarketingViewData)
    yield takeLatest(GET_RULE_HISTORY_DATA, fetchRuleHistoryData)
    yield takeLatest(GET_POSTMARK_VERIFIED_EMAILS, fetchPostmarkVerifiedEmails)
}

function* marketing() {
    yield all([fork(watchGetMarketingData)]);
}

export default marketing;
