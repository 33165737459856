import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED, REGISTER_EMAIL_VERIFIED, REGISTER_OTP_VERIFIED, REGISTER_CREATE_SELF_NEW_ACCOUNT,
} from "./actionTypes"

export const registerUser = user => {
  return {
    type: REGISTER_USER,
    payload: { user },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const registerEmailVerified = email => {
  return {
    type: REGISTER_EMAIL_VERIFIED,
    payload: email,
  }
}

export const registerOtpVerified = (otpData) => {
  return {
    type: REGISTER_OTP_VERIFIED,
    payload: otpData,
  }
}

export const createSelfNewAccount = (newAccount) => {
  return {
    type: REGISTER_CREATE_SELF_NEW_ACCOUNT,
    payload: newAccount,
  }
}
