import {
    GET_SUBSCRIBER_DATA,
    GET_SUBSCRIBER_DATA_SUCCESS,
    GET_SUBSCRIBER_DETAILS_DATA_LOADER,
    GET_SUBSCRIBER_DETAILS_DATA_SUCCESS,
    GET_SUBSCRIBER_GROUP_DATA_SUCCESS,
    GET_SUBSCRIBER_IMAGE_DATA_SUCCESS,
    GET_TENANTS_DETAILS_DATA_BY_ID_ERROR,
    GET_TENANTS_DETAILS_DATA_BY_ID_SUCCESS,
    POST_SUBSCRIBER_DATA_FAIL,
    POST_SUBSCRIBER_DATA_LOADER_START,
    POST_SUBSCRIBER_DATA_LOADER_STOP,
    POST_SUBSCRIBER_DATA_SUCCESS,
    POST_TENANTS_STATUS_UPDATE_DATA_FAIL,
    POST_TENANTS_STATUS_UPDATE_DATA_SUCCESS,
    SUBSCRIBER_INITIALIZE
} from "./actionType";


const INITIAL_STATE = {
    subscriberSuccessData: {},
    subscriberErrorData: {},
    subscribersList: {},
    subscribersFilterList: {},
    subscriptionsId : 0,
    subscribersGroupSummary: {},
    subscriberProfileImageData: "",
    subscriberDetailsDataList: {},
    subscriberDetailsDataListLoader: false,
    subscriberInfo: {},
    subscriberInfoError: {},
    tenantsStatusSuccess: {},
    tenantsStatusError: {},
    progressBarLoader: ''
};


const subscriberReducer = (state = INITIAL_STATE, action) => {
    //console.log('action ', action);
    switch (action.type) {
        case SUBSCRIBER_INITIALIZE:
            return {
                ...state,
                progressBarLoader: ''
            }
        case POST_SUBSCRIBER_DATA_SUCCESS:
            return {
                ...state,
                subscriberSuccessData: action.payload,
            }
        case POST_SUBSCRIBER_DATA_LOADER_START:
            return {
                ...state,
                progressBarLoader: true
            }
        case POST_SUBSCRIBER_DATA_LOADER_STOP:
            return {
                ...state,
                progressBarLoader: false,
            }
        case POST_SUBSCRIBER_DATA_FAIL:
            return {
                ...state,
                subscriberErrorData: action.payload,
            }
        case GET_SUBSCRIBER_GROUP_DATA_SUCCESS:
            return {
                ...state,
                subscribersGroupSummary: action.payload,
            }
        case GET_SUBSCRIBER_DATA:
            //console.log('Subscription ID ', action.payload);
            return {
                ...state,
                subscriptionsId: action.payload,
            }
        case GET_SUBSCRIBER_DATA_SUCCESS:
            //console.log({...state});
            const { subscriptionsId } = state;
            console.log('Subscription ID ', subscriptionsId);
            let subscriberData = [];
            action.payload.content.map(item => {
                if (item.subscriptionId === subscriptionsId) {
                    subscriberData.push({...item });
                }
            });
            console.log(subscriberData);
            action.payload = {...action.payload, content : subscriberData};
            return {
                ...state,
                subscribersList: action.payload,
            }
        case GET_SUBSCRIBER_IMAGE_DATA_SUCCESS:
            return {
                ...state,
                subscriberProfileImageData: action.payload,
            }
        case GET_SUBSCRIBER_DETAILS_DATA_LOADER:
            return {
                ...state,
                subscriberDetailsDataListLoader: action.payload,
            }
        case GET_SUBSCRIBER_DETAILS_DATA_SUCCESS:
            return {
                ...state,
                subscriberDetailsDataList: action.payload,
            }
        case GET_TENANTS_DETAILS_DATA_BY_ID_SUCCESS:
            return {
                ...state,
                subscriberInfo: action.payload,
            }
        case GET_TENANTS_DETAILS_DATA_BY_ID_ERROR:
            return {
                ...state,
                subscriberInfoError: action.payload,
            }
        case POST_TENANTS_STATUS_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                tenantsStatusSuccess: action.payload,
            }
        case POST_TENANTS_STATUS_UPDATE_DATA_FAIL:
            return {
                ...state,
                tenantsStatusError: action.payload,
            }
        default:
            return state;
    }
}

export default subscriberReducer;