import React, {useState} from "react";


const SubscriptionsPlans = () => {
    // Optional: Use state to track Monthly vs Annual
    const [billingCycle, setBillingCycle] = useState("monthly");

    // Example prices if toggling monthly/annual
    const starterPrice = billingCycle === 'monthly' ? 59 : 599;
    const premiumPrice = billingCycle === 'monthly' ? 89 : 899;
    const enterprisePrice = billingCycle === 'monthly' ? 99 : 999;
    return (
        <>
            <section className="pricing-section py-5">
                <div className="container">
                    {/* Title */}
                    <div className="text-center mb-5">
                        <h2 className="fw-bold">Pick the best plan for you</h2>
                        <p className="text-muted">
                            You have Free Unlimited Updates and Premium Support on each package.
                        </p>
                    </div>

                    {/* Toggle Button */}
                    <div className="d-flex justify-content-center mb-5">
                        <div className="btn-group-header" role="group">
                            <button
                                className={`btn ${billingCycle === "monthly" ? "btn-primary" : "btn-outline-primary"}`}
                                onClick={() => setBillingCycle("monthly")}
                            >
                                Monthly
                            </button>
                            <button
                                className={`btn ${billingCycle === "annual" ? "btn-primary" : "btn-outline-primary"}`}
                                onClick={() => setBillingCycle("annual")}
                            >
                                Annual
                            </button>
                        </div>
                    </div>

                    {/* Pricing Cards */}
                    <div className="row">
                        {/* Starter Plan */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100 shadow">
                                <div className="text-center" style={{marginTop: "-10px"}}>
                                    <span className="badge bg-light text-dark mb-2" style={{borderRadius: "7px", width: "20%"}}>STARTER</span>
                                </div>
                                <div className="card-body text-center">
                                    <h2 className="fw-bold">
                                        ${billingCycle === "monthly" ? "59" : "599"}
                                        <small className="text-muted">/mo</small>
                                    </h2>
                                    <ul className="list-unstyled mt-3 mb-4 text-muted">
                                        <li>✔ 2 team members</li>
                                        <li>✔ 20GB Cloud storage</li>
                                        <li>✖ Integration help</li>
                                        <li>✖ Sketch Files</li>
                                        <li>✖ API Access</li>
                                        <li>✖ Complete documentation</li>
                                    </ul>
                                    <button className="btn btn-dark w-100">Join →</button>
                                </div>
                            </div>
                        </div>

                        {/* Premium Plan */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100 shadow-lg bg-dark text-white">
                                <div className="text-center" style={{marginTop: "-10px"}}>
                                    <span className="badge bg-primary mb-2" style={{borderRadius: "7px", width: "20%"}}>PREMIUM</span>
                                </div>
                                <div className="card-body text-center">
                                    <h2 className="fw-bold" style={{color: "white"}}>
                                        ${billingCycle === "monthly" ? "89" : "899"}
                                        <small className="text-muted" style={{color: "white"}}>/mo</small>
                                    </h2>
                                    <ul className="list-unstyled mt-3 mb-4">
                                        <li>✔ 10 team members</li>
                                        <li>✔ 40GB Cloud storage</li>
                                        <li>✔ Integration help</li>
                                        <li>✔ Sketch Files</li>
                                        <li>✔ API Access</li>
                                        <li>✔ Complete documentation</li>
                                    </ul>
                                    <button className="btn btn-primary w-100">Try Premium →</button>
                                </div>
                            </div>
                        </div>

                        {/* Enterprise Plan */}
                        <div className="col-md-4 mb-4">
                            <div className="card h-100 shadow">
                                <div className="text-center" style={{marginTop: "-10px"}}>
                                    <span className="badge bg-light text-dark mb-2" style={{borderRadius: "7px", width: "25%"}}>ENTERPRISE</span>
                                </div>
                                <div className="card-body text-center">
                                    <h2 className="fw-bold">
                                        ${billingCycle === "monthly" ? "99" : "999"}
                                        <small className="text-muted">/mo</small>
                                    </h2>
                                    <ul className="list-unstyled mt-3 mb-4 text-muted">
                                        <li>✔ Unlimited team members</li>
                                        <li>✔ 100GB Cloud storage</li>
                                        <li>✔ Integration help</li>
                                        <li>✔ Sketch Files</li>
                                        <li>✔ API Access</li>
                                        <li>✔ Complete documentation</li>
                                    </ul>
                                    <button className="btn btn-dark w-100">Join →</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SubscriptionsPlans;