import React, {useState} from 'react';
import {Modal} from "reactstrap";

const PermissionDeniedModal = ({message, isVisible, setToggle, onDismiss}) => {
    return(
        <>
            <Modal
                size="sm"
                isOpen={isVisible}
                toggle={() => {
                    setToggle();
                }}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="mySmallModalLabel">
                        Permission Denied!
                    </h5>
                    <button
                        onClick={() => {
                            onDismiss(false);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p className="mb-0">
                        {message}
                    </p>
                </div>
            </Modal>
        </>
    );
}

export default PermissionDeniedModal;