import {
  GET_MARKETING_TABLE_DATA_LODER,
  GET_MARKETING_TABLE_DATA_SUCCESS, GET_POSTMARK_VERIFIED_EMAILS_SUCCESS,
  GET_RULE_HISTORY_DATA_SUCCESS,
} from "./actiontype";

const INIT_STATE = {
  tenant_rule_data: [],
  tenant_rule_data_loder:false,
  rule_history_data:[],
  verifiedEmails: [],
  templateLists: [],
};

const Marketing = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MARKETING_TABLE_DATA_SUCCESS:
      return {
        ...state,
        tenant_rule_data: action.payload,
      };
      case GET_RULE_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        rule_history_data: action.payload,
      };
      case GET_MARKETING_TABLE_DATA_LODER:
      return {
        ...state,
        tenant_rule_data_loder: action.payload,
      }
      case GET_POSTMARK_VERIFIED_EMAILS_SUCCESS:
        return {
          ...state,
          verifiedEmails: action.payload?.verified_emails.map(email => ({ value: email, label: email })),
          templateLists: action.payload?.template_ids.map(template => ({ value: template?.template_id, label: template?.name }))
        }
    default:
      return state;
  }
};

export default Marketing;
