/* INVOICES */
export const GET_INVOICES = "GET_INVOICES"
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS"
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL"

export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL"
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS"
export const GET_INVOICE_DETAIL_FAIL = "GET_INVOICE_DETAIL_FAIL"

export const ADD_INVOICE_DETAIL = "ADD_INVOICE_DETAIL"
export const ADD_INVOICE_DETAIL_SUCCESS = "ADD_INVOICE_DETAIL_SUCCESS"
export const ADD_INVOICE_DETAIL_FAIL = "ADD_INVOICE_DETAIL_FAIL"

export const DELETE_INVOICE_DETAIL = "DELETE_INVOICE_DETAIL"
export const DELETE_INVOICE_DETAIL_SUCCESS = "DELETE_INVOICE_DETAIL_SUCCESS"
export const DELETE_INVOICE_DETAIL_FAIL = "DELETE_INVOICE_DETAIL_FAIL"

export const UPDATE_INVOICE_DETAIL = "UPDATE_INVOICE_DETAIL"
export const UPDATE_INVOICE_DETAIL_SUCCESS = "UPDATE_INVOICE_DETAIL_SUCCESS"
export const UPDATE_INVOICE_DETAIL_FAIL = "UPDATE_INVOICE_DETAIL_FAIL"
