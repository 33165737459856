import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Modal } from 'reactstrap';
import { updateQuotionAssingToData } from '../../../../store/Sales/actions';
import { showErrorToast } from '../../../../components/Common/CustomToast';
import { IN_PROGRESS } from '../../../Dashboard/Partials/initialData';

const AssignePopUp = ({ isOpen, onClose, onConfirm, selectedRows }) => {
  if (!isOpen) return null;
  const [quatationAssignTo, setQuotationAssignTo] = useState();
  const { settings_users_data, users_loader } = useSelector((state) => state.settings);
  const { login_user_data } = useSelector((state) => state.Login);
  const dispatch = useDispatch();
 const { status_master } = useSelector((state) => state.globalReducer);
  const userData = settings_users_data?.content?.map(data => ({
    id: data?.id,
    label: `${data?.firstName || ''} ${data?.lastName || ''}`.trim(),
    value: data?.id,
    managerId: data?.managerId
  }));

  const onClickSave = () => {
    console.log(quatationAssignTo, selectedRows);
    if (!quatationAssignTo?.id) {
     showErrorToast("Please Select User")
    } else{
      let data = {
        inquiryIds: selectedRows,
        userId: quatationAssignTo?.id,
        statusId:status_master?.find(item => item.statusCode === IN_PROGRESS)?.id
    }
      dispatch(updateQuotionAssingToData(data))
      onClose();
    }
  }
  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose} className='transferDialog_modal' >
      <div className="modal-header">
        <h1 className='m-0'>Inquiry Assign</h1>
        <button
          onClick={onClose}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className='modal-body'>
        <Select
          name="destinationPort"
          value={quatationAssignTo}
          onChange={(e) => {
            setQuotationAssignTo(e);
          }}
          options={userData?.filter(data => data?.managerId === login_user_data?.userId || data?.id === login_user_data?.userId)}
          classNamePrefix="select2-selection form-select"
        />
      </div>
      <div className='modal-footer'>
        <div className="btn_wrap d-flex mt-auto">
          <button className="btn border" type="button" onClick={onClose}>Cancel</button>
          <button className="btn btn-primary" type="button" onClick={onClickSave} >Save</button>
        </div>
      </div>

    </Modal>
  );
};


export default AssignePopUp

