import React, { useState } from "react"
import {Alert, Col, Container, FormFeedback, Input, Row} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import logo from "../../../assets/images/logo-sm.svg";
import CarouselPage from "../../AuthenticationInner/CarouselPage";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as schema from "../../../api/global-schema";
import {networkLoginUser} from "../../../store/auth/login/actions";
import {isAnyValueEmpty} from "../../../components/Common/CommonLogic";
import withRouter from "../../../components/Common/withRouter";
import PropTypes from "prop-types";

const NetworkLogin = (props) => {
    const [passwordShow, setPasswordShow] = useState(false);
    const dispatch = useDispatch();
    const { error } = useSelector(state => ({
        error: state.Login.error,
    }))

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            validateonmount: true,
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: schema.email,
            password: schema.password,
        }),
        onSubmit: async(values) => {
            let dataObj = {
                "user-name": values?.email,
                'password': values?.password,
                "remember-me": "false"
            }
            dispatch(networkLoginUser(dataObj, props.router.navigate));
        }
    })
    //meta title
    document.title = "Login | Minia - React Admin & Dashboard Template";
    return(
        <>
            <div className="auth-page">
                <Container fluid>
                    <Row className="g-0">
                        <Col xxl={3} lg={4} md={5}>
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={logo} alt="" height="28"/> <span
                                                className="logo-txt">SuperAdmin</span>
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">Welcome Back !</h5>
                                                <p className="text-muted mt-2">Sign in to continue to SuperAdmin.</p>
                                            </div>
                                            <form className="custom-form mt-4 pt-2"
                                                  onSubmit={(e) => {
                                                      e.preventDefault();
                                                      validation.handleSubmit();
                                                      return false;
                                                  }}>
                                                {error ? <Alert color="danger">{error}</Alert> : null}
                                                <div className="mb-3">
                                                    <label className="form-label">Username</label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email address"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <label className="form-label">Password</label>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="">
                                                                <Link to="/page-recoverpw" className="text-muted">Forgot
                                                                    password?</Link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="input-group auth-pass-inputgroup">
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password || ""}
                                                            type={passwordShow ? "text" : "password"}
                                                            placeholder="Enter password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        <button onClick={() => setPasswordShow(!passwordShow)}
                                                                className="btn btn-light shadow-none ms-0" type="button"
                                                                id="password-addon"><i
                                                            className="mdi mdi-eye-outline"></i></button>
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox"
                                                                   id="remember-check"/>
                                                            <label className="form-check-label"
                                                                   htmlFor="remember-check">
                                                                Remember me
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="mb-3">
                                                    {/*<button
                                                        className={`btn btn-primary btn-block `}
                                                        type="submit" > Log In
                                                    </button>*/}
                                                    <button
                                                        className={`btn btn-primary w-100 waves-effect waves-light ${isAnyValueEmpty(validation.values) && 'disabled'}`}
                                                        type="submit" disabled={!validation.isValid}>Log In
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">© {new Date().getFullYear()} SuperAdmin <i
                                                className="mdi mdi-heart text-danger"></i> by Tarifftales</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage/>
                    </Row>
                </Container>
            </div>
        </>
    );
}


export default withRouter(NetworkLogin);

NetworkLogin.propTypes = {
    history: PropTypes.object,
}