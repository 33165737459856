import React, {useEffect, useMemo, useState} from "react";
import {Container, DropdownItem, FormGroup, Input} from "reactstrap";
import TfBreadcrumbs from "../../components/Common/TfBreadcrumbs";
import {SubscriberBreadcrumb, SubscribersDetailsBreadcrumb, SubscriptionBreadcrumb} from "../../common/data/parties";
import SubscriptionsTable from "../Subscriptions/components/SubscriptionsTable";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useAsyncDebounce} from "react-table";
import {CommonValue} from "../Parties/PartiesCol";
import {GET_SUBSCRIBER_DATA} from "../../store/Subscribers/actionType";
import SubscriberDetailsTable from "./components/SubscriberDetailsTable";
import {
    getSubscriberDetailsList,
    getSubscriberSummaryList,
    postSubscriberData,
    postTenantsStatusUpdate
} from "../../store/Subscribers/actions";
import {Edit} from "../Settings/SettingsCol";
import {FclTabName} from "../Dashboard/Partials/initialData";
import {isObjectEmpty} from "../../components/Common/CommonLogic";


const SubscriberDetails = () => {
    const [value, setValue] = useState('');
    const navigate = useNavigate();
    const navigateState = useLocation();
    const [currentPage, setCurrentPage] = useState(0);
    const [previewModal, setPreviewModal] = useState(false);
    const { subscriberDetailsDataList, subscriberDetailsDataListLoader, tenantsStatusSuccess, tenantsStatusError } = useSelector((state) => state.subscriberReducer);

    const dispatch = useDispatch();

    const onChange = useAsyncDebounce(value => {
        console.log(value);
    }, 200);
    //dispatch({ type:  GET_SUBSCRIBER_DATA, payload: navigateState.state.data.id });

    useEffect(() => {
        if (currentPage !== '' && currentPage !== undefined) {
            let url = `?subscriptionId=${navigateState.state.data.id}&page=${currentPage}&size=10`;
            dispatch(getSubscriberDetailsList(url));
        }

        if (tenantsStatusSuccess !== undefined && !isObjectEmpty(tenantsStatusSuccess)) {
            let url = `?subscriptionId=${navigateState.state.data.id}&page=${currentPage}&size=10`;
            dispatch(getSubscriberDetailsList(url));
        }
    }, [dispatch, currentPage, tenantsStatusSuccess]);

    const viewPopupHandler = (data) => {
    }

    const editHandler = (data) => {
        navigate(`/network/subscriber/edit`, {
            state: {
                id: data.id || '',
                data: data
            },
        });
    }

    const switchHandler = (data) => {
        data.status = data.status === 'ACTIVE' ? "INACTIVE" : "ACTIVE";
        let statusUrl = "?tenantId="+data.id+"&status="+data.status+" ";
        dispatch(postTenantsStatusUpdate(statusUrl));
    }

    const subscriberDetailsColumns = useMemo(
        () => [
            {
                Header: "Subscriber Id",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Subscriber Name",
                accessor: "name",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Active Since",
                accessor: "createdDate",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Last Transaction (Days)",
                accessor: "lastTransaction",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Total Inquiries",
                accessor: "totalInquirys",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Total Quotations",
                accessor: "totalQuotations",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Status",
                accessor: "status",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex justify-content-start align-items-start h-100">
                            <p style={{marginTop: "15px"}}>
                                <Edit cellProps={cellProps} viewPopupHandler={editHandler}/>
                            </p>
                            <p style={{marginLeft: "15px", marginTop: "15px"}}>
                                <div className="switch_wrap">
                                    <FormGroup switch>
                                        <Input
                                            type="switch"
                                            checked={cellProps.row.original?.status === "ACTIVE" || false}
                                            onClick={() => {
                                                switchHandler(cellProps.row.original);
                                            }}
                                            readOnly
                                        />
                                    </FormGroup>
                                </div>
                            </p>
                        </div>
                    );
                },
            }
        ], []);

    const previewModalHand = () => {
        setPreviewModal(!previewModal);
    }

    return (
        <>
            <div className="page-content settings_users_wrapper">
                <Container fluid>
                    <div className="main_freight_wrapper">
                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TfBreadcrumbs breadcrumb={SubscribersDetailsBreadcrumb}/>
                        </div>
                        <button type="button" className="btn border mb-3" onClick={() => {
                            navigate(-1);
                        }}> Back
                        </button>
                        <div className="freight_filter_wrap d-flex align-items-center">
                            <p className="label flex-grow-1 m-0">Filters :</p>
                            <div className="right_actions_wrap flex-shrink-0 d-flex align-items-center">
                                <div className="search_form">
                                    <form>
                                        <div className="position-relative">
                                            <input
                                                type="search"
                                                onChange={e => {
                                                    e.preventDefault();
                                                    setValue(e.target.value);
                                                    onChange(e.target.value);
                                                    return false;
                                                }}
                                                className="form-control"
                                                placeholder="Search"
                                                value={value || ""}
                                            />
                                            <button className="btn" type="button">
                                                <i className="bx bx-search-alt-2 align-middle"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <SubscriberDetailsTable
                            columns={subscriberDetailsColumns}
                            data={subscriberDetailsDataList.content || []}
                            customPageSize={10}
                            component={"Subscriber"}
                            loader={subscriberDetailsDataListLoader || false}
                            setCurrentPage={setCurrentPage}
                            totalPages={subscriberDetailsDataList?.totalPages || 0}
                            totalEntries={subscriberDetailsDataList?.totalElements || 0}/>

                    </div>
                </Container>
            </div>
        </>
    );
}


export default SubscriberDetails;