import { useFormik } from "formik";
import React, { useEffect, useState, version } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Card, CardBody, FormFeedback, Input, Table } from "reactstrap";
import * as Yup from "yup";
import { showErrorToast } from "../../components/Common/CustomToast";
import { saveMarketingData } from "../../store/Marketing/actions";
import { getVendorDataListAction } from "../../store/Parties/Vendor/action";
import DailyRecurrenceModal from "./DailyRecurrenceModal";
import MonthlyRecurrenceModal from "./MonthlyRecurrenceModal";
import RecurrenceModal from "./RecurrenceModal";
import WeeklyRecurrenceModal from "./WeeklyRecurrenceModal";
import YearlyRecurrenceModal from "./YearlyRecurrenceModal";
import {GET_POSTMARK_VERIFIED_EMAILS} from "../../store/Marketing/actiontype";

const options = [
    { value: "no-repeat", label: "Does not repeat" },
    { value: "weekdays", label: "Every weekday (Mon - Fri)" },
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
];

const channel = [
    { value: "PostMark", label: "PostMark" },
    { value: "CompanyEmail", label: " Company Email" },
    { value: "MailChimp", label: "MailChimp" },
    { value: "Whatsapp", label: "Whatsapp" },
];

const serviceType = [
    { value: "FCL", label: "FCL" },
    { value: "LCL", label: "LCL" },
    { value: "DOM_AIR", label: "DOM AIR" },
    { value: "INT_AIR", label: "INT AIR" },
];

const serviceTypeRates = [
    { value: "Inland", label: "Inland" },
    { value: "Port&localCharges", label: "Port & local Charges" },
    { value: "Freight", label: "Freight" },
];

const AddRulePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const navigateState = useLocation();
    const [recurrenceModal, setRecurrenceModal] = useState(false)
    const [isDailyModalOpen, setIsDailyModalOpen] = useState(false);
    const [monthlyRecurrenceModal, setMonthlyRecurrenceModal] = useState(false)
    const [yearlyRecurrenceModal, setYearlyRecurrenceModal] = useState(false)
    const [weeklyRecurrenceModal, setWeeklyRecurrenceModal] = useState(false)
    const [sheduleData, setSheduleData] = useState()
    const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
    const [serviceTypeRatesOptions, setServiceTypeRatesOptions] = useState([]);
    const { verifiedEmails, templateLists } = useSelector((state) => state?.Marketing);
    const { country_master } = useSelector((state) => state?.globalReducer);
    const { customer_data } = useSelector((state) => state?.customer);
    const [selectedIds, setSelectedIds] = useState([]);
    const { tenant_info } = useSelector((state) => state?.settings);

    const handleSelectAll = () => {
        const allIds = customer_data?.content
            ?.filter((data) => data?.country?.id === formik.values.country?.id)
            ?.map((item) => item.id) || [];

        if (allIds.length === selectedIds.length) {
            setSelectedIds([]); // Deselect all
        } else {
            setSelectedIds(allIds); // Select all
        }
    };

    const handleRowSelect = (inquiryId) => {
        setSelectedIds((prev) =>
            prev.includes(inquiryId)
                ? prev.filter((id) => id !== inquiryId) // Deselect
                : [...prev, inquiryId] // Select
        );
    };
    const selectField = Yup.string().transform((value) =>
        typeof value === 'object' && value?.value ? value.value : value
    );

    const formik = useFormik({
        initialValues: {
            templateId: "",
            senderEmail: "",
            subject: "",
            fromEmail: "",
            serviceType: "",
            ruleName: "",
            whatsappNumber: "",
            companyEmail: '',
            channel: "",
            startDateTime: new Date().toISOString().slice(0, 16),
            endDateTime: new Date().toISOString().slice(0, 16),
            frequency: "",
            country: "",
            postmarkTemplates: "",
        },
        validationSchema: Yup.object({
            ruleName: Yup.string()
                .required("Please enter the rule name")
                .min(3, "Rule name must be at least 3 characters long")
                .max(50, "Rule name cannot exceed 50 characters"),

            // whatsappNumber:selectField.required("Please enter a valid email address") ||  Yup.string()
            //     .matches(/^\+?[1-9]\d{1,14}$/, "Please enter a valid WhatsApp number (e.g., +1234567890)")
            //     .required("Please enter a WhatsApp number") || selectField.required("Please enter a valid email address"),

            // companyEmail:  selectField.required("Please enter a valid email address") || Yup.string()
            // .matches(/^\+?[1-9]\d{1,14}$/, "Please enter a valid WhatsApp number (e.g., +1234567890)")
            // .required("Please enter a WhatsApp number"),
            channel: selectField.required("Please select a channel"),
            frequency: selectField.required("Please select a frequency"),
            country: selectField.required("Please select a country"),
        }),

        onSubmit: (values) => {
            console.log("Form submitted:", values);

            const serviceTypeRatesItems = serviceTypeRatesOptions.reduce((acc, item) => {
                acc.push({ chargeType: item?.value });
                return acc;
            }, []);

            const payload = {
                id: navigateState?.state?.data?.id,
                version: navigateState?.state?.data?.version,
                ruleName: values.ruleName, //
                channel: values.channel?.value || values.channel,
                channelDetail: values.whatsappNumber || values?.companyEmail?.value || '',
                frequency: values.frequency?.value,
                startDate: new Date().toISOString().split('T')[0],
                endDate: new Date().toISOString().split('T')[0],
                interval: values.interval?.value || 0,
                countryId: values?.country?.id,
                lastSent: new Date().toISOString().split('T')[0] || '',
                localTime: values.startDateTime.split("T")[1] || "00:00",
                templateId: values.templateId,
                senderEmail: values.senderEmail?.value || '',
                subject: values.subject || '',
                fromEmail: values.fromEmail || '',
                serviceType: values.serviceType?.value || '',
                tenantEmailChargeTypes: serviceTypeRatesItems,
                tenantRuleRecipientsMappings: customer_data?.content
                    ?.filter((data) =>
                        data?.country?.id === formik.values.country?.id && selectedIds.includes(data?.id)
                    )
                    ?.map((recipient) => ({
                        emailId: recipient.contactEmail,
                        contactNo: recipient.contactNo,
                        customerId: recipient?.id,
                        customerName: recipient?.name
                    })) || [],
                tenantEmailScheduleDays: values.scheduleDays?.map((day) => ({
                    dayOfWeek: day.value,
                })) || [],
            };

            if (values.frequency?.value == "weekdays") {
                payload.startDate = sheduleData.start
                payload.endDate = sheduleData.end
                payload.tenantEmailScheduleDays = sheduleData?.selectedDays?.map((day) => ({
                    dayOfWeek: day,
                })) || []
            } else if (values.frequency?.value == "daily") {
                payload.startDate = sheduleData.start
                payload.endDate = sheduleData.end
            } else if (values.frequency?.value == "weekly") {
                payload.startDate = sheduleData.start
                payload.endDate = sheduleData.end
                payload.tenantEmailScheduleDays = sheduleData?.selectedDays?.map((day) => ({
                    dayOfWeek: day,
                })) || []
            } else if (values.frequency?.value == "monthly") {
                payload.startDate = sheduleData.start
                payload.endDate = sheduleData.end
                payload.tenantEmailScheduleDays = [{ dayOfWeek: sheduleData.weekDay }]
            } else if (values.frequency?.value == "yearly") {
                payload.startDate = sheduleData.start
                payload.endDate = sheduleData.end
                payload.tenantEmailScheduleDays = [{ dayOfWeek: sheduleData.weekDay }]
            }

            console.log("Payload prepared:", payload);
            if (payload?.tenantRuleRecipientsMappings?.length > 0) {
                dispatch(saveMarketingData(payload))
            } else {
                showErrorToast("Please Select At least One Recipient")
            }
        },
    });

    const onCloseClick = () => {
        setRecurrenceModal(false)
        setIsDailyModalOpen(false)
        setMonthlyRecurrenceModal(false)
        setYearlyRecurrenceModal(false)
        setWeeklyRecurrenceModal(false)
    }

    useEffect(() => {
        dispatch({ type: GET_POSTMARK_VERIFIED_EMAILS });
    }, []);

    useEffect(() => {
        formik.setValues({
            subject: navigateState?.state?.data?.subject,
            fromEmail: navigateState?.state?.data?.fromEmail,
            postmarkTemplates: templateLists.find(data => data.value === navigateState?.state?.data?.templateId),
            serviceType: serviceType.find(type => type.value === navigateState?.state?.data?.serviceType),
            senderEmail: verifiedEmails.find(email => email.value === navigateState?.state?.data?.fromEmail),
            ruleName: navigateState?.state?.data?.ruleName,
            whatsappNumber: navigateState?.state?.data?.channelDetail,
            companyEmail: { label: tenant_info?.tenantEmailDetails?.find(data => data?.emailId == navigateState?.state?.data?.channelDetail)?.emailId, value: tenant_info?.tenantEmailDetails?.find(data => data?.emailId == navigateState?.state?.data?.channelDetail)?.emailId },
            channel: channel.find(data => data?.value == navigateState?.state?.data?.channel),
            startDateTime: navigateState?.state?.data?.startDate || new Date().toISOString().split('T')[0],
            endDateTime: navigateState?.state?.data?.endDate || new Date().toISOString().split('T')[0],
            frequency: options?.find(data => data?.value == navigateState?.state?.data?.frequency),
            country: country_master?.find(data => data?.id == navigateState?.state?.data?.countryId)
        });

        const customerIds = navigateState?.state?.data?.tenantRuleRecipientsMappings?.map(data => data?.customerId) || [];
        setSelectedIds(customerIds)

        const chargeTypesSet = new Set(navigateState?.state?.data?.tenantEmailChargeTypes.map(item => item.chargeType));
        const filteredOptions = serviceTypeRates.filter(rate => chargeTypesSet.has(rate.value));
        setServiceTypeRatesOptions(filteredOptions || []);

    }, []);

    return (
        <>
            <div className="page-content settings_users_wrapper">
                <div className="form-container">
                    <button type="button" className="btn border mb-3" onClick={() => { navigate(-1); }} > Back </button>
                    <div className="row">
                        <div className="col-lg-6">
                            <h5 className="mb-3">Add Campaign</h5>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label className="form-label">Campaign Name</label>
                                            <Input
                                                type="text"
                                                name="ruleName"
                                                value={formik.values.ruleName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className="form-control"
                                                placeholder="Enter Rule Name"
                                                invalid={formik.touched.ruleName && formik.errors.ruleName}
                                            />
                                            {formik.touched.ruleName && formik.errors.ruleName ? (
                                                <FormFeedback>{formik.errors.ruleName}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label className="form-label">Campaign Channel</label>
                                            <Select
                                                name="channel"
                                                options={channel}
                                                value={formik.values.channel}
                                                onChange={(e) => { formik.setFieldValue(`channel`, e); }}
                                                onBlur={formik.handleBlur}
                                                placeholder="Select channel"
                                                classNamePrefix="select2-selection form-select"
                                            />
                                            {formik.touched.channel && formik.errors.channel ? (
                                                <FormFeedback>{formik.errors.channel}</FormFeedback>
                                            ) : null}
                                        </div>

                                        {formik.values.channel?.value == 'PostMark' && <div className="col-md-12 mb-3">
                                            <label className="form-label">Postmark Templates</label>
                                            <Select
                                                name="postmarkTemplates"
                                                options={templateLists}
                                                value={formik.values.postmarkTemplates}
                                                onChange={(e) => {
                                                    console.log(e, "postmarkTemplates");
                                                    formik.setFieldValue(`postmarkTemplates`, e);
                                                    formik.setFieldValue(`templateId`, e.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                placeholder="Select Postmark Emails"
                                                classNamePrefix="select2-selection form-select"
                                            />
                                            {formik.touched.postmarkTemplates && formik.errors.postmarkTemplates ? (
                                                <FormFeedback>{formik.errors.postmarkTemplates}</FormFeedback>
                                            ) : null}
                                        </div>}

                                        {formik.values.channel?.value == 'CompanyEmail' && <div className="col-md-12 mb-3">
                                            <label className="form-label">Company Email</label>
                                            <Select
                                                name="companyEmail"
                                                options={tenant_info?.tenantEmailDetails?.filter((value, index, self) => index === self.findIndex((email) => email?.emailId === value?.emailId))?.map(data => ({ label: data?.emailId, value: data?.emailId }))}
                                                value={formik.values.companyEmail}
                                                onChange={(e) => { formik.setFieldValue(`companyEmail`, e); }}
                                                onBlur={formik.handleBlur}
                                                placeholder="Select companyEmail"
                                                classNamePrefix="select2-selection form-select"
                                            />
                                            {formik.touched.companyEmail && formik.errors.companyEmail ? (
                                                <FormFeedback>{formik.errors.companyEmail}</FormFeedback>
                                            ) : null}
                                        </div>}
                                        {formik.values.channel?.value == 'Whatsapp' && <div className="col-md-12 mb-3">
                                            <label className="form-label">Whatsapp Number</label>
                                            <Input
                                                type="number"
                                                name="whatsappNumber"
                                                value={formik.values.whatsappNumber}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className="form-control"
                                                placeholder="Enter Rule Name"
                                                invalid={formik.touched.whatsappNumber && formik.errors.whatsappNumber}
                                            />
                                            {formik.touched.whatsappNumber && formik.errors.whatsappNumber ? (
                                                <FormFeedback>{formik.errors.whatsappNumber}</FormFeedback>
                                            ) : null}
                                        </div>
                                        }

                                        <div className="col-12 col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Subject Line</label>
                                                <Input
                                                    type="text"
                                                    name="subject"
                                                    value={formik.values.subject}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className="form-control"
                                                    placeholder="Enter Subject Line"
                                                    invalid={formik.touched.subject && formik.errors.subject}
                                                />

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Sender Email</label>
                                                <Select
                                                    name="senderEmail"
                                                    options={verifiedEmails}
                                                    value={formik.values.senderEmail}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`senderEmail`, e);
                                                        formik.setFieldValue(`fromEmail`, e.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    placeholder="Select Sender Email"
                                                    classNamePrefix="select2-selection form-select"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Service Type</label>
                                                <Select
                                                    name="serviceType"
                                                    options={serviceType}
                                                    value={formik.values.serviceType}
                                                    onChange={(e) => {
                                                        setServiceTypeOptions(e)
                                                        formik.setFieldValue("serviceType", e);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    placeholder="Select service type"
                                                    classNamePrefix="select2-selection form-select"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Charge Type Rates</label>
                                                <Select
                                                    isMulti
                                                    name="serviceTypeRates"
                                                    options={serviceTypeRates}
                                                    value={serviceTypeRatesOptions}
                                                    onChange={setServiceTypeRatesOptions}
                                                    onBlur={formik.handleBlur}
                                                    placeholder="Select service type"
                                                    classNamePrefix="select2-selection form-select"
                                                />

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Frequency</label>
                                                <Select
                                                    name="frequency"
                                                    options={options}
                                                    value={formik.values.frequency}
                                                    onChange={(e) => {
                                                        if (e.value == "weekdays") {
                                                            setRecurrenceModal(true)
                                                        } else if (e.value == "daily") {
                                                            setIsDailyModalOpen(true)
                                                        } else if (e.value == "weekly") {
                                                            setWeeklyRecurrenceModal(true)
                                                        } else if (e.value == "monthly") {
                                                            setMonthlyRecurrenceModal(true)
                                                        } else if (e.value == "yearly") {
                                                            setYearlyRecurrenceModal(true)
                                                        }
                                                        formik.setFieldValue(`frequency`, e);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    placeholder="Select Frequency"
                                                    classNamePrefix="select2-selection form-select"
                                                />
                                                {formik.touched.frequency && formik.errors.frequency ? (
                                                    <FormFeedback>{formik.errors.frequency}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Country</label>
                                            <Select
                                                name="country"
                                                options={country_master}
                                                value={formik.values.country}
                                                onChange={(e) => { formik.setFieldValue(`country`, e); }}
                                                onBlur={formik.handleBlur}
                                                placeholder="Select country"
                                                classNamePrefix="select2-selection form-select"
                                            />
                                            {formik.touched.country && formik.errors.country ? (
                                                <FormFeedback>{formik.errors.country}</FormFeedback>
                                            ) : null}
                                        </div>



                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-lg-6">
                            <h5>Recipients</h5>
                            <div>
                                <div className="table-responsive">
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <button
                                                        type="button"
                                                        className={`btn btn-checkbox ${customer_data?.content?.filter(data => data?.country?.id === formik.values.country?.id)?.length &&
                                                            selectedIds.length === customer_data?.content?.filter(data => data?.country?.id === formik.values.country?.id)?.length
                                                            ? "btn-checkbox-selected" : ""}`}
                                                        onClick={() => handleSelectAll()}
                                                    >
                                                        {selectedIds.length === customer_data?.content?.filter(data => data?.country?.id === formik.values.country?.id)?.length && (
                                                            <i className="bi bi-check"></i>
                                                        )}
                                                    </button>
                                                </th>
                                                <th>Name</th>
                                                <th>Contact No.</th>
                                                <th>Email ID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customer_data?.content
                                                ?.filter((data) => data?.country?.id === formik.values.country?.id)
                                                .map((customer) => (
                                                    <tr key={customer.id}>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className={`btn btn-checkbox ${selectedIds.includes(customer.id) ? "btn-checkbox-selected" : ""}`}
                                                                onClick={() => handleRowSelect(customer.id)}
                                                            >
                                                                {selectedIds.includes(customer.id) && <i className="bi bi-check"></i>}
                                                            </button>
                                                        </td>
                                                        <td>{customer.name}</td>
                                                        <td>{customer.contactNo}</td>
                                                        <td>{customer.contactEmail}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="d-flex justify-content-center mt-4">
                        <button className="btn btn-primary mx-2" onClick={formik.handleSubmit}>
                            Save
                        </button>
                        <button className="btn btn-secondary mx-2" onClick={formik.resetForm}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <RecurrenceModal modal={recurrenceModal} onCloseClick={onCloseClick} setSheduleData={setSheduleData} />
            <DailyRecurrenceModal modal={isDailyModalOpen} onCloseClick={onCloseClick} setSheduleData={setSheduleData} />
            <WeeklyRecurrenceModal modal={weeklyRecurrenceModal} onCloseClick={onCloseClick} setSheduleData={setSheduleData} />
            <MonthlyRecurrenceModal modal={monthlyRecurrenceModal} onCloseClick={onCloseClick} setSheduleData={setSheduleData} />
            <YearlyRecurrenceModal modal={yearlyRecurrenceModal} onCloseClick={onCloseClick} setSheduleData={setSheduleData} />
        </>
    )
}

export default AddRulePage;
