import {
    GET_SUBSCRIBER_DETAILS_DATA,
    GET_SUBSCRIBER_GROUP_DATA,
    GET_SUBSCRIBER_IMAGE_DATA, GET_TENANTS_DETAILS_DATA_BY_ID, GET_TENANTS_DETAILS_DATA_BY_ID_ERROR,
    POST_SUBSCRIBER_DATA, POST_TENANTS_STATUS_UPDATE_DATA
} from "./actionType";


export const getSubscriberSummaryList = (dataObj) => ({
    type: GET_SUBSCRIBER_GROUP_DATA,
    payload: dataObj
});

export const getSubscriberProfileImageData = (dataObj) => ({
    type: GET_SUBSCRIBER_IMAGE_DATA,
    payload: dataObj
})

export const getSubscriberDetailsList = (dataObj) => ({
    type: GET_SUBSCRIBER_DETAILS_DATA,
    payload: dataObj
})

export const postSubscriberData = (dataObj) => ({
    type: POST_SUBSCRIBER_DATA,
    payload: dataObj,
});

export const getTenantsDetailsData = (dataObj) => ({
    type: GET_TENANTS_DETAILS_DATA_BY_ID,
    payload: dataObj
});

export const postTenantsStatusUpdate = (dataObj) => ({
    type: POST_TENANTS_STATUS_UPDATE_DATA,
    payload: dataObj
})