import React, {useEffect, useState} from "react";
import {Button, FormFeedback, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {
    getAllUserDetails,
    getCustomersCityData,
    getCustomersCountryData,
    getCustomersPincodeData,
    getCustomersStateData
} from "../../../store/Parties/actions";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {optionCusttitle} from "../../../common/data/settings";
import {addCompanySchema} from "../schema/AddCompanySchema";
import {getAllPartiesCustomerData, postCustomerDetailsAction} from "../../../store/Parties/Customer/action";
import {getTenantInfoData} from "../../../store/Settings/actions";

const ModalAddNewCustomer = ({ modal, onCloseClick }) => {
    const dispatch = useDispatch();
    const [salesEmployeeModal, setSalesEmployeeModal] = useState(false);
    const [keyAccountManagerModal, setKeyAccountManagerModal] = useState(false);
    const [authUser, setAuthUser] = useState({});
    const { parties_city_details, parties_all_details, parties_all_employee_details, parties_state_details, parties_country_details, parties_pincode_details } = useSelector(
        (state) => state?.parties
    );
    useEffect(() => {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        setAuthUser(JSON.parse(localStorage.getItem("authUser")));
    }, []);

    const { country_master_isd_icd } = useSelector((state) => state?.globalReducer);

    useEffect(() => {
        dispatch(getCustomersCityData())
        dispatch(getAllUserDetails());
    }, []);

    useEffect(() => {
        if (parties_state_details && parties_state_details?.content?.length > 0) {
            companyDetailsFormik.setFieldValue("state", parties_state_details?.content[0]?.stateName)
        }
        if (parties_country_details && parties_country_details?.content?.length > 0) {
            companyDetailsFormik.setFieldValue("country", parties_country_details?.content[0]?.countryName)
            companyDetailsFormik.setFieldValue("opCode", country_master_isd_icd.find(data => data.id == parties_country_details?.content[0]?.id))
        }
    }, [parties_state_details, parties_country_details, parties_pincode_details, parties_all_details]);

    const optionOfEmployee = parties_all_employee_details?.content || [];

    const salesEmployeeOptions = optionOfEmployee?.map((item) => ({
        value: item?.firstName,
        label: item?.firstName + " " + item?.lastName,
        id: item?.id,
        version: item?.version
    }));

    const accountManagerOptions = optionOfEmployee?.map((item) => {
        return {
            value: item?.firstName,
            label: item?.firstName + " " + item?.lastName,
            id: item?.id,
            version: item?.version
        }
    });


    const companyDetailsFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyName:  "",
            companyCode: "",
            city: null,
            state: null,
            country: null,
            zipcode: null,
            contactName: "",
            phoneNumber: "",
            email: "",
            title: "",
            opCode: "",
            salesEmployee: salesEmployeeOptions.find((option) => option.id === authUser?.userId)?.value,
            keyAccountManager: accountManagerOptions.find((option) => option.id === authUser?.userId)?.value,
        },
        validationSchema: addCompanySchema,
        onSubmit: async (values) => {
            console.log("values", values);
            let countryVal = parties_country_details?.content?.filter((con) => con?.countryName === values?.country) || [];
            let cityVal = parties_city_details?.content?.filter((city) => city?.cityName === values?.city) || [];
            let stateVal = parties_state_details?.content?.filter((state) => state?.stateName === values?.state) || [];
            let pincodeVal = parties_pincode_details?.content?.filter((pin) => pin?.pin === values?.zipcode) || [];
            let salesEmployeeVal = salesEmployeeOptions.find((option) => option.value === values?.salesEmployee);
            let accountManagerVal = accountManagerOptions.find((option) => option.value === values?.keyAccountManager);

            let formData = new FormData();

            const projectUATRequestDTO = {
                ...Object.fromEntries(Object.entries({
                    "name": values.companyName || null,
                    customerCode: values.companyCode,
                    id: null,
                    version: 0,
                    ...(pincodeVal?.length !== 0 && {
                        "pinCode": {
                            id: pincodeVal[0]?.id,
                            version: pincodeVal[0]?.version
                        },
                    }),
                    ...(cityVal?.length !== 0 && {
                        "city": {
                            id: cityVal[0]?.id,
                            version: cityVal[0]?.version
                        },
                    }),
                    ...(stateVal?.length !== 0 && {
                        "state": {
                            id: stateVal[0]?.id,
                            version: stateVal[0]?.version
                        },
                    }),
                    ...(countryVal?.length !== 0 && {
                        "country": {
                            id: countryVal[0]?.id,
                            version: countryVal[0]?.version
                        },
                    }),
                    "contactName": values.contactName || null,
                    "contactNo": values.phoneNumber || null,
                    "contactEmail": values.email || null,
                    "salesUser": {
                        id: salesEmployeeVal?.id,
                        version: salesEmployeeVal?.version
                    },
                    "accountManager": {
                        id: accountManagerVal?.id,
                        version: accountManagerVal?.version
                    },
                    "transportMode": "AIR",
                    "addresses": [],
                    "contacts": [],
                    tenantCustomerQuotationMargins: [],
                    documents: [],
                    "status": "ACTIVE",
                }).filter(([_, value]) => value !== null)),
            }
            console.log(projectUATRequestDTO, "projectUATRequestDTO");
            formData.append('tenantCustomer', new Blob([JSON.stringify(projectUATRequestDTO)], { type: "application/json" }));
            dispatch(postCustomerDetailsAction(formData));
            setTimeout(() => {
                dispatch(getAllPartiesCustomerData(""));
                companyDetailsFormik.resetForm();
                onCloseClick();
            }, 4000);
        }
    });

    return(
        <>
            <Modal isOpen={modal} toggle={onCloseClick} size="xl">
                <ModalHeader tag="h4">
                    Add New Customer
                    <span className="close" onClick={onCloseClick}></span>
                </ModalHeader>
                <ModalBody>
                    <div className="p-3">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">
                                        Customer Code<span className="required_star">*</span>
                                    </label>
                                    <Input
                                        type="text"
                                        name="companyCode"
                                        value={companyDetailsFormik.values.companyCode}
                                        onChange={companyDetailsFormik.handleChange}
                                        className="form-control"
                                        placeholder=""
                                        onBlur={companyDetailsFormik.handleBlur}
                                        invalid={
                                            companyDetailsFormik.touched.companyCode &&
                                            companyDetailsFormik.errors.companyCode
                                                ? true
                                                : false
                                        }
                                    />
                                    {companyDetailsFormik.touched.companyCode &&
                                    companyDetailsFormik.errors.companyCode ? (
                                        <FormFeedback>{companyDetailsFormik.errors.companyCode}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Customer Name<span className='required_star'>*</span></label>
                                    <Input
                                        type="text"
                                        name="companyName"
                                        value={companyDetailsFormik.values.companyName}
                                        onChange={companyDetailsFormik.handleChange}
                                        className="form-control"
                                        placeholder=""
                                        onBlur={companyDetailsFormik.handleBlur}
                                        invalid={companyDetailsFormik.touched.companyName && companyDetailsFormik.errors.companyName ? true : false}
                                    />
                                    {companyDetailsFormik.touched.companyName && companyDetailsFormik.errors.companyName ? (
                                        <FormFeedback>{companyDetailsFormik.errors.companyName}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">City<span className='required_star'>*</span></label>
                                    <Input
                                        type="text"
                                        name="city"
                                        list='cityList'
                                        value={companyDetailsFormik.values.city}
                                        // onChange={companyDetailsFormik.handleChange}
                                        onChange={(e) => {
                                            companyDetailsFormik.handleChange(e);
                                            companyDetailsFormik.setFieldValue('state', '');
                                            companyDetailsFormik.setFieldValue('country', '');
                                            companyDetailsFormik.setFieldValue('zipcode', '');
                                            companyDetailsFormik.setFieldValue('opCode', '');
                                            const cityData = parties_city_details?.content?.find((city) => city.cityName === e.target.value);
                                            if (cityData) {
                                                dispatch(getCustomersStateData({ cityId: cityData.id }));
                                                dispatch(getCustomersCountryData({ cityId: cityData.id }));
                                                dispatch(getCustomersPincodeData({ cityId: cityData.id }));
                                            }
                                        }
                                        }
                                        className="form-control"
                                        placeholder=""
                                        onBlur={companyDetailsFormik.handleBlur}
                                        invalid={companyDetailsFormik.touched.city && companyDetailsFormik.errors.city ? true : false}
                                    />
                                    {companyDetailsFormik.touched.city && companyDetailsFormik.errors.city ? (
                                        <FormFeedback>{companyDetailsFormik.errors.city}</FormFeedback>
                                    ) : null}
                                    <datalist id="cityList">
                                        {parties_city_details && parties_city_details?.content?.map((item, i) => <option key={i} value={item.cityName} />)}
                                    </datalist>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">State</label>
                                    <Input
                                        type="text"
                                        name="state"
                                        value={companyDetailsFormik.values.state}
                                        onChange={companyDetailsFormik.handleChange}
                                        className="form-control"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Country<span className='required_star'>*</span></label>
                                    <Input
                                        type="text"
                                        name="country"
                                        value={companyDetailsFormik.values.country}
                                        onChange={companyDetailsFormik.handleChange}
                                        className="form-control"
                                        placeholder=""
                                        readOnly={true}
                                        onBlur={companyDetailsFormik.handleBlur}
                                        invalid={companyDetailsFormik.touched.country && companyDetailsFormik.errors.country ? true : false}
                                    />
                                    {companyDetailsFormik.touched.country && companyDetailsFormik.errors.country ? (
                                        <FormFeedback>{companyDetailsFormik.errors.country}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Zipcode</label>
                                    <Input
                                        type="text"
                                        name="zipcode"
                                        list='pincodeList'
                                        value={companyDetailsFormik.values.zipcode}
                                        onChange={companyDetailsFormik.handleChange}
                                        className="form-control"
                                        placeholder=""
                                    />
                                    <datalist id="pincodeList">
                                        {parties_pincode_details && parties_pincode_details?.content?.map((item, i) => <option key={i} value={item.pin} />)}
                                    </datalist>
                                </div>
                            </div>
                        </div>
                        {/* Primary Contact Details */}
                        <div className="text-center mb-4">
                            <h5>Primary Contact Details</h5>
                        </div>
                        <div className='row'>
                            <div className="col">
                                <div className="mb-3">
                                    <label className="form-label">Contact Name<span className='required_star'>*</span></label>
                                    <div className='row'>
                                        <div className='col-4 col-md-2'>
                                            <Select
                                                name='title'
                                                value={
                                                    optionCusttitle
                                                        ? optionCusttitle.find(
                                                            (option) =>
                                                                option.value ===
                                                                companyDetailsFormik?.values?.title
                                                        )
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    companyDetailsFormik.setFieldValue(
                                                        `title`,
                                                        e.value
                                                    );
                                                }}
                                                placeholder="Mr"
                                                options={optionCusttitle}
                                                classNamePrefix="select2-selection form-select"
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <Input
                                                type="text"
                                                name="contactName"
                                                value={companyDetailsFormik.values.contactName}
                                                onChange={companyDetailsFormik.handleChange}
                                                className="form-control"
                                                placeholder=""
                                                onBlur={companyDetailsFormik.handleBlur}
                                                invalid={companyDetailsFormik.touched.contactName && companyDetailsFormik.errors.contactName ? true : false}
                                            />
                                            {companyDetailsFormik.touched.contactName && companyDetailsFormik.errors.contactName ? (
                                                <FormFeedback>{companyDetailsFormik.errors.contactName}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12 col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Phone Number<span className='required_star'>*</span></label>
                                    <div className='row'>
                                        <div className='col-4 col-md-3'>
                                            <Select
                                                name='opCode'
                                                value={companyDetailsFormik?.values?.opCode}
                                                onChange={companyDetailsFormik.handleChange}
                                                placeholder="+91"
                                                options={country_master_isd_icd}
                                                classNamePrefix="select2-selection form-select"
                                            />
                                        </div>
                                        <div className='col-8 col-md-9'>
                                            <Input
                                                type="text"
                                                name="phoneNumber"
                                                value={companyDetailsFormik.values.phoneNumber}
                                                onChange={companyDetailsFormik.handleChange}
                                                className="form-control"
                                                placeholder=""
                                                onBlur={companyDetailsFormik.handleBlur}
                                                invalid={companyDetailsFormik.touched.phoneNumber && companyDetailsFormik.errors.phoneNumber ? true : false}
                                            />
                                            {companyDetailsFormik.touched.phoneNumber && companyDetailsFormik.errors.phoneNumber ? (
                                                <FormFeedback>{companyDetailsFormik.errors.phoneNumber}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="">
                                    <label className="form-label">Email Id<span className='required_star'>*</span></label>
                                </div>
                                <Input
                                    type="text"
                                    name="email"
                                    value={companyDetailsFormik.values.email}
                                    onChange={companyDetailsFormik.handleChange}
                                    className="form-control"
                                    placeholder=""
                                    onBlur={companyDetailsFormik.handleBlur}
                                    invalid={companyDetailsFormik.touched.email && companyDetailsFormik.errors.email ? true : false}
                                />
                                {companyDetailsFormik.touched.email && companyDetailsFormik.errors.email ? (
                                    <FormFeedback>{companyDetailsFormik.errors.email}</FormFeedback>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Sales Employee<span className='required_star'>*</span></label>
                                    <Select
                                        name='salesEmployee'
                                        value={
                                            salesEmployeeOptions
                                                ? salesEmployeeOptions.find(
                                                    (option) =>
                                                        option?.id === authUser?.userId
                                                )
                                                : ""
                                        }
                                        onChange={(e) => {
                                            if (e.label == "Add New") {
                                                setSalesEmployeeModal(true)
                                            } else {
                                                companyDetailsFormik.setFieldValue(`salesEmployee`, e.value);
                                            }
                                        }}
                                        options={salesEmployeeOptions}
                                        classNamePrefix="select2-selection form-select"
                                        onBlur={companyDetailsFormik.handleBlur}
                                        invalid={companyDetailsFormik.touched.salesEmployee && companyDetailsFormik.errors.salesEmployee ? true : false}
                                    />
                                    {companyDetailsFormik.touched.salesEmployee && companyDetailsFormik.errors.salesEmployee ? (
                                        <FormFeedback>{companyDetailsFormik.errors.salesEmployee}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Key Account Manager<span className='required_star'>*</span></label>
                                    <Select
                                        name='keyAccountManager'
                                        value={
                                            accountManagerOptions
                                                ? accountManagerOptions.find(
                                                    (option) =>
                                                        option?.id === authUser?.userId
                                                )
                                                : ""
                                        }
                                        onChange={(e) => {
                                            if (e.label == "Add New") {
                                                setKeyAccountManagerModal(true)
                                            } else {
                                                companyDetailsFormik.setFieldValue(`keyAccountManager`, e.value);
                                            }
                                        }}
                                        options={accountManagerOptions}
                                        classNamePrefix="select2-selection form-select"
                                        onBlur={companyDetailsFormik.handleBlur}
                                        invalid={companyDetailsFormik.touched.keyAccountManager && companyDetailsFormik.errors.keyAccountManager ? true : false}
                                    />
                                    {companyDetailsFormik.touched.keyAccountManager && companyDetailsFormik.errors.keyAccountManager ? (
                                        <FormFeedback>{companyDetailsFormik.errors.keyAccountManager}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end">
                    <Button type="button" className="btn btn-primary d-flex align-items-center" onClick={() => companyDetailsFormik.submitForm()}>
                        Add Customer
                    </Button>
                    <Button color="secondary" onClick={() => {
                        companyDetailsFormik.resetForm();
                        onCloseClick();
                    }}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalAddNewCustomer;
