import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap'
import { eye_icon, upload_icon } from '../../../assets/images'
import { quotationApprovalcrumb, requestForRatescrumb } from '../../../common/data/sales'
import TfBreadcrumbs from '../../../components/Common/TfBreadcrumbs'
import { getInquirySummeryData, getSalesInquiryData, getTenantQuotationSummery, getTenantQuotationSummeryById } from '../../../store/Sales/actions'
import { GET_INQUIRY_DATA_SUCCESS } from '../../../store/Sales/actiontype'
import EmailSendtoCarrierPopUp from '../../Email/EmailSendtoCarrierPopUp'
import FilterSalesInquiryComp from '../partials/FilterSalesInquiryComp'
import { QueriesColVal } from "../partials/SalesCol"
import SalesCommonTable from '../partials/SalesCommonTable'
import { MGR_APPRV_PENDING, MANAGER_APPRVD, REQUEST_FOR_RATE } from '../../Dashboard/Partials/initialData'
import { getUsersData } from '../../../store/Settings/actions'
import Select from "react-select";
import AssignePopUp from '../Quotations/Dialog/AssignePopUp'
import ApproveRejectPopUp from '../Quotations/Dialog/ApproveRejectPopUp'
import FilterSalesComp from '../partials/FilterSalesComp'
import QuotationModalComp from '../../InstantRate/partials/QuotationModalComp'
import {getInternalRoute} from "../../../components/Common/CommonLogic";

export default function RequestForRates() {
    document.title = "Inquiry || Navigating Freight Costs with Precision||Ultimate Rate Management platform"
    const { settings_users_data, users_loader } = useSelector((state) => state.settings);
    const { inquiry_data_loader } = useSelector((state) => state?.sales);

    const { fcl_summary_data } = useSelector((state) => state?.quotation);

    const [isRight, setIsRight] = useState(false);
    const [popUpData, setPopUpData] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [assingToPopUp, setAssingToPopUp] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modal, setmodal] = useState(false);
    const [quotationData, setQuotationData] = useState(null);
    const [viewQuotation, setViewQuotation] = useState(null)
    const inputArr = {
        org_port: '',
        dest_port: '',
        status: '',
    }
    const [filterDetails, setfilterDetails] = useState(inputArr);
    const [selectedRows, setSelectedRows] = useState([]);
    const [seletedInquiry, seletedInquiryRows] = useState([]);

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const onClickAssingto = () => {
        setPopUpData("Approve")
        setAssingToPopUp(true)
    }

    const onClickReject = () => {
        setPopUpData("Reject")
        setAssingToPopUp(true)
    }

    const onClickCancle = () => {
        setAssingToPopUp(false);
    }


    const onCloseClick = () => {
        setmodal(false)
    }

    const applyFilterHandler = () => {
        setIsRight(false);
        if (filterDetails?.carrier_name || filterDetails?.cargo_type || filterDetails?.quotation_from || filterDetails?.quotation_to || filterDetails?.quote_status || filterDetails?.movement || filterDetails?.dest_port || filterDetails?.org_port) {
            let url = `?`;
            url += `${filterDetails?.carrier_name?.id ? `carriers=${filterDetails?.carrier_name.id}&` : ''}`;
            url += `${filterDetails?.vendor_name?.id ? `vendor=${filterDetails?.vendor_name.id}&` : ''}`;
            url += `${filterDetails?.cargo_type?.id ? `cargoType=${filterDetails?.cargo_type.id}&` : ''}`;
            url += `${filterDetails?.org_port?.id ? `originPort=${filterDetails?.org_port.id}&` : ''}`;
            url += `${filterDetails?.dest_port?.id ? `destinationPort=${filterDetails?.dest_port.id}&` : ''}`;
            url += `${filterDetails?.quotation_from ? `validFrom=${filterDetails?.quotation_from}&` : ''}`;
            url += `${filterDetails?.quotation_to ? `validTo=${filterDetails?.quotation_to}&` : ''}`;
            url += `${filterDetails?.quote_status?.id ? `quotationStatus=${filterDetails?.quote_status?.id}&` : ''}`;
            url += `${filterDetails?.container_type?.id ? `containerType=${filterDetails?.container_type?.id}&` : ''}`;
            let newUrl = url.substring(0, url.length - 1);
            dispatch(getTenantQuotationSummery(newUrl));
        }
    }

    const clearValueHandler = () => {
        setfilterDetails(inputArr)
    }

    useEffect(() => {
        dispatch(getUsersData(""));
        dispatch(getTenantQuotationSummery(""));
    }, []);

    useEffect(() => {
        setSelectedRows([])
        seletedInquiryRows([])
    }, [inquiry_data_loader])


    const onClickRequestForRates = (data) => {
        console.log(data);
        let newData = {
            destinationPort: data?.destination,
            originPort: data?.origin,
            inquiryId: data?.inquiryId,
        };
        /*navigate(`/freight/ocean/fcl/AddDetails`, {
            state: {
                data: newData || '',
            },
        });*/
        navigate(getInternalRoute("freight-ocean-fcl-add"), {
            state: {
                data: newData || '',
            },
        });
    }

    const handleSelectAll = (isChecked) => {
        if (isChecked) {
            const allIds = fcl_summary_data?.DRAFT?.filter(data => data?.statusCode == MGR_APPRV_PENDING)?.map((item) => item.id) || [];
            setSelectedRows(allIds);
        } else {
            setSelectedRows([]);
        }
    };

    const handleInquirySelectAll = (isChecked) => {
        if (isChecked) {
            const allIds = fcl_summary_data?.DRAFT?.filter(data => data?.statusCode == MGR_APPRV_PENDING)?.map((item) => item?.inquiryId) || [];
            seletedInquiryRows(allIds);
        } else {
            seletedInquiryRows([]);
        }
    };

    const handleRowSummaryIdSelect = (isChecked, summeryId) => {
        if (isChecked) {
            setSelectedRows((prev) => [...prev, summeryId]);
        } else {
            setSelectedRows((prev) => prev.filter((id) => id !== summeryId));
        }
    };
    const handleRowInquiryIdSelect = (isChecked, inquiryId) => {
        if (isChecked) {
            seletedInquiryRows((prev) => [...prev, inquiryId]);
        } else {
            seletedInquiryRows((prev) => prev.filter((id) => id !== inquiryId));
        }
    };

    const columns = useMemo(() => [
        {
            Header: (
                <Input
                    type="checkbox"
                    className="custom-checkbox"
                    onChange={(e) => { handleSelectAll(e.target.checked); handleInquirySelectAll(e.target.checked) }}
                    defaultChecked={
                        fcl_summary_data?.DRAFT?.length > 0 &&
                        selectedRows.length === fcl_summary_data?.DRAFT?.length
                    }
                />
            ),
            accessor: 'select',
            disableFilters: true,
            Cell: ({ row }) => (
                <Input
                    type="checkbox"
                    className="custom-checkbox"
                    defaultChecked={selectedRows.includes(row.original.id)}
                    onChange={(e) => { handleRowSummaryIdSelect(e.target.checked, row.original.id); handleRowInquiryIdSelect(e.target.checked, row.original?.inquiryId) }}
                />
            ),
        },
        {
            Header: 'Inquiry Dt',
            accessor: 'inquiryDate',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Inquiry ID',
            accessor: 'inquiryId',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Quotation Date',
            accessor: 'quotationDate',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Quotation Id',
            accessor: 'id',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Version',
            accessor: 'version',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Customer Name',
            accessor: 'customerName',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Sales Emp',
            accessor: 'salesUserName',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'View Quotation',
            accessor: 'viewQuotation',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <div className="d-flex justify-content-center align-items-center h-100">
                    <img src={eye_icon} alt="Eye" onClick={() => { setViewQuotation(true); dispatch(getTenantQuotationSummeryById(cellProps.row?.original?.id)) }} />
                </div>
            }
        },
    ], [selectedRows, settings_users_data]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div className="main_freight_wrapper">
                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TfBreadcrumbs breadcrumb={quotationApprovalcrumb} />
                        </div>

                        <SalesCommonTable
                            columns={columns}
                            data={fcl_summary_data?.DRAFT?.filter(data => data?.statusCode == MGR_APPRV_PENDING)?.length > 0 ? fcl_summary_data?.DRAFT?.filter(data => data?.statusCode == MGR_APPRV_PENDING) : []}
                            isGlobalFilter={true}
                            isAddInvoiceList={true}
                            loader={inquiry_data_loader}
                            onClickAssingto={onClickAssingto}
                            customPageSize={10}
                            toggleRightCanvas={toggleRightCanvas}
                            onClickReject={onClickReject}
                            component={'quotationApproval'}
                            setCurrentPage={setCurrentPage}
                            totalPages={fcl_summary_data?.DRAFT?.length || 0}
                            totalEntries={fcl_summary_data?.DRAFT?.length || 0}
                        />
                    </div>
                </Container>
            </div>
            <EmailSendtoCarrierPopUp modal={modal} onCloseClick={onCloseClick} quotationData={quotationData} />
            <FilterSalesComp isRight={isRight} toggleRightCanvas={toggleRightCanvas} filterDetails={filterDetails} setfilterDetails={setfilterDetails} applyFilterHandler={applyFilterHandler} clearValueHandler={clearValueHandler} />
            <ApproveRejectPopUp isOpen={assingToPopUp} onClose={onClickCancle} selectedRows={selectedRows} seletedInquiry={seletedInquiry} data={popUpData} />
            <QuotationModalComp quoteModal={viewQuotation} setQuoteModal={setViewQuotation} viewData={true} isViewQuotation={true} />
        </>
    )
}
