import { useEffect } from "react";

const tenantsInternalRoute = [
    { name: "instant-search-rate", path: "/instant-rate/search-rate" },
    { name: "fcl-port-local-update", path: "/fcl/port-local/update" },
    { name: "freight-ocean-fcl-upload-form", path: "/freight/ocean/fcl/upload/form" },
    { name: "freight-upload", path: "/freight/upload/" },
    { name: "freight-inland", path: "/freight/inland" },
    { name: "freight-ocean-fcl-add", path: "/freight/ocean/fcl/AddDetails" },
    { name: "vendor-add", path: "/vendor/add-vendor" },
    { name: "fcl-port-local-upload", path: "/fcl/port-local/upload" },
    { name: "freight-air-masterbill-details", path: "/freight/air/masterbill/details" },
    { name: "freight-air-upload-air-waybill", path: "/freight/air/upload/air-waybill"},
    { name: "customer-add", path: "/customers/add-customer"},
    { name: "freight-ocean-fcl-details", path: "/freight/ocean/fcl/Details"},
    { name: "add-user", path: "/settings/users/addUser"},
    { name: "user-upload-file", path: "/settings/users/uploadFile"},
    { name: "surcharge-add", path: "/settings/surcharge/add"},
    { name: "settings-role", path: "/settings/addRole"},
    { name: "add-rule-lead-genaration", path: "/add-rule-lead-genaration-page"},
    { name: "rule-lead-genarations-page-history", path: "/rule-lead-genaration-page-history"},
    { name: "instant-rate-booking", path: "/instant-rate/search-rate/booking" },
]

const superAdminInternalRoute = [
    { name: "instant-search-rate", path: "/network/instant-rate/search-rate" },
    { name: "fcl-port-local-update", path: "/network/fcl/port-local/update" },
    { name: "freight-ocean-fcl-upload-form", path: "/network/freight/ocean/fcl/upload/form" },
    { name: "freight-upload", path: "/network/freight/upload/" },
    { name: "freight-inland", path: "/network/freight/inland" },
    { name: "freight-ocean-fcl-add", path: "/network/freight/ocean/fcl/AddDetails" },
    { name: "vendor-add", path: "/network/vendor/add-vendor" },
    { name: "fcl-port-local-upload", path: "/network/fcl/port-local/upload" },
    { name: "freight-air-masterbill-details", path: "/network/freight/air/masterbill/details" },
    { name: "freight-air-upload-air-waybill", path: "/network/freight/air/upload/air-waybill"},
    { name: "customer-add", path: "/network/customers/add-customer"},
    { name: "freight-ocean-fcl-details", path: "/network/freight/ocean/fcl/Details"},
    { name: "add-user", path: "/network/settings/users/addUser"},
    { name: "user-upload-file", path: "/network/settings/users/uploadFile"},
    { name: "surcharge-add", path: "/settings/surcharge/add"},
    { name: "settings-role", path: "/network/settings/addRole"},
    { name: "add-rule-lead-genaration", path: "/network/add-rule-lead-genaration-page"},
    { name: "rule-lead-genarations-page-history", path: "/network/rule-lead-genaration-page-history"},
    { name: "instant-rate-booking", path: "/network/instant-rate/search-rate/booking" },
]


export const internalRoutePolicy = (authType) => {
    return authType === '1' ? superAdminInternalRoute : tenantsInternalRoute;
} 

