import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getQuotationSellerListById, getSalesInquiryData} from "../../../store/Sales/actions";
import {formatModifyDate, getAuthType, getInternalRoute} from "../../../components/Common/CommonLogic";
import {QueriesColVal} from "../partials/SalesCol";
import {RATE_AVAILABLE, RATE_UPDATED} from "../../Dashboard/Partials/initialData";
import {Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {upload_icon} from "../../../assets/images";
import TfBreadcrumbs from "../../../components/Common/TfBreadcrumbs";
import {inquiryBreadcrumb, inquiryMarketPlaceBreadcrumb} from "../../../common/data/sales";
import SalesCommonTable from "../partials/SalesCommonTable";
import FilterSalesInquiryComp from "../partials/FilterSalesInquiryComp";

const MarketPlaceRates = () => {

    document.title = "MarketPlace Inquiry || Navigating Freight Costs with Precision||Ultimate Rate Management platform"

    const { inquiry_data_loader, inquiry_data } = useSelector((state) => state?.sales);
    const [isRight, setIsRight] = useState(false);
    const { inquiry_summary_data, quotation_seller_history_list } = useSelector((state) => state?.sales);
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [showSellerList, setShowSellerList] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [modalStyle, setModalStyle] = useState({});
    const tableRef = useRef(null);
    const [rowData, setRowData] = useState(null);

    const dispatch = useDispatch();
    const inputArr = {
        org_port: '',
        dest_port: '',
        status: '',
        valid_from: '',
        valid_to: '',
        customer: '',
        sales_employee: '',
        isExcelGenerated: false
    }
    const [filterDetails, setfilterDetails] = useState(inputArr);

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const constructUrl = (page, filters) => {
        let url = `?isMarketPlace=true&page=${page}&size=${filterDetails?.isExcelGenerated ? inquiry_data?.totalElements : 10}&`;
        url += `${filters?.customer?.id ? `customers=${filters?.customer.id}&` : ''}`;
        url += `${filters?.org_port?.id ? `origins=${filters?.org_port.id}&` : ''}`;
        url += `${filters?.dest_port?.id ? `destinations=${filters?.dest_port.id}&` : ''}`;
        url += `${filters?.sales_employee?.id ? `salesEmployees=${filters?.sales_employee.id}&` : ''}`;
        url += `${filters?.valid_from ? `validFrom=${filters?.valid_from}&` : ''}`;
        url += `${filters?.valid_to ? `validTo=${filters?.valid_to}&` : ''}`;
        url += `${filters?.status?.id ? `status=${filters?.status?.id}&` : ''}`;
        url += `${filters?.container_type?.id ? `containerType=${filters?.container_type?.id}&` : ''}`;
        url += `${filterDetails?.isExcelGenerated ? `isExcelGenerated=${true}&` : ''}`;
        url += 'requestForRates=false&';
        return url.substring(0, url.length - 1); // Remove trailing '&'
    };

    const applyFilterHandler = () => {
        setIsRight(false);
        filterDetails.isExcelGenerated = false;
        let url = constructUrl(currentPage, filterDetails);
        dispatch(getSalesInquiryData(url));
    };

    const clearValueHandler = () => {
        setfilterDetails(inputArr);
        let url = constructUrl(currentPage, {});
        dispatch(getSalesInquiryData(url));
    };
    useEffect(() => {
        let url = constructUrl(currentPage, filterDetails);
        dispatch(getSalesInquiryData(url));
    }, [currentPage]);

    const onClickQuoteNow = (data) => {
        navigate(getInternalRoute("instant-search-rate"), { state: { data: data }, })
    }

    const downLoadExcelReport = () => {
        filterDetails.isExcelGenerated = true;
        let url = constructUrl(currentPage, filterDetails);
        dispatch(getSalesInquiryData(url));
    }

    const handleRowClick = (row, rowRect) => {
        setSelectedRow(row);

        // Get current scroll position
        const scrollY = window.scrollY || document.documentElement.scrollTop;
        const scrollX = window.scrollX || document.documentElement.scrollLeft;

        // Approximate modal dimensions (adjust based on content)
        const modalPadding = 10;
        const modalWidth = 500; // Adjust based on actual modal width
        const modalHeight = 180; // Adjust based on actual modal height

        // Default modal position (below the clicked row)
        let topPosition = rowRect.bottom + scrollY + modalPadding;
        let leftPosition = rowRect.left + scrollX;

        // Adjust modal if it goes off the right screen edge
        if (leftPosition + modalWidth > window.innerWidth) {
            leftPosition = window.innerWidth - modalWidth - modalPadding; // Shift left
        }

        // Adjust modal if it goes off the bottom screen edge
        if (topPosition + modalHeight > window.innerHeight + scrollY) {
            topPosition = rowRect.top + scrollY - modalHeight - modalPadding; // Show above row
        }

        // Ensure modal is always within the viewport
        topPosition = Math.max(modalPadding, Math.min(topPosition, window.innerHeight + scrollY - modalHeight));
        leftPosition = Math.max(modalPadding, Math.min(leftPosition, window.innerWidth + scrollX - modalWidth));

        // Set modal style dynamically
        setModalStyle({
            top: `${topPosition}px`,
            left: `${leftPosition}px`,
            position: "absolute",
            zIndex: 1050, // Higher than Bootstrap modals
            backgroundColor: "white",
            border: "1px solid #ccc",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
            padding: "15px",
            borderRadius: "8px",
            width: "auto",
            height: "auto",
            maxWidth: `${window.innerWidth * 0.9}px`,
            maxHeight: `${window.innerHeight * 0.7}px`,
            overflow: "auto", // Scroll if content overflows
        });

        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    }

    const handleOnSellerIdClick = (sellerId, rowRect) => {
        dispatch(getQuotationSellerListById(sellerId));
        setRowData(rowRect)
    }

    useEffect(() => {
        console.log(rowData, "rowData");
        console.log(quotation_seller_history_list, "quotation_seller_history_list");
        if (rowData != null && quotation_seller_history_list != null) {
            // Get current scroll position
            const scrollY = window.scrollY || document.documentElement.scrollTop;
            const scrollX = window.scrollX || document.documentElement.scrollLeft;

            // Approximate modal dimensions (adjust based on content)
            const modalPadding = 10;
            const modalWidth = 500; // Adjust based on actual modal width
            const modalHeight = 180; // Adjust based on actual modal height

            // Default modal position (below the clicked row)
            let topPosition = rowData.bottom + scrollY + modalPadding;
            let leftPosition = rowData.left + scrollX;

            // Adjust modal if it goes off the right screen edge
            if (leftPosition + modalWidth > window.innerWidth) {
                leftPosition = window.innerWidth - modalWidth - modalPadding; // Shift left
            }

            // Adjust modal if it goes off the bottom screen edge
            if (topPosition + modalHeight > window.innerHeight + scrollY) {
                topPosition = rowData.top + scrollY - modalHeight - modalPadding; // Show above row
            }

            // Ensure modal is always within the viewport
            topPosition = Math.max(modalPadding, Math.min(topPosition, window.innerHeight + scrollY - modalHeight));
            leftPosition = Math.max(modalPadding, Math.min(leftPosition, window.innerWidth + scrollX - modalWidth));

            // Set modal style dynamically
            setModalStyle({
                top: `${topPosition}px`,
                left: `${leftPosition}px`,
                position: "absolute",
                zIndex: 1050, // Higher than Bootstrap modals
                backgroundColor: "white",
                border: "1px solid #ccc",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                padding: "15px",
                borderRadius: "8px",
                width: "auto",
                height: "auto",
                maxWidth: `${window.innerWidth * 0.9}px`,
                maxHeight: `${window.innerHeight * 0.7}px`,
                overflow: "auto", // Scroll if content overflows
            });

            setShowSellerList(true);
        }
    }, [quotation_seller_history_list]);

    const columns = useMemo(() => [
        {
            Header: 'Inquiry ID',
            accessor: 'inquiryId',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Inquiry Dt',
            accessor: 'inquiryDate',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <span>{formatModifyDate(cellProps.row.original?.inquiryDate)}</span>
                )
            }
        },
        {
            Header: 'Customer Name',
            accessor: 'customerName',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        // {
        //     Header: 'Customer Contact No',
        //     accessor: 'customerContactNo',
        //     filterable: true,
        //     disableFilters: true,
        //     Cell: (cellProps) => {
        //         return <QueriesColVal cellProps={cellProps} />
        //     }
        // },
        {
            Header: 'Origin',
            accessor: 'origin',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Destination',
            accessor: 'destination',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Cargo Ready Date',
            accessor: 'cargoDate',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                const cargoDate = cellProps.row.original?.cargoDate;
                const cargoDateSplit = typeof cargoDate === "string" ? cargoDate.split('/') : [];
                return (cargoDateSplit[0] !== "" && cargoDateSplit[1] !== "") ? (
                    <span>{formatModifyDate(cargoDateSplit[0])}/{formatModifyDate(cargoDateSplit[1])}</span>
                ) : <span>/</span>;
            }
        },
        {
            Header: 'View Details',
            accessor: 'viewDetails',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <label className="form-label" onClick={(e) => {
                        // Get clicked cell position
                        const cellRect = e.target.getBoundingClientRect();

                        console.log("cellRect (Clicked Cell Position):", cellRect);

                        // If using tableRef (table's position relative to viewport)
                        if (tableRef.current) {
                            const tableRect = tableRef.current.getBoundingClientRect();
                            console.log("tableRect (Table Position):", tableRect);
                        }
                        console.log("cell props", cellProps.row.original);
                        // Call handleRowClick with cell position
                        handleRowClick(cellProps.row.original, cellRect);
                    }}>
                        <span className="align-content-center">View Details</span>
                    </label>
                )
            }
        },
        // {
        //     Header: 'Cargo Type',
        //     accessor: 'cargoType',
        //     filterable: true,
        //     disableFilters: true,
        //     Cell: (cellProps) => {
        //         return <QueriesColVal cellProps={cellProps} />
        //     }
        // },
        // {
        //     Header: 'Container type',
        //     accessor: 'containerTypes',
        //     filterable: true,
        //     disableFilters: true,
        //     Cell: (cellProps) => {
        //         return <QueriesColVal cellProps={cellProps} />
        //     }
        // },
        // {
        //     Header: 'Weight',
        //     accessor: 'weight',
        //     filterable: true,
        //     disableFilters: true,
        //     Cell: (cellProps) => {
        //         return <QueriesColVal cellProps={cellProps} />
        //     }
        // },
        // {
        //     Header: 'Cargo Value',
        //     accessor: 'cargoValue',
        //     filterable: true,
        //     disableFilters: true,
        //     Cell: (cellProps) => {
        //         return <QueriesColVal cellProps={cellProps} />
        //     }
        // },
        // {
        //     Header: 'Incoterms',
        //     accessor: 'incoterm',
        //     filterable: true,
        //     disableFilters: true,
        //     Cell: (cellProps) => {
        //         return <QueriesColVal cellProps={cellProps} />
        //     }
        // },
        {
            Header: 'Sales Emp',
            accessor: 'salesEmp',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Status',
            accessor: 'statusCode',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Action',
            Cell: (cellProps) => {
                const assignedBy = cellProps.row.original?.statusCode == RATE_UPDATED || cellProps.row.original?.statusCode == RATE_AVAILABLE;
                return (
                    <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                            <i className='bx bx-dots-vertical-rounded'></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className={!assignedBy ? "disabled-item" : ""} disabled={!assignedBy} onClick={(e) => { e.stopPropagation(); onClickQuoteNow(cellProps.row.original) }}>Quote Now <img src={upload_icon} alt="Add" /></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )
            }
        }
    ], []);

    if (getAuthType() === '1') {
        columns.splice(2, 0, {
            Header: 'Sellers',
            accessor: 'sellersId',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <label className="form-label" onClick={(e) => {
                        // Get clicked cell position
                        const cellRect = e.target.getBoundingClientRect();

                        console.log("cellRect (Clicked Cell Position):", cellRect);

                        // If using tableRef (table's position relative to viewport)
                        if (tableRef.current) {
                            const tableRect = tableRef.current.getBoundingClientRect();
                            console.log("tableRect (Table Position):", tableRect);
                        }
                        console.log("cell props", cellProps.row.original);
                        // Call handleRowClick with cell position
                        handleOnSellerIdClick(cellProps.row?.original?.inquiryId, cellRect);
                    }}>
                        <span className="align-content-center">{cellProps.row?.original?.inquiryId}</span>
                    </label>
                )
            }
        });
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div className="main_freight_wrapper">
                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TfBreadcrumbs breadcrumb={inquiryMarketPlaceBreadcrumb} />
                            <div className="tf_box_wrap d-flex">
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Total Inquires</p>
                                    <div className="sh_inquiry_rate">{inquiry_summary_data?.totalCount}
                                        {inquiry_summary_data?.actionedCount !== undefined ? (
                                            <span className={`${inquiry_summary_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{inquiry_summary_data?.totalCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Inquires Actioned</p>
                                    <div className="sh_inquiry_rate">{inquiry_summary_data?.actionedCount}
                                        {inquiry_summary_data?.compare_rate !== '' ? (
                                            <span className={`${inquiry_summary_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{inquiry_summary_data?.actionedCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Pending Inquires</p>
                                    <div className="sh_inquiry_rate">{inquiry_summary_data?.pendingCount}
                                        {inquiry_summary_data?.compare_rate !== '' ? (
                                            <span className={`${inquiry_summary_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{inquiry_summary_data?.pendingCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">SLA breached</p>
                                    <div className="sh_inquiry_rate">{inquiry_summary_data?.slaBreachedCount}
                                        <span className={`${inquiry_summary_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{inquiry_summary_data?.slaBreachedCountPercentage}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <SalesCommonTable
                            tableRef={tableRef}
                            columns={columns}
                            data={!!inquiry_data.content ? inquiry_data.content : []}
                            isGlobalFilter={true}
                            isAddInvoiceList={true}
                            loader={inquiry_data_loader}
                            customPageSize={10}
                            toggleRightCanvas={toggleRightCanvas}
                            downLoadExcelReport={downLoadExcelReport}
                            component={'inquiry'}
                            setCurrentPage={setCurrentPage}
                            totalPages={inquiry_data?.totalPages || 0}
                            totalEntries={inquiry_data?.totalElements || 0}
                        />

                        {/* Positioned Modal */}
                        {show && (
                            <>
                                {/* Close Button Positioned Outside */}
                                {console.log(parseInt(modalStyle.top) - 10)}
                                {console.log("model Left", parseInt(modalStyle.left))}
                                {console.log("model Width", parseInt(modalStyle.width))}
                                {console.log(parseInt(modalStyle.left) + parseInt(modalStyle.width || 1050) - 30)}
                                <button
                                    onClick={() => setShow(false)}
                                    style={{
                                        position: "absolute",
                                        top: `${parseInt(modalStyle.top) - 10}px`, // Slightly above modal
                                        left: `${parseInt(modalStyle.left) + 450 - 10}px`, // Right side
                                        //left: "1400.2px",
                                        zIndex: 1100,
                                        backgroundColor: "red",
                                        color: "white",
                                        border: "none",
                                        padding: "6px 10px",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                                    }}
                                >
                                    ✖
                                </button>
                                <div className="p-3 shadow border rounded bg-white w-25" style={modalStyle}>
                                    <h5>Row Details</h5>
                                    {selectedRow && (
                                        <div className="row">
                                            <div className="col-md-6 col-lg-12 mb-4">
                                                <div className="row">
                                                    <div className="row col-md-2 col-lg-5 mb-2">
                                                        <p className="align-content-center"> <strong>Type:</strong></p>
                                                    </div>
                                                    <div className="row col-md-2 col-lg-6 mb-2">
                                                        <p className="align-content-center"> <strong>{selectedRow?.rateType}</strong></p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row">
                                                    <div className="row col-md-2 col-lg-5 mb-2">
                                                        <p className="align-content-center"> <strong>Container:</strong></p>
                                                    </div>
                                                    <div className="row col-md-2 col-lg-6 mb-2">
                                                        <p className="align-content-center"> <strong>{selectedRow?.containerTypes}</strong></p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row">
                                                    <div className="row col-md-2 col-lg-5 mb-2">
                                                        <p className="align-content-center"> <strong>Cargo Type:</strong></p>
                                                    </div>
                                                    <div className="row col-md-2 col-lg-6 mb-2">
                                                        <p className="align-content-center"> <strong>{selectedRow?.cargoType}</strong></p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row">
                                                    <div className="row col-md-2 col-lg-5 mb-2">
                                                        <p className="align-content-center"> <strong>Weight:</strong></p>
                                                    </div>
                                                    <div className="row col-md-2 col-lg-6 mb-2">
                                                        <p className="align-content-center"> <strong>{selectedRow?.weight}</strong></p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row">
                                                    <div className="row col-md-2 col-lg-5 mb-2">
                                                        <p className="align-content-center"> <strong>Cargo Value:</strong></p>
                                                    </div>
                                                    <div className="row col-md-2 col-lg-6 mb-2">
                                                        <p className="align-content-center"> <strong>{selectedRow?.cargoValue}</strong></p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row">
                                                    <div className="row col-md-2 col-lg-5 mb-2">
                                                        <p className="align-content-center"> <strong>Incoterms:</strong></p>
                                                    </div>
                                                    <div className="row col-md-2 col-lg-6 mb-2">
                                                        <p className="align-content-center"> <strong>{selectedRow?.incoterm}</strong></p>
                                                    </div>
                                                </div>
                                            </div>


                                            {/*<p><strong>Container:</strong> </p>*/}
                                            {/*<p><strong></strong> </p>*/}
                                            {/*<p><strong>Weight</strong></p>*/}
                                            {/*<p><strong>Cargo Value</strong></p>*/}
                                            {/*<p><strong>Incoterms</strong></p>*/}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {showSellerList &&
                            <>
                                {/* Close Button Positioned Outside */}
                                {console.log(parseInt(modalStyle.top) - 10)}
                                {console.log("model Left", parseInt(modalStyle.left))}
                                {console.log("model Width", parseInt(modalStyle.width))}
                                {console.log(parseInt(modalStyle.left) + parseInt(modalStyle.width || 1050) - 30)}
                                <button
                                    onClick={() => setShowSellerList(false)}
                                    style={{
                                        position: "absolute",
                                        top: `${parseInt(modalStyle.top) - 10}px`, // Slightly above modal
                                        left: `${parseInt(modalStyle.left) + 450 - 10}px`, // Right side
                                        //left: "1400.2px",
                                        zIndex: 1100,
                                        backgroundColor: "red",
                                        color: "white",
                                        border: "none",
                                        padding: "6px 10px",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                                    }}
                                >
                                    ✖
                                </button>
                                <div className="p-3 shadow border rounded bg-white w-25" style={modalStyle}>
                                    <h5>History List</h5>
                                    <table className="table table-bordered table-striped">
                                        <thead className="table-dark">
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {quotation_seller_history_list?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item?.id}</td>
                                                <td>{item?.name?.toLocaleUpperCase()}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </div>
                </Container>
            </div>

            <FilterSalesInquiryComp isRight={isRight} toggleRightCanvas={toggleRightCanvas} filterDetails={filterDetails} setfilterDetails={setfilterDetails} applyFilterHandler={applyFilterHandler} clearValueHandler={clearValueHandler} />
        </>
    )
}

export default MarketPlaceRates;