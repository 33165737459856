import {
    GET_SUBSCRIPTION_DATA,
    GET_SUBSCRIPTION_DATA_FAIL,
    GET_SUBSCRIPTION_DATA_SUCCESS, POST_SUBSCRIPTION_DATA,
} from "./actiontype";

export const getSubscriptionsSummary = (dataObj) => ({
    type : GET_SUBSCRIPTION_DATA,
    payload : dataObj,
});

export const getSubscriptionsSummaryDataFail = (error) => ({
    type: GET_SUBSCRIPTION_DATA_FAIL,
    payload: error
});

export const getSubscriptionsSummaryDataSuccess = (dataObj) => ({
    type : GET_SUBSCRIPTION_DATA_SUCCESS,
    payload : dataObj
})

export const postSubscriptionsSummaryData = (dataObj) => ({
    type : POST_SUBSCRIPTION_DATA,
    payload : dataObj
});
