import classnames from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {Container, DropdownItem, FormGroup, Input, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';

import { airSearchData } from "../../common/data/sales";
import { GET_CARGO_TYPE_DATA, GET_CONTAINER_DATA, GET_UOM_WEIGHT_DATA } from "../../store/Global/actiontype";
import { ADD_OBJECT_INSTANT_SEARCH, BLANK_INSTANT_SEARCH, GET_INSTANT_AIR_SEARCH_RESULT_DETAILS, INSTANT_RATE_ACTIVE_TAB_TYPE, QUOTATION_RESULT_SELECTED_BLANK, REMOVE_OBJECT_INSTANT_SEARCH } from "../../store/InstantRate/actionType";
import { getInstantSearchByJsonResponceAction, postAirFrightInstantSearch, postInstantSearchAction } from "../../store/InstantRate/actions";
import { BLANK_MODAL_CHARGE } from "../../store/Sales/Quotation/actiontype";
import AirBookQuoteModal from "./AirFreightResult/AirBookQuoteModal";
import AirFreightResultComp from "./AirFreightResult/AirFreightResultComp";
import SearchResultComp from "./OceanFreightResult/SearchResultComp";
import SearchForm from './SearchForm';
import PreviewQuotationModal from "./Preview/PreviewQuotationModal";
import QuotationModalComp from "./partials/QuotationModalComp";
import { useLocation } from "react-router-dom";
import {getMarketPlaceRate} from "../../components/Common/CommonLogic";
import {
    hasInstantDomesticsRatePermission,
    hasInstantInternationalRatePermission
} from "../../common/data/ApplicationModules";



const InstantRate = () => {
    const [searchResult, setSearchResult] = useState(false);
    const { $instantActiveTab, searchForm } = useSelector((state) => state?.instantRate);
    const [quoteModal, setQuoteModal] = useState(false);
    const [bookModal, setBookModal] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const dispatch = useDispatch();
    const navigateState = useLocation();

    const bookModalHandler = useCallback(() => {
        setBookModal(!bookModal);
    }, [bookModal]);

    function QuoteModalHandler() {
        setQuoteModal(!quoteModal);
    }

    const showSearchResultHandler = () => {
        console.log(searchForm, "searchForm------------------------------------")
        //console.log($instantActiveTab, "activeTab------------------------------------")
        let dateFrom = moment(searchForm?.cargo_date?.[0]).format("YYYY-MM-DD");
        let dateTo = moment(searchForm?.cargo_date?.[1]).format("YYYY-MM-DD");
        if ($instantActiveTab?.sub === 'FCL') {
            let data = {
                inquiryField: {
                    isMarketPlace: getMarketPlaceRate(),
                    customerId: searchForm?.customerName?.value || null,
                    findAlternativeRoute: searchForm?.alternate_route,
                    originLocationTypeId: searchForm?.location_from?.locationType || null,
                    destinationLocationTypeId: searchForm?.location_to?.locationType || null,

                    ...(searchForm?.location_from?.locationType === "PORT" ? {
                        "originPortId": searchForm?.location_from?.value || null,
                    } : searchForm?.location_from?.locationType === "CITY" ? {
                        "originCityId": searchForm?.location_from?.value || null,
                    } : searchForm?.location_from?.locationType === "ICD" ? {
                        "originIcdId": searchForm?.location_from?.value || null,
                    } : ''),

                    ...(searchForm?.location_to?.locationType === "PORT" ? {
                        "destinationPortId": searchForm?.location_to?.value || null,
                    } : searchForm?.location_to?.locationType === "CITY" ? {
                        "destinationCityId": searchForm?.location_to?.value || null,
                    } : searchForm?.location_to?.locationType === "ICD" ? {
                        "destinationIcdId": searchForm?.location_to?.value || null,
                    } : ''),
                    cargoDateFrom: dateFrom || null,
                    cargoDateTo: dateTo || null,
                    cargoTypeId: searchForm?.cargo_type?.id || null,
                    cargoValue: searchForm?.cargo_value?.value || 0,
                    cargoWeight: searchForm?.container_type?.cargo_weight?.value || 0,
                    ...(searchForm?.container_type?.cargo_weight?.weight && { cargoWeightUOMId: searchForm?.container_type?.cargo_weight?.weight?.id || null }),
                    intercomId: searchForm?.incoterm?.value || null,
                    containerDetails: (searchForm?.container_type?.containerArray || [])
                        .map((data) => (
                            data?.unitNew !== 0
                                ? {
                                    weight: data?.weight,
                                    id: data?.id,
                                    size: data?.size,
                                    noOfUnits: data?.unitNew,
                                    cargoWeightUOMId: data?.uomWeight
                                }
                                : null
                        ))
                        .filter(Boolean)
                }
            }
            dispatch(postInstantSearchAction(data));
        }
        if ($instantActiveTab?.sub === 'dom_air') {
            let data = {
                ...(searchForm?.customerName && { customerId: searchForm?.customerName?.value }),
                originType: "PORT",
                destinationType: "PORT",
                originId: searchForm?.location_from.id,
                destinationId: searchForm?.location_to.id,
                cargoDate: dateFrom,
                ...(searchForm?.dom_air_cargo_type && { cargoTypeId: searchForm?.dom_air_cargo_type?.id }),
                // commodityTypeId: null,
                ...(searchForm?.cargo_value && { cargoValue: searchForm?.cargo_value?.value }),
                cargoWeight: searchForm?.shipment_details?.v_weight > searchForm?.shipment_details?.weight ? searchForm?.shipment_details?.v_weight : searchForm?.shipment_details?.weight || 0,
                // cargoWeightUOMId: null,
                // incoterm: null,
                // // inquiryDate: "2023-06-28",
                actualWeight: searchForm?.shipment_details?.weight || 0,
                // volumetricWeight: 1,
                bookingMode: searchForm?.bookingMode?.value || "MAWB",
                shipmentDetails: (searchForm?.shipment_details?.array || [])
                    .map((data) => (
                        data?.unitNew !== 0
                            ? {
                                noOfUnits: data?.no_unit,
                                length: data?.dimensions_l,
                                width: data?.dimensions_w,
                                height: data?.dimensions_h,
                                uom: data?.dimensions_unit
                            }
                            : null
                    ))
                    .filter(Boolean)
            }
            console.log(data, "post data")
            dispatch(postAirFrightInstantSearch(data))
            dispatch({ type: GET_INSTANT_AIR_SEARCH_RESULT_DETAILS, payload: airSearchData });
        }
        setSearchResult(true);
    }

    const previewModalHand = () => {
        setPreviewModal(!previewModal);
    }

    const blankValueHandler = () => {
        dispatch({ type: BLANK_MODAL_CHARGE, payload: {} });
        dispatch({ type: QUOTATION_RESULT_SELECTED_BLANK, payload: {} });
        dispatch({ type: BLANK_INSTANT_SEARCH });
    }

    useEffect(() => {
        // if ($instantActiveTab?.main === "ocean_freight") {
        //     dispatch({ type: INSTANT_RATE_ACTIVE_TAB_TYPE, payload: { main: "domestic", sub: "dom_air" } });
        // }
        dispatch({ type: GET_CARGO_TYPE_DATA });
        dispatch({ type: GET_CONTAINER_DATA });
        dispatch({ type: GET_UOM_WEIGHT_DATA });
        blankValueHandler();
    }, []);

    useEffect(() => {
        //console.log(navigateState?.state?.data);
        //console.log(searchResult);
        if (navigateState?.state?.data && navigateState?.state?.data?.inquiryId) {
            setSearchResult(true)
            dispatch(getInstantSearchByJsonResponceAction(navigateState?.state.data?.inquiryId))
        }
    }, []);

    const switchHandler = (data) => {

    }

    return(
        <>
            <div className="page-content">
                <Container fluid>
                    <div className="create_sales_wrapper instant_rate_wrapper">
                        <div className="create_sales_wrapper_inner">
                            <div className="d-flex justify-content-end main_tab_wrap">
                                <button type="button"
                                        className={`btn d-flex align-items-center ${$instantActiveTab?.main === "domestic" ? "active" : ""}`}
                                        disabled={!hasInstantDomesticsRatePermission()}
                                        onClick={() => {
                                            setSearchResult(false);
                                            blankValueHandler();
                                            dispatch({
                                                type: INSTANT_RATE_ACTIVE_TAB_TYPE,
                                                payload: {main: "domestic", sub: "dom_air"}
                                            });
                                            dispatch({
                                                type: ADD_OBJECT_INSTANT_SEARCH,
                                                payload: 'shipment_details'
                                            });
                                        }}>
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 10L12 3L21 10V21H14V14H10V21H3V10Z" stroke="black" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Domestic
                                </button>
                                <button type="button"
                                        className={`btn d-flex align-items-center ms-3 ${$instantActiveTab?.main === "international" ? "active" : ""}`}
                                        disabled={!hasInstantInternationalRatePermission()}
                                        onClick={() => {
                                            setSearchResult(false);
                                            blankValueHandler();
                                            dispatch({
                                                type: INSTANT_RATE_ACTIVE_TAB_TYPE,
                                                payload: {main: "international", sub: "FCL"}
                                            });
                                            dispatch({
                                                type: REMOVE_OBJECT_INSTANT_SEARCH,
                                                payload: 'shipment_details'
                                            })
                                        }}>
                                    <svg width="19" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="1"/>
                                        <path d="M2 12H22M12 2C15.866 2 19 6.477 19 12C19 17.523 15.866 22 12 22C8.13401 22 5 17.523 5 12C5 6.477 8.13401 2 12 2Z" stroke="black" strokeWidth="1"/>
                                    </svg>

                                    International
                                </button>
                            </div>
                            {/* --------------------------tabs------------------------------- */}
                            <Nav className="nav-tabs-custom card-header-tabs border-bottom mb-3">
                                {$instantActiveTab?.main === "domestic" ? (
                                    <React.Fragment>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({active: $instantActiveTab?.sub === "dom_air",}, "px-3 py-2")}
                                                onClick={() => {
                                                    dispatch({
                                                        type: INSTANT_RATE_ACTIVE_TAB_TYPE,
                                                        payload: {main: "domestic", sub: "dom_air"}
                                                    });
                                                    dispatch({
                                                        type: ADD_OBJECT_INSTANT_SEARCH,
                                                        payload: 'shipment_details'
                                                    });
                                                }}>
                                                <i className='bx bx-rocket mx-1'></i>
                                                AIR CARGO</NavLink>
                                        </NavItem>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({active: $instantActiveTab?.sub === "FCL",}, "px-3 py-2")}
                                                onClick={() => {
                                                    dispatch({
                                                        type: INSTANT_RATE_ACTIVE_TAB_TYPE,
                                                        payload: {main: "international", sub: "FCL"}
                                                    });
                                                    dispatch({
                                                        type: REMOVE_OBJECT_INSTANT_SEARCH,
                                                        payload: 'shipment_details'
                                                    })
                                                }}
                                            ><i className='bx bx-package mx-1'></i>FCL</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({active: $instantActiveTab?.sub === "LCL",}, "px-3 py-2")}
                                                onClick={() => {
                                                    dispatch({
                                                        type: INSTANT_RATE_ACTIVE_TAB_TYPE,
                                                        payload: {main: "international", sub: "LCL"}
                                                    });
                                                    dispatch({
                                                        type: ADD_OBJECT_INSTANT_SEARCH,
                                                        payload: 'shipment_details'
                                                    })
                                                }}
                                                disabled={true}
                                            ><i className='bx bx-package mx-1'></i>LCL</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({active: $instantActiveTab?.sub === "intl_Air",}, "px-3 py-2")}
                                                onClick={() => {
                                                    dispatch({
                                                        type: INSTANT_RATE_ACTIVE_TAB_TYPE,
                                                        payload: {main: "international", sub: "intl_Air"}
                                                    });
                                                    dispatch({
                                                        type: ADD_OBJECT_INSTANT_SEARCH,
                                                        payload: 'shipment_details'
                                                    });
                                                }}
                                            ><i className='bx bx-rocket mx-1'></i>AIR</NavLink>
                                        </NavItem>
                                    </React.Fragment>
                                )}
                            </Nav>
                            {/* --------------------------tabs------------------------------- */}
                            <TabContent activeTab={$instantActiveTab?.sub}>
                                <TabPane tabId={$instantActiveTab?.sub}>
                                    <SearchForm activeTab={$instantActiveTab?.sub}
                                                searchQuoteHandler={showSearchResultHandler}
                                                mainactiveTab={$instantActiveTab?.main}/>
                                </TabPane>
                            </TabContent>
                            {/* --------------------------tabs------------------------------- */}

                            {/* Search Result */}
                            {searchResult && (
                                <>
                                    {($instantActiveTab?.main === "domestic" && $instantActiveTab?.sub === "dom_air") ? (
                                        <AirFreightResultComp QuoteModalHandler={QuoteModalHandler}
                                                              bookModalHandler={bookModalHandler}
                                                              searchResult={searchResult}
                                                              mainTab={$instantActiveTab?.sub}/>
                                    ) : (
                                        <SearchResultComp QuoteModalHandler={QuoteModalHandler}
                                                          searchResult={searchResult}/>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
            {/* Quotation Modal */}
            {quoteModal && <QuotationModalComp quoteModal={quoteModal} setQuoteModal={setQuoteModal}
                                               QuoteModalHandler={QuoteModalHandler}
                                               setPreviewModal={setPreviewModal}/>}

            {bookModal && <AirBookQuoteModal bookModal={bookModal} setBookModal={setBookModal}
                                             QuoteModalHandler={bookModalHandler}/>}

            {/* Preview Quotation Modal */}
            {previewModal && <PreviewQuotationModal previewModal={previewModal} previewModalHand={previewModalHand}
                                                    setPreviewModal={setPreviewModal}
                                                    QuoteModalHandler={QuoteModalHandler}/>}
        </>
    );
}

export default InstantRate;
