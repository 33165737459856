import { GET_CURRENCY_EXCHANGE_RATE, GET_TENANT_FCL_QUOTATION_SUMMERY, GET_TENANT_FCL_QUOTATION_SUMMERY_BY_ID, POST_TENANT_FCL_QUOTATION_SUMMERY, POST_TENANT_FCL_QUOTATION_SUMMERY_BY_ID, POST_TRANSFER_STATUS, UPDATE_QUOTION_ASSIGN_TO, UPDATE_QUOTION_STATUS } from "./Quotation/actiontype";
import {
    GET_DASHBOARD_INQUIRY_SUMMARY_DATA,
    GET_INQUIRY_CUSTOMER_SUMMARY_DATA,
    GET_INQUIRY_DATA,
    GET_INQUIRY_DATA_SUCCESS,
    GET_INQUIRY_EXPORT_SUMMARY_DATA,
    GET_INQUIRY_IMPORT_SUMMARY_DATA,
    GET_INQUIRY_SALES_CUSTOMER_SUMMARY_DATA,
    GET_INQUIRY_SUMMARY_DATA,
    GET_QUOTATION_DATA,
    GET_QUOTATION_DATA_FAIL,
    GET_QUOTATION_DATA_SUCCESS,
    GET_QUOTATION_HISTORY_DATA_BY_ID,
    GET_QUOTATION_HISTORY_DATA_SUCCESS,
    GET_QUOTATION_RESULT_DATA,
    GET_QUOTATION_RESULT_DATA1,
    GET_QUOTATION_RESULT_DATA2,
    GET_QUOTATION_RESULT_DATA3,
    GET_QUOTATION_SELLER_DETAILS_LIST_BY_ID,
} from "./actiontype";


export const getSalesQuotationData = (data) => ({
    type: GET_QUOTATION_DATA,
    payload: data
})
export const getSalesQuotationDataSuccess = (data) => ({
    type: GET_QUOTATION_DATA_SUCCESS,
    payload: data
})
export const getSalesQuotationDataFail = (error) => ({
    type: GET_QUOTATION_DATA_FAIL,
    payload: error
})

export const getCurrencyExchangeRate = (data) => ({
    type: GET_CURRENCY_EXCHANGE_RATE,
    payload: data
})

export const getSalesInquiryData = (data) => ({
    type: GET_INQUIRY_DATA,
    payload: data
})
export const getSalesInquiryDataSuccess = (data) => ({
    type: GET_INQUIRY_DATA_SUCCESS,
    payload: data
})
export const getSalesInquiryDataFail = (error) => ({
    type: GET_QUOTATION_DATA_FAIL,
    payload: error
})

export const getInquirySummeryData = (data) => ({
    type: GET_INQUIRY_SUMMARY_DATA,
    payload: data
})

export const getInquiryExportSummeryData = (data) => ({
    type: GET_INQUIRY_EXPORT_SUMMARY_DATA,
    payload: data
})

export const getInquiryImportSummeryData = (data) => ({
    type: GET_INQUIRY_IMPORT_SUMMARY_DATA,
    payload: data
})

export const getInquiryCustomerSummeryData = (data) => ({
    type: GET_INQUIRY_CUSTOMER_SUMMARY_DATA,
    payload: data
})

export const getInquirySalesCustomerSummeryData = (data) => ({
    type: GET_INQUIRY_SALES_CUSTOMER_SUMMARY_DATA,
    payload: data
})

export const getTenantQuotationSummery = (data) => ({
    type: GET_TENANT_FCL_QUOTATION_SUMMERY,
    payload: {data},
})


export const postTenantQuotationSummeryDetails = (data) => {
    return {
        type: POST_TENANT_FCL_QUOTATION_SUMMERY,
        payload: { data }
    }
}


export const postTransferStatusData = (data) => {
    return {
        type: POST_TRANSFER_STATUS,
        payload: { data }
    }
}

export const updateQuotionStatusData = (data) => {
    return {
        type: UPDATE_QUOTION_STATUS,
        payload: { data }
    }
}

export const updateQuotionAssingToData = (data) => {
    return {
        type: UPDATE_QUOTION_ASSIGN_TO,
        payload: { data }
    }
}

export const getTenantQuotationSummeryById = (data) => {
    return {
        type: GET_TENANT_FCL_QUOTATION_SUMMERY_BY_ID,
        payload: { data }
    }
}

export const getDashBoardQuotionSummery = (data) => {
    return {
        type: GET_DASHBOARD_INQUIRY_SUMMARY_DATA,
        payload: { data }
    }
}


export const getQuotationHistoryById = (data) => {
    return {
        type: GET_QUOTATION_HISTORY_DATA_BY_ID,
        payload: { data }
    }
}

export const getQuotationSellerListById = (data) => ({
    type: GET_QUOTATION_SELLER_DETAILS_LIST_BY_ID,
    payload: { data }
})