import PropTypes from "prop-types"
import React, {useEffect, useRef, useCallback, useState} from "react"

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import images
import giftBox from "../../assets/images/giftbox.png"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link, useLocation } from "react-router-dom"
import withRouter from "../Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import { BLANK_MODAL_CHARGE } from "../../store/Sales/Quotation/actiontype";
import { BLANK_INSTANT_SEARCH, INSTANT_RATE_ACTIVE_TAB_TYPE, QUOTATION_RESULT_SELECTED_BLANK } from "../../store/InstantRate/actionType";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  ApplicationModules,
  hasAirFrightPermission,
  hasCarrierRatesPermission,
  hasCompanySettingPermission,
  hasControlTowerPermission,
  hasCustomerListPermission,
  hasCustomerPermission,
  hasCustomerRatesPermission,
  hasDashboardPermission,
  hasInquiryListPermission,
  hasInquiryPermission,
  hasInquiryRequestForRatePermission, hasInstantDomesticsRatePermission, hasInstantInternationalRatePermission,
  hasInstantPermission, hasIntegrationsConfigPermission,
  hasLeadGenarationPermission, hasMarketingPermission,
  hasMarketPlaceConfigPermission,
  hasOceanFrightPermission,
  hasQuotationApprovalPermission, hasQuotationListPermission,
  hasQuotationPermission,
  hasSalesPermission,
  hasSettingPermission,
  hasSurchargeMasterPermission,
  hasUploadStatusPermission,
  hasUsersPermission,
  hasVendorDashboardPermission,
  hasVendorPermission
} from "../../common/data/ApplicationModules";
import { LOGIN_SUCCESS } from "../../store/auth/login/actionTypes";
import Tooltip from "../Common/Tootip";
import PopoverMessage from "../Common/PopoverMessage";
import PermissionDeniedModal from "../Common/PermissionDeniedModal";
import smallModal from "../Common/PermissionDeniedModal";

const SidebarContent = props => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { login_user_data } = useSelector((state) => state?.Login);
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    login_user_data?.modulePermissions && login_user_data?.modulePermissions > 0 ? "" : dispatch({ type: LOGIN_SUCCESS, payload: JSON.parse(localStorage.getItem("authUser")) })
  }, [])

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState('');

const ShowPermissionErrorModal = (notifyMessage) => {
  setModalShow(true);
  setMessage(notifyMessage)
}

const onToggleModal = () => {
  setModalShow(!modalShow);
}

const onDismissModal = () => {
  setModalShow(false);
}

  return (
    <React.Fragment>
      <PermissionDeniedModal message={message} isVisible={modalShow} onDismiss={onDismissModal} setToggle={onToggleModal} />
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className={hasControlTowerPermission() ? '' : 'text-muted disabled-link'}>
              <Link to="/#" className="has-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <path d="M2 7.25C2 5.73122 3.23122 4.5 4.75 4.5H15.25C16.7688 4.5 18 5.73122 18 7.25V13.75C18 15.2688 16.7688 16.5 15.25 16.5H4.75C3.23122 16.5 2 15.2688 2 13.75V7.25ZM4 12C4 12.2761 4.22386 12.5 4.5 12.5H11.5C11.7761 12.5 12 12.2761 12 12C12 11.7239 11.7761 11.5 11.5 11.5H4.5C4.22386 11.5 4 11.7239 4 12ZM4.5 13.5C4.22386 13.5 4 13.7239 4 14C4 14.2761 4.22386 14.5 4.5 14.5H7.5C7.77614 14.5 8 14.2761 8 14C8 13.7239 7.77614 13.5 7.5 13.5H4.5ZM13 12C13 12.2761 13.2239 12.5 13.5 12.5H15.5C15.7761 12.5 16 12.2761 16 12C16 11.7239 15.7761 11.5 15.5 11.5H13.5C13.2239 11.5 13 11.7239 13 12ZM9.5 13.5C9.22386 13.5 9 13.7239 9 14C9 14.2761 9.22386 14.5 9.5 14.5H15.5C15.7761 14.5 16 14.2761 16 14C16 13.7239 15.7761 13.5 15.5 13.5H9.5Z" fill="#2D1C5B" />
                </svg>
                {/*className="clickable-span" onClick={() => ShowPermissionErrorModal("Control Tower disabled by admin or no active subscription.")}*/}
                <span >Control Tower</span>
                {!hasControlTowerPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', margin:'0px', padding:"0px"}}></i>}
              </Link>
              <ul className="sub-menu">
                {/*{hasDashboardPermission() &&}*/}
                <li>
                  <Link to="/dashboard" className={hasDashboardPermission() ? '' : 'text-muted disabled-link'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                      <path d="M7.5 11.5C8.32843 11.5 9 12.1716 9 13V17C9 17.8284 8.32843 18.5 7.5 18.5H3.5C2.67157 18.5 2 17.8284 2 17V13C2 12.1716 2.67157 11.5 3.5 11.5H7.5ZM16.5 11.5C17.3284 11.5 18 12.1716 18 13V17C18 17.8284 17.3284 18.5 16.5 18.5H12.5C11.6716 18.5 11 17.8284 11 17V13C11 12.1716 11.6716 11.5 12.5 11.5H16.5ZM7.5 2.5C8.32843 2.5 9 3.17157 9 4V8C9 8.82843 8.32843 9.5 7.5 9.5H3.5C2.67157 9.5 2 8.82843 2 8V4C2 3.17157 2.67157 2.5 3.5 2.5H7.5ZM16.5 2.5C17.3284 2.5 18 3.17157 18 4V8C18 8.82843 17.3284 9.5 16.5 9.5H12.5C11.6716 9.5 11 8.82843 11 8V4C11 3.17157 11.6716 2.5 12.5 2.5H16.5Z" fill="#4848F7" />
                    </svg>
                    {/*className="clickable-span" onClick={() => ShowPermissionErrorModal("Dashboard disabled by admin or no active subscription.")}*/}
                    <span >Dashboard</span>
                    {!hasDashboardPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
                {/*{hasVendorDashboardPermission() &&}*/}
                <li>
                  <Link to="/dashboard-vendor" className={hasVendorDashboardPermission() ? '' : 'text-muted disabled-link'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                      <path d="M7.5 11.5C8.32843 11.5 9 12.1716 9 13V17C9 17.8284 8.32843 18.5 7.5 18.5H3.5C2.67157 18.5 2 17.8284 2 17V13C2 12.1716 2.67157 11.5 3.5 11.5H7.5ZM16.5 11.5C17.3284 11.5 18 12.1716 18 13V17C18 17.8284 17.3284 18.5 16.5 18.5H12.5C11.6716 18.5 11 17.8284 11 17V13C11 12.1716 11.6716 11.5 12.5 11.5H16.5ZM7.5 2.5C8.32843 2.5 9 3.17157 9 4V8C9 8.82843 8.32843 9.5 7.5 9.5H3.5C2.67157 9.5 2 8.82843 2 8V4C2 3.17157 2.67157 2.5 3.5 2.5H7.5ZM16.5 2.5C17.3284 2.5 18 3.17157 18 4V8C18 8.82843 17.3284 9.5 16.5 9.5H12.5C11.6716 9.5 11 8.82843 11 8V4C11 3.17157 11.6716 2.5 12.5 2.5H16.5Z" fill="#4848F7" />
                    </svg>
                    {/*className="clickable-span" onClick={() => ShowPermissionErrorModal("Vendor Dashboard disabled by admin or no active subscription.")}*/}
                    <span >Vendor DashBoard</span>
                    {!hasVendorDashboardPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
              </ul>
            </li>

            {/*{hasInstantPermission() &&}*/}
            <li className={hasInstantPermission() ? '' : 'text-muted disabled-link'}
                onClick={() => {
                  dispatch({ type: BLANK_MODAL_CHARGE, payload: {} });
                  dispatch({ type: QUOTATION_RESULT_SELECTED_BLANK, payload: {} });
                  dispatch({ type: BLANK_INSTANT_SEARCH });
                  if (hasInstantDomesticsRatePermission()) {
                    dispatch({ type: INSTANT_RATE_ACTIVE_TAB_TYPE, payload: { main: "domestic", sub: "dom_air" } });
                  } else if (hasInstantInternationalRatePermission()) {
                    dispatch({ type: INSTANT_RATE_ACTIVE_TAB_TYPE, payload: { main: "international", sub: "FCL" } });
                  }
                }}>
              <Link to="/instant-rate/search-rate" className="has-arrow">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="1920.000000pt" height="1920.000000pt" viewBox="0 0 1920.000000 1920.000000"
                     preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,1920.000000) scale(0.100000,-0.100000)"
                     fill="#000000" stroke="none">
                    <path d="M11040 16158 c-65 -35 -103 -93 -108 -168 -3 -37 1 -72 9 -93 7 -17
                                75 -135 151 -262 75 -126 136 -231 134 -232 -2 -2 -45 8 -97 22 -294 77 -667
                                142 -1024 177 -174 17 -836 17 -1015 0 -952 -91 -1812 -372 -2613 -854 -876
                                -525 -1596 -1263 -2109 -2158 -123 -215 -273 -527 -368 -765 -210 -526 -343
                                -1073 -407 -1675 -22 -211 -25 -871 -5 -1085 68 -702 243 -1366 527 -1985 300
                                -656 743 -1285 1257 -1787 85 -83 124 -114 154 -122 93 -26 177 -2 232 65 76
                                92 66 213 -22 295 -78 72 -342 356 -438 472 -448 538 -784 1138 -1013 1807
                                -288 845 -364 1797 -214 2685 171 1014 589 1922 1253 2720 124 148 471 498
                                626 630 1183 1007 2751 1490 4265 1315 205 -24 355 -49 569 -95 178 -38 240
                                -54 234 -60 -2 -2 -136 -50 -298 -105 -162 -55 -312 -112 -333 -126 -54 -35
                                -91 -117 -85 -185 10 -103 91 -184 195 -195 43 -5 112 17 669 205 342 115 635
                                216 650 224 99 52 136 186 80 293 -14 27 -153 263 -309 524 -322 541 -315 532
                                -435 538 -56 3 -74 0 -112 -20z"/>
                    <path d="M13514 14049 c-50 -12 -115 -66 -139 -114 -21 -45 -22 -129 -3 -175
                                9 -19 55 -73 103 -120 367 -357 698 -774 958 -1210 105 -177 295 -565 374
                                -765 212 -536 337 -1074 384 -1655 14 -182 15 -632 0 -810 -88 -1061 -437
                                -2027 -1037 -2871 -686 -963 -1659 -1676 -2814 -2060 -377 -125 -796 -214
                                -1240 -261 -168 -17 -821 -18 -990 0 -186 20 -399 50 -545 78 -177 34 -385 84
                                -385 92 0 4 123 48 272 97 323 107 355 121 396 171 108 131 23 330 -146 342
                                -53 3 -106 -13 -680 -207 -343 -116 -639 -220 -658 -232 -54 -33 -89 -102 -88
                                -174 0 -37 8 -73 19 -95 25 -50 573 -969 598 -1004 27 -38 110 -76 167 -76
                                142 1 245 146 199 280 -7 18 -74 136 -150 264 -75 127 -134 232 -130 234 4 1
                                70 -13 147 -32 839 -209 1682 -241 2504 -95 545 97 1045 256 1540 489 952 448
                                1762 1127 2358 1978 605 863 968 1848 1079 2927 22 216 25 868 5 1075 -53 543
                                -166 1050 -339 1524 -89 244 -135 352 -263 611 -236 480 -528 916 -875 1312
                                -136 154 -426 443 -467 463 -46 25 -102 31 -154 19z"/>
                    <path d="M10685 13416 c-44 -18 -305 -131 -580 -251 -616 -268 -972 -423
                                -1310 -570 -251 -109 -450 -196 -1125 -490 -173 -76 -416 -181 -540 -235 -973
                                -423 -1461 -636 -1525 -664 -72 -32 -111 -67 -136 -121 -26 -56 -24 -121 6
                                -181 21 -43 75 -90 422 -376 219 -179 850 -698 1403 -1152 553 -454 1021 -833
                                1040 -843 21 -10 57 -17 91 -17 49 1 71 9 180 66 149 78 830 441 1019 543 74
                                40 203 109 285 153 83 44 251 133 375 199 124 66 396 211 605 323 209 111 441
                                235 515 275 74 40 304 163 510 273 206 109 605 322 885 472 281 150 584 311
                                675 359 104 54 180 101 206 128 71 69 83 167 31 255 -23 39 -84 82 -551 388
                                -290 190 -609 399 -709 465 -100 66 -259 170 -352 231 -94 61 -395 259 -670
                                439 -275 180 -518 336 -540 346 -60 28 -118 24 -210 -15z m627 -762 c282 -185
                                600 -393 705 -463 106 -69 395 -259 643 -421 247 -162 450 -297 450 -300 0 -3
                                -100 -58 -223 -122 -122 -65 -274 -145 -337 -179 -63 -34 -414 -221 -780 -417
                                -366 -195 -795 -424 -955 -509 -159 -86 -326 -175 -370 -198 -225 -121 -523
                                -280 -815 -435 -313 -166 -507 -270 -953 -509 -114 -61 -213 -111 -220 -111
                                -6 0 -107 78 -222 173 -383 313 -488 400 -655 537 -91 75 -291 239 -445 366
                                -900 739 -1055 867 -1055 873 0 4 174 83 388 176 213 93 497 217 632 275 135
                                59 470 205 745 325 813 353 1039 452 1240 540 105 46 363 158 575 250 568 247
                                830 360 975 425 72 32 138 59 147 59 9 1 248 -150 530 -335z"/>
                    <path d="M8826 11807 l-98 -61 38 -36 c22 -19 57 -50 78 -67 l40 -32 -53 -55
                                c-164 -174 -127 -378 94 -511 98 -59 181 -80 315 -79 134 0 200 15 453 98 201
                                67 284 83 344 67 105 -28 162 -135 118 -220 -22 -43 -101 -108 -174 -143 -54
                                -26 -81 -33 -152 -36 -95 -5 -148 8 -249 58 l-56 28 -112 -86 -112 -87 23 -18
                                c38 -31 199 -106 267 -124 49 -14 100 -18 205 -17 131 0 146 2 233 33 81 28
                                96 30 110 19 10 -8 45 -38 80 -67 l62 -53 95 56 c52 31 97 61 100 66 3 5 -28
                                37 -69 72 l-74 63 69 72 c85 87 112 144 113 233 1 91 -41 176 -127 257 -189
                                176 -424 208 -759 103 -51 -16 -143 -45 -205 -64 -138 -44 -218 -57 -268 -46
                                -74 17 -122 73 -109 127 11 43 101 126 179 164 62 30 79 34 150 34 69 0 89 -4
                                147 -32 l66 -31 119 79 c65 44 119 83 121 87 4 14 -138 92 -216 119 -66 23
                                -94 27 -192 27 -118 0 -191 -14 -282 -53 l-44 -19 -79 69 c-44 38 -82 68 -85
                                68 -3 0 -49 -28 -104 -62z"/>
                    <path d="M13470 10727 c-27 -10 -1345 -711 -2240 -1190 -36 -19 -333 -178
                                -660 -352 -744 -397 -1593 -851 -1810 -967 -91 -49 -197 -105 -237 -125 l-72
                                -37 -48 40 c-51 43 -248 205 -648 534 -138 113 -637 522 -1108 910 -472 388
                                -875 713 -895 722 -45 21 -128 23 -173 4 -103 -44 -156 -172 -114 -274 21 -49
                                38 -66 189 -191 72 -58 168 -138 215 -177 47 -39 527 -433 1066 -875 539 -443
                                1069 -878 1178 -968 108 -89 214 -169 235 -178 62 -26 126 -20 201 19 59 30
                                400 212 1071 571 124 66 326 174 450 240 198 105 516 275 725 387 75 40 1418
                                757 1780 950 121 65 326 174 455 243 129 68 255 136 280 149 25 14 99 53 165
                                88 195 103 223 123 250 183 38 80 31 154 -22 225 -47 63 -160 97 -233 69z"/>
                    <path d="M13476 9803 c-22 -6 -44 -17 -1056 -558 -410 -219 -810 -433 -890
                                -475 -80 -43 -379 -202 -665 -355 -286 -153 -623 -333 -750 -400 -126 -68
                                -551 -294 -944 -504 -392 -209 -719 -378 -725 -374 -11 7 -69 54 -451 368
                                -121 99 -272 223 -335 275 -63 52 -189 155 -280 230 -91 75 -217 178 -280 230
                                -63 52 -227 187 -365 300 -137 113 -322 265 -410 337 -88 72 -248 204 -355
                                292 -158 131 -205 164 -248 177 -67 20 -118 12 -179 -29 -85 -58 -117 -172
                                -73 -262 27 -56 -148 91 1675 -1406 446 -366 891 -732 990 -813 99 -82 196
                                -155 215 -163 21 -10 55 -14 90 -11 48 3 84 19 246 105 579 309 880 470 2469
                                1318 369 197 769 411 890 475 468 249 838 447 1030 550 110 59 286 153 392
                                209 134 71 202 114 227 141 78 86 74 207 -10 287 -56 54 -133 75 -208 56z"/>
                    <path d="M13425 8853 c-46 -24 -1325 -705 -1460 -778 -88 -47 -447 -239 -965
                                -515 -250 -134 -552 -294 -670 -358 -118 -63 -253 -135 -300 -160 -47 -24
                                -146 -77 -220 -117 -119 -64 -278 -149 -515 -275 -85 -46 -803 -429 -826 -441
                                -12 -6 -45 16 -135 90 -360 296 -967 794 -1389 1141 -264 217 -584 480 -711
                                584 -412 340 -438 361 -483 383 -141 72 -314 -47 -299 -204 3 -27 13 -65 22
                                -85 19 -40 24 -44 676 -579 637 -523 897 -737 1036 -851 76 -63 240 -197 364
                                -299 124 -102 339 -278 478 -393 139 -114 269 -218 288 -231 44 -30 114 -41
                                164 -26 21 6 258 129 526 272 269 144 595 317 724 386 129 69 262 140 295 158
                                33 18 202 108 375 200 173 92 347 185 385 205 39 21 169 91 290 155 121 65
                                321 172 445 238 124 66 290 155 370 197 80 42 377 201 660 352 283 151 648
                                346 810 432 223 119 304 167 332 198 74 80 76 194 6 274 -69 78 -177 97 -273
                                47z"/>
                  </g>
                </svg>
                <span>Instant Rate</span>
                {!hasInstantPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', margin:'0px', padding:"0px"}}></i>}
              </Link>
            </li>
            {/*{hasInquiryPermission() &&}*/}
            <li className={hasInquiryPermission() ? '' : 'text-muted disabled-link'}>
              <Link to="/#" className="has-arrow">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                     preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                     fill="#000000" stroke="none">
                    <path d="M1500 5109 c-280 -32 -569 -142 -800 -307 -121 -86 -296 -261 -382
                          -382 -394 -555 -420 -1279 -66 -1858 246 -402 674 -691 1144 -772 135 -24 379
                          -28 504 -10 235 34 474 120 676 244 l40 25 94 -94 94 -95 -54 -55 -55 -55 805
                          -805 c443 -443 831 -824 863 -848 190 -139 437 -123 608 40 172 164 195 421
                          53 618 -23 33 -405 422 -848 865 l-806 805 -55 -55 -55 -54 -95 94 -95 94 26
                          41 c59 95 147 288 181 394 23 74 48 130 69 157 17 24 40 67 49 96 10 29 19 54
                          20 55 1 2 60 -22 131 -52 70 -30 130 -55 131 -55 2 0 3 94 3 209 l0 209 128 4
                          c114 3 133 6 190 32 80 37 161 122 194 203 23 57 23 63 23 443 0 341 -2 391
                          -18 435 -24 69 -82 143 -147 187 -101 69 -92 68 -886 68 l-711 0 -84 39 c-262
                          121 -583 172 -869 140z m460 -164 c115 -23 120 -27 62 -53 -27 -12 -66 -37
                          -88 -56 l-40 -34 -85 10 c-107 12 -276 3 -401 -22 -264 -54 -490 -174 -684
                          -362 -207 -201 -334 -433 -396 -721 -28 -135 -30 -379 -4 -511 56 -287 182
                          -524 385 -726 58 -58 141 -129 183 -158 163 -111 373 -197 560 -227 120 -19
                          341 -19 454 0 439 76 817 360 1011 759 l46 96 79 0 c95 0 95 13 3 -175 -306
                          -620 -1000 -960 -1677 -820 -521 107 -957 490 -1132 994 -65 186 -80 280 -80
                          501 0 164 4 211 23 300 45 213 133 415 254 587 77 110 246 279 354 356 205
                          145 451 242 703 277 97 13 371 4 470 -15z m1954 -177 c54 -16 128 -90 144
                          -144 16 -53 17 -701 1 -757 -13 -47 -70 -112 -120 -138 -34 -17 -60 -19 -224
                          -19 l-185 0 0 -170 c0 -94 -4 -170 -9 -170 -5 0 -190 77 -412 170 l-403 170
                          -288 0 c-238 0 -295 3 -326 16 -47 19 -88 58 -115 109 -21 39 -22 50 -22 410
                          0 360 1 371 22 410 27 51 57 79 113 104 44 20 61 21 914 21 656 0 879 -3 910
                          -12z m-2116 -104 c16 -5 21 -11 17 -23 -4 -9 -9 -86 -12 -173 l-6 -156 -251
                          -4 -251 -3 -66 -31 c-37 -18 -84 -48 -106 -68 -48 -43 -97 -132 -112 -204 -14
                          -67 -15 -679 -1 -753 22 -118 103 -225 210 -276 49 -25 68 -28 188 -31 l132
                          -4 0 -209 c0 -115 2 -209 4 -209 2 0 227 95 501 210 l497 210 125 0 c146 0
                          142 7 72 -116 -89 -155 -238 -312 -389 -409 -370 -239 -815 -267 -1205 -76
                          -192 94 -349 226 -467 391 -259 363 -299 847 -104 1245 67 138 146 245 262
                          355 185 177 421 293 670 329 71 10 260 13 292 5z m2 -606 c1 -208 32 -310 122
                          -394 27 -25 74 -57 106 -72 l57 -27 310 -5 310 -6 275 -118 c151 -65 279 -121
                          283 -125 15 -15 -15 -100 -50 -141 -19 -22 -54 -51 -77 -63 -39 -21 -56 -22
                          -351 -27 l-310 -5 -382 -163 c-209 -89 -386 -162 -392 -162 -8 0 -11 52 -11
                          164 l0 164 -182 4 c-201 4 -230 11 -289 71 -65 65 -64 54 -64 467 0 365 1 376
                          22 415 27 51 57 79 113 104 41 19 65 21 278 21 l232 0 0 -102z m1270 -1763
                          l85 -85 -123 -123 -122 -122 -85 86 -86 85 118 121 c65 66 120 121 123 122 3
                          0 44 -37 90 -84z m387 -167 c46 -46 83 -87 83 -93 0 -13 -442 -455 -455 -455
                          -6 0 -49 38 -95 85 l-85 85 230 230 c126 127 232 230 235 230 2 0 42 -37 87
                          -82z m71 -535 l-233 -233 -52 52 -53 53 232 232 233 233 52 -52 53 -53 -232
                          -232z m864 -400 c587 -589 572 -571 573 -713 0 -71 -4 -94 -26 -140 -33 -70
                          -89 -126 -159 -159 -46 -22 -69 -26 -140 -26 -142 1 -124 -14 -712 573 -285
                          284 -518 521 -518 527 0 13 442 455 455 455 6 0 243 -233 527 -517z"/>
                    <path d="M2180 4445 l0 -75 830 0 830 0 0 75 0 75 -830 0 -830 0 0 -75z"/>
                    <path d="M2180 4045 l0 -75 330 0 330 0 0 75 0 75 -330 0 -330 0 0 -75z"/>
                    <path d="M2990 4045 l0 -75 75 0 75 0 0 75 0 75 -75 0 -75 0 0 -75z"/>
                    <path d="M3290 4045 l0 -75 75 0 75 0 0 75 0 75 -75 0 -75 0 0 -75z"/>
                  </g>
                </svg>
                {/*className="clickable-span" onClick={() => ShowPermissionErrorModal("Inquiries disabled by admin or no active subscription.")}*/}
                <span >Inquiries </span>
                {!hasInquiryPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', margin:'0px', padding:"0px"}}></i>}
              </Link>

              <ul className="sub-menu" style={{width: "150%"}}>
                {/*{hasInquiryListPermission() &&}*/}
                <li>
                  <Link to="/sales/inquiry" className={hasInquiryListPermission() ? '' : 'text-muted disabled-link'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                      <path d="M2 4.5C2 3.39543 2.89543 2.5 4 2.5H11C12.1046 2.5 13 3.39543 13 4.5V10.5C13 11.6046 12.1046 12.5 11 12.5H9.42084L7.93984 15.2378C7.8527 15.3989 7.68444 15.4995 7.50128 15.4999C7.31812 15.5004 7.14938 15.4007 7.06145 15.24L5.56195 12.5H4C2.89543 12.5 2 11.6046 2 10.5V4.5ZM5.5 5.5C5.22386 5.5 5 5.72386 5 6V7C5 7.27614 5.22386 7.5 5.5 7.5C5.61192 7.5 5.71526 7.46322 5.79858 7.4011C5.59719 8.06709 5.29647 8.49643 5.14645 8.64645C4.95118 8.84171 4.95118 9.15829 5.14645 9.35355C5.34171 9.54882 5.65829 9.54882 5.85355 9.35355C6.28256 8.92455 7 7.7299 7 6C7 5.72386 6.77614 5.5 6.5 5.5H5.5ZM8.5 5.5C8.22386 5.5 8 5.72386 8 6V7C8 7.27614 8.22386 7.5 8.5 7.5C8.61192 7.5 8.71526 7.46322 8.79858 7.4011C8.59719 8.06709 8.29647 8.49643 8.14645 8.64645C7.95118 8.84171 7.95118 9.15829 8.14645 9.35355C8.34171 9.54882 8.65829 9.54882 8.85355 9.35355C9.28256 8.92455 10 7.7299 10 6C10 5.72386 9.77614 5.5 9.5 5.5H8.5ZM5.51653 14.5H2.5C2.22386 14.5 2 14.7239 2 15C2 15.2761 2.22386 15.5 2.5 15.5H6.06379L5.51653 14.5ZM8.93496 15.5H17.5C17.7761 15.5 18 15.2761 18 15C18 14.7239 17.7761 14.5 17.5 14.5H9.47589L8.93496 15.5ZM2 18C2 17.7239 2.22386 17.5 2.5 17.5H12.5C12.7761 17.5 13 17.7239 13 18C13 18.2761 12.7761 18.5 12.5 18.5H2.5C2.22386 18.5 2 18.2761 2 18Z" fill="#4848F7" />
                    </svg>
                    <span>Inquiry List</span>
                    {!hasInquiryListPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
                {/*{hasInquiryRequestForRatePermission() &&}*/}
                <li>
                  <Link to="/sales/requestForRates" className={hasInquiryRequestForRatePermission() ? '' : 'text-muted disabled-link'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                      <path d="M2 4.5C2 3.39543 2.89543 2.5 4 2.5H11C12.1046 2.5 13 3.39543 13 4.5V10.5C13 11.6046 12.1046 12.5 11 12.5H9.42084L7.93984 15.2378C7.8527 15.3989 7.68444 15.4995 7.50128 15.4999C7.31812 15.5004 7.14938 15.4007 7.06145 15.24L5.56195 12.5H4C2.89543 12.5 2 11.6046 2 10.5V4.5ZM5.5 5.5C5.22386 5.5 5 5.72386 5 6V7C5 7.27614 5.22386 7.5 5.5 7.5C5.61192 7.5 5.71526 7.46322 5.79858 7.4011C5.59719 8.06709 5.29647 8.49643 5.14645 8.64645C4.95118 8.84171 4.95118 9.15829 5.14645 9.35355C5.34171 9.54882 5.65829 9.54882 5.85355 9.35355C6.28256 8.92455 7 7.7299 7 6C7 5.72386 6.77614 5.5 6.5 5.5H5.5ZM8.5 5.5C8.22386 5.5 8 5.72386 8 6V7C8 7.27614 8.22386 7.5 8.5 7.5C8.61192 7.5 8.71526 7.46322 8.79858 7.4011C8.59719 8.06709 8.29647 8.49643 8.14645 8.64645C7.95118 8.84171 7.95118 9.15829 8.14645 9.35355C8.34171 9.54882 8.65829 9.54882 8.85355 9.35355C9.28256 8.92455 10 7.7299 10 6C10 5.72386 9.77614 5.5 9.5 5.5H8.5ZM5.51653 14.5H2.5C2.22386 14.5 2 14.7239 2 15C2 15.2761 2.22386 15.5 2.5 15.5H6.06379L5.51653 14.5ZM8.93496 15.5H17.5C17.7761 15.5 18 15.2761 18 15C18 14.7239 17.7761 14.5 17.5 14.5H9.47589L8.93496 15.5ZM2 18C2 17.7239 2.22386 17.5 2.5 17.5H12.5C12.7761 17.5 13 17.7239 13 18C13 18.2761 12.7761 18.5 12.5 18.5H2.5C2.22386 18.5 2 18.2761 2 18Z" fill="#4848F7" />
                    </svg>
                    <span>Request for Rates</span>
                    {!hasInquiryRequestForRatePermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
                <li>
                  <Link to="/sales/marketplace/inquiry" className={hasInquiryListPermission() ? '' : 'text-muted disabled-link'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                      <path d="M2 4.5C2 3.39543 2.89543 2.5 4 2.5H11C12.1046 2.5 13 3.39543 13 4.5V10.5C13 11.6046 12.1046 12.5 11 12.5H9.42084L7.93984 15.2378C7.8527 15.3989 7.68444 15.4995 7.50128 15.4999C7.31812 15.5004 7.14938 15.4007 7.06145 15.24L5.56195 12.5H4C2.89543 12.5 2 11.6046 2 10.5V4.5ZM5.5 5.5C5.22386 5.5 5 5.72386 5 6V7C5 7.27614 5.22386 7.5 5.5 7.5C5.61192 7.5 5.71526 7.46322 5.79858 7.4011C5.59719 8.06709 5.29647 8.49643 5.14645 8.64645C4.95118 8.84171 4.95118 9.15829 5.14645 9.35355C5.34171 9.54882 5.65829 9.54882 5.85355 9.35355C6.28256 8.92455 7 7.7299 7 6C7 5.72386 6.77614 5.5 6.5 5.5H5.5ZM8.5 5.5C8.22386 5.5 8 5.72386 8 6V7C8 7.27614 8.22386 7.5 8.5 7.5C8.61192 7.5 8.71526 7.46322 8.79858 7.4011C8.59719 8.06709 8.29647 8.49643 8.14645 8.64645C7.95118 8.84171 7.95118 9.15829 8.14645 9.35355C8.34171 9.54882 8.65829 9.54882 8.85355 9.35355C9.28256 8.92455 10 7.7299 10 6C10 5.72386 9.77614 5.5 9.5 5.5H8.5ZM5.51653 14.5H2.5C2.22386 14.5 2 14.7239 2 15C2 15.2761 2.22386 15.5 2.5 15.5H6.06379L5.51653 14.5ZM8.93496 15.5H17.5C17.7761 15.5 18 15.2761 18 15C18 14.7239 17.7761 14.5 17.5 14.5H9.47589L8.93496 15.5ZM2 18C2 17.7239 2.22386 17.5 2.5 17.5H12.5C12.7761 17.5 13 17.7239 13 18C13 18.2761 12.7761 18.5 12.5 18.5H2.5C2.22386 18.5 2 18.2761 2 18Z" fill="#4848F7" />
                    </svg>
                    <span>MarketPlace Inquiry List</span>
                    {!hasInquiryListPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
              </ul>
            </li>
            <li className={hasQuotationPermission() ? '' : 'text-muted disabled-link'}>
              {/*{hasQuotationPermission() &&}*/}
              <Link to="/#" className="has-arrow">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                     preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                     fill="#000000" stroke="none">
                    <path d="M585 4936 l-25 -25 0 -2351 0 -2351 25 -24 24 -25 1951 0 1951 0 25
                              25 24 24 -2 2223 -3 2223 -32 67 c-44 93 -100 151 -191 196 l-76 37 -1823 3
                              -1824 2 -24 -24z m3239 -150 c18 -8 46 -31 62 -52 l29 -37 5 -250 c5 -247 5
                              -249 29 -268 21 -17 42 -19 237 -19 l214 0 0 -1920 0 -1920 -1840 0 -1840 0 0
                              2240 0 2240 1535 0 c1290 0 1541 -2 1569 -14z m410 2 c55 -16 138 -99 154
                              -154 7 -24 12 -100 12 -178 l0 -136 -160 0 -160 0 0 178 c0 169 -5 214 -35
                              285 -6 15 0 17 70 17 43 0 96 -5 119 -12z"/>
                    <path d="M1530 4626 c-187 -40 -340 -130 -460 -271 -227 -268 -252 -656 -60
                              -948 278 -427 875 -490 1234 -131 359 359 296 956 -131 1234 -75 50 -143 79
                              -243 107 -78 21 -260 26 -340 9z m284 -161 c367 -78 594 -474 473 -824 -36
                              -102 -81 -174 -157 -251 -76 -75 -148 -121 -252 -158 -63 -22 -89 -26 -193
                              -27 -97 0 -133 4 -190 22 -318 101 -508 425 -440 747 72 341 416 564 759 491z"/>
                    <path d="M1625 4295 c-14 -13 -25 -36 -25 -50 0 -16 -6 -25 -16 -25 -27 0
                              -119 -101 -132 -146 -28 -94 -8 -169 63 -239 51 -52 102 -75 164 -75 42 0 81
                              -38 81 -80 0 -41 -39 -80 -80 -80 -41 0 -80 39 -80 80 0 41 -39 80 -80 80 -42
                              0 -80 -39 -80 -81 0 -62 23 -113 77 -166 28 -29 59 -53 67 -53 10 0 16 -9 16
                              -25 0 -36 42 -75 80 -75 38 0 80 39 80 75 0 16 6 25 16 25 27 0 119 101 132
                              146 28 94 8 169 -63 239 -51 52 -102 75 -164 75 -71 0 -106 85 -56 135 49 50
                              135 15 135 -55 0 -41 39 -80 80 -80 42 0 80 39 80 81 0 62 -23 113 -77 167
                              -28 28 -59 52 -67 52 -10 0 -16 9 -16 25 0 36 -42 75 -80 75 -19 0 -40 -9 -55
                              -25z"/>
                    <path d="M3145 3815 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 431
                              0 431 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -431 0 -431 0
                              -24 -25z"/>
                    <path d="M2745 3495 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 631
                              0 631 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -631 0 -631 0
                              -24 -25z"/>
                    <path d="M2745 3175 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 631
                              0 631 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -631 0 -631 0
                              -24 -25z"/>
                    <path d="M1065 2695 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 24 -25 26
                              -25 215 -25 189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25
                              -26 25 -215 25 -189 0 -191 0 -215 -25z"/>
                    <path d="M1865 2695 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 671
                              0 671 0 24 25 c33 32 33 78 0 110 l-24 25 -671 0 -671 0 -24 -25z"/>
                    <path d="M3785 2695 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 23 -24 31
                              -25 135 -25 104 0 112 1 135 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -23 24
                              -31 25 -135 25 -104 0 -112 -1 -135 -25z"/>
                    <path d="M1065 2375 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 24 -25 26
                              -25 215 -25 189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25
                              -26 25 -215 25 -189 0 -191 0 -215 -25z"/>
                    <path d="M1865 2375 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 671
                              0 671 0 24 25 c33 32 33 78 0 110 l-24 25 -671 0 -671 0 -24 -25z"/>
                    <path d="M3785 2375 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 23 -24 31
                              -25 135 -25 104 0 112 1 135 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -23 24
                              -31 25 -135 25 -104 0 -112 -1 -135 -25z"/>
                    <path d="M1065 2055 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 24 -25 26
                              -25 215 -25 189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25
                              -26 25 -215 25 -189 0 -191 0 -215 -25z"/>
                    <path d="M1865 2055 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 671
                              0 671 0 24 25 c33 32 33 78 0 110 l-24 25 -671 0 -671 0 -24 -25z"/>
                    <path d="M3785 2055 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 23 -24 31
                              -25 135 -25 104 0 112 1 135 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -23 24
                              -31 25 -135 25 -104 0 -112 -1 -135 -25z"/>
                    <path d="M1065 1735 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 24 -25 26
                              -25 215 -25 189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25
                              -26 25 -215 25 -189 0 -191 0 -215 -25z"/>
                    <path d="M1865 1735 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 671
                              0 671 0 24 25 c33 32 33 78 0 110 l-24 25 -671 0 -671 0 -24 -25z"/>
                    <path d="M3785 1735 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 23 -24 31
                              -25 135 -25 104 0 112 1 135 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -23 24
                              -31 25 -135 25 -104 0 -112 -1 -135 -25z"/>
                    <path d="M1065 1415 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 24 -25 26
                              -25 215 -25 189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25
                              -26 25 -215 25 -189 0 -191 0 -215 -25z"/>
                    <path d="M1865 1415 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 671
                              0 671 0 24 25 c33 32 33 78 0 110 l-24 25 -671 0 -671 0 -24 -25z"/>
                    <path d="M3785 1415 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 23 -24 31
                              -25 135 -25 104 0 112 1 135 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -23 24
                              -31 25 -135 25 -104 0 -112 -1 -135 -25z"/>
                    <path d="M3305 935 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 351
                              0 351 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -351 0 -351 0
                              -24 -25z"/>
                    <path d="M2665 615 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 671
                              0 671 0 24 25 c33 32 33 78 0 110 l-24 25 -671 0 -671 0 -24 -25z"/>
                  </g>
                </svg>
                <span>Quotations </span>
                {!hasQuotationPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'0px', padding:"0px"}}></i>}
              </Link>
              <ul className="sub-menu">
                {/*{hasQuotationListPermission() &&}*/}
                <li>
                  <Link to="/sales/quotation" className={hasQuotationListPermission() ? '' : 'text-muted disabled-link'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                      <path d="M2 4.5C2 3.39543 2.89543 2.5 4 2.5H11C12.1046 2.5 13 3.39543 13 4.5V10.5C13 11.6046 12.1046 12.5 11 12.5H9.42084L7.93984 15.2378C7.8527 15.3989 7.68444 15.4995 7.50128 15.4999C7.31812 15.5004 7.14938 15.4007 7.06145 15.24L5.56195 12.5H4C2.89543 12.5 2 11.6046 2 10.5V4.5ZM5.5 5.5C5.22386 5.5 5 5.72386 5 6V7C5 7.27614 5.22386 7.5 5.5 7.5C5.61192 7.5 5.71526 7.46322 5.79858 7.4011C5.59719 8.06709 5.29647 8.49643 5.14645 8.64645C4.95118 8.84171 4.95118 9.15829 5.14645 9.35355C5.34171 9.54882 5.65829 9.54882 5.85355 9.35355C6.28256 8.92455 7 7.7299 7 6C7 5.72386 6.77614 5.5 6.5 5.5H5.5ZM8.5 5.5C8.22386 5.5 8 5.72386 8 6V7C8 7.27614 8.22386 7.5 8.5 7.5C8.61192 7.5 8.71526 7.46322 8.79858 7.4011C8.59719 8.06709 8.29647 8.49643 8.14645 8.64645C7.95118 8.84171 7.95118 9.15829 8.14645 9.35355C8.34171 9.54882 8.65829 9.54882 8.85355 9.35355C9.28256 8.92455 10 7.7299 10 6C10 5.72386 9.77614 5.5 9.5 5.5H8.5ZM5.51653 14.5H2.5C2.22386 14.5 2 14.7239 2 15C2 15.2761 2.22386 15.5 2.5 15.5H6.06379L5.51653 14.5ZM8.93496 15.5H17.5C17.7761 15.5 18 15.2761 18 15C18 14.7239 17.7761 14.5 17.5 14.5H9.47589L8.93496 15.5ZM2 18C2 17.7239 2.22386 17.5 2.5 17.5H12.5C12.7761 17.5 13 17.7239 13 18C13 18.2761 12.7761 18.5 12.5 18.5H2.5C2.22386 18.5 2 18.2761 2 18Z" fill="#4848F7" />
                    </svg>
                    <span>Quotations List</span>
                    {!hasQuotationListPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
                {/*{hasQuotationApprovalPermission() &&}*/}
                <li>
                  <Link to="/sales/quotationApproval" className={hasQuotationApprovalPermission() ? '' : 'text-muted disabled-link'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                      <path d="M2 4.5C2 3.39543 2.89543 2.5 4 2.5H11C12.1046 2.5 13 3.39543 13 4.5V10.5C13 11.6046 12.1046 12.5 11 12.5H9.42084L7.93984 15.2378C7.8527 15.3989 7.68444 15.4995 7.50128 15.4999C7.31812 15.5004 7.14938 15.4007 7.06145 15.24L5.56195 12.5H4C2.89543 12.5 2 11.6046 2 10.5V4.5ZM5.5 5.5C5.22386 5.5 5 5.72386 5 6V7C5 7.27614 5.22386 7.5 5.5 7.5C5.61192 7.5 5.71526 7.46322 5.79858 7.4011C5.59719 8.06709 5.29647 8.49643 5.14645 8.64645C4.95118 8.84171 4.95118 9.15829 5.14645 9.35355C5.34171 9.54882 5.65829 9.54882 5.85355 9.35355C6.28256 8.92455 7 7.7299 7 6C7 5.72386 6.77614 5.5 6.5 5.5H5.5ZM8.5 5.5C8.22386 5.5 8 5.72386 8 6V7C8 7.27614 8.22386 7.5 8.5 7.5C8.61192 7.5 8.71526 7.46322 8.79858 7.4011C8.59719 8.06709 8.29647 8.49643 8.14645 8.64645C7.95118 8.84171 7.95118 9.15829 8.14645 9.35355C8.34171 9.54882 8.65829 9.54882 8.85355 9.35355C9.28256 8.92455 10 7.7299 10 6C10 5.72386 9.77614 5.5 9.5 5.5H8.5ZM5.51653 14.5H2.5C2.22386 14.5 2 14.7239 2 15C2 15.2761 2.22386 15.5 2.5 15.5H6.06379L5.51653 14.5ZM8.93496 15.5H17.5C17.7761 15.5 18 15.2761 18 15C18 14.7239 17.7761 14.5 17.5 14.5H9.47589L8.93496 15.5ZM2 18C2 17.7239 2.22386 17.5 2.5 17.5H12.5C12.7761 17.5 13 17.7239 13 18C13 18.2761 12.7761 18.5 12.5 18.5H2.5C2.22386 18.5 2 18.2761 2 18Z" fill="#4848F7" />
                    </svg>
                    <span>Quotation Approval</span>
                    {!hasQuotationApprovalPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
              </ul>
            </li>
            {/*{hasCarrierRatesPermission() &&}*/}
            <li className={hasCarrierRatesPermission() ? '' : 'text-muted disabled-link'}>
              <Link to="/#" className="has-arrow">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                     preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                     fill="#000000" stroke="none">
                    <path d="M1593 5002 c-40 -6 -48 -29 -53 -156 l-5 -121 -135 -5 c-149 -6 -173
                            -13 -234 -76 -57 -59 -66 -101 -66 -321 l0 -193 -370 0 -370 0 0 -450 0 -450
                            595 0 595 0 0 440 0 440 75 0 75 0 0 -435 0 -435 220 0 221 0 64 55 c38 32 75
                            55 88 55 13 0 50 -23 87 -55 l65 -55 223 0 222 0 0 435 0 435 70 0 70 0 0
                            -435 0 -435 600 0 600 0 0 445 0 445 -379 0 -380 0 -3 208 c-3 228 -8 247 -70
                            311 -57 58 -81 65 -228 71 l-135 5 -5 122 c-6 137 -13 154 -70 155 -25 1 -40
                            -6 -57 -26 -21 -25 -23 -36 -23 -142 l0 -114 -255 0 -255 0 0 114 c0 106 -2
                            117 -23 142 -17 20 -32 27 -57 26 -57 -1 -64 -18 -70 -155 l-5 -122 -262 -3
                            -263 -2 0 113 c0 99 -3 118 -20 139 -21 27 -42 36 -77 30z m-612 -1131 c26
                            -31 24 -64 -5 -98 -24 -28 -25 -28 -164 -31 -107 -3 -147 0 -175 12 -41 17
                            -56 52 -41 95 16 45 45 52 212 49 148 -3 155 -4 173 -27z m1494 -6 c30 -30 32
                            -64 4 -99 l-20 -26 -165 0 c-151 0 -166 2 -184 20 -28 28 -26 75 5 105 24 24
                            28 25 180 25 152 0 156 -1 180 -25z m1486 12 c26 -20 40 -55 33 -82 -12 -49
                            -35 -55 -205 -55 -137 0 -160 2 -180 18 -33 27 -30 78 5 109 26 22 34 23 179
                            23 104 -1 157 -5 168 -13z"/>
                    <path d="M1700 2983 c0 -62 2 -113 5 -113 8 0 255 213 255 220 0 3 -59 5 -130
                            5 l-130 0 0 -112z"/>
                    <path d="M2135 3043 c-38 -33 -117 -102 -175 -153 -359 -316 -702 -507 -1084
                                -605 -184 -47 -343 -65 -567 -65 -104 0 -189 -3 -189 -7 0 -5 144 -301 320
                                -659 176 -359 320 -654 320 -656 0 -9 -102 11 -166 32 -34 12 -100 44 -146 72
                                -127 78 -132 80 -162 68 -14 -5 -31 -21 -37 -34 -21 -45 -4 -73 77 -126 132
                                -86 241 -131 371 -150 109 -17 210 -7 407 42 167 41 169 42 257 30 63 -7 156
                                -33 312 -86 122 -41 253 -80 291 -87 90 -15 206 -7 279 21 l57 21 58 -22 c47
                                -19 77 -23 169 -24 61 0 114 3 118 7 3 3 -5 28 -18 55 -38 74 -94 242 -112
                                335 -25 129 -30 324 -11 446 20 134 73 290 142 422 31 58 64 133 75 168 27 86
                                78 142 128 142 28 0 44 7 62 26 35 39 169 137 239 176 33 18 60 35 60 39 0 3
                                -19 15 -42 25 -116 52 -393 253 -593 430 -49 44 -117 102 -150 129 l-60 48 -3
                                -1145 -2 -1145 -39 10 c-28 8 -50 8 -75 0 l-36 -11 -2 1146 -3 1146 -70 -61z
                                m-326 -845 c137 -140 92 -621 -58 -621 -95 0 -160 186 -139 402 13 141 74 251
                                138 251 18 0 39 -11 59 -32z"/>
                    <path d="M2633 3089 c4 -12 249 -219 258 -219 6 0 8 47 7 113 l-3 112 -132 0
                              c-73 0 -132 -3 -130 -6z"/>
                    <path d="M3044 3091 c-2 -2 -4 -80 -4 -172 l0 -168 83 -51 c45 -28 127 -73
                              182 -100 l100 -49 66 19 c233 64 494 65 697 2 l62 -20 0 272 0 271 -591 0
                              c-325 0 -593 -2 -595 -4z m928 -208 c37 -42 28 -101 -19 -122 -14 -6 -84 -11
                              -169 -11 l-146 0 -29 30 c-34 33 -33 58 4 98 20 21 27 22 182 22 142 0 164 -2
                              177 -17z"/>
                    <path d="M360 2730 l0 -360 43 0 c148 0 424 52 617 116 121 40 362 155 468
                              223 l62 40 0 171 0 170 -595 0 -595 0 0 -360z m617 148 c31 -29 30 -72 -2
                              -103 -24 -24 -28 -25 -180 -25 -152 0 -156 1 -180 25 -31 30 -32 71 -3 102 21
                              22 27 23 182 23 150 0 161 -1 183 -22z"/>
                    <path d="M3680 2454 c-385 -58 -682 -251 -871 -565 -222 -370 -217 -845 13
                                -1217 74 -119 235 -282 358 -361 455 -293 1039 -240 1436 132 131 122 246 301
                                303 472 230 686 -208 1414 -919 1530 -86 13 -256 18 -320 9z m178 -376 c7 -7
                                12 -42 12 -88 l0 -77 94 -11 c113 -14 223 -46 254 -75 37 -35 26 -88 -26 -116
                                -19 -10 -34 -9 -85 8 -72 24 -201 45 -223 37 -12 -4 -14 -41 -14 -214 l0 -209
                                103 -33 c132 -41 188 -72 246 -136 53 -58 71 -106 71 -190 0 -64 -28 -136 -75
                                -199 -52 -67 -173 -126 -289 -140 l-56 -7 0 -68 c0 -83 -5 -99 -38 -111 -18
                                -7 -31 -5 -48 6 -22 14 -24 23 -24 93 l0 78 -90 12 c-126 17 -273 66 -300 99
                                -27 35 -26 74 4 102 29 28 55 25 163 -13 40 -15 106 -29 148 -33 l75 -6 0 206
                                0 206 -72 22 c-165 48 -258 113 -301 207 -32 69 -30 187 5 263 58 128 161 195
                                341 224 l37 6 0 72 c0 80 9 97 50 97 14 0 31 -5 38 -12z"/>
                    <path d="M3717 1749 c-83 -19 -151 -68 -173 -126 -30 -78 -5 -153 65 -197 30
                                  -19 141 -66 156 -66 8 0 5 389 -2 393 -5 2 -25 0 -46 -4z"/>
                    <path d="M3876 1163 c-3 -4 -6 -89 -6 -191 l0 -185 52 7 c128 17 198 82 202
                                185 1 61 -21 94 -86 127 -66 33 -154 64 -162 57z"/>
                  </g>
                </svg>
                <span>Carrier Rates</span>
                {!hasCarrierRatesPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'0px', padding:"0px"}}></i>}
              </Link>
              <ul className="sub-menu">
                {/*{hasOceanFrightPermission() &&}*/}
                <li className={hasOceanFrightPermission() ? '' : 'text-muted disabled-link'}>
                  <Link to="/#" className="has-arrow">
                    <span>Ocean Freight</span>
                    {!hasOceanFrightPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                  <ul className="sub-menu">

                    {/* <li>
                      <Link to="/freight/ocean/fcl">
                        <span>FCL</span>
                      </Link>
                    </li> */}

                    <li>
                      <Link to="/freight/ocean/fcl/upload">
                        <span>FCL Freight</span>
                      </Link>
                    </li>

                    {/* <li>
                      <Link to="/freight/ocean/lcl" className="opacity-25">
                      <Link to="/#" className="opacity-25" style={{ pointerEvents: 'none' }}>
                        <span>LCL</span>
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/freight/ocean/portlocal">
                        <span>FCL Port/Local Charges</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/freight/inland">
                        <span>FCL Inland Charges </span>
                      </Link>
                    </li>
                  </ul>
                </li>
                {/*{hasAirFrightPermission() &&}*/}
                <li className={hasAirFrightPermission() ? '' : 'text-muted disabled-link'}>
                  <Link to="/#" className="has-arrow">
                    <span>Air Freight</span>
                    {!hasAirFrightPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                  <ul className="sub-menu">

                    <li>
                      <Link to="/freight/air/masterbill">
                        <span>Master Waybill</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/freight/air/console">
                        <span>Console</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/freight/air/local">
                        <span>Airport/Local Charges</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/freight/air/line">
                        <span>Airline charges</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/freight/air/compare-rate">
                        <span>Compare Rate</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="/freight/inland">
                    <span>Inland Charges</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/express">
                    <span className="menu_circle"></span>
                    <span>Express</span>
                  </Link>
                </li> */}
              </ul>
            </li>
            {/*{hasCustomerPermission() &&}*/}
            <li className={hasCustomerPermission() ? '' : 'text-muted disabled-link'}>
              <Link to="/#" className="has-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <path d="M12.5 5C12.5 6.38071 11.3807 7.5 10 7.5C8.61929 7.5 7.5 6.38071 7.5 5C7.5 3.61929 8.61929 2.5 10 2.5C11.3807 2.5 12.5 3.61929 12.5 5ZM17.5 5.5C17.5 6.60457 16.6046 7.5 15.5 7.5C14.3954 7.5 13.5 6.60457 13.5 5.5C13.5 4.39543 14.3954 3.5 15.5 3.5C16.6046 3.5 17.5 4.39543 17.5 5.5ZM4.5 7.5C5.60457 7.5 6.5 6.60457 6.5 5.5C6.5 4.39543 5.60457 3.5 4.5 3.5C3.39543 3.5 2.5 4.39543 2.5 5.5C2.5 6.60457 3.39543 7.5 4.5 7.5ZM6 9.75C6 9.05964 6.55964 8.5 7.25 8.5H12.75C13.4404 8.5 14 9.05964 14 9.75V14.5C14 16.7091 12.2091 18.5 10 18.5C7.79086 18.5 6 16.7091 6 14.5V9.75ZM5 9.75C5 9.28746 5.13957 8.85752 5.37889 8.5H3.25C2.55964 8.5 2 9.05964 2 9.75V13.5C2 15.1569 3.34315 16.5 5 16.5C5.13712 16.5 5.27209 16.4908 5.40434 16.473C5.14412 15.8677 5 15.2007 5 14.5V9.75ZM15 14.5C15 15.2007 14.8559 15.8677 14.5957 16.473C14.7279 16.4908 14.8629 16.5 15 16.5C16.6569 16.5 18 15.1569 18 13.5V9.75C18 9.05964 17.4404 8.5 16.75 8.5H14.6211C14.8604 8.85752 15 9.28746 15 9.75V14.5Z" fill="#6264A0" />
                </svg>
                <span>Customers</span>
                {!hasCustomerPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'0px', padding:"0px"}}></i>}
              </Link>
              <ul className="sub-menu">
                {/*{hasCustomerListPermission() &&}*/}
                <li className={hasCustomerListPermission() ? '' : 'text-muted disabled-link'}>
                  <Link to="/customers">
                    <span>Customers List</span>
                    {!hasCustomerListPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
                {/*{hasCustomerRatesPermission() &&}*/}
                <li className={hasCustomerRatesPermission() ? '' : 'text-muted disabled-link'}>
                  <Link to="/customerRates">
                    <span>Customer Rates</span>
                    {!hasCustomerRatesPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
              </ul>
            </li>
            {/*{hasMarketingPermission() &&}*/}
            <li className={hasMarketingPermission() ? '' : 'text-muted disabled-link'}
                onClick={() => {
                  dispatch({ type: BLANK_MODAL_CHARGE, payload: {} });
                  dispatch({ type: QUOTATION_RESULT_SELECTED_BLANK, payload: {} });
                  dispatch({ type: BLANK_INSTANT_SEARCH });
                  dispatch({ type: INSTANT_RATE_ACTIVE_TAB_TYPE, payload: { main: "domestic", sub: "dom_air" } }); }}>
              <Link to="/lead-genaration-page" className="has-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <path d="M4.5 4.25C4.5 3.83579 4.16421 3.5 3.75 3.5C3.33579 3.5 3 3.83579 3 4.25V14.75C3 16.2688 4.23122 17.5 5.75 17.5H16.25C16.6642 17.5 17 17.1642 17 16.75C17 16.3358 16.6642 16 16.25 16H5.75C5.05964 16 4.5 15.4404 4.5 14.75V4.25ZM12.75 5.5C12.3358 5.5 12 5.83579 12 6.25C12 6.66421 12.3358 7 12.75 7H14.4393L11 10.4393L9.53033 8.96967C9.38968 8.82902 9.19892 8.75 9 8.75C8.80109 8.75 8.61032 8.82902 8.46967 8.96967L5.71967 11.7197C5.42678 12.0126 5.42678 12.4874 5.71967 12.7803C6.01256 13.0732 6.48744 13.0732 6.78033 12.7803L9 10.5607L10.4696 12.0303C10.6103 12.171 10.8011 12.25 11 12.25C11.1989 12.25 11.3896 12.171 11.5303 12.0303L15.5 8.06063V9.75C15.5 10.1642 15.8358 10.5 16.25 10.5C16.6642 10.5 17 10.1642 17 9.75V6.25C17 5.83579 16.6642 5.5 16.25 5.5H12.75Z" fill="#6264A0" />
                </svg>
                <span>Marketing</span>
                {!hasMarketingPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'0px', padding:"0px"}}></i>}
              </Link>
            </li>
            {/*{hasSettingPermission() &&}*/}
            <li className={hasSettingPermission() ? '' : 'text-muted disabled-link'}>
              <Link to="/#" className="has-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <path d="M1.91075 7.88266C2.28004 6.74053 2.88839 5.69213 3.69109 4.80364C3.82683 4.65339 4.03978 4.59984 4.23044 4.66802L6.14873 5.35392C6.6688 5.53977 7.24107 5.26883 7.42692 4.74875C7.4452 4.69762 7.45927 4.64507 7.469 4.59173L7.83446 2.58573C7.8708 2.38627 8.02398 2.2285 8.22227 2.1863C8.80246 2.0628 9.39734 2 10 2C10.6023 2 11.1968 2.06273 11.7767 2.18607C11.9749 2.22824 12.1281 2.38591 12.1645 2.58529L12.531 4.59165C12.6301 5.13497 13.1509 5.4951 13.6942 5.39601C13.7476 5.38627 13.8002 5.37219 13.8512 5.35395L15.7696 4.66802C15.9602 4.59984 16.1732 4.65339 16.3089 4.80364C17.1116 5.69213 17.72 6.74053 18.0893 7.88266C18.1516 8.07534 18.0915 8.28658 17.9371 8.41764L16.3823 9.73773C15.9613 10.0952 15.9098 10.7263 16.2673 11.1473C16.3024 11.1887 16.3409 11.2271 16.3823 11.2623L17.9371 12.5824C18.0915 12.7134 18.1516 12.9247 18.0893 13.1173C17.72 14.2595 17.1116 15.3079 16.3089 16.1964C16.1732 16.3466 15.9602 16.4002 15.7696 16.332L13.8513 15.6461C13.3312 15.4602 12.759 15.7312 12.5731 16.2512C12.5548 16.3024 12.5408 16.3549 12.531 16.4085L12.1645 18.4147C12.1281 18.6141 11.9749 18.7718 11.7767 18.8139C11.1968 18.9373 10.6023 19 10 19C9.39734 19 8.80246 18.9372 8.22227 18.8137C8.02398 18.7715 7.8708 18.6137 7.83446 18.4143L7.46902 16.4084C7.36993 15.865 6.84916 15.5049 6.30583 15.604C6.25241 15.6137 6.19987 15.6278 6.14881 15.6461L4.23044 16.332C4.03978 16.4002 3.82683 16.3466 3.69109 16.1964C2.88839 15.3079 2.28004 14.2595 1.91075 13.1173C1.84845 12.9247 1.90852 12.7134 2.06289 12.5824L3.61773 11.2623C4.03872 10.9048 4.09021 10.2737 3.73274 9.85274C3.69759 9.81135 3.65913 9.77288 3.61775 9.73775L2.06289 8.41764C1.90852 8.28658 1.84845 8.07534 1.91075 7.88266ZM8.00001 10.5C8.00001 11.6046 8.89544 12.5 10 12.5C11.1046 12.5 12 11.6046 12 10.5C12 9.39543 11.1046 8.5 10 8.5C8.89544 8.5 8.00001 9.39543 8.00001 10.5Z" fill="#6264A0" />
                </svg>
                <span>Settings</span>
                {!hasSettingPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'0px', padding:"0px"}}></i>}
              </Link>
              <ul className="sub-menu">
                {/*{hasCompanySettingPermission() &&}*/}
                <li className={hasCompanySettingPermission() ? '' : 'text-muted disabled-link'}>
                  <Link to="/settings/company-settings">
                    <span>Company Settings</span>
                    {!hasCompanySettingPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
                {/*{hasMarketPlaceConfigPermission() &&}*/}
                <li className={hasMarketPlaceConfigPermission() ? '' : 'text-muted disabled-link'}>
                  <Link to="/Settings/marketplace/config">
                    <span>MarketPlace Config</span>
                    {!hasMarketPlaceConfigPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
                {/*{hasIntegrationsConfigPermission() &&}*/}
                <li className={hasIntegrationsConfigPermission() ? '' : 'text-muted disabled-link'}>
                  <Link to="/settings/integrations/config">
                    <span>Integrations</span>
                    {!hasIntegrationsConfigPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
                <li className={hasUsersPermission() ? '' : 'text-muted disabled-link'}>
                  {/*{hasUsersPermission() &&}*/}
                  <Link className="has-arrow">
                    <span>Users</span>
                    {!hasUsersPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/settings/users">
                        <span>Users</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/settings/roles">
                        <span>Roles</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                {/*{hasSurchargeMasterPermission() &&}*/}
                <li className={hasSurchargeMasterPermission() ? '' : 'text-muted disabled-link'}>
                  <Link to="/settings/surcharge">
                    <span>Surcharge Master</span>
                    {!hasSurchargeMasterPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
                {/*{hasVendorPermission() &&}*/}
                <li className={hasVendorPermission() ? '' : 'text-muted disabled-link'}>
                  <Link to="/vendors">
                    <span>Vendors</span>
                    {!hasVendorPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
                {/*{hasCarrierRatesPermission() &&}*/}
                <li className={hasCarrierRatesPermission() ? '' : 'text-muted disabled-link'}>
                  <Link to="/carrierMaster">
                    <span>Carrier Master</span>
                    {!hasCarrierRatesPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
                {/*{hasUploadStatusPermission() &&}*/}
                <li className={hasUploadStatusPermission() ? '' : 'text-muted disabled-link'}>
                  <Link to="/upload-status">
                    <span>Upload Status</span>
                    {!hasUploadStatusPermission() && <i className="bx bx-lock-alt" style={{fontSize: '13px', marginLeft:'10px', padding:"0px"}}></i>}
                  </Link>
                </li>
              </ul>

            </li>
          </ul>

          {/*<div className="card sidebar-alert border-0 text-center mx-4 mb-0 mt-5">*/}
          {/*  <div className="card-body">*/}
          {/*    <img src={giftBox} alt="" />*/}
          {/*    <div className="mt-4">*/}
          {/*      <h5 className="alertcard-title font-size-16">Unlimited Access</h5>*/}
          {/*      <p className="font-size-13">Upgrade your plan from a Free trial, to select ‘Business Plan’.</p>*/}
          {/*      <a href="#!" className="btn btn-primary mt-2">Upgrade Now</a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </SimpleBar>
    </React.Fragment >
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
