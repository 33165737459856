import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Card, CardBody, Container, DropdownItem, FormFeedback, FormGroup, Input, Modal, Table} from "reactstrap";
import {useFormik} from "formik";
import TfBreadcrumbs from "../../components/Common/TfBreadcrumbs";
import {AddSubscriptionBreadcrumb, EditSubscriptionBreadcrumb} from "../../common/data/parties";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {GET_MODULE_TYPE,} from "../../store/Global/actiontype";
import {RoleName} from "../Settings/Roles/RolesCol";
import {showErrorToast} from "../../components/Common/CustomToast";
import * as Yup from "yup";
import AccessModuleTable from "./components/AccessModuleTable";
import { postSubscriptionsSummaryData } from "../../store/Subscriptions/actions";
import {getAuthType, isObjectEmpty} from "../../components/Common/CommonLogic";
import { useLoadingBar } from '../../components/Common/ProgressBar';
import {SUBSCRIPTION_INITIALIZE, SUBSCRIPTION_LOADER_START} from "../../store/Subscriptions/actiontype";
import {getAllModuleBasedOnLogin} from "../../store/Global/actions";


const AddEditSubscriptions = () => {
    const { start, complete, decrease, increase } = useLoadingBar({
        height: 3,
      });
    const navigateState = useLocation();
    const navigate = useNavigate();
    const { roleData, moduleData, new_Permission, module_loader, role_data_By_id } = useSelector((state) => state.globalReducer);
    const [permissions, setPermissions] = useState(navigateState?.state?.permissions ? navigateState?.state?.permissions : []);
    const selectAllRefs = useRef(navigateState?.state?.data?.subscriptionModuleMaps ? navigateState?.state?.data?.subscriptionModuleMaps : []);
    const selectAllPermissions = useRef(navigateState?.state?.data?.subscriptionModuleMaps ? navigateState?.state?.data?.subscriptionModuleMaps : []);
    const { subscriptionsLoader } = useSelector((state) => state.subscriptionsReducer);
    const [disabled, setDisabled] = useState(true);
    const [disableInput, setDisableInput] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [cancelUpdate, setCancelUpdate] = useState(false);
    const [resetAllCheckBox, setResetAllCheckBox] = useState(false);
    const dispatch = useDispatch();

    let newPermission = [];

    useEffect(() => {
        dispatch(getAllModuleBasedOnLogin(getAuthType() !== '1' ));
        //console.log(navigateState?.state?.data);
        if (navigateState?.state?.data !== undefined) {
            setDisableInput(true);
            setDisableButton(true);
            setDisabled(false);
            setIsUpdate(true);
            setCancelUpdate(true);
            navigateState?.state?.data?.subscriptionModuleMaps.map((item) => {
                newPermission.push({
                    moduleId: item.moduleId,
                });
            });
            setPermissions(newPermission);
        }
    }, [dispatch])

    const viewPopupHandler = (data) => {
        //console.log("popup");
    };

    function checkPermission(module) {
        //console.log("checkPermission", module);
        const permission = selectAllPermissions.current
            .filter(data => data.moduleId === getRowModuleId(module))
            .some(data => data.moduleId === getRowModuleId(module));
        return permission;
    }

    useEffect(() => {
        //console.log(new_Permission);
        selectAllRefs.current = []
        selectAllRefs.current = new_Permission;
    }, [new_Permission])



    const onClickSave = async (event, rowData) => {
        setResetAllCheckBox(true);
        //console.log(event, rowData);
        if (rowData) {
            if (event) {
                newPermission.push({
                    moduleId: getRowModuleId(rowData),
                });
            } else {
                newPermission = newPermission.filter(permission => !(
                    permission.moduleId === getRowModuleId(rowData)
                ));
            }
            setPermissions(newPermission);
        }
        //console.log(newPermission)
    }

    const getRowModuleId = (rowData) => {
        if (rowData.hasOwnProperty("parentModuleId")) {
            return rowData.id;
        } else {
            return rowData.id;
        }
    }

    const roleFormik = useFormik({
        initialValues: {
            subscriptionCode: navigateState?.state?.data?.subscriptionCode,
            subscriptionName: navigateState?.state?.data?.subscriptionName || "",
            //version: navigateState?.state?.data?.version || 0,
        },
        validationSchema: Yup.object({
            subscriptionCode: Yup.string().required("Please enter subscription code"),
            subscriptionName: Yup.string().required("Please select subscription name"),
        }),
        onSubmit: (values) => {
            setDisableInput(true);
            setDisabled(false);
        },
    });

    const resetSubscriptions = () => {
        setDisableInput(false);
        setDisabled(true);
    }

    const onModuleCancel = () => {
        setResetAllCheckBox(false);
        subscriptionsGroupingColumns.
        newPermission = [];
    }

    const onModuleSubmit = async () => {
        if (permissions.length > 0) {
            dispatch({type: SUBSCRIPTION_LOADER_START});
            start();
            let subscriptionData = {
                id: navigateState?.state?.data?.id || "",
                version: navigateState?.state?.data?.version || "0",
                subscriptionCode: roleFormik.values.subscriptionCode,
                subscriptionName: roleFormik.values.subscriptionName,
                subscriptionModuleMaps : permissions
            }
            console.log("onModuleSubmit", subscriptionData);
            dispatch(postSubscriptionsSummaryData(subscriptionData));
        } else {
            showErrorToast("Please select at-least one module");
        }
    };

    useEffect(() => {
        //console.log(subscriptionResponse);
        if (subscriptionsLoader === true) {
            start();
        }
        if (subscriptionsLoader === false) {
            dispatch({ type: SUBSCRIPTION_INITIALIZE });
            navigate('/network/subscriptions');
            setTimeout(() => {
                complete();
            }, 1000);
        }
    }, [subscriptionsLoader])


    const subscriptionsGroupingColumns = useMemo(() => ([
        {
            Header: "Modules",
            accessor: "label",
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <RoleName cellProps={cellProps} viewPopupHandler={viewPopupHandler} />
                );
            },
        },
        {
            Header: "Sub Modules",
            accessor: "modules",
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <>
                        {cellProps.row.original.subModules?.map((module, index) => (
                            <div key={index}>
                                {module.name}
                                {index !== cellProps.row.original.subModules?.length - 1 && <hr className="module-divider" />}
                            </div>
                        ))}
                    </>
                );
            },
        },
        {
            Header: "Access",
            accessor: "access",
            filterable: true,
            width: 2,
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <>
                        {cellProps.row.original.subModules && cellProps.row.original.subModules.length > 0 ? cellProps.row.original.subModules?.map((module, index) => (
                                <div key={index}>
                                    <Input
                                        type="checkbox"
                                        className="custom-checkbox"
                                        onChange={(e) => { onClickSave(e.target.checked, module) }}
                                        defaultChecked={checkPermission(module) || false}
                                    />
                                    {index !== cellProps.row.original.subModules?.length - 1 && <hr className="module-divider" />}
                                </div>
                            )) :
                            <Input
                                type="checkbox"
                                className="custom-checkbox"
                                onChange={(e) => onClickSave(e.target.checked, cellProps.row.original)}
                                defaultChecked={checkPermission(cellProps.row.original) || false}
                            />
                        }
                    </>
                );
            },
        }
    ]), []);


    return(
        <>
            <div className="page-content settings_users_wrapper">
                <Container fluid>
                    <div className="main_freight_wrapper">
                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TfBreadcrumbs breadcrumb={isUpdate ? EditSubscriptionBreadcrumb : AddSubscriptionBreadcrumb} />
                            <div className="form-container">
                                <button type="button" className="btn border mb-3" onClick={() => { navigate(-1); }} > Back </button>
                                <div className="row">
                                    <Card>
                                        <CardBody>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-4 mb-4">
                                                    <label className="form-label">Subscription Code</label>
                                                    <Input
                                                        type="text"
                                                        name="subscriptionCode"
                                                        value={roleFormik.values.subscriptionCode}
                                                        onChange={roleFormik.handleChange}
                                                        onBlur={roleFormik.handleBlur}
                                                        className="form-control"
                                                        placeholder="Subscription Code"
                                                        invalid={roleFormik.touched.subscriptionCode && roleFormik.errors.subscriptionCode ? true : false}
                                                        disabled={disableInput}
                                                    />
                                                    {roleFormik.touched.subscriptionCode && roleFormik.errors.subscriptionCode ? (
                                                        <FormFeedback>{roleFormik.errors.subscriptionCode}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="col-12 col-md-4 mb-4">
                                                    <label className="form-label">Subscription Name</label>
                                                    <Input
                                                        type="text"
                                                        name="subscriptionName"
                                                        value={roleFormik.values.subscriptionName}
                                                        onChange={roleFormik.handleChange}
                                                        onBlur={roleFormik.handleBlur}
                                                        className="form-control"
                                                        placeholder="Subscription Name"
                                                        invalid={roleFormik.touched.subscriptionName && roleFormik.errors.subscriptionName ? true : false}
                                                    />
                                                    {roleFormik.touched.subscriptionName && roleFormik.errors.subscriptionName ? (
                                                        <FormFeedback>{roleFormik.errors.subscriptionName}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="mb-3 mx-3 d-flex justify-content-center">
                                                        <button className=" btn btn-primary"
                                                                onClick={roleFormik.handleSubmit}
                                                                disabled={disableButton}
                                                                type="submit"> Save
                                                        </button>
                                                    </div>
                                                    <div className="mb-3 mx-3 d-flex justify-content-center">
                                                        <button className=" btn btn-primary"
                                                                onClick={() => resetSubscriptions()}
                                                                disabled={cancelUpdate}
                                                                type="button">Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <AccessModuleTable
                                    columns={subscriptionsGroupingColumns}
                                    data={moduleData || []}
                                    customPageSize={10}
                                    loader={module_loader || false}
                                    onModuleCancel={onModuleCancel}
                                    onModuleSave={onModuleSubmit}
                                    enableAction={disabled}
                                    isUpdate={isUpdate}
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default AddEditSubscriptions;