import React, {useEffect, useState} from "react";
import Select from "react-select";
import {optionMarkupType} from "../../common/data/common";
import {
    DOMECTICSOceanSurcharges, DOMESTICSDestInlandCharge,
    DOMESTICSDestLocalPortCharge, DOMESTICSInlandCharge,
    DOMESTICSOceanFreightCharge,
    DOMESTICSOriginInlandCharges,
    DOMESTICSOriginLocalPortCharge,
    DomesticsTabName,
    FCLDestInlandCharge,
    FCLDestLocalPortCharge, FCLInlandCharge,
    FCLOceanFreightCharge,
    FCLOceanSurcharges,
    FCLOriginInlandCharges,
    FCLOriginLocalPortCharge,
    FclTabName,
    INTERNATIONALDestInlandCharge,
    INTERNATIONALDestLocalPortCharge,
    INTERNATIONALOceanFreightCharge,
    INTERNATIONALOceanSurcharges,
    INTERNATIONALOriginInlandCharges,
    INTERNATIONALOriginLocalPortCharge,
    InternationTabName,
    LCLDestInlandCharge,
    LCLDestLocalPortCharge,
    LCLOceanFreightCharge,
    LCLOceanSurcharges,
    LCLOriginInlandCharges,
    LCLOriginLocalPortCharge,
    LclTabName
} from "../Dashboard/Partials/initialData";
import {isObjectEmpty} from "../../components/Common/CommonLogic";


const QuotationMarginComponents = ({prefix, currency_data, useFormik, marketPlaceData, activeTab}) => {
    const [originInlandMarginType, setOriginInlandMarginType] = useState({ value:"", label:"Margin Type" });
    const [originInlandCurrency, setOriginInlandCurrency] = useState({value:"", label:"Select Currency"});
    const [originLocalPortMarginType, setOriginLocalPortMarginType] = useState({ value:"", label:"Margin Type" });
    const [originLocalPortCurrency, setOriginLocalPortCurrency] = useState({value:"", label:"Select Currency"});
    const [oceanFreightMarginType, setOceanFreightMarginType] = useState({ value:"", label:"Margin Type" });
    const [oceanFreightCurrency, setOceanFreightCurrency] = useState({value:"", label:"Select Currency"});
    const [oceanSurchargesMarginType, setOceanSurchargesMarginType] = useState({ value:"", label:"Margin Type" });
    const [oceanSurchargesCurrency, setOceanSurchargesCurrency] = useState({value:"", label:"Select Currency"});
    const [destLocalPortMarginType, setDestLocalPortMarginType] = useState({ value:"", label:"Margin Type" });
    const [destLocalPortCurrency, setDestLocalPortCurrency] = useState({value:"", label:"Select Currency"});
    const [destInlandMarginType, setDestInlandMarginType] = useState({ value:"", label:"Margin Type" });
    const [destInlandCurrency, setDestInlandCurrency] = useState({value:"", label:"Select Currency"});

    useEffect(() => {
        //console.log("marketPlaceData", marketPlaceData);
        //console.log("active Tab" , activeTab);
        if (marketPlaceData !== undefined) {
            originInlandChargesDataRender();
            originLocalPortDataRender();
            oceanFreightChargesDataRender();
            oceanSurchargesDataRender();
            destLocalPortChargesDataRender();
            //destInlandChargesDataRender();
        }
    }, [currency_data, marketPlaceData, activeTab])

    const originInlandChargesDataRender = () => {
        //For FCL
        if (activeTab === FclTabName) {
            setOriginInlandMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData.find(data => data.serviceType === FclTabName && data.chargeType === FCLInlandCharge)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            useFormik.setFieldValue(`${prefix}InlandChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData.find(data => data.serviceType === FclTabName && data.chargeType === FCLInlandCharge)?.marginType));

            /*setOriginInlandCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData.find(data => data.serviceType === FclTabName && data.chargeType === FCLInlandCharge)?.currencyId)
                || originInlandCurrency
            )

            useFormik.setFieldValue(`${prefix}InlandChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData.find(data => data.serviceType === FclTabName && data.chargeType === FCLInlandCharge)?.currencyId));*/
        }

        if(activeTab === LclTabName) {
            setOriginInlandMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData.find(data => data.serviceType === LclTabName && data.chargeType === LCLOriginInlandCharges)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            useFormik.setFieldValue(`${prefix}OriginInlandChargesMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData.find(data => data.serviceType === LclTabName && data.chargeType === LCLOriginInlandCharges)?.marginType));

            /*setOriginInlandCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData.find(data => data?.serviceType === LclTabName && data.chargeType === LCLOriginInlandCharges)?.currencyId)
                || originInlandCurrency
            )

            useFormik.setFieldValue(`${prefix}OriginInlandChargesCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData.find(data => data.serviceType === LclTabName && data.chargeType === LCLOriginInlandCharges)?.currencyId));*/
        }

        if (activeTab === InternationTabName) {
            setOriginInlandMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOriginInlandCharges)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            useFormik.setFieldValue(`${prefix}OriginInlandChargesMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOriginInlandCharges)?.marginType));

            /*setOriginInlandCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOriginInlandCharges)?.currencyId)
                || originInlandCurrency
            )

            useFormik.setFieldValue(`${prefix}OriginInlandChargesCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOriginInlandCharges)?.currencyId));*/
        }

        if (activeTab === DomesticsTabName) {

            setOriginInlandMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSInlandCharge)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            useFormik.setFieldValue(`${prefix}InlandChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSInlandCharge)?.marginType));

            /*setOriginInlandCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOriginInlandCharges)?.currencyId)
                || originInlandCurrency
            )

            useFormik.setFieldValue(`${prefix}OriginInlandChargesCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOriginInlandCharges)?.currencyId));*/
        }
    }

    const originLocalPortDataRender = () => {
        //For FCL
        if (activeTab === FclTabName) {
            setOriginLocalPortMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData.find(data => data.serviceType === FclTabName && data.chargeType === FCLOriginLocalPortCharge)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOriginLocalPortCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData.find(data => data?.serviceType === FclTabName && data.chargeType === FCLOriginLocalPortCharge)?.currencyId)
                || originLocalPortCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OriginLocalPortChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOriginLocalPortCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OriginLocalPortChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOriginLocalPortCharge)?.currencyId));*/
        }

        if (activeTab === LclTabName) {
            setOriginLocalPortMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOriginLocalPortCharge)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOriginLocalPortCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOriginLocalPortCharge)?.currencyId)
                || originLocalPortCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OriginLocalPortChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOriginLocalPortCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OriginLocalPortChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOriginLocalPortCharge)?.currencyId));*/
        }


        if (activeTab === InternationTabName) {
            setOriginLocalPortMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOriginLocalPortCharge)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOriginLocalPortCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOriginLocalPortCharge)?.currencyId)
                || originLocalPortCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OriginLocalPortChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOriginLocalPortCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OriginLocalPortChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOriginLocalPortCharge)?.currencyId));*/
        }

        if (activeTab === DomesticsTabName) {
            setOriginLocalPortMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOriginLocalPortCharge)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOriginLocalPortCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOriginLocalPortCharge)?.currencyId)
                || originLocalPortCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OriginLocalPortChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOriginLocalPortCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OriginLocalPortChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOriginLocalPortCharge)?.currencyId));*/
        }
    }

    const oceanFreightChargesDataRender = () => {
        //For FCL
        if (activeTab === FclTabName) {
            setOceanFreightMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData.find(data => data.serviceType === FclTabName && data.chargeType === FCLOceanFreightCharge)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOceanFreightCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOceanFreightCharge)?.currencyId)
                || oceanFreightCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OceanFreightChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOceanFreightCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OceanFreightChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOceanFreightCharge)?.currencyId));*/
        }

        if (activeTab === LclTabName) {
            setOceanFreightMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData.find(data => data.serviceType === LclTabName && data?.chargeType === LCLOceanFreightCharge)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOceanFreightCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOceanFreightCharge)?.currencyId)
                || oceanFreightCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OceanFreightChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOceanFreightCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OceanFreightChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOceanFreightCharge)?.currencyId));*/
        }

        if (activeTab === InternationTabName) {
            setOceanFreightMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOceanFreightCharge)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOceanFreightCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOceanFreightCharge)?.currencyId)
                || oceanFreightCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OceanFreightChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOceanFreightCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OceanFreightChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOceanFreightCharge)?.currencyId));*/
        }

        if (activeTab === DomesticsTabName) {
            setOceanFreightMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOceanFreightCharge)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOceanFreightCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOceanFreightCharge)?.currencyId)
                || oceanFreightCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OceanFreightChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOceanFreightCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OceanFreightChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOceanFreightCharge)?.currencyId));*/
        }
    }

    const oceanSurchargesDataRender = () => {
        //For FCL
        if (activeTab === FclTabName) {
            setOceanSurchargesMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOceanSurcharges)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOceanSurchargesCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOceanSurcharges)?.currencyId)
                || oceanSurchargesCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OceanSurchargesMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOceanSurcharges)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OceanSurchargesCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOceanSurcharges)?.currencyId));*/
        }

        if (activeTab === LclTabName) {
            setOceanSurchargesMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOceanSurcharges)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOceanSurchargesCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOceanSurcharges)?.currencyId)
                || oceanSurchargesCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OceanSurchargesMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOceanSurcharges)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OceanSurchargesCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOceanSurcharges)?.currencyId));*/
        }

        if (activeTab === InternationTabName) {
            setOceanSurchargesMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOceanSurcharges)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOceanSurchargesCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOceanSurcharges)?.currencyId)
                || oceanSurchargesCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OceanSurchargesMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOceanSurcharges)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OceanSurchargesCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOceanSurcharges)?.currencyId));*/
        }

        if (activeTab === DomesticsTabName) {
            setOceanSurchargesMarginType(
                optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMECTICSOceanSurcharges)?.marginType)
                || { value:"", label:"Margin Type" }
            )

            /*setOceanSurchargesCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMECTICSOceanSurcharges)?.currencyId)
                || oceanSurchargesCurrency
            )*/

            useFormik.setFieldValue(`${prefix}OceanSurchargesMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMECTICSOceanSurcharges)?.marginType));

            /*useFormik.setFieldValue(`${prefix}OceanSurchargesCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMECTICSOceanSurcharges)?.currencyId));*/
        }
    }

    const destLocalPortChargesDataRender = () => {
        //For Fcl
        if (activeTab === FclTabName) {
            setDestLocalPortMarginType(optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLDestLocalPortCharge)?.marginType)
                || { value:"", label:"Margin Type" });

            /*setDestLocalPortCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLDestLocalPortCharge)?.currencyId)
                || destLocalPortCurrency
            )*/

            useFormik.setFieldValue(`${prefix}DestLocalPortChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLDestLocalPortCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}DestLocalPortChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLDestLocalPortCharge)?.currencyId));*/
        }

        if (activeTab === LclTabName) {
            setDestLocalPortMarginType(optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLDestLocalPortCharge)?.marginType)
                || { value:"", label:"Margin Type" });

            /*setDestLocalPortCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLDestLocalPortCharge)?.currencyId)
                || destLocalPortCurrency
            )*/

            useFormik.setFieldValue(`${prefix}DestLocalPortChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLDestLocalPortCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}DestLocalPortChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLDestLocalPortCharge)?.currencyId));*/
        }

        if (activeTab === InternationTabName) {
            setDestLocalPortMarginType(optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALDestLocalPortCharge)?.marginType)
                || { value:"", label:"Margin Type" });

            /*setDestLocalPortCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALDestLocalPortCharge)?.currencyId)
                || destLocalPortCurrency
            )*/

            useFormik.setFieldValue(`${prefix}DestLocalPortChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALDestLocalPortCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}DestLocalPortChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALDestLocalPortCharge)?.currencyId));*/
        }

        if (activeTab === DomesticsTabName) {
            setDestLocalPortMarginType(optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSDestLocalPortCharge)?.marginType)
                || { value:"", label:"Margin Type" });

            /*setDestLocalPortCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSDestLocalPortCharge)?.currencyId)
                || destLocalPortCurrency
            )*/

            useFormik.setFieldValue(`${prefix}DestLocalPortChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSDestLocalPortCharge)?.marginType));

            /*useFormik.setFieldValue(`${prefix}DestLocalPortChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSDestLocalPortCharge)?.currencyId));*/
        }
    }

    const destInlandChargesDataRender = () => {
        if (activeTab === FclTabName) {
            setDestInlandMarginType(optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLDestInlandCharge)?.marginType)
                || { value:"", label:"Margin Type" });

            setDestInlandCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLDestInlandCharge)?.currencyId)
                || destInlandCurrency
            )

            useFormik.setFieldValue(`${prefix}DestInlandChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLDestInlandCharge)?.marginType));

            useFormik.setFieldValue(`${prefix}DestInlandChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLDestInlandCharge)?.currencyId));
        }

        if (activeTab === LclTabName) {
            setDestInlandMarginType(optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLDestInlandCharge)?.marginType)
                || { value:"", label:"Margin Type" });

            setDestInlandCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLDestInlandCharge)?.currencyId)
                || destInlandCurrency
            )

            useFormik.setFieldValue(`${prefix}DestInlandChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLDestInlandCharge)?.marginType));

            useFormik.setFieldValue(`${prefix}DestInlandChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLDestInlandCharge)?.currencyId));
        }

        if (activeTab === InternationTabName) {
            setDestInlandMarginType(optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALDestInlandCharge)?.marginType)
                || { value:"", label:"Margin Type" });

            setDestInlandCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALDestInlandCharge)?.currencyId)
                || destInlandCurrency
            )

            useFormik.setFieldValue(`${prefix}DestInlandChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALDestInlandCharge)?.marginType));

            useFormik.setFieldValue(`${prefix}DestInlandChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALDestInlandCharge)?.currencyId));
        }

        if (activeTab === DomesticsTabName) {
            setDestInlandMarginType(optionMarkupType.find((obj) =>
                    obj.value === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSDestInlandCharge)?.marginType)
                || { value:"", label:"Margin Type" });

            setDestInlandCurrency(
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSDestInlandCharge)?.currencyId)
                || destInlandCurrency
            )

            useFormik.setFieldValue(`${prefix}DestInlandChargeMarginType`, optionMarkupType.find((obj) =>
                obj.value === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSDestInlandCharge)?.marginType));

            useFormik.setFieldValue(`${prefix}DestInlandChargeCurrency`,
                currency_data.find((obj) => obj.id === marketPlaceData?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSDestInlandCharge)?.currencyId));
        }
    }

    return (
        <>
            <div className="my-4">
                <div className="card p-4 shadow-sm">
                    <form>
                        <div className="row mb-3">
                            <label className="col-md-3 col-form-label">Inland Charges</label>
                            <div className="col-md-3">
                                <Select
                                    value={originInlandMarginType}
                                    name={`${prefix}InlandChargeMarginType`}
                                    placeholder="Margin Type"
                                    onChange={(e) => {
                                        //fclFreightRateFormik.setFieldValue(`freight_rate[${index}].margin_type`, e);
                                        console.log(`${prefix}InlandChargeMarginType`, e, "Check Domestics Inland Charges");
                                        setOriginInlandMarginType(e);
                                        useFormik.setFieldValue(`${prefix}InlandChargeMarginType`, e)
                                    }}
                                    options={optionMarkupType || []}
                                    classNamePrefix="select2-selection form-select"
                                />
                            </div>
                            {/*{originInlandMarginType.value === 'FLAT' &&
                                <div className="col-md-3">
                                <Select
                                    name={`${prefix}InlandChargeCurrency`}
                                    value={originInlandCurrency}
                                    placeholder="Select Curreny"
                                    onChange={(e) => {
                                        console.log(e);
                                        setOriginInlandCurrency(e);
                                        useFormik.setFieldValue(`${prefix}InlandChargeCurrency`, e)
                                    }}
                                    options={currency_data}
                                    classNamePrefix="select2-selection form-select"
                                />
                            </div> }*/}

                            <div className="col-md-3">
                                <input className="form-control"
                                       type="number"
                                       placeholder="0"
                                       value={useFormik.values[`${prefix}InlandCharge`]}
                                       onChange={useFormik.handleChange}
                                       name={`${prefix}InlandCharge`} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="col-md-3 col-form-label">Origin Local & Port Charge</label>
                            <div className="col-md-3">
                                <Select
                                    value={originLocalPortMarginType}
                                    name={`${prefix}OriginLocalPortChargeMarginType`}
                                    placeholder="Margin Type"
                                    onChange={(e) => {
                                        //fclFreightRateFormik.setFieldValue(`freight_rate[${index}].margin_type`, e);
                                        setOriginLocalPortMarginType(e);
                                        useFormik.setFieldValue(`${prefix}OriginLocalPortChargeMarginType`, e);
                                    }}
                                    options={optionMarkupType || []}
                                    classNamePrefix="select2-selection form-select"
                                />
                            </div>

                            {/*{originLocalPortMarginType.value === 'FLAT' &&
                                <div className="col-md-3">
                                <Select
                                    name={`${prefix}OriginLocalPortChargeCurrency`}
                                    value={originLocalPortCurrency}
                                    placeholder="Select Curreny"
                                    onChange={(e) => {
                                        //fclFreightRateFormik.setFieldValue(`freight_rate[${index}].carrier`, e);
                                        setOriginLocalPortCurrency(e);
                                        useFormik.setFieldValue(`${prefix}OriginLocalPortChargeCurrency`, e);
                                    }}
                                    options={currency_data}
                                    classNamePrefix="select2-selection form-select"
                                />
                            </div>
                            }*/}

                            <div className="col-md-3">
                                <input className="form-control"
                                       type="number"
                                       placeholder="0"
                                       value={useFormik.values[`${prefix}OriginLocalPortCharge`]}
                                       onChange={useFormik.handleChange}
                                       name={`${prefix}OriginLocalPortCharge`}/>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="col-md-3 col-form-label">{prefix === InternationTabName.toLocaleLowerCase() || prefix === DomesticsTabName.toLocaleLowerCase() ? 'Airline' : 'Ocean'} Freight Charge</label>
                            <div className="col-md-3">
                                <Select
                                    value={oceanFreightMarginType}
                                    name={`${prefix}OceanFreightChargeMarginType`}
                                    placeholder="Margin Type"
                                    onChange={(e) => {
                                        //fclFreightRateFormik.setFieldValue(`freight_rate[${index}].margin_type`, e);
                                        setOceanFreightMarginType(e);
                                        useFormik.setFieldValue(`${prefix}OceanFreightChargeMarginType`, e);
                                    }}
                                    options={optionMarkupType || []}
                                    classNamePrefix="select2-selection form-select"
                                />
                            </div>
                            {/*{oceanFreightMarginType.value === 'FLAT' &&
                                <div className="col-md-3">
                                    <Select
                                        name={`${prefix}OceanFreightChargeCurrency`}
                                        value={oceanFreightCurrency}
                                        placeholder="Select Curreny"
                                        onChange={(e) => {
                                            //fclFreightRateFormik.setFieldValue(`freight_rate[${index}].carrier`, e);
                                            setOceanFreightCurrency(e);
                                            useFormik.setFieldValue(`${prefix}OceanFreightChargeCurrency`, e);
                                        }}
                                        options={currency_data}
                                        classNamePrefix="select2-selection form-select"
                                    />
                                </div>
                            }*/}

                            <div className="col-md-3">
                                <input className="form-control"
                                       type="number"
                                       placeholder="0"
                                       value={useFormik.values[`${prefix}OceanFreightCharge`]}
                                       onChange={useFormik.handleChange}
                                       name={`${prefix}OceanFreightCharge`}/>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="col-md-3 col-form-label">{prefix === InternationTabName.toLocaleLowerCase() || prefix === DomesticsTabName.toLocaleLowerCase() ? 'Airline' : 'Ocean'} Surcharges</label>
                            <div className="col-md-3">
                                <Select
                                    value={oceanSurchargesMarginType}
                                    name={`${prefix}OceanSurchargesMarginType`}
                                    placeholder="Margin Type"
                                    onChange={(e) => {
                                        //fclFreightRateFormik.setFieldValue(`freight_rate[${index}].margin_type`, e);
                                        setOceanSurchargesMarginType(e);
                                        useFormik.setFieldValue(`${prefix}OceanSurchargesMarginType`, e);
                                    }}
                                    options={optionMarkupType || []}
                                    classNamePrefix="select2-selection form-select"
                                />
                            </div>
                            {/*{oceanSurchargesMarginType.value === 'FLAT' &&
                                <div className="col-md-3">
                                    <Select
                                        name={`${prefix}OceanSurchargesCurrency`}
                                        value={oceanSurchargesCurrency}
                                        placeholder="Select Curreny"
                                        onChange={(e) => {
                                            //fclFreightRateFormik.setFieldValue(`freight_rate[${index}].carrier`, e);
                                            setOceanSurchargesCurrency(e);
                                            useFormik.setFieldValue(`${prefix}OceanSurchargesCurrency`, e);
                                        }}
                                        options={currency_data}
                                        classNamePrefix="select2-selection form-select"
                                    />
                                </div>
                            }*/}

                            <div className="col-md-3">
                                <input className="form-control"
                                       type="number"
                                       placeholder="0"
                                       value={useFormik.values[`${prefix}OceanSurcharges`]}
                                       onChange={useFormik.handleChange}
                                       name={`${prefix}OceanSurcharges`}/>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="col-md-3 col-form-label">Dest. Local & Port Charge</label>
                            <div className="col-md-3">
                                <Select
                                    value={destLocalPortMarginType}
                                    name={`${prefix}DestLocalPortChargeMarginType`}
                                    placeholder="Margin Type"
                                    onChange={(e) => {
                                        //fclFreightRateFormik.setFieldValue(`freight_rate[${index}].margin_type`, e);
                                        setDestLocalPortMarginType(e);
                                        useFormik.setFieldValue(`${prefix}DestLocalPortChargeMarginType`, e);
                                    }}
                                    options={optionMarkupType || []}
                                    classNamePrefix="select2-selection form-select"
                                />
                            </div>
                            {/*{destLocalPortMarginType.value === 'FLAT' &&
                                <div className="col-md-3">
                                    <Select
                                        name={`${prefix}DestLocalPortChargeCurrency`}
                                        value={destLocalPortCurrency}
                                        placeholder="Select Curreny"
                                        onChange={(e) => {
                                            //fclFreightRateFormik.setFieldValue(`freight_rate[${index}].carrier`, e);
                                            setDestLocalPortCurrency(e);
                                            useFormik.setFieldValue(`${prefix}DestLocalPortChargeCurrency`, e);
                                        }}
                                        options={currency_data}
                                        classNamePrefix="select2-selection form-select"
                                    />
                                </div>
                            }*/}

                            <div className="col-md-3">
                                <input className="form-control"
                                       type="number"
                                       placeholder="0"
                                       value={useFormik.values[`${prefix}DestLocalPortCharge`]}
                                       onChange={useFormik.handleChange}
                                       name={`${prefix}DestLocalPortCharge`}/>
                            </div>
                        </div>

                        {/*<div className="row mb-3">*/}
                        {/*    <label className="col-md-3 col-form-label">Dest Inland Charge</label>*/}
                        {/*    <div className="col-md-3">*/}
                        {/*        <Select*/}
                        {/*            value={destInlandMarginType}*/}
                        {/*            name={`${prefix}DestInlandChargeMarginType`}*/}
                        {/*            placeholder="Margin Type"*/}
                        {/*            onChange={(e) => {*/}
                        {/*                //fclFreightRateFormik.setFieldValue(`freight_rate[${index}].margin_type`, e);*/}
                        {/*                setDestInlandMarginType(e);*/}
                        {/*                useFormik.setFieldValue(`${prefix}DestInlandChargeMarginType`, e);*/}
                        {/*            }}*/}
                        {/*            options={optionMarkupType || []}*/}
                        {/*            classNamePrefix="select2-selection form-select"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*    {*/}
                        {/*        destInlandMarginType.value === 'FLAT' &&*/}
                        {/*        <div className="col-md-3">*/}
                        {/*            <Select*/}
                        {/*                name={`${prefix}DestInlandChargeCurrency`}*/}
                        {/*                value={destInlandCurrency}*/}
                        {/*                placeholder="Select Curreny"*/}
                        {/*                onChange={(e) => {*/}
                        {/*                    //fclFreightRateFormik.setFieldValue(`freight_rate[${index}].carrier`, e);*/}
                        {/*                    setDestInlandCurrency(e);*/}
                        {/*                    useFormik.setFieldValue(`${prefix}DestInlandChargeCurrency`, e);*/}
                        {/*                }}*/}
                        {/*                options={currency_data}*/}
                        {/*                classNamePrefix="select2-selection form-select"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    }*/}

                        {/*    <div className="col-md-3">*/}
                        {/*        <input className="form-control"*/}
                        {/*               type="number"*/}
                        {/*               placeholder="0"*/}
                        {/*               value={useFormik.values[`${prefix}DestInlandCharge`]}*/}
                        {/*               onChange={useFormik.handleChange}*/}
                        {/*               name={`${prefix}DestInlandCharge`}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </form>
                </div>
            </div>
        </>
    );
}

export default QuotationMarginComponents;