import React, {useEffect, useMemo, useState} from "react";
import {Container, FormGroup, Input} from "reactstrap";
import TfBreadcrumbs from "../../components/Common/TfBreadcrumbs";
import {SubscriberBreadcrumb, SubscriptionBreadcrumb} from "../../common/data/parties";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useAsyncDebounce} from "react-table";
import {GET_SUBSCRIPTION_DATA} from "../../store/Subscriptions/actiontype";
import {CommonValue} from "../Parties/PartiesCol";
import {Edit} from "../Settings/SettingsCol";
import SubscribersTable from "./components/SubscribersTable";
import {getSubscriptionsSummary} from "../../store/Subscriptions/actions";
import {getSubscriberSummaryList} from "../../store/Subscribers/actions";

const Subscribers = () => {
    const [value, setValue] = useState('');
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [previewModal, setPreviewModal] = useState(false);
    const { subscribersGroupSummary } = useSelector((state) => state.subscriberReducer);
    const dispatch = useDispatch();

    const onChange = useAsyncDebounce(value => {
        //console.log(value);
    }, 200);

    useEffect(() => {
        if (currentPage !== '' && currentPage !== undefined) {
            let url = `?page=${currentPage}&size=10`;
            dispatch(getSubscriberSummaryList(url));
        }
    }, [dispatch, currentPage])

    const viewPopupHandler = (data) => {
        //console.log("viewPopupHandler", data);
        navigate(`/network/subscriber/details`, {
            state: {
                id: data.id || '',
                data: data
            },
        });
    }

    const subscribersColumns = useMemo(
        () => [
            {
                Header: "Subscription Code",
                accessor: "code",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Subscription Name",
                accessor: "name",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Total Subscriber",
                accessor: "totalRecords",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Active",
                accessor: "totalActiveRecords",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "New Subscribers",
                accessor: "totalNewRecords",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "InActive",
                accessor: "totalInactiveRecords",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            }
        ], []);

    return(
        <>
            <div className="page-content settings_users_wrapper">
                <Container fluid>
                    <div className="main_freight_wrapper">
                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TfBreadcrumbs breadcrumb={SubscriberBreadcrumb}/>
                            <div className="tf_box_wrap d-flex">
                                <div className="sh_box flex-grow-1">
                                    <p className="box_title">Active Subscriber</p>
                                    <div className="sh_inquiry_rate">{subscribersGroupSummary ? subscribersGroupSummary.totalActiveRecords : ""}
                                        <span className='green_text'>0%</span>
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1">
                                    <p className="box_title">New Subscriber</p>
                                    <div className="sh_inquiry_rate">{subscribersGroupSummary ? subscribersGroupSummary.totalNewRecords : ""}
                                        <span className='green_text'>0%</span>
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1">
                                    <p className="box_title">InActive Subscriber</p>
                                    <div className="sh_inquiry_rate">{subscribersGroupSummary ? subscribersGroupSummary.totalInactiveRecords : ""}
                                        <span className='green_text'>0%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="freight_filter_wrap d-flex align-items-center">
                            <p className="label flex-grow-1 m-0">Filters :</p>
                            <div className="right_actions_wrap flex-shrink-0 d-flex align-items-center">
                                <div className="search_form">
                                    <form>
                                        <div className="position-relative">
                                            <input
                                                type="search"
                                                onChange={e => {
                                                    e.preventDefault();
                                                    setValue(e.target.value);
                                                    onChange(e.target.value);
                                                    return false;
                                                }}
                                                className="form-control"
                                                placeholder="Search"
                                                value={value || ""}
                                            />
                                            <button className="btn" type="button">
                                                <i className="bx bx-search-alt-2 align-middle"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="add_btn">
                                    <button className='border-0' onClick={() => {
                                        navigate(`/network/subscriber/add`);
                                    }}>
                                        <i className='bx bx-plus align-middle'></i>Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        <SubscribersTable
                            columns={subscribersColumns}
                            data={subscribersGroupSummary.content || []}
                            customPageSize={10}
                            component={"Subscriptions"}
                            loader={false}
                            setCurrentPage={setCurrentPage}
                            totalPages={subscribersGroupSummary.totalPages || 0}
                            totalEntries={subscribersGroupSummary.totalElements || 0}
                        />
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Subscribers;