import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Container
} from "reactstrap";
import { getRuleHistory } from "../../store/Marketing/actions";
import { CommonValue } from "../Parties/PartiesCol";
import LeadGenarationListingTable from "./LeadGenarationListingTable";

const RuleHistory = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const { rule_history_data, tenant_rule_data_loder } = useSelector((state) => state?.Marketing);
    const navigateState = useLocation();
    const navigate = useNavigate();


    const viewPopupHandler = (data) => {

    };
    useEffect(() => {
        let url = `?ruleId=${navigateState?.state?.data?.id}`;
        dispatch(getRuleHistory(url))
    }, []);


    const columns = useMemo(
        () => [
            {
                Header: "Broad Cast Date",
                accessor: "updatedOn",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Total Recipients",
                accessor: "totalRecipientCount",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },

            {
                Header: "Total Delivered",
                accessor: "develered43",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Total Read",
                accessor: "develered3",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Total Bounced",
                accessor: "develered2",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Download Report",
                accessor: "develered1",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            }
        ],
        []
    );

    return (
        <>
            <div className="page-content settings_users_wrapper">
                {/* <TopBreadcrumbs breadcrumbs={LeadGenarationBreadcrumb} /> */}
                <Container fluid>
                    <div className="main_freight_wrapper">
                        <button
                            type="button"
                            className="btn border mb-3"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Back
                        </button>
                    </div>
                    <div className="main_freight_wrapper">
                        <LeadGenarationListingTable
                            columns={columns}
                            data={rule_history_data || []}
                            isGlobalFilter={true}
                            isAddInvoiceList={true}
                            customPageSize={10}
                            component={"history"}
                            loader={tenant_rule_data_loder || false}
                            setCurrentPage={setCurrentPage}
                            totalPages={rule_history_data?.length / 10 || 0}
                            totalEntries={rule_history_data?.length || 0}
                        />
                    </div>
                </Container>
            </div>
        </>
    );
};

export default RuleHistory;
