import React, {useEffect, useMemo, useState} from 'react';
import {Container} from "reactstrap";
import {useAsyncDebounce} from "react-table";
import {CommonValue} from "../Parties/PartiesCol";
import {Edit} from "../Settings/SettingsCol";
import SubscriptionsTable from "./components/SubscriptionsTable";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getSubscriptionsSummary} from "../../store/Subscriptions/actions";
import TfBreadcrumbs from "../../components/Common/TfBreadcrumbs";
import {SubscriberBreadcrumb, SubscriptionBreadcrumb} from "../../common/data/parties";


const Subscriptions = () => {
    const [value, setValue] = useState('');
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [previewModal, setPreviewModal] = useState(false);
    const { subscriptions_data } = useSelector((state) => state.subscriptionsReducer);
    const dispatch = useDispatch();

    const onChange = useAsyncDebounce(value => {
        //console.log(value);
    }, 200);

    useEffect(() => {
        if (currentPage !== '' && currentPage !== undefined) {
            let url = `?page=${currentPage}&size=10`;
            dispatch(getSubscriptionsSummary(url));
        }
    }, [dispatch, currentPage])

    const viewPopupHandler = (data) => {
    }

    const editHandler = (data) => {
        //console.log(data);
        navigate(`/network/edit/subscriptions`, {
            state: {
                id: data.id || '',
                data: data
            },
        });
    }
    const subscriptionsColumns = useMemo(
        () => [
            {
                Header: "Subscription Code",
                accessor: "subscriptionCode",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Subscription Name",
                accessor: "subscriptionName",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "No. Of Modules",
                accessor: "noOfModules",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Payment",
                accessor: "payment",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Subscriptions Amount",
                accessor: "subscriptionAmount",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Action",
                accessor: "action",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex justify-content-start align-items-start h-100">
                            <p style={{marginTop: "15px"}}>
                                <Edit cellProps={cellProps} viewPopupHandler={editHandler} />
                            </p>
                        </div>
                    );
                },
            }
        ], []);

    const previewModalHand = () => {
        setPreviewModal(!previewModal);
    }

    return (
        <>
            <div className="page-content settings_users_wrapper">
                <Container fluid>
                    <div className="main_freight_wrapper">
                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TfBreadcrumbs breadcrumb={SubscriptionBreadcrumb}/>
                        </div>
                        <div className="freight_filter_wrap d-flex align-items-center">
                            <p className="label flex-grow-1 m-0">Filters :</p>
                            <div className="right_actions_wrap flex-shrink-0 d-flex align-items-center">
                                <div className="search_form">
                                    <form>
                                        <div className="position-relative">
                                            <input
                                                type="search"
                                                onChange={e => {
                                                    e.preventDefault();
                                                    setValue(e.target.value);
                                                    onChange(e.target.value);
                                                    return false;
                                                }}
                                                className="form-control"
                                                placeholder="Search"
                                                value={value || ""}
                                            />
                                            <button className="btn" type="button">
                                                <i className="bx bx-search-alt-2 align-middle"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="add_btn">
                                    <button className='border-0' onClick={() => {
                                        navigate(`/network/add/subscriptions`);
                                    }}>
                                        <i className='bx bx-plus align-middle'></i> Add Subscriptions
                                    </button>
                                </div>
                            </div>
                        </div>
                        <SubscriptionsTable
                            columns={subscriptionsColumns}
                            data={subscriptions_data?.content || []}
                            customPageSize={10}
                            component={"Subscriptions"}
                            loader={false}
                            setCurrentPage={setCurrentPage}
                            totalPages={subscriptions_data?.totalPages || 0}
                            totalEntries={subscriptions_data?.totalElements || 0}
                        />
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Subscriptions;