import React from "react";
import { Navigate } from "react-router-dom";

const NetworkAuthMiddleWare = (props) => {
    //const authType = localStorage.getItem("isNetworkLogin");
    if (!localStorage.getItem("authUser")) {
        return (
            <Navigate to={{ pathname: "/network/login", state: { from: props.location } }} />
        );
    }
    return (<React.Fragment>
        {props.children}
    </React.Fragment>);
};

export default NetworkAuthMiddleWare;
