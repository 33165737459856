export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR"


export const FORGET_PASSWORD_EMAIL_VERIFY = "FORGET_PASSWORD_EMAIL_VERIFY"
export const FORGET_PASSWORD_EMAIL_VERIFY_SUCCESS = "FORGET_PASSWORD_EMAIL_VERIFY_SUCCESS"

export const FORGET_PASSWORD_OTP_VERIFY = "FORGET_PASSWORD_OTP_VERIFY"
export const FORGET_PASSWORD_OTP_VERIFY_SUCCESS = "FORGET_PASSWORD_OTP_VERIFY_SUCCESS"

export const FORGET_PASSWORD_PASSWORD_CHANGE = "FORGET_PASSWORD_PASSWORD_CHANGE"
export const FORGET_PASSWORD_PASSWORD_CHANGE_SUCCESS = "FORGET_PASSWORD_PASSWORD_CHANGE_SUCCESS"


