import React, {useCallback, useEffect, useRef, useState} from "react";
import { Alert, Col, Container, Form, FormFeedback, Input, Label, Row, } from "reactstrap";
import {Link} from "react-router-dom";
// import images
import logo from "../../assets/images/logo-sm.png";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import { industryType} from "../../common/data/settings";
import Select from "react-select";
import useRegistration from "./hook/useRegistration";
import {useSelector} from "react-redux";
import Account from "../../store/auth/register/reducer";
import {useFormik} from "formik";
import {ToastWrapper} from "../../components/Common/CustomToast";

const RegisterNew = (props) => {
    //meta title
    document.title="Register || Navigating Freight Costs with Precision||Ultimate Rate Management platform"
    const [step, setStep] = useState(1);
    const [confirmEmail, setConfirmEmail] = useState("");
    const otpInputs = useRef([]);
    const [passwordShow, setPasswordShow] = useState(false)
    const { emailVerified, otpVerified, onBoarding } = useSelector((state) => state?.Account);

    const handleEmailChange = (e) => {
        setConfirmEmail(e.target.value);
        sendEmailFormik.setFieldValue("email", e.currentTarget.value);
    }

    // const handleOtpChange = (index, value) => {
    //     if (value.length > 1) return;
    //     let newOtp = [...otp];
    //     newOtp[index] = value;
    //     setOtp(newOtp);
    //
    //     if (value && index < 5) {
    //         otpInputs.current[index + 1].focus();
    //     }
    // };

    useEffect(() => {
        if (emailVerified === true && step === 1) {
            otpFormik.setFieldValue("email", confirmEmail);
            setStep(2);
        }
    }, [emailVerified]);

    useEffect(() => {
        if (otpVerified === true && step === 2) {
            setStep(3);
            createAccountFormik.setFieldValue("password", generatePassword());
            createAccountFormik.setFieldValue("email", confirmEmail);
        }
    }, [otpVerified]);

    useEffect(() => {
        if (onBoarding === true && step === 3) {
            console.log("onBoarding done");
        }
    }, [onBoarding]);

    const { handleSendEmail, emailValidationSchema, sendEmailInitialValues, country_master_isd_icd,
        handleOtpVerified, otpValidationSchema, generatePassword, createAccountInitialValues,
        createAccountHandleSubmit, createAccountValidationSchema } = useRegistration();

    const sendEmailFormik = useFormik({
        enableReinitialize: true,
        initialValues: sendEmailInitialValues,
        validationSchema: emailValidationSchema,
        onSubmit: handleSendEmail,
    });

    const otpFormik = useFormik({
        initialValues: {
            otp: Array(6).fill(""),
        },
        validationSchema: otpValidationSchema,
        onSubmit: handleOtpVerified,
    })

    const createAccountFormik = useFormik({
        enableReinitialize: true,
        initialValues: createAccountInitialValues,
        validationSchema: createAccountValidationSchema,
        onSubmit: createAccountHandleSubmit
    })

    const handleOtpChange = (index, value) => {
        if (!/^[0-9]?$/.test(value)) return;
        if (value.length > 1) return; // Allow only one character
        const otpArray = [...otpFormik.values.otp];
        otpArray[index] = value;
        otpFormik.setFieldValue("otp", otpArray);
        if (value && index < 5) {
            otpInputs.current[index + 1].focus();
        }
    };

  return (
    <React.Fragment>
        <div className="auth-page login_auth_wrap">
            <Container fluid className="p-0">
                <Row className="g-0">
                    <Col lg={4} md={5} className="col-xxl-3">
                        <div className="auth-full-page-content d-flex">
                            <div className="w-100">
                                <div className="d-flex flex-column h-100">
                                    <div className="mb-4 mb-md-1 text-center">
                                        <Link to="/dashboard" className="d-block auth-logo">
                                            <img src={logo} alt="" height="64" />
                                        </Link>
                                    </div>
                                    <div className="auth-content my-auto">
                                        <div className="text-center">
                                            <h5 className="mb-0">Create your account 👋</h5>
                                            <p className="text-muted mt-2 sub_text">
                                                Please enter your details to create an account.
                                            </p>
                                        </div>

                                        {step === 1 && (
                                            <div className="mt-4">
                                                <div className="mb-3">
                                                    <Label className="form-label">Email address</Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email address"
                                                        type="email"
                                                        value={sendEmailFormik.values.email}
                                                        onChange={(e) => handleEmailChange(e)}
                                                        onBlur={sendEmailFormik.handleBlur}
                                                        invalid={sendEmailFormik.touched.email && sendEmailFormik.errors.email ? true : false}
                                                    />
                                                    {sendEmailFormik.touched.email && sendEmailFormik.errors.email ? (
                                                        <FormFeedback> {sendEmailFormik.errors.email} </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <button className="btn btn-primary w-100" onClick={sendEmailFormik.handleSubmit}>
                                                    Send OTP
                                                </button>
                                            </div>
                                        )}

                                        {step === 2 && (
                                            <div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Enter OTP</Label>
                                                    <div className="d-flex justify-content-between">
                                                        {otpFormik.values.otp.map((digit, index) => (
                                                            <input
                                                                key={index}
                                                                type="text"
                                                                className="form-control text-center mx-1"
                                                                style={{ width: "40px" }}
                                                                maxLength="1"
                                                                value={digit}
                                                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                                                ref={(el) => (otpInputs.current[index] = el)}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary w-100 mt-3" onClick={otpFormik.handleSubmit}>
                                                    Verify OTP
                                                </button>
                                            </div>
                                        )}

                                        {step === 3 && (
                                            <div>
                                                <div className="row">
                                                    <div className="col-12 mb-2">
                                                        <label className="form-label">Company Name<span
                                                            className='required_star'>*</span></label>
                                                        <Input
                                                            type="text"
                                                            name="companyName"
                                                            value={createAccountFormik.values.companyName}
                                                            onChange={createAccountFormik.handleChange}
                                                            onBlur={createAccountFormik.handleBlur}
                                                            className="form-control"
                                                            placeholder=""
                                                        />
                                                        {createAccountFormik.touched.companyName && createAccountFormik.errors.companyName ? (
                                                            <FormFeedback> {createAccountFormik.errors.companyName} </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 mb-2">
                                                        <label className="form-label">First Name<span
                                                            className='required_star'>*</span></label>
                                                        <Input
                                                            type="text"
                                                            name="firstName"
                                                            value={createAccountFormik.values.firstName}
                                                            onChange={createAccountFormik.handleChange}
                                                            onBlur={createAccountFormik.handleBlur}
                                                            className="form-control"
                                                            placeholder=""
                                                        />
                                                        {createAccountFormik.touched.firstName && createAccountFormik.errors.firstName ? (
                                                            <FormFeedback> {createAccountFormik.errors.firstName} </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 mb-2">
                                                        <label className="form-label">Last Name<span
                                                            className='required_star'>*</span></label>
                                                        <Input
                                                            type="text"
                                                            name="lastName"
                                                            value={createAccountFormik.values.lastName}
                                                            onChange={createAccountFormik.handleChange}
                                                            onBlur={createAccountFormik.handleBlur}
                                                            className="form-control"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-2">
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex-grow-1">
                                                                <Label className="form-label">Password</Label>
                                                            </div>
                                                        </div>
                                                        <div id="enterPasswordArea" className="input-group auth-pass-inputgroup">
                                                            <Input
                                                                name="password"
                                                                value={createAccountFormik.values.password || ""}
                                                                type={passwordShow ? "text" : "password"}
                                                                placeholder="Enter password"
                                                                onChange={createAccountFormik.handleChange}
                                                                onBlur={createAccountFormik.handleBlur}
                                                                invalid={
                                                                    createAccountFormik.touched.password && createAccountFormik.errors.password ? true : false
                                                                }
                                                            />
                                                            <button onClick={() => setPasswordShow(!passwordShow)} className={`btn btn-light shadow-none ms-0 ${passwordShow ? 'show' : ''}`} type="button" id="onboarding-password-addon"></button>
                                                            {createAccountFormik.touched.password && createAccountFormik.errors.password ? (
                                                                <FormFeedback type="invalid">{createAccountFormik.errors.password}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 mb-2">
                                                        <label className="form-label">
                                                            Phone Number <span className="required_star">*</span>
                                                        </label>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-2" style={{ width: "100px" }}> {/* Adjust width as needed */}
                                                                <Select
                                                                    name="opCode"
                                                                    onChange={(e) => {
                                                                        createAccountFormik.setFieldValue("opCode", e.value);
                                                                    }}
                                                                    options={country_master_isd_icd}
                                                                    placeholder="+91"
                                                                    classNamePrefix="select2-selection form-select"
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <input
                                                                    type="text"
                                                                    name="phoneNumber"
                                                                    value={createAccountFormik.values.phoneNumber}
                                                                    onChange={createAccountFormik.handleChange}
                                                                    onBlur={createAccountFormik.handleBlur}
                                                                    className="form-control"
                                                                    placeholder=""
                                                                />
                                                                {createAccountFormik.touched.phoneNumber && createAccountFormik.errors.phoneNumber ? (
                                                                    <FormFeedback> {createAccountFormik.errors.phoneNumber} </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 mb-2">
                                                        <label className="form-label">Email id<span
                                                            className='required_star'>*</span></label>
                                                        <Input
                                                            type="text"
                                                            name="email"
                                                            value={confirmEmail}
                                                            className="form-control"
                                                            placeholder=""
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <label className="form-label">Company Type<span
                                                            className='required_star'>*</span></label>
                                                        <Select
                                                            value={industryType ? industryType.find((option) => option.value === "AGENT") : ""}
                                                            name="industryType"
                                                            onChange={(e) => {
                                                                //setUserData("industryType", e.value);
                                                                createAccountFormik.setFieldValue("industryType", e.value);
                                                            }}
                                                            options={industryType}
                                                            placeholder={"Enter Industry Type"}
                                                            classNamePrefix="select2-selection form-select"
                                                        />
                                                    </div>
                                                </div>
                                                <button className="btn btn-success w-100" onClick={createAccountFormik.handleSubmit}>Submit</button>
                                            </div>
                                        )}

                                        <div className="text-center login_account_text">
                                            <p className="text-muted mb-0">
                                                Already have an account ?
                                                <Link to="/login" className="text-primary fw-semibold" > Login </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <CarouselPage width={9} />
                </Row>
            </Container>
        </div>
        <ToastWrapper />
    </React.Fragment>
  );
};

export default RegisterNew;
