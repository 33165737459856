import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Form, Input } from "reactstrap";

const MonthlyRecurrenceModal = ({ modal, onCloseClick ,setSheduleData }) => {
    const [recurrenceData, setRecurrenceData] = useState({
        start: "",
        repeatEvery: 1,
        repeatType: "Month",
        dayType: "specific", // "specific" or "pattern"
        specificDay: 1, // For "specific day" selection
        weekIndex: "Second", // For "pattern" selection
        weekDay: "Sunday", // For "pattern" selection
        end: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRecurrenceData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Recurrence Data:", recurrenceData);
        setSheduleData(recurrenceData)

        onCloseClick(); // Close modal
    };

    return (
        <Modal isOpen={modal} toggle={onCloseClick}>
            <ModalHeader tag="h4">
                Set Recurrence
                <span className="close" onClick={onCloseClick}></span>
            </ModalHeader>
            <ModalBody>
                <Form
                    className="custom-form mt-2 p-1"
                    onSubmit={handleSubmit}
                >
                    {/* Start Date */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-label">Start</label>
                            <Input
                                type="date"
                                name="start"
                                value={recurrenceData.start}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {/* Repeat Every */}
                    <div className="row mb-4">
                        <div className="col-6">
                            <label className="form-label">Repeat every</label>
                            <Input
                                type="number"
                                name="repeatEvery"
                                value={recurrenceData.repeatEvery}
                                onChange={handleChange}
                                className="form-control"
                                min="1"
                            />
                        </div>
                        <div className="col-6">
                            <label className="form-label">Type</label>
                            <Input
                                type="select"
                                name="repeatType"
                                value={recurrenceData.repeatType}
                                onChange={handleChange}
                                className="form-control"
                            >
                                <option value="Day">Day</option>
                                <option value="Week">Week</option>
                                <option value="Month">Month</option>
                                <option value="Year">Year</option>
                            </Input>
                        </div>
                    </div>

                    {/* Recurrence Pattern */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-label">Repeat On</label>
                            <div>
                                <Input
                                    type="radio"
                                    name="dayType"
                                    value="specific"
                                    checked={recurrenceData.dayType === "specific"}
                                    onChange={handleChange}
                                    className="me-2"
                                />
                                <label className="me-3">On day</label>
                                <Input
                                    type="number"
                                    name="specificDay"
                                    value={recurrenceData.specificDay}
                                    onChange={handleChange}
                                    className="form-control d-inline-block w-25"
                                    min="1"
                                    max="31"
                                    disabled={recurrenceData.dayType !== "specific"}
                                />
                            </div>
                            <div className="mt-3">
                                <Input
                                    type="radio"
                                    name="dayType"
                                    value="pattern"
                                    checked={recurrenceData.dayType === "pattern"}
                                    onChange={handleChange}
                                    className="me-2"
                                />
                                <label className="me-3">On the</label>
                                <Input
                                    type="select"
                                    name="weekIndex"
                                    value={recurrenceData.weekIndex}
                                    onChange={handleChange}
                                    className="form-control d-inline-block w-25"
                                    disabled={recurrenceData.dayType !== "pattern"}
                                >
                                    <option value="First">First</option>
                                    <option value="Second">Second</option>
                                    <option value="Third">Third</option>
                                    <option value="Fourth">Fourth</option>
                                    <option value="Last">Last</option>
                                </Input>
                                <Input
                                    type="select"
                                    name="weekDay"
                                    value={recurrenceData.weekDay}
                                    onChange={handleChange}
                                    className="form-control d-inline-block w-25 ms-2"
                                    disabled={recurrenceData.dayType !== "pattern"}
                                >
                                    <option value="Sunday">Sunday</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                </Input>
                            </div>
                        </div>
                    </div>

                    {/* End Date */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-label">End</label>
                            <Input
                                type="date"
                                name="end"
                                value={recurrenceData.end}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {/* Actions */}
                    <div className="row">
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-primary mx-3" type="submit">
                                Save
                            </button>
                            <button
                                className="btn btn-secondary mx-3"
                                type="button"
                                onClick={() => {
                                    onCloseClick();
                                    setRecurrenceData({
                                        start: "",
                                        repeatEvery: 1,
                                        repeatType: "Month",
                                        dayType: "specific",
                                        specificDay: 1,
                                        weekIndex: "Second",
                                        weekDay: "Sunday",
                                        end: "",
                                    });
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default MonthlyRecurrenceModal;
