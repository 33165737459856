export const REGISTER_USER = "register_user"
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull"
export const REGISTER_USER_FAILED = "register_user_failed"


export const REGISTER_EMAIL_VERIFIED = "register_user_email_verified";
export const REGISTER_EMAIL_VERIFIED_SUCCESS = "REGISTER_EMAIL_VERIFIED_SUCCESS";

export const REGISTER_OTP_VERIFIED = "REGISTER_OTP_VERIFIED";
export const REGISTER_OTP_VERIFIED_SUCCESS = "REGISTER_OTP_VERIFIED_SUCCESS";


export const REGISTER_CREATE_SELF_NEW_ACCOUNT = "REGISTER_CREATE_SELF_NEW_ACCOUNT";
export const REGISTER_CREATE_SELF_NEW_ACCOUNT_SUCCESS = "REGISTER_CREATE_SELF_NEW_ACCOUNT_SUCCESS";
