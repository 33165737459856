import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    FormGroup,
    CardBody,
    Label,
    Button,
    FormFeedback,
    Form,
    Input,
    CardHeader
  } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import NormalValidation from "./NormalValidation";
import TooltipsValidation from "./TooltipsValidation";

const FormValidation = () => {

  //meta title
  document.title = "Form Validation | Minia - React Admin & Dashboard Template";

    // Form validation 
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
      password1: '',
      email: '',
      digits: '',
      number: '',
      alphanumeric: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required(
        "This value is required"
      ),
      password: Yup.string().required(
        "This value is required"
      ),
      password1: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
      email: Yup.string()
        .email("Must be a valid Email")
        .max(255)
        .required("Email is required"),
      url: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .required("Please enter correct Url"),
      digits: Yup.number().required(
        "Please Enter Your Digits"
      ),
      number: Yup.number().required(
        "Please Enter Your Number"
      ),
      alphanumeric: Yup.string()
        .matches(
          /^[a-z0-9]+$/i,
          "Enter correct Alphanumeric!"
        )
        .required("Please Enter Your Alphanumeric"),
      textarea: Yup.string().required(
        "Please Enter Your Textarea"
      ),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    }
  });

  // Form validation 
  const rangeValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      min_Length: '',
      max_Length: '',
      min_Value: '',
      max_Value: '',
      range_Value: '',
      regular_Exp: '',
    },
    validationSchema: Yup.object().shape({
      min_Length: Yup.string()
        .min(6, "Must be exactly 6 digits")
        .required("Min 6 chars"),
      max_Length: Yup.string()
        .max(6, "Must be exactly 6 digits")
        .required("Max 6 chars"),
      min_Value: Yup.string().required("Min Value 6").test('val', 'This value should be greater than or equal to 6', (val) => val >= 6),
      max_Value: Yup.string().required("Max Value 6").matches(/^[0-6]+$/, "This value should be lower than or equal to 6."),
      range_Value: Yup.string().required(
        "range between 5 to 10"
      ).min(5, "This value should be between 5 and 10")
        .max(10, "This value should be between 5 and 10"),
      regular_Exp: Yup.string()
        .matches(
          /^[#0-9]+$/,
          "Only Hex Value"
        )
        .required("Only Hex Value"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Forms" breadcrumbItem="Form Validation" />

          <Row>
            {/* import NormalValidation */}
            <NormalValidation />
            {/* import TooltipsValidation */}
            <TooltipsValidation />
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-2">Validation type</h4>
                  <p className="card-title-desc">
                    Parsley is a availity reactstrap validation. It helps you
                    provide your users with feedback on their form submission
                    before sending it to your server.
                  </p>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationType.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Required</Label>
                      <Input
                        name="username"
                        placeholder="Type Something"
                        type="text"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.username || ""}
                        invalid={
                          validationType.touched.username &&
                          validationType.errors.username
                            ? true
                            : false
                        }
                      />
                      {validationType.touched.username &&
                      validationType.errors.username ? (
                        <FormFeedback type="invalid">
                          {validationType.errors.username}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Equal To</Label>
                      <Input
                        name="password"
                        type="password"
                        placeholder="Password"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.password || ""}
                        invalid={
                          validationType.touched.password &&
                          validationType.errors.password
                            ? true
                            : false
                        }
                      />
                      {validationType.touched.password &&
                      validationType.errors.password ? (
                        <FormFeedback type="invalid">
                          {validationType.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Input
                        name="password1"
                        type="password"
                        placeholder="Re-type Password"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.password1 || ""}
                        invalid={
                          validationType.touched.password1 &&
                          validationType.errors.password1
                            ? true
                            : false
                        }
                      />
                      {validationType.touched.password1 &&
                      validationType.errors.password1 ? (
                        <FormFeedback type="invalid">
                          {validationType.errors.password1}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">E-Mail</Label>
                      <Input
                        name="email"
                        placeholder="Enter Valid Email"
                        type="email"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.email || ""}
                        invalid={
                          validationType.touched.email &&
                          validationType.errors.email
                            ? true
                            : false
                        }
                      />
                      {validationType.touched.email &&
                      validationType.errors.email ? (
                        <FormFeedback type="invalid">
                          {validationType.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Digits</Label>
                      <Input
                        name="digits"
                        label="Digits"
                        placeholder="Enter Only Digits"
                        type="number"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.digits || ""}
                        invalid={
                          validationType.touched.digits &&
                          validationType.errors.digits
                            ? true
                            : false
                        }
                      />
                      {validationType.touched.digits &&
                      validationType.errors.digits ? (
                        <FormFeedback type="invalid">
                          {validationType.errors.digits}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Number</Label>
                      <Input
                        name="number"
                        placeholder="Enter Only number"
                        type="number"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.number || ""}
                        invalid={
                          validationType.touched.number &&
                          validationType.errors.number
                            ? true
                            : false
                        }
                      />
                      {validationType.touched.number &&
                      validationType.errors.number ? (
                        <FormFeedback type="invalid">
                          {validationType.errors.number}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Alphanumeric</Label>
                      <Input
                        name="alphanumeric"
                        placeholder="Enter Only alphanumeric value"
                        type="text"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.alphanumeric || ""}
                        invalid={
                          validationType.touched.alphanumeric &&
                          validationType.errors.alphanumeric
                            ? true
                            : false
                        }
                      />
                      {validationType.touched.alphanumeric &&
                      validationType.errors.alphanumeric ? (
                        <FormFeedback type="invalid">
                          {validationType.errors.alphanumeric}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="">
                        Submit
                      </Button>{" "}
                      <Button type="reset" color="secondary" className="">
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-2">Range validation</h4>
                  <p className="card-title-desc">
                    Parsley is a availity reactstrap validation. It helps you
                    provide your users with feedback on their form submission
                    before sending it to your server.
                  </p>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      rangeValidation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label>Min Length</Label>
                      <Input
                        name="min_Length"
                        label="Min Length  "
                        placeholder="Min 6 chars"
                        type="number"
                        onChange={rangeValidation.handleChange}
                        onBlur={rangeValidation.handleBlur}
                        value={rangeValidation.values.min_Length || ""}
                        invalid={
                          rangeValidation.touched.min_Length &&
                          rangeValidation.errors.min_Length
                            ? true
                            : false
                        }
                      />
                      {rangeValidation.touched.min_Length &&
                      rangeValidation.errors.min_Length ? (
                        <FormFeedback type="invalid">
                          {rangeValidation.errors.min_Length}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Max Length</Label>
                      <Input
                        name="max_Length"
                        placeholder="Max 6 chars"
                        type="number"
                        onChange={rangeValidation.handleChange}
                        onBlur={rangeValidation.handleBlur}
                        value={rangeValidation.values.max_Length || ""}
                        invalid={
                          rangeValidation.touched.max_Length &&
                          rangeValidation.errors.max_Length
                            ? true
                            : false
                        }
                      />
                      {rangeValidation.touched.max_Length &&
                      rangeValidation.errors.max_Length ? (
                        <FormFeedback type="invalid">
                          {rangeValidation.errors.max_Length}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Min Value</Label>
                      <Input
                        name="min_Value"
                        placeholder="Min 6 Chars"
                        min={6}
                        type="number"
                        onChange={rangeValidation.handleChange}
                        onBlur={rangeValidation.handleBlur}
                        value={rangeValidation.values.min_Value || ""}
                        invalid={
                          rangeValidation.touched.min_Value &&
                          rangeValidation.errors.min_Value
                            ? true
                            : false
                        }
                      />
                      {rangeValidation.touched.min_Value &&
                      rangeValidation.errors.min_Value ? (
                        <FormFeedback type="invalid">
                          {rangeValidation.errors.min_Value}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Max Value</Label>
                      <Input
                        name="max_Value"
                        placeholder="max 5 Chars"
                        max={6}
                        type="number"
                        onChange={rangeValidation.handleChange}
                        onBlur={rangeValidation.handleBlur}
                        value={rangeValidation.values.max_Value || ""}
                        invalid={
                          rangeValidation.touched.max_Value &&
                          rangeValidation.errors.max_Value
                            ? true
                            : false
                        }
                      />
                      {rangeValidation.touched.max_Value &&
                      rangeValidation.errors.max_Value ? (
                        <FormFeedback type="invalid">
                          {rangeValidation.errors.max_Value}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Range Length</Label>
                      <Input
                        name="range_Value"
                        placeholder="Text between 5 - 10 chars length"
                        type="number"
                        onChange={rangeValidation.handleChange}
                        onBlur={rangeValidation.handleBlur}
                        value={rangeValidation.values.range_Value || ""}
                        invalid={
                          rangeValidation.touched.range_Value &&
                          rangeValidation.errors.range_Value
                            ? true
                            : false
                        }
                      />
                      {rangeValidation.touched.range_Value &&
                      rangeValidation.errors.range_Value ? (
                        <FormFeedback type="invalid">
                          {rangeValidation.errors.range_Value}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Regular Exp</Label>
                      <Input
                        name="regular_Exp"
                        placeholder="Hex. Color"
                        type="number"
                        onChange={rangeValidation.handleChange}
                        onBlur={rangeValidation.handleBlur}
                        value={rangeValidation.values.regular_Exp || ""}
                        invalid={
                          rangeValidation.touched.regular_Exp &&
                          rangeValidation.errors.regular_Exp
                            ? true
                            : false
                        }
                      />
                      {rangeValidation.touched.regular_Exp &&
                      rangeValidation.errors.regular_Exp ? (
                        <FormFeedback type="invalid">
                          {rangeValidation.errors.regular_Exp}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <FormGroup className="mb-0">
                      <div>
                        <Button type="submit" color="primary" className="ms-1">
                          Submit
                        </Button>{" "}
                        <Button type="reset" color="secondary">
                          Cancel
                        </Button>
                      </div>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormValidation;
