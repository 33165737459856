import React from "react";
import {FormFeedback, Input} from "reactstrap";


const GoodsConsentForm = ({yourGoodsFormik}) => {

    const documentUploadHandler = (e, name) => {
        yourGoodsFormik.setFieldValue(name, e.target.files[0]);
    }

    return (
        <div className="container mt-4">
            <div className="card p-4 shadow-sm">
                <h4 className="fw-bold">Commodities</h4>

                <div className="mb-3">
                    <label className="form-label">Product name</label>
                    <Input
                        type="text"
                        name="productName"
                        value={yourGoodsFormik.values.productName}
                        onChange={yourGoodsFormik.handleChange}
                        className="form-control"
                        placeholder="Add product name"
                        onBlur={yourGoodsFormik.handleBlur}
                        invalid={
                            yourGoodsFormik.touched.productName &&
                            yourGoodsFormik.errors.productName
                                ? true
                                : false
                        }
                    />
                    {yourGoodsFormik.touched.productName &&
                    yourGoodsFormik.errors.productName ? (
                        <FormFeedback>{yourGoodsFormik.errors.productName}</FormFeedback>
                    ) : null}
                </div>

                <div className="mb-3">
                    <label className="form-label">SKU (Optional)</label>
                    <input type="text" className="form-control" placeholder="Enter SKU" />
                    <Input
                        type="text"
                        name="productSku"
                        value={yourGoodsFormik.values.productSku}
                        onChange={yourGoodsFormik.handleChange}
                        className="form-control"
                        placeholder="Enter SKU"
                        onBlur={yourGoodsFormik.handleBlur}
                        invalid={
                            yourGoodsFormik.touched.productSku &&
                            yourGoodsFormik.errors.productSku
                                ? true
                                : false
                        }
                    />
                    {yourGoodsFormik.touched.productSku &&
                    yourGoodsFormik.errors.productSku ? (
                        <FormFeedback>{yourGoodsFormik.errors.productSku}</FormFeedback>
                    ) : null}
                </div>

                <div className="mb-3">
                    <label className="form-label">Detailed product description (Optional)</label>
                    <textarea className="form-control" placeholder="Describe what your goods are and what they're made of. Include any brand and model numbers." rows="3"></textarea>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label className="form-label">End-use of product (Optional)</label>
                        <input type="text" className="form-control" placeholder="Describe how the product will be used" />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label className="form-label">Country of manufacture (Optional)</label>
                        <select className="form-select">
                            <option>China</option>
                            <option>USA</option>
                            <option>Germany</option>
                            <option>India</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label className="form-label">Product URL (Optional)</label>
                        <input type="url" className="form-control" placeholder="Enter URL" />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label className="form-label">HS/HTS code (Optional)</label>
                        <input type="text" className="form-control" placeholder="Enter HT/HTS code" />
                    </div>
                </div>

                <h5 className="fw-bold">Supporting documents and images (Optional)</h5>
                <p>Adding additional documents such as product specs, FDA data sheets, permits, government agency forms, and photographs will help your customs broker classify your product more accurately.</p>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label className="form-label">Upload your product image</label>
                        <Input
                            type="file"
                            name={`productImage`}
                            onChange={(e) => { documentUploadHandler(e, "productImage") }}
                            className="form-control"
                            placeholder=""
                            onBlur={yourGoodsFormik.handleBlur}
                            invalid={
                                yourGoodsFormik.touched.productImage &&
                                yourGoodsFormik.touched.productImage &&
                                yourGoodsFormik.errors.productImage &&
                                yourGoodsFormik.errors.productImage
                                    ? true
                                    : false
                            }
                        />
                        {yourGoodsFormik.touched.productImage &&
                        yourGoodsFormik.touched.productImage &&
                        yourGoodsFormik.errors.productImage &&
                        yourGoodsFormik.errors.productImage ? (
                            <FormFeedback>{yourGoodsFormik.errors.productImage}</FormFeedback>
                        ) : null}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label className="form-label">Upload your Doc</label>
                        <Input
                            type="file"
                            name={`doc`}
                            onChange={(e) => { documentUploadHandler(e, "doc") }}
                            className="form-control"
                            placeholder=""
                            onBlur={yourGoodsFormik.handleBlur}
                            invalid={
                                yourGoodsFormik.touched.doc &&
                                yourGoodsFormik.touched.doc &&
                                yourGoodsFormik.errors.doc &&
                                yourGoodsFormik.errors.doc
                                    ? true
                                    : false
                            }
                        />
                        {yourGoodsFormik.touched.doc &&
                        yourGoodsFormik.touched.doc &&
                        yourGoodsFormik.errors.doc &&
                        yourGoodsFormik.errors.doc ? (
                            <FormFeedback>{yourGoodsFormik.errors.doc}</FormFeedback>
                        ) : null}
                    </div>
                </div>
                <hr />
                <h4 className="fw-bold">
                    Commercial invoice <span className="badge bg-danger ms-2">REQUIRED</span>
                </h4>
                <p>Your commercial invoice should be in English and should include:</p>
                <ul>
                    <li>Contact information and address of all involved parties (Buyer, Seller (Manufacturer if different), Consignee, and Ship-to)</li>
                    <li>A clear and detailed description of the merchandise.</li>
                    <li>Merchandise country of origin (i.e. Made In China).</li>
                    <li>Cost per item, currency, and valuation (any additional line items with value, tangible or not, such as selling commissions, assists, royalties, production costs, packing, etc.).</li>
                </ul>
                <div className="mb-3">
                    <label className="form-label">Upload your Commercial Invoice</label>
                    <Input
                        type="file"
                        name={`commercialInvoice`}
                        onChange={(e) => { documentUploadHandler(e, "commercialInvoice") }}
                        className="form-control"
                        placeholder=""
                        onBlur={yourGoodsFormik.handleBlur}
                        invalid={
                            yourGoodsFormik.touched.commercialInvoice &&
                            yourGoodsFormik.touched.commercialInvoice &&
                            yourGoodsFormik.errors.commercialInvoice &&
                            yourGoodsFormik.errors.commercialInvoice
                                ? true
                                : false
                        }
                    />
                    {yourGoodsFormik.touched.commercialInvoice &&
                    yourGoodsFormik.touched.commercialInvoice &&
                    yourGoodsFormik.errors.commercialInvoice &&
                    yourGoodsFormik.errors.commercialInvoice ? (
                        <FormFeedback>{yourGoodsFormik.errors.commercialInvoice}</FormFeedback>
                    ) : null}
                </div>
                <hr />
                <h4 className="fw-bold">Packing list</h4>
                <p>A Packing List provides you with information about your shipment. This document must include quantities, packaging units, weights, and measurements for each product/commercial good you are shipping.</p>
                <div className="mb-3">
                    <label className="form-label">Upload your Packing List</label>
                    <Input
                        type="file"
                        name={`packingList`}
                        onChange={(e) => { documentUploadHandler(e, "packingList") }}
                        className="form-control"
                        placeholder=""
                        onBlur={yourGoodsFormik.handleBlur}
                        invalid={
                            yourGoodsFormik.touched.packingList &&
                            yourGoodsFormik.touched.packingList &&
                            yourGoodsFormik.errors.packingList &&
                            yourGoodsFormik.errors.packingList
                                ? true
                                : false
                        }
                    />
                    {yourGoodsFormik.touched.packingList &&
                    yourGoodsFormik.touched.packingList &&
                    yourGoodsFormik.errors.packingList &&
                    yourGoodsFormik.errors.packingList ? (
                        <FormFeedback>{yourGoodsFormik.errors.packingList}</FormFeedback>
                    ) : null}
                </div>

                <a href="#" className="d-block mb-3">What can I ship?</a>
            </div>
        </div>
    );
}

export default GoodsConsentForm