import React, {Fragment} from "react";
import {useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {DefaultColumnFilter, Filter} from "../../../components/Common/filters";
import {useNavigate} from "react-router-dom";
import {Row, Table} from "reactstrap";
import TableCommonSkeleton from "../../Skeleton/TableCommonSkeleton";

const AccessModuleTable = ({ columns, data, customPageSize, loader, onModuleSave, onModuleCancel, enableAction, isUpdate }) => {

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, canPreviousPage, canNextPage, pageOptions,
        pageCount, gotoPage, nextPage, previousPage, setPageSize, state, preGlobalFilteredRows, setGlobalFilter, state: { pageIndex, pageSize }, } =
        useTable({
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: { pageIndex: 0, pageSize: customPageSize },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination);

    const navidate = useNavigate();
    return (
        <>
            <div className="table_pagination_wrap">
                <div className="table-responsive table_wrap_role">
                    <Table hover {...getTableProps()} className={'test'}>
                        <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th key={column.id} className={column.render("width") == 2 ? "px-0 " : "px-2"}>
                                            <span
                                                className='d-flex align-items-center' {...column.getSortByToggleProps()}>
                                                {column.render("Header")}
                                                <i className='fas fa-sort'></i>
                                            </span>
                                        <Filter column={column}/>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr className='th-reduce'>
                                        {row.cells.map(cell => {
                                            return (
                                                <td key={cell.id} {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </Fragment>
                            );
                        })}
                        {page?.length === 0 && (
                            <>
                                {loader ? (
                                        <TableCommonSkeleton tdCount={headerGroups[0].headers.length}/>
                                    ) :
                                    <tr>
                                        <td colSpan={headerGroups[0].headers.length}>
                                            <div className='no_table_data_found'>
                                                <p>No Data Found.</p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </>
                        )}
                        </tbody>
                    </Table>
                </div>
                <Row className="align-items-center g-3 text-center text-sm-start pagination_wrap" style={{marginTop: 25}}>
                    <div className="row">
                        <div className="d-flex justify-content-center">
                            <div className="mb-3 mx-3 d-flex justify-content-center">
                                <button className=" btn btn-primary" disabled={enableAction} onClick={() => onModuleSave()}
                                        type="submit"> {isUpdate ? "Update": "Save"}
                                </button>
                            </div>
                            <div className="mb-3 mx-3 d-flex justify-content-center">
                                <button className=" btn btn-primary" disabled={enableAction} onClick={() => onModuleCancel()}
                                        type="button">Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default AccessModuleTable;