import PropTypes from 'prop-types';
import React from "react";

import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import {userRoutes, authRoutes, networkAuthRoutes, networkRoutes} from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";


// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend";
import NetworkLayout from "./components/NetworkLayout";
import NetworkAuthMiddleWare from "./routes/middleware/NetworkAuthMiddleWare";

// Activating fake backend
// fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  const NetworkLayoutCls = NetworkLayout;

  return (
    <React.Fragment>
      {/* <Router> */}
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route?.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}

        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>}
            key={idx}
            exact={true}
          />
        ))}

        {networkAuthRoutes.map((route, idx) => (
            <Route
                path={route.path}
                element={
                  <NonAuthLayout>
                    {route?.component}
                  </NonAuthLayout>
                }
                key={idx}
                exact={true}
            />
        ))}

        {networkRoutes.map((route, idx) => (
            <Route
                path={route.path}
                element={
                  <NetworkAuthMiddleWare>
                    <NetworkLayoutCls>{route?.component}</NetworkLayoutCls>
                  </NetworkAuthMiddleWare>
                }
                key={idx}
                exact={true}
            />
        ))}

      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
