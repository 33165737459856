import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap'
import { eye_icon, upload_icon } from '../../../assets/images'
import { requestForRatescrumb } from '../../../common/data/sales'
import TfBreadcrumbs from '../../../components/Common/TfBreadcrumbs'
import { getInquirySummeryData, getSalesInquiryData } from '../../../store/Sales/actions'
import { GET_INQUIRY_DATA_SUCCESS } from '../../../store/Sales/actiontype'
import EmailSendtoCarrierPopUp from '../../Email/EmailSendtoCarrierPopUp'
import FilterSalesInquiryComp from '../partials/FilterSalesInquiryComp'
import { QueriesColVal } from "../partials/SalesCol"
import SalesCommonTable from '../partials/SalesCommonTable'
import { IN_PROGRESS, REQUEST_FOR_RATE } from '../../Dashboard/Partials/initialData'
import { getUsersData } from '../../../store/Settings/actions'
import AssignePopUp from '../Quotations/Dialog/AssignePopUp'
import ApproveRejectPopUp from '../Quotations/Dialog/ApproveRejectPopUp'
import {formatModifyDate, getInternalRoute} from "../../../components/Common/CommonLogic";

export default function RequestForRates() {
    document.title = "Inquiry || Navigating Freight Costs with Precision||Ultimate Rate Management platform"
    const { settings_users_data, users_loader } = useSelector((state) => state.settings);
    const { inquiry_data_loader, inquiry_data } = useSelector((state) => state?.sales);
    const [isRight, setIsRight] = useState(false);
    const [quatationAssignTo, setQuotationAssignTo] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [assingToPopUp, setAssingToPopUp] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modal, setmodal] = useState(false);
    const [quotationData, setQuotationData] = useState(null);
    const [rejectPopUp, setRejectPopUp] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [popUpData, setPopUpData] = useState("");

    const inputArr = {
        org_port: '',
        dest_port: '',
        status: '',
    }
    const [filterDetails, setfilterDetails] = useState(inputArr);
    const [selectedRows, setSelectedRows] = useState([]);
    const [seletedInquiry, setSeletedInquiry] = useState();
    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const onClickAssingto = () => {
        setAssingToPopUp(true)
    }

    const onClickCancle = () => {
        setAssingToPopUp(false);
        setRejectPopUp(false)
    }

    const onClickSendToCarrier = (item) => {
        setmodal(true)
        setQuotationData(item)
    }


    const onCloseClick = () => {
        setmodal(false)
    }


    const constructUrl = (page, filters) => {
        let url = `?page=${page}&size=10&`;
        url += `${filters?.customer?.id ? `customers=${filters?.customer.id}&` : ''}`;
        url += `${filters?.org_port?.id ? `origins=${filters?.org_port.id}&` : ''}`;
        url += `${filters?.dest_port?.id ? `destinations=${filters?.dest_port.id}&` : ''}`;
        url += `${filters?.sales_employee?.id ? `salesEmployees=${filters?.sales_employee.id}&` : ''}`;
        url += `${filters?.valid_from ? `validFrom=${filters?.valid_from}&` : ''}`;
        url += `${filters?.valid_to ? `validTo=${filters?.valid_to}&` : ''}`;
        url += `${filters?.status?.id ? `status=${filters?.status?.id}&` : ''}`;
        url += `${filters?.container_type?.id ? `containerType=${filters?.container_type?.id}&` : ''}`;
        url += `requestForRates=true&statusCode=${REQUEST_FOR_RATE},${IN_PROGRESS}&`;
        return url.substring(0, url.length - 1);
    };

    const applyFilterHandler = () => {
        setIsRight(false);
        let url = constructUrl(currentPage, filterDetails);
        dispatch(getSalesInquiryData(url));
    };

    const clearValueHandler = () => {
        setfilterDetails(inputArr);
        let url = constructUrl(currentPage, {});
        dispatch(getSalesInquiryData(url));
    };
    useEffect(() => {
        let url = constructUrl(currentPage, filterDetails);
        dispatch(getSalesInquiryData(url));
    }, [currentPage]);

    useEffect(() => {
        dispatch(getUsersData(""));
    }, []);

    useEffect(() => {
        setSelectedRows([])
    }, [inquiry_data])

    const onClickRequestForRates = (data) => {
        console.log(data);
        let newData = {
            destinationPort: data?.destination,
            originPort: data?.origin,
            inquiryId: data?.inquiryId,
        };
        /*navigate(`/freight/ocean/fcl/AddDetails`, {
            state: {
                data: newData || '',
            },
        });*/
        navigate(getInternalRoute("freight-ocean-fcl-add"), {
            state: {
                data: newData || '',
            },
        });
    }

    const handleSelectAll = (isChecked) => {
        if (isChecked) {
            const allIds = inquiry_data?.content?.map((item) => item.inquiryId) || [];
            setSelectedRows(allIds);
        } else {
            setSelectedRows([]);
        }
    };

    const handleRowSelect = (isChecked, inquiryId) => {
        if (isChecked) {
            setSelectedRows((prev) => [...prev, inquiryId]);
        } else {
            setSelectedRows((prev) => prev.filter((id) => id !== inquiryId));
        }
    };

    const columns = useMemo(() => [
        {
            Header: (
                <Input
                    type="checkbox"
                    className="custom-checkbox"
                    onChange={(e) => handleSelectAll(e.target.checked)}
                    defaultChecked={
                        inquiry_data?.content?.length > 0 &&
                        selectedRows.length === inquiry_data?.content?.length
                    }
                />
            ),
            accessor: 'select',
            disableFilters: true,
            Cell: ({ row }) => (
                <Input
                    type="checkbox"
                    className="custom-checkbox"
                    defaultChecked={selectedRows.includes(row.original.inquiryId)}
                    onChange={(e) => handleRowSelect(e.target.checked, row.original.inquiryId)}
                />
            ),
        },
        {
            Header: 'Inquiry ID',
            accessor: 'inquiryId',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Inquiry Dt',
            accessor: 'inquiryDate',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <span>{formatModifyDate(cellProps.row.original?.inquiryDate)}</span>
                )
            }
        },
        {
            Header: 'Customer Name',
            accessor: 'customerName',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Origin',
            accessor: 'origin',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Destination',
            accessor: 'destination',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Cargo Ready Date',
            accessor: 'cargoDate',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                const cargoDate = cellProps.row.original?.cargoDate;
                const cargoDateSplit = typeof cargoDate === "string" ? cargoDate.split('/') : [];
                return (cargoDateSplit[0] !== "" && cargoDateSplit[1] !== "") ? (
                    <span>{formatModifyDate(cargoDateSplit[0])}/{formatModifyDate(cargoDateSplit[1])}</span>
                ) : <span>/</span>;
            }
        },
        {
            Header: 'Cargo Type',
            accessor: 'cargoType',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Container type',
            accessor: 'containerTypes',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Weight',
            accessor: 'weight',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Cargo Value',
            accessor: 'cargoValue',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Incoterms',
            accessor: 'incoterm',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Sales Emp',
            accessor: 'salesEmp',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Assigned TO',
            accessor: 'inquiryAssignToName',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Remarks',
            accessor: 'remarks',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <div className="d-flex justify-content-center align-items-center h-100">
                    <img src={eye_icon} alt="Eye" onClick={() => { setRejectPopUp(true); setPopUpData("View Remarks"); setRemarks(cellProps.row?.original?.remarks) }} />
                </div>
            }
        },
        {
            Header: 'Action',
            Cell: (cellProps) => {
                const assignedBy = settings_users_data?.content?.find(data => data.id === cellProps.row.original?.inquiryAssignToId);
                return (
                    <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                            <i className='bx bx-dots-vertical-rounded'></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className={!assignedBy ? "disabled-item" : ""} disabled={!assignedBy} onClick={(e) => { e.stopPropagation(); onClickRequestForRates(cellProps.row.original) }}>Update Rate <img src={upload_icon} alt="Add" /></DropdownItem>
                            <DropdownItem className={!assignedBy ? "disabled-item" : ""} disabled={!assignedBy} onClick={(e) => { e.stopPropagation(); onClickSendToCarrier(cellProps.row.original) }}>Send To Carrier<i className="mdi mdi-email-outline mdi-24px me-1"></i></DropdownItem>
                            <DropdownItem className={!assignedBy ? "disabled-item" : ""} disabled={!assignedBy} onClick={(e) => { e.stopPropagation(); setRejectPopUp(true); setPopUpData("Reason For Reject Inquiry"); setSeletedInquiry(cellProps.row.original.inquiryId) }}>Reject <i className="mdi mdi-close-circle-outline mdi-24px me-1"></i></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )
            }
        },
    ], [selectedRows, settings_users_data]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div className="main_freight_wrapper">
                        {/* breadcrumbs && rate */}
                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TfBreadcrumbs breadcrumb={requestForRatescrumb} />
                        </div>

                        <SalesCommonTable
                            columns={columns}
                            data={!!inquiry_data.content ? inquiry_data.content : []}
                            isGlobalFilter={true}
                            isAddInvoiceList={true}
                            loader={inquiry_data_loader}
                            onClickAssingto={onClickAssingto}
                            customPageSize={10}
                            toggleRightCanvas={toggleRightCanvas}
                            component={'requestForRate'}
                            setCurrentPage={setCurrentPage}
                            totalPages={inquiry_data?.totalPages || 0}
                            totalEntries={inquiry_data?.totalElements || 0}
                        />
                    </div>
                </Container>
            </div>
            <EmailSendtoCarrierPopUp modal={modal} onCloseClick={onCloseClick} quotationData={quotationData} />
            <ApproveRejectPopUp isOpen={rejectPopUp} onClose={onClickCancle} data={popUpData} seletedInquiry={seletedInquiry} remark={remarks} />
            {/* filter right sidebar */}
            <FilterSalesInquiryComp isRight={isRight} toggleRightCanvas={toggleRightCanvas} filterDetails={filterDetails} setfilterDetails={setfilterDetails} applyFilterHandler={applyFilterHandler} clearValueHandler={clearValueHandler} />
            <AssignePopUp isOpen={assingToPopUp} onClose={onClickCancle} selectedRows={selectedRows} />
        </>
    )
}
