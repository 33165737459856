import { GET_MARKETING_TABLE_DATA, GET_RULE_HISTORY_DATA, POST_MARKETING_DATA } from "./actiontype"

export const saveMarketingData = (data) => {
    return {
    type: POST_MARKETING_DATA,
    payload: data,
}
}

export const getTenantRuleDataSuccess = (data) => ({
    type: GET_MARKETING_TABLE_DATA,
    payload: data,
})

export const getRuleHistory = (data) => ({
    type: GET_RULE_HISTORY_DATA,
    payload: data,
})

// export const getFclSurchargeDataFail = (error) => ({
//     type: GET_FCL_SURCHARGE_TABLE_DATA_FAIL,
//     payload: error,
// })