import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";

import store from "./store";
import { LoadingBarContainer } from "./components/Common/ProgressBar";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <LoadingBarContainer props={{ color: "blue", }}>
            <App />
        </LoadingBarContainer>
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
