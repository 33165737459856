import React, {useEffect, useState} from "react";
import {FormFeedback, Input, Label} from "reactstrap";
import {getCustomersPincodeData, getCustomersStateData} from "../../../store/Parties/actions";
import {useDispatch, useSelector} from "react-redux";

const PickupAddressForm = ({pickupAddressFormik}) => {

    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);
    const { parties_city_details, parties_state_details, parties_pincode_details } = useSelector(
        (state) => state?.parties
    );

    useEffect(() => {
        if (parties_state_details && parties_state_details?.content?.length > 0) {
            pickupAddressFormik.setFieldValue("state", parties_state_details?.content[0]?.stateName)
        }

    }, [parties_state_details, parties_pincode_details]);

    useEffect(() => {
        const cityData = parties_city_details?.content?.find((city) => city.cityName === pickupAddressFormik.values.city);
        if (!!cityData) {
            dispatch(getCustomersStateData({ cityId: cityData.id }));
            dispatch(getCustomersPincodeData({ cityId: cityData.id }));
        }
    }, []);


    return (
        <>
            <form>
                <div className="container mt-4">
                    <div className="d-flex w-100 justify-content-between">
                        <div className="d-flex align-items-center">
                            <h6 className="mb-3" style={{flex: 1}}>Pickup Address</h6>
                            <div className="square-switch" style={{marginLeft: "10px"}}>
                                <Input type="checkbox"
                                       id="square-switch2"
                                       name="pickupAddressNeeded"
                                       switch="none"
                                       role="switch"
                                       checked={isChecked}
                                       onClick={() => {
                                           pickupAddressFormik.setFieldValue("pickupAddressNeeded", !isChecked);
                                           pickupAddressFormik.setTouched({ pickupAddressNeeded: true });
                                           // if (!isChecked) {
                                           //     pickupAddressFormik.resetForm();
                                           // }
                                           setIsChecked(!isChecked);
                                       }}
                                />
                                <Label htmlFor="square-switch2" data-on-label="Yes"
                                       data-off-label="No"></Label>
                            </div>
                        </div>
                    </div>
                    <div className="collapse-container"
                         style={{
                             maxHeight: isChecked ? "500px" : "0",
                             overflow: "hidden",
                             transition: "max-height 0.5s ease-in-out",
                         }}>
                        <div className="row mt-4">
                            {/* Address Input */}
                            <div className="col-12 mb-3">
                                <label className="form-label">Address <span className="text-danger">*</span></label>
                                <Input type="text" className="form-control" placeholder="Pickup Address"
                                       name="pickupAddress"
                                       value={pickupAddressFormik?.values.pickupAddress || ""}
                                       onChange={pickupAddressFormik.handleChange}
                                       onBlur={pickupAddressFormik.handleBlur}
                                       invalid={
                                           pickupAddressFormik.touched.pickupAddress &&
                                           pickupAddressFormik.errors.pickupAddress
                                               ? true
                                               : false
                                       }/>
                                {pickupAddressFormik.touched.pickupAddress &&
                                pickupAddressFormik.errors.pickupAddress ? (
                                    <FormFeedback>{pickupAddressFormik.errors.pickupAddress}</FormFeedback>
                                ) : null}
                            </div>

                            {/* State and City Inputs */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">State <span className="text-danger">*</span></label>
                                <Input
                                    type="text"
                                    name="state"
                                    list="stateList"
                                    value={pickupAddressFormik?.values?.state || ""}
                                    onChange={pickupAddressFormik.handleChange}
                                    onBlur={pickupAddressFormik.handleBlur}
                                    className="form-control"
                                    placeholder=""
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="form-label">City <span className="text-danger">*</span></label>
                                <Input
                                    type="text"
                                    name="city"
                                    list="cityList"
                                    value={pickupAddressFormik?.values?.city || ""}
                                    onChange={(e) => {
                                        pickupAddressFormik.handleChange(e);
                                        pickupAddressFormik.setFieldValue('state', '');
                                        pickupAddressFormik.setFieldValue('zipcode', '');
                                        const cityData = parties_city_details?.content?.find((city) => city.cityName === e.target.value);
                                        if (cityData) {
                                            dispatch(getCustomersStateData({ cityId: cityData.id }));
                                            dispatch(getCustomersPincodeData({ cityId: cityData.id }));
                                        }
                                    }
                                    }
                                    className="form-control"
                                    placeholder=""
                                    onBlur={pickupAddressFormik.handleBlur}
                                    invalid={pickupAddressFormik.touched.city && pickupAddressFormik.errors.city ? true : false}
                                />
                                {pickupAddressFormik.touched.city && pickupAddressFormik.errors.city ? (
                                    <FormFeedback>{pickupAddressFormik.errors.city}</FormFeedback>
                                ) : null}
                                <datalist id="cityList">
                                    {parties_city_details &&
                                        parties_city_details?.content?.map((item, i) => (
                                            <option key={i} value={item.cityName} />
                                        ))}
                                </datalist>
                            </div>

                            {/* Postal Code Input */}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Postal Code <span className="text-danger">*</span></label>
                                <Input
                                    type="text"
                                    name="zipcode"
                                    list="zipcodeList"
                                    value={pickupAddressFormik?.values?.zipcode || ""}
                                    onChange={pickupAddressFormik.handleChange}
                                    className="form-control"
                                    placeholder=""
                                />
                                <datalist id="zipcodeList">
                                    {parties_pincode_details &&
                                        parties_pincode_details?.content?.map((item, i) => (
                                            <option key={i} value={item.pin} />
                                        ))}
                                </datalist>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </>
    );
}

export default PickupAddressForm;