import React, {useContext} from 'react'
import TfBreadcrumbs from '../../../components/Common/TfBreadcrumbs'
import {TourContext} from "../../../components/Common/TourGuide/TourGuide";

export default function TopBreadcrumbs({ breadcrumbs, data }) {

    const { onStartTour } = useContext(TourContext);

    return (
        <>
            <div className="tf_top_breadcrumb_rate_wrap">
                <div className="d-flex align-items-center gap-2">
                    <TfBreadcrumbs breadcrumb={breadcrumbs} />
                    <div onClick={() => onStartTour("AddUserDetails")}>
                        <i className="fas fa-info-circle" style={{ color: "#0056b3", size: "24px" }}></i>
                    </div>
                </div>

            </div>
        </>
    )
}
