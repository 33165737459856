import {useSelector} from "react-redux";
import {FieldArray, FormikProvider, useFormik} from "formik";
import {Card, CardBody, Input} from "reactstrap";
import Select from "react-select";
import React from "react";
import {formatModifyDate} from "../../../components/Common/CommonLogic";
import {cube_filled, oocl_logo, zim_logo} from "../../../assets/images";


const ShipmentInformationForm = ({proceedToNext}) => {
    const { searchForm, $instantActiveTab } = useSelector((state) => state?.instantRate);
    const quoteData = useSelector((state) => state.instantRate.quote_selected_data);



    const shipmentDetailsFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            origin_city: searchForm?.location_from?.locationType === "CITY" && searchForm?.location_from?.label || '-',
            destination_city: searchForm?.location_to?.locationType === "CITY" && searchForm?.location_to?.label || '-',
            origin_port: $instantActiveTab?.sub === 'dom_air' ? searchForm?.location_from?.label : searchForm?.location_from?.locationType === "PORT" ? searchForm?.location_from?.label : data?.[0]?.tariffDetails?.[0]?.to || '-',
            destination_port: $instantActiveTab?.sub === 'dom_air' ? searchForm?.location_to?.label : searchForm?.location_to?.locationType === "PORT" ? searchForm?.location_to?.label : data?.[0]?.tariffDetails?.[data?.[0]?.tariffDetails?.length - 1 || 0]?.from || '-',
            cargo_type: searchForm?.cargo_type?.value || '-',
            commodity: "",
            cargo_value: searchForm?.cargo_value?.value || '-',
            incoterms: searchForm?.incoterm?.label || '-',

            container_type: searchForm?.container_type?.containerArray?.length !== 0 ? searchForm?.container_type?.containerArray?.map((item) => ({
                quantity: item?.unitNew,
                type: item?.label
            })) : '',
            gross_weight: $instantActiveTab?.sub === 'dom_air' ? searchForm?.shipment_details?.weight || 0 + " " + (searchForm?.shipment_details?.cargo_weight?.weight_unit || '') : (searchForm?.container_type?.cargo_weight?.value || 0) + " " + (searchForm?.container_type?.cargo_weight?.weight?.value || ''),

            shipment_details: searchForm?.shipment_details?.array?.length !== 0 ? searchForm?.shipment_details?.array?.map((item) => ({
                type: item?.label,
                length: item?.dimensions_l,
                width: item?.dimensions_w,
                height: item?.dimensions_h,
                unit: item?.no_unit,
                volumentric_wt: item?.amount || '',
            })) : '',

        },
        onSubmit: (values) => {
            console.log(values);
        },
    })

    return (
        <>
            <div className="air-flight_form_details">
                <Card>
                    <CardBody>
                        <form>
                            <div className="p-3">
                                {/* Route & Date */}
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="fw-bold">
                                        <span className="text-dark">{shipmentDetailsFormik.values.origin_port}</span> →{" "}
                                        <span className="text-dark">{shipmentDetailsFormik.values.destination_port}</span>
                                    </h6>
                                    <small className="text-muted">{formatModifyDate(searchForm?.cargo_date)}</small>
                                </div>

                                <div className="row align-items-center">
                                    {/* Left Column - Airline Info with Vertical Line */}
                                    <div className="col-md-2 d-flex flex-column">
                                        {/* Airline Logo */}
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-7 text-center">
                                                <div className="d-flex flex-column align-items-center text-center">
                                                    {quoteData.map((item, mainindex) => (
                                                        <>
                                                            <img
                                                                src={$instantActiveTab?.sub === "dom_air" ? item?.carrierLogo : item?.carrierName?.toLowerCase() === 'oocl' ? oocl_logo : item?.carrierName?.toLowerCase() === 'zim' ? zim_logo : cube_filled}
                                                                alt="Logo"
                                                                className="img-fluid mb-2"
                                                                style={{ height: "30px", marginLeft:"13px" }}
                                                            />
                                                            <p className="fw-bold mb-1">{$instantActiveTab?.sub === "dom_air" ? item?.flightname || '-' : item.carrierName || '-'}</p>
                                                        </>
                                                    ))}
                                                </div>

                                            </div>
                                            <div className="col-2">
                                                <div className="d-flex flex-column align-items-center">
                                                    <div className="text-secondary mb-1">
                                                        <svg width={9} height={97} viewBox="0 0 9 97">
                                                            <g fill="none" fillRule="evenodd">
                                                                <circle fill="#999" cx="4.5" cy="4.5" r="4.5"/>
                                                                <circle fill="#999" cx="4.5" cy="92.5" r="4.5"/>
                                                                <path
                                                                    stroke="#999"
                                                                    strokeLinecap="square"
                                                                    strokeDasharray={7}
                                                                    d="M4.5 7v84"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Right Column - Flight Timing and Details */}
                                    <div className="col-md-10">
                                        {/* Departure */}
                                        <div className="d-flex align-items-center">
                                            <h5 className="fw-light mb-0">{formatModifyDate(searchForm?.cargo_date)}</h5>
                                            <span className="fw-bold ms-2">{shipmentDetailsFormik.values.origin_port}</span>
                                        </div>

                                        {/* Duration */}
                                        <div className="d-flex align-items-center my-2">
                                            <i className="bi bi-clock text-muted"></i>
                                            <span className="ms-2 text-muted"></span>
                                        </div>

                                        {/* Arrival */}
                                        <div className="d-flex align-items-center">
                                            <h5 className="fw-light mb-0">{formatModifyDate(searchForm?.cargo_date)}</h5>
                                            <span className="fw-bold ms-2">{shipmentDetailsFormik.values.destination_port}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row justify-content-start">
                                    <div className="col-12 col-md-3">
                                        <div className="mb-3">
                                            <label className="form-label">Cargo Type</label>
                                            <span className="fw-400">{shipmentDetailsFormik.values.cargo_type}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="mb-3">
                                            <label className="form-label">Cargo Value</label>
                                            <span className="fw-400">{shipmentDetailsFormik.values.cargo_value}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label">Weight</label>
                                            <span className="fw-400">{shipmentDetailsFormik.values.gross_weight} ({searchForm?.shipment_details?.weight_unit || 'KG'})</span>
                                        </div>
                                    </div>

                                    {$instantActiveTab?.sub !== 'FCL' && (
                                        <div className='row' style={{marginLeft: "-19px"}}>
                                            <div className="col-12 col-md-12">
                                                <Card>
                                                    <CardBody>
                                                        <FormikProvider value={shipmentDetailsFormik}>
                                                            <FieldArray name="shipment_details">
                                                                {(arrayHelpers, i) => {
                                                                    return (
                                                                        <React.Fragment key={i}>
                                                                            {shipmentDetailsFormik.values.shipment_details?.length > 0 &&
                                                                                shipmentDetailsFormik.values.shipment_details.map((item, index) => (
                                                                                    <div className="row mb-2" key={index}>
                                                                                        <div className="col-12 col-md-2">
                                                                                            <label className="form-label">Unit</label>
                                                                                            <span className="fw-400">{item?.unit || 0}</span>
                                                                                        </div>
                                                                                        <div className="col-12 col-md-2">
                                                                                            <div className="mb-3">
                                                                                                <label className="form-label">Length</label>
                                                                                                <span className="fw-400">{item?.length || 0}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-md-2">
                                                                                            <div className="mb-3">
                                                                                                <label className="form-label">Width</label>
                                                                                                <span className="fw-400">{item?.width || 0}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-md-2">
                                                                                            <div className="mb-3">
                                                                                                <label className="form-label">Height</label>
                                                                                                <span className="fw-400">{item?.height || 0}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-md-3">
                                                                                            <div className="mb-3">
                                                                                                <label className="form-label">Volumentric Wt</label>
                                                                                                <span className="fw-400">{item?.volumentric_wt || 0}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                        </React.Fragment>
                                                                    )
                                                                }}
                                                            </FieldArray>
                                                        </FormikProvider>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault();
                                    proceedToNext("addon");
                                }}>Continue</button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </>
    )
}

export default ShipmentInformationForm;