import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useEffect, useState} from "react";
import {GET_ALL_COUNTRY_DATA} from "../../../store/Global/actiontype";
import {createSelfNewAccount, registerEmailVerified, registerOtpVerified} from "../../../store/auth/register/actions";

export const useRegistration = () => {
    const dispatch = useDispatch();
    const { country_master_isd_icd } = useSelector((state) => state?.globalReducer);
    const [password, setPassword] = useState("");
    const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    useEffect(() => {
        //dispatch({ type: GET_ALL_COUNTRY_DATA });
    }, []);

    const emailValidationSchema = yup.object({
        email: yup
            .string()
            .required("Email required")
            .matches(emailRules, { message: "Not valid email" }),
    });

    const otpValidationSchema = yup.object({
        otp: yup.array()
            .of(yup.string().matches(/^[0-9]$/, "Only numbers allowed"))
            .required("OTP is required")
            .test("complete-otp", "Enter complete OTP", (otp) =>
                otp.every((val) => val !== "")
            ),
    })

    const sendEmailInitialValues = {
        email: "",
    }

    const handleSendEmail = async (values) => {
        const payload = {
            email: values.email
        };
        dispatch(registerEmailVerified(payload));
    };


    const handleOtpVerified = async (values) => {
        const payload = {
            email: values.email,
            otp: values.otp.join(""),
        };
        console.log(payload, "in Hook");
        dispatch(registerOtpVerified(payload));
    }

    const createAccountHandleSubmit = async (values) => {
        const payload = {
            companyName : values.companyName,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            tenant: {
                name: values.companyName,
                firstName: values.firstName,
                lastName: values.lastName,
                logo: "",
                logoPath: "",
                contactName: values.firstName,
                contactNumber: values.phoneNumber,
                email: values.email,
                industryType: values.industryType,
            }
        }
        dispatch(createSelfNewAccount(payload));
        console.log(values, "Create account in Hook");
    }

    const createAccountInitialValues = {
        companyName: "",
        firstName: "",
        lastName: "",
        password: "",
        phoneNumber: "",
        industryType: ""
    }

    const createAccountValidationSchema = yup.object({
        companyName: yup.string().required("Company name is required"),
        firstName: yup.string().required("First name is required"),
        lastName: yup.mixed().notRequired(),
        password: yup.string().required("Password is required"),
        phoneNumber: yup.string().required("Phone number is required"),
    })

    const generatePassword = () => {
        const lowerCase = "abcdefghijklmnopqrstuvwxyz";
        const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numbers = "0123456789";
        const symbols = "!@#$%^&*()_+{}[]<>?";

        let characters = lowerCase + upperCase;
        characters += numbers;
        characters += symbols;

        let newPassword = "";
        for (let i = 0; i < 12; i++) {
            newPassword += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return newPassword;
    };

    return {
        handleSendEmail,
        emailValidationSchema,
        sendEmailInitialValues,
        country_master_isd_icd,
        handleOtpVerified,
        otpValidationSchema,
        generatePassword,
        createAccountInitialValues,
        createAccountValidationSchema,
        createAccountHandleSubmit
    }

}


export default useRegistration;
