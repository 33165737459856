import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Form, Input } from "reactstrap";

const DailyRecurrenceModal = ({ modal, onCloseClick,setSheduleData }) => {
    const [recurrenceData, setRecurrenceData] = useState({
        start: "",
        repeatEvery: 1,
        end: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRecurrenceData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Daily Recurrence Data:", recurrenceData);
        setSheduleData(recurrenceData)
        onCloseClick(); // Close modal
    };

    return (
        <Modal isOpen={modal} toggle={onCloseClick} >
            <ModalHeader tag="h4">
                Set Recurrence
                <span className="close" onClick={onCloseClick}></span>
            </ModalHeader>
            <ModalBody>
                <Form
                    className="custom-form mt-2 p-1"
                    onSubmit={handleSubmit}
                >
                    {/* Start Date */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-label">Start</label>
                            <Input
                                type="date"
                                name="start"
                                value={recurrenceData.start}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {/* Repeat Every */}
                    <div className="row mb-4">
                        <div className="col-6">
                            <label className="form-label">Repeat every</label>
                            <Input
                                type="number"
                                name="repeatEvery"
                                value={recurrenceData.repeatEvery}
                                onChange={handleChange}
                                className="form-control"
                                min="1"
                            />
                        </div>
                        <div className="col-6">
                            <label className="form-label">Type</label>
                            <Input
                                type="select"
                                name="repeatType"
                                className="form-control"
                                disabled
                            >
                                <option value="Day">Day</option>
                            </Input>
                        </div>
                    </div>

                    {/* End Date */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-label">End</label>
                            <Input
                                type="date"
                                name="end"
                                value={recurrenceData.end}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {/* Summary */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <p className="text-muted">
                                Occurs every day starting {recurrenceData.start || "Select a start date"}.
                            </p>
                        </div>
                    </div>

                    {/* Actions */}
                    <div className="row">
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-primary mx-3" type="submit">
                                Save
                            </button>
                            <button
                                className="btn btn-secondary mx-3"
                                type="button"
                                onClick={() => {
                                    onCloseClick();
                                    setRecurrenceData({
                                        start: "",
                                        repeatEvery: 1,
                                        end: "",
                                    });
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default DailyRecurrenceModal;
