
import { get, post } from "../api_helper";
import * as url from "../url_helper"
import {QUOTATIONS_LIST_BY_ID} from "../url_helper";

export const postQuotationSummerySer = (data) => post(url.QUOTATION_SUMMERY, data);
export const getQuotationSummerySer = (data) => get(url.QUOTATION_SUMMERY+data);
export const getQuotationSummeryByIdSer = (data) => get(url.QUOTATION_SUMMERY+data);
export const getQuotationHistoryByIdSer = (data) => get(url.QUOTATION_HISTORY+data);
export const getQuotationSellerListByIdSer = (data) => get(url.QUOTATIONS_LIST_BY_ID+data);


//export const getQuotationSellerListByIdSer = (data) => get(url.QUOTATIONS_LIST_BY_ID+data);


export const postTransferStatusSer = (quotationId,statusId,reasonId) =>  post(url.POST_TRANSFER_STATUS + quotationId + "/" + statusId +"/"+reasonId);

export const getDashBoardQuotionSummerySer=() => get(url.DAHSBOARD_QUOTATION_SUMMERY)

export const postUpdateQuotionStatusSer = (data) =>  post(url.UPDATE_QUOTATION_STATUS , data);

export const postUpdateQuotionAssingToSer = (data) =>  post(url.UPDATE_QUOTATION_ASSIGN_TO , data);