import * as Yup from "yup";

const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

export const addCompanySchema = Yup.object().shape({
    companyName: Yup.string().required("Please Enter Customer Name"),
    companyCode: Yup.string().required("Please Enter Customer Name"),
    city: Yup.string().nullable().required("Please Enter selected City"),
    country: Yup.string().nullable().required("Please Enter selected country"),
    email: Yup.string().required("Email required").matches(emailRules, { message: "Not valid email" }),
    contactName: Yup.string().required("Please Enter Your contact Name"),
    phoneNumber: Yup.string().required("Please Enter Your Phone Number"),
    keyAccountManager: Yup.string().required("Please select manager account"),
    salesEmployee: Yup.string().required("Please select sales employee")
});
