import React, {useEffect, useState} from "react";
import TermsAndConditionsModel from "./components/TermsAndConditionsModel";
import TopBreadcrumbs from "../Surcharge/TopBreadcrumbs";
import {marketPlaceConfigBreadcrumb} from "../../../common/data/parties";
import {
    Card,
    CardBody,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import { postMarketPlaceConfig } from "../../../store/Settings/actions";
import Select from "react-select";
import classnames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {
    DOMECTICSOceanSurcharges,
    DOMESTICSDestInlandCharge,
    DOMESTICSDestLocalPortCharge, DOMESTICSInlandCharge,
    DOMESTICSOceanFreightCharge,
    DOMESTICSOriginInlandCharges,
    DOMESTICSOriginLocalPortCharge,
    DomesticsTabName,
    FCLDestInlandCharge,
    FCLDestLocalPortCharge, FCLInlandCharge,
    FCLOceanFreightCharge,
    FCLOceanSurcharges,
    FCLOriginLocalPortCharge,
    FclTabName, INTERNATIONALDestInlandCharge, INTERNATIONALDestLocalPortCharge,
    INTERNATIONALOceanFreightCharge,
    INTERNATIONALOceanSurcharges,
    INTERNATIONALOriginInlandCharges,
    INTERNATIONALOriginLocalPortCharge,
    InternationTabName,
    LCLDestInlandCharge,
    LCLDestLocalPortCharge,
    LCLOceanFreightCharge,
    LCLOceanSurcharges,
    LCLOriginInlandCharges,
    LCLOriginLocalPortCharge,
    LclTabName
} from "../../Dashboard/Partials/initialData";
import QuotationMarginComponents from "../../Common/QuotationMarginComponents";
import { buyersLocationsList } from "../../../common/data/common";
import {GET_ALL_COUNTRY_DATA} from "../../../store/Global/actiontype";
import {isObjectEmpty} from "../../../components/Common/CommonLogic";
import {useNavigate} from "react-router-dom";
import { useLoadingBar } from "../../../components/Common/ProgressBar";
import { SETTINGS_INITIALIZE } from "../../../store/Settings/actiontype";

const MarketPlaceConfig = () => {
    const { start, complete, decrease, increase } = useLoadingBar({ height: 3, });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currency_data, country_master } = useSelector(state => state?.globalReducer);
    const { tenant_info, marketPlaceConfigUpdateLoading } = useSelector((state) => state?.settings);
    const [pdfPreviewModal, setPdfPreviewModal] = useState(true);
    const [activeTab, toggleTab] = useState("");
    const [tenantMarketPlaces, setTenantMarketPlaces] = useState([]);

    const [isChecked, setIsChecked] = useState(true);
    const [isOn, setIsOn] = useState(false);

    const [fclChecked, setFclChecked] = useState(false);
    const [lclChecked, setLclChecked] = useState(false);
    const [iAFChecked, setIafChecked] = useState(false);
    const [dAFChecked, setDAFChecked] = useState(false);

    const [fclTabActive, setFclTabActive] = useState(true);
    const [lclTabActive, setLclTabActive] = useState(true);
    const [iAFTabActive, setIafTabActive] = useState(true);
    const [dAFTabActive, setDafTabActive] = useState(true);

    const [buyersLocations, setBuyersLocations] = useState({value: "", label: "--Select Location--"});

    const [selectedCountryOptions, setSelectedCountryOptions] = useState([]);

    const pdfPreviewModalAction = () => {
        setPdfPreviewModal(!pdfPreviewModal);
    }

    useEffect(() => {
        dispatch({ type: GET_ALL_COUNTRY_DATA });
        //console.log("tenant_info", tenant_info);
    }, []);


    useEffect(() => {
        if(!isObjectEmpty(tenant_info)) {
            tenant_info?.tenantMarketPlaces.map((item) => {
                switch (item.serviceType) {
                    case FclTabName:
                        toggleTab(item.access ? FclTabName : "");
                        setFclTabActive(!item.access);
                        setFclChecked(item.access);
                        addOrUpdateItem({
                            serviceType: FclTabName,
                            access: item.access
                        });
                        return;
                    case LclTabName:
                        toggleTab(item.access ? LclTabName : "");
                        setLclChecked(item.access);
                        setLclTabActive(!item.access);
                        addOrUpdateItem({
                            serviceType: LclTabName,
                            access: item.access
                        });
                        return;
                    case InternationTabName:
                        toggleTab(item.access ? InternationTabName : "");
                        setIafTabActive(!item.access);
                        setIafChecked(item.access);
                        addOrUpdateItem({
                            serviceType: InternationTabName,
                            access: item.access
                        });
                        return;
                    case DomesticsTabName:
                        toggleTab(item.access ? DomesticsTabName : "");
                        setDafTabActive(!item.access);
                        setDAFChecked(item.access);
                        addOrUpdateItem({
                            serviceType: DomesticsTabName,
                            access: item.access
                        });
                        return;
                    default:
                        return;
                }
            });

            //console.log(tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOriginInlandCharges)?.marginValue );

            /*tenant_info?.tenantMarketPlaceMargins.map((item) => {
                switch (item.serviceType) {
                    case FclTabName:
                        marketPlaceConfigFormik.setFieldValue(`${FclTabName.toLocaleLowerCase()}OriginInlandChargesMarginType`, { label: "Percentage", value: "PERCENTAGE" });
                    case LclTabName:
                    case InternationTabName:
                    case DomesticsTabName:
                }
            });*/

            if (tenant_info?.tenantMarketPlaceVisibilityGeolocations.length > 0) {
                setBuyersLocations(
                    buyersLocationsList.find((obj) =>
                        obj.value === tenant_info?.tenantMarketPlaceVisibilityGeolocations[0]?.buyersLocationType || {value: "", label: "--Select Location--"})
                )
                const commonCountryElements = country_master.filter(element => tenant_info?.tenantMarketPlaceVisibilityGeolocations.find(el => el.countryId === element.id));

                setSelectedCountryOptions(commonCountryElements)
            }
        }
    }, [tenant_info, country_master])


    useEffect(() => {
        if (marketPlaceConfigUpdateLoading === false) {
            dispatch({ type: SETTINGS_INITIALIZE });
            setTimeout(() => {
                complete();
            }, 1000);
        }
    }, [marketPlaceConfigUpdateLoading])

    /*
    {
        "id":22,
        "isNameVisible":true,
        "tenantMarketPlaces": [
                {
                    "serviceType": "Example Service",
                    "access": true
                }
            ],
       "tenantMarketPlaceMargins": [
                {
                    "serviceType": "Example Service",
                    "chargeType": "",
                    "marginType":"",
                    "marginValue":""
                    "currencyId": ""
                }
            ],
            "tenantMarketPlaceVisibilityGeolocations": [
                {
                    "countryId": 2,
                    "cityId": 2
                },
                {
                    "countryId": 1,
                    "cityId": 2
                }
            ]
        }

     */

    const marketPlaceConfigFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            isNameVisible: tenant_info?.isNameVisible || false,
            fclInlandCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLInlandCharge)?.marginValue || "",
            fclOriginLocalPortCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOriginLocalPortCharge)?.marginValue || '',
            fclOceanFreightCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOceanFreightCharge)?.marginValue || '',
            fclOceanSurcharges: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLOceanSurcharges)?.marginValue || '',
            fclDestLocalPortCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLDestLocalPortCharge)?.marginValue || '',
            fclDestInlandCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === FclTabName && data?.chargeType === FCLDestInlandCharge)?.marginValue || '',

            lclOriginInlandCharges: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOriginInlandCharges)?.marginValue || "",
            lclOriginLocalPortCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOriginLocalPortCharge)?.marginValue || '',
            lclOceanFreightCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOceanFreightCharge)?.marginValue || '',
            lclOceanSurcharges: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLOceanSurcharges)?.marginValue || '',
            lclDestLocalPortCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLDestLocalPortCharge)?.marginValue || '',
            lclDestInlandCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === LclTabName && data?.chargeType === LCLDestInlandCharge)?.marginValue || '',

            internationalOriginInlandCharges: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOriginInlandCharges)?.marginValue || "",
            internationalOriginLocalPortCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOriginLocalPortCharge)?.marginValue || '',
            internationalOceanFreightCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOceanFreightCharge)?.marginValue || '',
            internationalOceanSurcharges: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALOceanSurcharges)?.marginValue || '',
            internationalDestLocalPortCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALDestLocalPortCharge)?.marginValue || '',
            internationalDestInlandCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === InternationTabName && data?.chargeType === INTERNATIONALDestInlandCharge)?.marginValue || '',

            domesticInlandCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSInlandCharge)?.marginValue || "",
            domesticOriginLocalPortCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOriginLocalPortCharge)?.marginValue || '',
            domesticOceanFreightCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSOceanFreightCharge)?.marginValue || '',
            domesticOceanSurcharges: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMECTICSOceanSurcharges)?.marginValue || '',
            domesticDestLocalPortCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSDestLocalPortCharge)?.marginValue || '',
            domesticDestInlandCharge: tenant_info?.tenantMarketPlaceMargins?.find(data => data?.serviceType === DomesticsTabName && data?.chargeType === DOMESTICSDestInlandCharge)?.marginValue || '',

        },
        onSubmit: async(values) => {
            //setLoading(true);
            //console.log(values, "values");
            start();
            let tenantCustomerQuotationMargins = [];

            const charges = [
                { key: FCLInlandCharge, type: FclTabName, },
                { key: FCLOriginLocalPortCharge, type: FclTabName },
                { key: FCLOceanFreightCharge, type: FclTabName },
                { key: FCLOceanSurcharges, type: FclTabName },
                { key: FCLDestLocalPortCharge, type: FclTabName },
                //{ key: FCLDestInlandCharge, type: FclTabName },
                { key: LCLOriginInlandCharges, type: LclTabName },
                { key: LCLOriginLocalPortCharge, type: LclTabName },
                { key: LCLOceanFreightCharge, type: LclTabName },
                { key: LCLOceanSurcharges, type: LclTabName },
                { key: LCLDestLocalPortCharge, type: LclTabName },
                //{ key: LCLDestInlandCharge, type: LclTabName },
                { key: DOMESTICSInlandCharge, type: DomesticsTabName },
                { key: DOMESTICSOriginLocalPortCharge, type: DomesticsTabName },
                { key: DOMESTICSOceanFreightCharge, type: DomesticsTabName },
                { key: DOMECTICSOceanSurcharges, type: DomesticsTabName },
                { key: DOMESTICSDestLocalPortCharge, type: DomesticsTabName },
                //{ key: DOMESTICSDestInlandCharge, type: DomesticsTabName },
                { key: INTERNATIONALOriginInlandCharges, type: InternationTabName },
                { key: INTERNATIONALOriginLocalPortCharge, type: InternationTabName },
                { key: INTERNATIONALOceanFreightCharge, type: InternationTabName },
                { key: INTERNATIONALOceanSurcharges, type: InternationTabName },
                { key: INTERNATIONALDestLocalPortCharge, type: InternationTabName },
                //{ key: INTERNATIONALDestInlandCharge, type: InternationTabName },
            ];

            charges.forEach(({ key, type }) => {
                if (values[key]) {
                    let marginTypeKey = key + "MarginType";
                    //let currencyTypeKey =  key + "Currency";
                    let marginTypeValue = values[marginTypeKey] ? values[marginTypeKey].label : "";
                    //let currencyIdValue = values[currencyTypeKey] ? values[currencyTypeKey].id : "";
                    tenantCustomerQuotationMargins.push({
                        marginValue: values[key],
                        marginType: marginTypeValue,
                        currencyId: null,
                        chargeType: key,
                        serviceType: type,
                    });
                }
            });
            //console.log(tenantCustomerQuotationMargins);
            //console.log(tenantMarketPlaces);

            let geoLocations = [];

            selectedCountryOptions.map((item) => {
                geoLocations.push({
                    countryId: item.id,
                    cityId: 0,
                    buyersLocationType: values["buyersLocations"]
                })
            });


            const marketPlaceConfigPayload = {
                id: tenant_info?.id,
                isNameVisible : values.isNameVisible,
                tenantMarketPlaces: tenantMarketPlaces,
                tenantMarketPlaceMargins: tenantCustomerQuotationMargins,
                tenantMarketPlaceVisibilityGeolocations: geoLocations
            }

            console.log("marketPlaceConfigPayload", marketPlaceConfigPayload);
            //console.log(geoLocations);
            dispatch(postMarketPlaceConfig(marketPlaceConfigPayload));
        }
    });

    const addOrUpdateItem = (newItem) => {
        setTenantMarketPlaces(prevItems => {
            const index = prevItems.findIndex(item => item.serviceType === newItem.serviceType);
            if (index === -1) {
                // Item doesn't exist, add it to the array
                return [...prevItems, newItem];
            } else {
                // Item exists, update it
                const updatedItems = [...prevItems];
                updatedItems[index] = newItem;
                return updatedItems;
            }
        });
    };


    const onAccepts = () => {
        setPdfPreviewModal(false);
    }

    const onCancels = () => {
        setPdfPreviewModal(false);
        navigate(-1);
    }

    const handleToggle = () => {
        setIsOn(!isOn);
    };

    return (
        <>
            {/*<TermsAndConditionsModel
                onAccepts={onAccepts}
                previewModal={pdfPreviewModal}
                previewModalHand={pdfPreviewModalAction}
                onCancel={onCancels} />*/}

            <div className="page-content settings_wrapper">
                <Container fluid>
                    <div className="main_freight_wrapper">
                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TopBreadcrumbs breadcrumbs={marketPlaceConfigBreadcrumb}/>
                            <div className="form-container">
                                <div className="col-12 col-md-12">
                                    <div style={{height: "auto"}}>
                                        {/* Comapany details  */}
                                        <Card id="comapanyDetails" className="mb-4">
                                            <CardBody>
                                                <div>
                                                    <h5>What Service you Want To Offer</h5>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            Full Container Load (FCL)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="fclCheckbox"
                                                                checked={fclChecked || false}
                                                                readOnly
                                                                onClick={(e) => {
                                                                    console.log("fclCheckbox onClick", e);
                                                                    setFclTabActive(!fclTabActive);
                                                                    setFclChecked(!fclChecked);
                                                                    toggleTab(!fclChecked ? FclTabName : "");
                                                                    addOrUpdateItem({
                                                                        serviceType: FclTabName,
                                                                        access: !fclChecked
                                                                    });
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            Less Then Container (LCL)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                checked={lclChecked || false}
                                                                className="form-check-input"
                                                                id="lclCheckbox"
                                                                readOnly
                                                                onClick={() => {
                                                                    toggleTab(!lclChecked ? LclTabName : "");
                                                                    setLclChecked(!lclChecked);
                                                                    setLclTabActive(!lclTabActive);
                                                                    addOrUpdateItem({
                                                                        serviceType: LclTabName,
                                                                        access: lclChecked
                                                                    });
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            AIR Freight (International)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                checked={iAFChecked}
                                                                className="form-check-input"
                                                                id="iafCheckbox"
                                                                readOnly
                                                                onClick={() => {
                                                                    toggleTab(!iAFChecked ? InternationTabName : "");
                                                                    setIafTabActive(!iAFTabActive);
                                                                    setIafChecked(!iAFChecked);
                                                                    addOrUpdateItem({
                                                                        serviceType: InternationTabName,
                                                                        access: !iAFChecked
                                                                    });
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            AIR Freight (Domestics)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="dafCheckbox"
                                                                checked={dAFChecked}
                                                                readOnly
                                                                onClick={() => {
                                                                    toggleTab(!dAFChecked ? DomesticsTabName : "");
                                                                    setDafTabActive(!dAFTabActive);
                                                                    setDAFChecked(!dAFChecked);
                                                                    addOrUpdateItem({
                                                                        serviceType: DomesticsTabName,
                                                                        access: !dAFChecked
                                                                    });
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>


                                        <Card id="bussinessType" className="my-4 mb-auto">
                                            <CardBody>
                                                <div>
                                                    <h5>Add Margins On Your Buy Rate For MarketPlace</h5>
                                                </div>
                                                <div className="mt-5">
                                                    <Nav
                                                        className="nav-tabs-custom card-header-tabs border-bottom mb-3">
                                                        <React.Fragment>
                                                            <NavItem>
                                                                <NavLink
                                                                    href="#"
                                                                    disabled={fclTabActive}
                                                                    className={classnames({active: activeTab === FclTabName,}, "px-3 py-2")}
                                                                    onClick={() => {
                                                                        toggleTab(FclTabName);
                                                                    }}>
                                                                    <i className='bx bx-cube mx-1'></i>
                                                                    FCL (Full Container Load)</NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink
                                                                    href="#"
                                                                    disabled={lclTabActive}
                                                                    className={classnames({active: activeTab === LclTabName,}, "px-3 py-2")}
                                                                    onClick={() => {
                                                                        toggleTab(LclTabName);
                                                                    }}
                                                                ><i className='bx bx-package mx-1'></i>LCL (Less Then
                                                                    Container)</NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink
                                                                    href="#"
                                                                    disabled={iAFTabActive}
                                                                    className={classnames({active: activeTab === InternationTabName,}, "px-3 py-2")}
                                                                    onClick={() => {
                                                                        toggleTab(InternationTabName);
                                                                    }}
                                                                ><i className='mdi mdi-airplane mx-1'></i>International
                                                                    AIR
                                                                    Freight</NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink
                                                                    href="#"
                                                                    disabled={dAFTabActive}
                                                                    className={classnames({active: activeTab === DomesticsTabName,}, "px-3 py-2")}
                                                                    onClick={() => {
                                                                        toggleTab(DomesticsTabName);
                                                                    }}
                                                                > <i className='mdi mdi-airplane mx-1'></i>Domestics AIR
                                                                    Freight</NavLink>
                                                            </NavItem>
                                                        </React.Fragment>
                                                    </Nav>
                                                    <TabContent activeTab={activeTab}>
                                                        <TabPane tabId={`${FclTabName}`} >
                                                            {/*<p>FCL TAB</p>*/}
                                                            {/*<FullContainerLoad currency_data={currency_data}/>*/}
                                                            <QuotationMarginComponents
                                                                activeTab={activeTab}
                                                                marketPlaceData={tenant_info?.tenantMarketPlaceMargins}
                                                                useFormik={marketPlaceConfigFormik}
                                                                currency_data={currency_data}
                                                                prefix={FclTabName.toLocaleLowerCase()} />
                                                        </TabPane>
                                                        <TabPane tabId={`${LclTabName}`}>
                                                            {/*<p>LCL TAB</p>*/}
                                                            {/*<FullContainerLoad currency_data={currency_data}/>*/}
                                                            <QuotationMarginComponents
                                                                activeTab={activeTab}
                                                                marketPlaceData={tenant_info?.tenantMarketPlaceMargins}
                                                                useFormik={marketPlaceConfigFormik}
                                                                currency_data={currency_data}
                                                                prefix={LclTabName.toLocaleLowerCase()} />
                                                        </TabPane>
                                                        <TabPane tabId={`${InternationTabName}`}>
                                                            {/*<p>IAF TAB</p>*/}
                                                            {/*<FullContainerLoad currency_data={currency_data}/>*/}
                                                            <QuotationMarginComponents
                                                                activeTab={activeTab}
                                                                marketPlaceData={tenant_info?.tenantMarketPlaceMargins}
                                                                useFormik={marketPlaceConfigFormik}
                                                                currency_data={currency_data}
                                                                prefix={InternationTabName.toLocaleLowerCase()} />
                                                        </TabPane>
                                                        <TabPane tabId={`${DomesticsTabName}`}>
                                                            {/*<p>DAF TAB</p>*/}
                                                            <QuotationMarginComponents
                                                                activeTab={activeTab}
                                                                marketPlaceData={tenant_info?.tenantMarketPlaceMargins}
                                                                useFormik={marketPlaceConfigFormik}
                                                                currency_data={currency_data}
                                                                prefix={DomesticsTabName.toLocaleLowerCase()} />
                                                        </TabPane>
                                                    </TabContent>
                                                </div>
                                            </CardBody>
                                        </Card>

                                        {/* Subscription Type */}
                                        <div className="my-4 mb-auto card">
                                            <div className="card p-4 shadow-sm">
                                                <h6 className="mb-3">Who Can See Your Rates in Marketplace</h6>


                                                <div className="form-check form-switch mb-3">
                                                    <div className="form-check form-switch form-switch-md mb-3"
                                                         dir="ltr">
                                                        <label className="form-check-label" htmlFor="displayNameToggle">Display
                                                            My
                                                            Name Against My Rates</label>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customSwitchsizelg"
                                                            checked={marketPlaceConfigFormik.values.isNameVisible || false}
                                                            onClick={() => {
                                                                marketPlaceConfigFormik.setFieldValue("isNameVisible", !marketPlaceConfigFormik.values.isNameVisible);
                                                            }}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label className="form-label">Buyers Locations</label>
                                                        <Select
                                                            value={buyersLocations}
                                                            name={"buyersLocations"}
                                                            placeholder="AnyWhere"
                                                            onChange={(e) => {
                                                                setBuyersLocations(e);
                                                                //marketPlaceConfigFormik.setFieldValue("buyersLocations", e.value);
                                                            }}
                                                            options={buyersLocationsList || []}
                                                            classNamePrefix="select2-selection form-select"
                                                        />
                                                    </div>
                                                    {
                                                        buyersLocations.value !== "anywhere" && buyersLocations.value !== "" &&
                                                        <>
                                                            <div className="col-md-4">
                                                                <label className="form-label">Country</label>
                                                                <Select
                                                                    isMulti
                                                                    value={selectedCountryOptions}
                                                                    onChange={(e) => {
                                                                        setSelectedCountryOptions(e);
                                                                    }}
                                                                    options={country_master}
                                                                    placeholder="Select Country"
                                                                />
                                                            </div>
                                                            {/*<div className="col-md-4">
                                                                <label className="form-label">City</label>
                                                                <Input
                                                                    type="text"
                                                                    name="city"
                                                                    list="cityList"
                                                                    value={marketPlaceConfigFormik.values.city}
                                                                    onChange={(e) => {
                                                                        marketPlaceConfigFormik.handleChange(e);
                                                                        marketPlaceConfigFormik.setFieldValue('country', '');
                                                                        const cityData = settings_companyCity_data?.content?.find((city) => city.cityName === e.target.value);
                                                                        if (cityData) {
                                                                            dispatch(getCompanyCountryData({cityId: cityData.id}));
                                                                        }
                                                                    }}
                                                                    className="form-control"
                                                                    placeholder=""
                                                                />
                                                                <datalist id="cityList">
                                                                    {settings_companyCity_data &&
                                                                        settings_companyCity_data?.content?.map((item, i) => (
                                                                            <option key={i} value={item.cityName}/>
                                                                        ))}
                                                                </datalist>
                                                            </div>*/}
                                                        </>
                                                    }


                                                </div>


                                                <div className="mt-4 text-center">
                                                    <button type="button" className="btn btn-primary" onClick={() => marketPlaceConfigFormik.handleSubmit()}>Save</button>
                                                    <button type="button" className="btn btn-secondary" onClick={() => marketPlaceConfigFormik.resetForm()}
                                                            style={{marginLeft: "20px"}}>Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{height: "200px"}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default MarketPlaceConfig;