// Parties Vendor
export const GET_VENDOR_LIST_TYPE = "GET_VENDOR_LIST_TYPE";
export const GET_VENDOR_LIST_SUCCESS = "GET_VENDOR_LIST_SUCCESS";
export const VENDOR_LOADER_TYPE = "VENDOR_LOADER_TYPE";

export const GET_VENDOR_DATA_LIST = "GET_VENDOR_DATA_LIST";
export const GET_VENDOR_DATA_LIST_SUCCESS = "GET_VENDOR_DATA_LIST_SUCCESS";

export const VENDOR_TAB_ACTIVE_TYPE = "VENDOR_TAB_ACTIVE_TYPE";
export const UPLOAD_VENDOR_DETAILS_TYPE = "UPLOAD_VENDOR_DETAILS_TYPE";
export const GET_VENDOR_DETAILS_ID = "GET_VENDOR_DETAILS_ID";
export const UPLOAD_VENDOR_CONTACT_TYPE = "UPLOAD_VENDOR_CONTACT_TYPE";
export const UPLOAD_VENDOR_DOCUMENT_TYPE = "UPLOAD_VENDOR_DOCUMENT_TYPE";

export const GET_VENDOR_BY_ID= "GET_VENDOR_BY_ID"
export const GET_VENDOR_BY_ID_SUCCESS= "GET_VENDOR_BY_ID_SUCCESS"

export const GET_VENDOR_CARRIER_DETAILS_SUCCESS = 'GET_VENDOR_CARRIER_DETAILS_SUCCESS'
export const GET_VENDOR_CARRIER_DETAILS = 'GET_VENDOR_CARRIER_DETAILS'