export const optionMarkupType = [
    { label: "FLAT", value: "FLAT" },
    { label: "PERCENTAGE", value: "PERCENTAGE" },
];

export const buyersLocationsList = [
    {
        value: "anywhere",
        label: "Anywhere",
        isDisabled: false
    },
    {
        value: "anywhereExcept",
        label: "Anywhere Except",
        isDisabled: true
    },
    {
        value: "only",
        label: "Only",
        isDisabled: true
    }
]