import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import * as Yup from "yup";

// import images
import logo from "../../assets/images/logo.png";
import CarouselPage from './CarouselPage';
import { useFormik } from 'formik';
import {useDispatch, useSelector} from "react-redux";
import {
    forgetEmailVerified,
    otpVerified,
    otpVerifiedApiCall,
    passwordChangeRequest
} from "../../store/auth/forgetpwd/actions";
import {ToastWrapper} from "../../components/Common/CustomToast";
import Select from "react-select";
import {industryType} from "../../common/data/settings";

const RecoverPassword = () => {
    //meta title
    document.title = "Reset Password";
    const [confirmEmail, setConfirmEmail] = useState("");
    const otpInputs = useRef([]);
    const [step, setStep] = useState(1);
    const dispatch = useDispatch();
    const { emailVerified, otpVerified, passwordChanged } = useSelector((state) => state?.ForgetPassword);
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const navidate = useNavigate();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
          email: Yup.string()
          .required("Please enter an email address")
          .matches(
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
          "Please enter a valid email address"
          ),
        }),
        onSubmit: (values) => {
            setConfirmEmail(values.email);
            const payload = {
                email: values.email,
                isResetOTP:true
            };
            dispatch(forgetEmailVerified(payload));
        }
      });

    const otpValidationSchema = Yup.object({
        otp: Yup.array()
            .of(Yup.string().matches(/^[0-9]$/, "Only numbers allowed"))
            .required("OTP is required")
            .test("complete-otp", "Enter complete OTP", (otp) =>
                otp.every((val) => val !== "")
            ),
    })

    const otpFormik = useFormik({
        initialValues: {
            otp: Array(6).fill(""),
        },
        validationSchema: otpValidationSchema,
        onSubmit: (values) => {
            console.log(values);
            const payload = {
                email: values.email,
                otp: values.otp.join(""),
            };
            dispatch(otpVerifiedApiCall(payload));
        },
    });

    /*
    .min(8, "Password must be at least 8 characters")
            .matches(/[a-z]/, "Must include a lowercase letter")
            .matches(/[A-Z]/, "Must include an uppercase letter")
            .matches(/[0-9]/, "Must include a number")
            .matches(/[!@#$%^&*]/, "Must include a special character (!@#$%^&*)")
     */

    const passwordValidationSchema = Yup.object({
        password: Yup.string()
            .required("Password is required"),

        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),
    });

    const passwordChangeFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: passwordValidationSchema,
        onSubmit: (values) => {
            console.log(values);
            const payload = {
                email: values.email,
                password: values.password,
            };
            dispatch(passwordChangeRequest(payload));
        }
    })

    const handleOtpChange = (index, value) => {
        if (!/^[0-9]?$/.test(value)) return;
        if (value.length > 1) return; // Allow only one character
        const otpArray = [...otpFormik.values.otp];
        otpArray[index] = value;
        otpFormik.setFieldValue("otp", otpArray);
        if (value && index < 5) {
            otpInputs.current[index + 1].focus();
        }
    };


    useEffect(() => {
        if (emailVerified === true && step === 1) {
            otpFormik.setFieldValue("email", confirmEmail);
            setStep(2);
        }
    }, [emailVerified]);

    useEffect(() => {
        if (otpVerified === true && step === 2) {
            setStep(3);
            passwordChangeFormik.setFieldValue("email", confirmEmail);
        }
    }, [otpVerified]);

    useEffect(() => {
        if (passwordChanged === true && step === 3) {
            setStep(1);
            navidate(`/login`, {});
        }
    }, [passwordChanged]);

    return (
        <React.Fragment>
            <div className="auth-page login_auth_wrap">
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4} md={5} className="col-xxl-3">
                            <div className="auth-full-page-content d-flex">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={logo} alt="" height="64" />
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">Reset Password</h5>
                                            </div>
                                            <div className="alert alert-success text-center mb-4 mt-4 p-2" role="alert">
                                                Enter your Email and instructions will be sent to you!
                                            </div>
                                            {step === 1 && (
                                                <Form className="custom-form tf_common_form mt-4"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;}}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Email address</Label>
                                                        <Input
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Enter email address"
                                                            type="email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            invalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3 mt-4">
                                                        <button className="btn btn-primary w-100 waves-effect waves-light" type="submit" disabled={!validation.isValid}>Reset Password</button>
                                                    </div>
                                                </Form>)}


                                            {step === 2 && (
                                                <div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Enter OTP</Label>
                                                        <div className="d-flex justify-content-between">
                                                            {otpFormik.values.otp.map((digit, index) => (
                                                                <input
                                                                    key={index}
                                                                    type="text"
                                                                    className="form-control text-center mx-1"
                                                                    style={{ width: "40px" }}
                                                                    maxLength="1"
                                                                    value={digit}
                                                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                                                    ref={(el) => (otpInputs.current[index] = el)}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-primary w-100 mt-3" onClick={otpFormik.handleSubmit}>
                                                        Verify OTP
                                                    </button>
                                                </div>
                                            )}

                                            {step === 3 && (
                                                <div>
                                                    <div className="row">
                                                        <div className="col-3 mb-2">
                                                            <label className="form-label">Email id<span
                                                                className='required_star'>*</span></label>
                                                        </div>
                                                        <div className="col-1 mb-2">
                                                            <label className="form-label">{confirmEmail}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="mb-2">
                                                            <div className="d-flex align-items-start">
                                                                <div className="flex-grow-1">
                                                                    <Label className="form-label">Password</Label>
                                                                </div>
                                                            </div>
                                                            <div id="enterPasswordArea" className="input-group auth-pass-inputgroup">
                                                                <Input
                                                                    name="password"
                                                                    value={passwordChangeFormik.values.password || ""}
                                                                    type={passwordShow ? "text" : "password"}
                                                                    placeholder="Enter password"
                                                                    onChange={passwordChangeFormik.handleChange}
                                                                    onBlur={passwordChangeFormik.handleBlur}
                                                                    invalid={
                                                                        passwordChangeFormik.touched.password && passwordChangeFormik.errors.password ? true : false
                                                                    }
                                                                />
                                                                <button onClick={() => setPasswordShow(!passwordShow)} className={`btn btn-light shadow-none ms-0 ${passwordShow ? 'show' : ''}`} type="button" id="onboarding-password-addon"></button>
                                                                {passwordChangeFormik.touched.password && passwordChangeFormik.errors.password ? (
                                                                    <FormFeedback type="invalid">{passwordChangeFormik.errors.password}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="mb-2">
                                                            <div className="d-flex align-items-start">
                                                                <div className="flex-grow-1">
                                                                    <Label className="form-label">Confirm Password</Label>
                                                                </div>
                                                            </div>
                                                            <div id="enterCPasswordArea" className="input-group auth-pass-inputgroup">
                                                                <Input
                                                                    name="confirmPassword"
                                                                    value={passwordChangeFormik.values.confirmPassword || ""}
                                                                    type={confirmPasswordShow ? "text" : "password"}
                                                                    placeholder="Enter Confirm password"
                                                                    onChange={passwordChangeFormik.handleChange}
                                                                    onBlur={passwordChangeFormik.handleBlur}
                                                                    invalid={
                                                                        passwordChangeFormik.touched.confirmPassword && passwordChangeFormik.errors.confirmPassword ? true : false
                                                                    }
                                                                />
                                                                <button onClick={() => setConfirmPasswordShow(!confirmPasswordShow)} className={`btn btn-light shadow-none ms-0 ${confirmPasswordShow ? 'show' : ''}`} type="button" id="onboarding-password-addon"></button>
                                                                {passwordChangeFormik.touched.confirmPassword && passwordChangeFormik.errors.confirmPassword ? (
                                                                    <FormFeedback type="invalid">{passwordChangeFormik.errors.confirmPassword}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <button className="btn btn-success w-100" onClick={passwordChangeFormik.handleSubmit}>Submit</button>
                                                </div>
                                            )}


                                            <div className="mt-5 text-center login_account_text">
                                                <p className="text-muted mb-0">Remember It ?  <Link to="/login"
                                                    className="text-primary fw-semibold"> Sign In </Link> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage width={9} />
                    </Row>
                </Container>
            </div>
            <ToastWrapper />
        </React.Fragment>
    );
};

export default RecoverPassword;
