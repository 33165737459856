import { useEffect, useRef } from "react";

/** keep typescript happy */
const noop = () => { };

export function useInterval(callback, delay, immediate) {
    const savedCallback = useRef(noop);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    });

    // Execute callback if immediate is set.
    useEffect(() => {
        if (!immediate) return;
        if (delay === null || delay === false) return;
        savedCallback.current();
    }, [immediate, delay]);

    // Set up the interval.
    useEffect(() => {
        if (delay === null || delay === false) return;
        const tick = () => savedCallback.current();
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}

export function randomValue(min, max) {
    return (Math.random() * (max - min + 1) + min)
}

export function randomInt(min, max) {
    return Math.floor(randomValue(min, max))
}
