import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Container,
    DropdownItem,
    FormGroup,
    Input
} from "reactstrap";
import { CommonValue } from "../Parties/PartiesCol";
import { Edit } from "../Settings/SettingsCol";
import LeadGenarationListingTable from "./LeadGenarationListingTable";
import { LeadGenarationBreadcrumb, RolesBreadcrumb } from "../../common/data/parties";
import TopBreadcrumbs from "../Settings/Surcharge/TopBreadcrumbs";
import TfBreadcrumbs from "../../components/Common/TfBreadcrumbs";
import { getTenantRuleDataSuccess, saveMarketingData } from "../../store/Marketing/actions";
import { eye_icon } from "../../assets/images";
import {formatModifyDate, getInternalRoute} from "../../components/Common/CommonLogic";

const LeadGenarationListingPage = () => {
    const [modal, setModal] = useState(false);
    const [viewData, setViewData] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(0);
    const { tenant_rule_data, tenant_rule_data_loder } = useSelector((state) => state?.Marketing);

    const editHandler = (data) => {
        console.log(data);
        navigate(getInternalRoute("add-rule-lead-genaration"), {
            state: {
                id: data?.id || '',
                data: data
            },
        });
    };

    const viewReport = (data) => {
        console.log(data);
        navigate(getInternalRoute("rule-lead-genarations-page-history"), {
            state: {
                data: data
            },
        });
    };
    const viewPopupHandler = (data) => {
        setModal(true);
        setViewData(data);
    };

    useEffect(() => {
        if (currentPage !== '' && currentPage !== undefined) {
            let url = `?page=${currentPage}&size=10`;
            dispatch(getTenantRuleDataSuccess(url))
        }
    }, [dispatch, currentPage]);

    const switchHandler = (data) => {
        data.status = data.status == 'ACTIVE' ? "INACTIVE" : "ACTIVE";
        dispatch(saveMarketingData(data));
    }

    const columns = useMemo(
        () => [
            {
                Header: "Campaign Id",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Campaign Name",
                accessor: "ruleName",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Channel Name",
                accessor: "channel",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Channel Details",
                accessor: "channelDetail",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Start Date",
                accessor: "startDate",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <span>{formatModifyDate(cellProps.row.original?.startDate)}</span>
                    )
                },
            },
            {
                Header: "End Date",
                accessor: "endDate",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <span>{formatModifyDate(cellProps.row.original?.endDate)}</span>
                    )
                },
            },
            {
                Header: "Frequency",
                accessor: "frequency",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Total Recipients",
                accessor: (row) => row.tenantRuleRecipientsMappings?.length || 0,
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "View Report",
                accessor: "report",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <img src={eye_icon} alt="Eye" onClick={() => viewReport(cellProps.row?.original)} />
                        </div>
                    );
                },
            },
            {
                Header: "Status",
                accessor: "status",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <DropdownItem onClick={(e) => e.stopPropagation()}>
                            <div className="switch_wrap">
                                <FormGroup switch>
                                    <Input
                                        type="switch"
                                        checked={cellProps.row.original?.status === "ACTIVE" || false}
                                        onClick={() => {
                                            switchHandler(cellProps.row.original);
                                        }}
                                        readOnly
                                    />
                                </FormGroup>
                            </div>
                        </DropdownItem>
                    );
                },
            },
            {
                Header: "Edit",
                Cell: (cellProps) => {
                    return (
                        <Edit cellProps={cellProps} viewPopupHandler={editHandler} />
                    );
                },
            }
        ],
        []
    );

    return (
        <>
            <div className="page-content settings_users_wrapper">
                {/* <TopBreadcrumbs breadcrumbs={LeadGenarationBreadcrumb} /> */}
                <Container fluid>
                    <div className="main_freight_wrapper">
                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TfBreadcrumbs breadcrumb={LeadGenarationBreadcrumb} />
                            <div className="tf_box_wrap d-flex">
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Active Rules</p>
                                    <div className="sh_inquiry_rate">{tenant_rule_data?.totalActiveRecords}
                                        {tenant_rule_data?.compare_rate !== '' ? (
                                            <span className={`${tenant_rule_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{tenant_rule_data?.actionedCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Total Recipients</p>
                                    <div className="sh_inquiry_rate">{tenant_rule_data?.totalRecipients}
                                        {tenant_rule_data?.compare_rate !== '' ? (
                                            <span className={`${tenant_rule_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{tenant_rule_data?.actionedCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Delivered</p>
                                    <div className="sh_inquiry_rate">{tenant_rule_data?.pendingCount}
                                        {tenant_rule_data?.compare_rate !== '' ? (
                                            <span className={`${tenant_rule_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{tenant_rule_data?.pendingCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Read</p>
                                    <div className="sh_inquiry_rate">{tenant_rule_data?.pendingCount}
                                        {tenant_rule_data?.compare_rate !== '' ? (
                                            <span className={`${tenant_rule_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{tenant_rule_data?.pendingCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LeadGenarationListingTable
                            columns={columns}
                            data={tenant_rule_data?.content || []}
                            isGlobalFilter={true}
                            isAddInvoiceList={true}
                            customPageSize={10}
                            component={"Customers"}
                            loader={tenant_rule_data_loder || false}
                            setCurrentPage={setCurrentPage}
                            totalPages={tenant_rule_data?.totalPages || 0}
                            totalEntries={tenant_rule_data?.totalElements || 0}
                        />
                    </div>
                </Container>
            </div>
        </>
    );
};

export default LeadGenarationListingPage;
