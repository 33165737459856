import React, { useState } from 'react';
import moment from "moment/moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';

export function removeNullProperties(obj) {
    if (obj && typeof obj === 'object') {
        for (const key in obj) {
            if (obj[key] === null || obj[key] === undefined) {
                delete obj[key];
            } else if (typeof obj[key] === 'object') {
                removeNullProperties(obj[key]);
            }
        }
    }
}

//------- Check View isMobileView Or Browser

export const useMobileView = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

//------ Get Current MarketPlase Rate is Access Or Not

export const getMarketPlaceRate = () => {
    return localStorage.getItem("isAccessMarketPlaceRate") === 'true' ? true : false;
}

//--- Get AuthType
export const getAuthType = () => {
    return localStorage.getItem("isNetworkLogin");
}


// ------------ Object Validations
export const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
}

//----------- Get Path based on Serach name
export const getInternalRoute = (routeKey) => {
    const authType = localStorage.getItem("isNetworkLogin");
    return internalRoutePolicy(authType).find(o => o.name === routeKey).path;
}

// ------------ Generate Random Password

export const generateRandomPassword = (passwordLength) => {
    let charset = "";
    let newPassword = "";
    charset += "!@#$%^&*()";
    charset += "0123456789";
    charset += "abcdefghijklmnopqrstuvwxyz";
    charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i < passwordLength; i++) {
        newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return newPassword;
}


// -------------- Currency wise total
export const convertToINR = (amount, currency) => {
    // Define your conversion rate from USD to INR here
    const usdToINRConversionRate = 83; // Replace with the actual conversion rate
    const BDTToINRConversionRate = 1.33;
    const IDRToINRConversionRate = 0.0053;
    if (currency === '$' || currency?.toLowerCase() === 'usd') {
        return amount * usdToINRConversionRate;
    }
    if (currency === 'BDT') {
        let newAmt = amount / BDTToINRConversionRate;
        return Math.round(newAmt);
    }
    if (currency === 'IDR') {
        let newAmt = amount * IDRToINRConversionRate;
        return Math.round(newAmt);
    }
    return amount;
};


export function convertCurrency(charge, amount, currency_ExchangeRate, searchForm) {
    let filterCurrency = currency_ExchangeRate?.find(data => data?.baseCurrency?.currencyCode === charge?.currencyCode && data?.targetCurrency?.currencyCode === searchForm?.quote_currency?.currencyCode);
    return filterCurrency?.value * amount || 0;
}

// ------------ Formats the Date
export const formatDate = (date) => {
    const d = new Date(date);
    return moment(d).format('ll');
}

export const formatModifyDate = (date) => {

    const options = { day: "2-digit", month: "short", year: "numeric" };
    if (date instanceof Array) {
        if (date[0] instanceof Date) {
            return date[0].toLocaleDateString("en-US", options);
        } else {
            return new Date(date[0]).toLocaleDateString("en-US", options);
        }
    } else if (typeof date === "string") {
        if (date instanceof Date) {
            return date.toLocaleDateString("en-US", options);
        } else {
            return new Date(date).toLocaleDateString("en-US", options);
        }
    } else {
        return "-";
    }
}

export const calculateTimeDifference = (lastLoggedIn) => {
    const currentTime = moment();
    const targetTime = moment(lastLoggedIn);
    const diffInDays = currentTime?.diff(targetTime, 'days');
    const diffInHours = currentTime?.diff(targetTime, 'hours');
    const diffInMinutes = currentTime?.diff(targetTime, 'minutes');

    if (diffInDays === 1) {
        return 'Yesterday';
    } else if (diffInDays === 0) {
        if (diffInHours === 0 && diffInMinutes > 0) {
            return `${diffInMinutes} minutes ago`;
        } else if (diffInHours > 0) {
            return `${diffInHours} hours ago`;
        } else {
            return 'Just now';
        }
    } else {
        return targetTime.format('MMM DD, YYYY');
    }
};

/** * Formats the size */
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}


/** * Checks if any value is empty */
export const isAnyValueEmpty = (obj, removeKey) => {
    //console.log(obj, removeKey);
    let updatedObj = { ...obj };
    if (removeKey !== undefined && removeKey?.length > 0) {
        for (const key of removeKey) {
            delete updatedObj?.[key];
        }
    }
    for (const key in updatedObj) {
        if (Object.prototype.hasOwnProperty.call(updatedObj, key)) {
            const value = updatedObj[key];
            if (value === '' || value === null || value === undefined) {
                //console.log("true");
                return true;
            }
        }
    }
    return false;
};

export const isAnyValueEmptyInArray = (arr, removeKey) => {
    for (const obj of arr) {
        let updatedObj = { ...obj };

        if (removeKey !== undefined && removeKey?.length > 0) {
            for (const key of removeKey) {
                delete updatedObj?.[key];
            }
        }

        for (const key in updatedObj) {
            if (Object.prototype.hasOwnProperty.call(updatedObj, key)) {
                const value = updatedObj[key];
                if (value === '' || value === null || value === undefined) {
                    return true;
                }
            }
        }
    }
    return false;
};

/* Custom sort function */
export function customSort(array, sortField, sortOrder) {
    return [...array].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
            a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
        );
    });
}

export const isExcelFile = (fileName) => {
    return fileName.endsWith(".xls") || fileName.endsWith(".xlsx");
};

/* --------------------- common logic -------------------------------------------*/
export const commonFunc = (array, index, params, seArray) => {
    if (array?.length !== 0) {
        if (array.some(obj => obj.index === index)) {
            array.find(obj => obj.index === index)[params] = !array.find(obj => obj.index === index)[params]
        } else {
            let newObj = { [params]: true, index }
            array.push(newObj);
        }
    } else {
        let newObj = { [params]: true, index }
        array.push(newObj);
    }
    seArray(array);
}



// -------------------- outside click
export const handleClickOutside = (event, wrapperRef, setOpenPop) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (event.target.id !== 'more_menu') {
            setOpenPop(false);
        }
    }
};

export const useOutsideClick = (wrapperRef, setOpenPop) => {
    useEffect(() => {
        const handleOutsideClick = (event) =>
            handleClickOutside(event, wrapperRef, setOpenPop);

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [wrapperRef, setOpenPop]);
};

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { sitelogo } from "../../assets/images";
import {internalRoutePolicy} from "./internalRoutePolicy";
import {showErrorToast} from "./CustomToast";


export const totalAmtArry = (data, newData) => {
    const processTariffDetails = (tariffDetails, type) => {
        const headerMapping = {
            "ORIGIN_INLAND_CHARGES": "origin",
            "ORIGIN_LOCAL_PORT_CHARGES": "origin",
            "FREIGHT_CHARGES": "origin",
            "DESTINATION_INLAND_CHARGES": "destination",
            "DESTINATION_LOCAL_PORT_CHARGES": "destination",
        };
        return tariffDetails?.reduce((result, currentOuter) => {
            if (type === 'new') {
                const newArray = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                    const currentCurrency = currentInner?.currencyCode;
                    const existingObj = accInner?.find((item) => item.currency === currentCurrency);
                    if (existingObj) {
                        existingObj.amount += Number(currentInner?.total_sale_cost || currentInner?.amount);
                    } else {
                        accInner.push({ currency: currentCurrency, amount: Number(currentInner?.total_sale_cost || currentInner?.amount) });
                    }
                    return accInner;
                }, []);
                const header = headerMapping[currentOuter?.header];
                const exObj = result?.find(obj => obj.header === header);
                if (exObj) {
                    exObj.data = exObj?.data.concat(newArray);
                } else {
                    result.push({ header, data: newArray });
                }
            } else {
                if (currentOuter?.selected) {
                    const newArray = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                        const currentCurrency = currentInner?.currencyCode;
                        const existingObj = accInner?.find((item) => item.currency === currentCurrency);
                        if (existingObj) {
                            existingObj.amount += Number(currentInner?.total_sale_cost || currentInner?.amount);
                        } else {
                            accInner.push({ currency: currentCurrency, amount: Number(currentInner?.total_sale_cost || currentInner?.amount) });
                        }
                        return accInner;
                    }, []);
                    const header = headerMapping[currentOuter?.header];
                    const exObj = result?.find(obj => obj.header === header);
                    if (exObj) {
                        exObj.data = exObj?.data.concat(newArray);
                    } else {
                        result.push({ header, data: newArray });
                    }
                }
            }
            return result;
        }, []).map((item) => ({
            ...item,
            data: item?.data?.reduce((acc, current) => {
                const exObj = acc?.find(obj => obj.currency === current?.currency);
                if (exObj) {
                    exObj.amount += Number(current?.amount);
                } else {
                    acc.push({ currency: current?.currency, amount: current?.amount });
                }
                return acc;
            }, [])
        })).reduce((finalResult, item) => {
            item.data.forEach((dataItem) => {
                const existingCurrency = finalResult?.find((resultItem) => resultItem.currency === dataItem.currency);
                if (existingCurrency) {
                    existingCurrency[item.header] = dataItem.amount;
                } else {
                    finalResult.push({ currency: dataItem.currency, [item.header]: dataItem.amount });
                }
            });
            return finalResult;
        }, []);
    };

    const filteredArray = processTariffDetails(data?.tariffDetails);
    const filteredArrayNew = processTariffDetails(newData?.tariffDetails, 'new');

    // console.log(filteredArray, "filteredArray");
    // console.log(filteredArrayNew, "filteredArrayNew");

    const mergedMap = new Map();
    const updateMap = (currency, origin, destination) => {
        if (mergedMap.has(currency)) {
            const existingEntry = mergedMap.get(currency);
            existingEntry.origin += origin;
            existingEntry.destination += destination;
        } else {
            mergedMap.set(currency, { currency, origin, destination });
        }
    };

    filteredArray?.forEach(({ currency, origin, destination }) => {
        updateMap(currency, origin || 0, destination || 0);
    });

    filteredArrayNew?.forEach(({ currency, origin, destination }) => {
        updateMap(currency, origin || 0, destination || 0);
    });
    // console.log(mergedMap,"mergedMap");

    // Convert the merged map values back to an array
    const mergedArray = [...mergedMap.values()];
    return mergedArray;
}

export const generatePDF = (quoteData, tenant_info, quoteSelectedTenantdata, totalAmt ,currencyCode) => {
    const doc = new jsPDF("p", "mm", "a4");
    let currentY = 10;

    doc.setFontSize(10);
    doc.text(`Issue Date: ${quoteSelectedTenantdata?.createdDate ? formatDate(quoteSelectedTenantdata?.createdDate) : new Date().toLocaleDateString()}`, 140, currentY + 5);
    doc.text(`Quotation: ${quoteSelectedTenantdata?.sequenceId || "-"}`, 140, currentY + 10);

    if (tenant_info?.logo) {
        doc.addImage(tenant_info?.logo, "PNG", 10, currentY, 30, 10);
    }

    currentY += 15;
    doc.line(10, currentY, 200, currentY);
    const customerBoxHeight = 15;
    doc.setFontSize(12);
    doc.rect(10, currentY, 190, customerBoxHeight);
    doc.text("Customer Details:", 12, currentY + 7);
    doc.setFontSize(10);
    doc.text(quoteData.tenantCustomerName || "-", 12, currentY + 12);
    currentY += customerBoxHeight + 2;

    const boxHeight = 20;
    doc.setFontSize(10);
    doc.rect(10, currentY, 90, boxHeight);
    doc.text("Origin:", 12, currentY + 5);
    doc.text(`Port: ${quoteData.originName || "-"}`, 12, currentY + 10);
    doc.text(`Detention Free (days): ${quoteData.detentionFreeOrg || "-"}`, 12, currentY + 15);

    doc.rect(110, currentY, 90, boxHeight);
    doc.text("Destination:", 112, currentY + 5);
    doc.text(`Port: ${quoteData.destinationName || "-"}`, 112, currentY + 10);
    doc.text(`Detention Free (days): ${quoteData.detentionFreeDest || "-"}`, 112, currentY + 15);

    currentY += boxHeight + 3;

    doc.line(10, currentY, 200, currentY);

    currentY += 5;
    doc.setFillColor(220, 220, 220);
    doc.rect(14, currentY, 190, 8, "F");
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text("COMMODITIES", 14, currentY + 6);
    currentY += 10;

    const commodities = quoteData.commodities || [["-", "-", "-", "-"]];
    autoTable(doc, {
        head: [["Description", "Package Type", "Gross Weight", "Volume (CBM)"]],
        body: commodities.map((item) => [
            item.description || "-",
            item.packageType || "-",
            item.grossWeight || "-",
            item.volume || "-",
        ]),
        startY: currentY,
        // theme: "grid",
        columnStyles: {
            0: { cellWidth: 50, halign: 'left' },
            1: { cellWidth: 50, halign: 'left' },
            2: { cellWidth: 45, halign: 'right' },
            3: { cellWidth: 45, halign: 'right' },
        }
    });
    currentY = doc.previousAutoTable.finalY + 5;

    doc.setFontSize(10);
    doc.text(`Carrier: ${quoteData.carrierName || "-"}`, 14, currentY);
    doc.text(`Valid From: ${quoteData.validFrom || "-"}  To: ${quoteData.validTo || "-"}`, 140, currentY);
    currentY += 3;


    autoTable(doc, {
        head: [["Charge Name", "Charge Basis", "Currency", "Quantity", "Total Cost"]],
        startY: currentY,
        body: [[]],
        columnStyles: {
            0: { cellWidth: 60, halign: 'left' },
            1: { cellWidth: 45, halign: 'center' },
            2: { cellWidth: 30, halign: 'center' },
            3: { cellWidth: 25, halign: 'right' },
            4: { cellWidth: 30, halign: 'right' },
        }
    });
    currentY += 10;
    quoteData.tariffDetails?.forEach((tariff) => {
        doc.setFillColor(240, 240, 240);
        doc.rect(14, currentY, 190, 8, "F");
        doc.setFontSize(10);
        doc.text(tariff.header?.split("_").join(" ") || "Tariff Header", 14, currentY + 6);
        currentY += 10;

        autoTable(doc, {
            head: [],
            body:
                tariff.fclTariffBreakDowns?.map((breakdown) => [
                    `${breakdown.component || "-"}-${breakdown.containerDetail || "-"}`,
                    breakdown.uomCode?.split("_").join(" ") || "-",
                    breakdown.currencyCode || "INR",
                    breakdown.unit || "0",
                    breakdown.total_sale_cost || "0",
                ]) || [["As Per Carrier Tariff At Actuals.", "-", "-", "-", "-"]],
            startY: currentY,
            columnStyles: {
                0: { cellWidth: 60, halign: 'left' },
                1: { cellWidth: 45, halign: 'center' },
                2: { cellWidth: 30, halign: 'center' },
                3: { cellWidth: 25, halign: 'right' },
                4: { cellWidth: 30, halign: 'right' },
            }
        });
        currentY = doc.previousAutoTable.finalY + 3;
    });

    doc.line(10, currentY, 200, currentY);
    doc.text("Remarks", 12, currentY + 5);
    doc.text(` ${quoteData.remarks || "-"}`, 12, currentY + 10);
    currentY += 15;
    const array = totalAmtArry(quoteData, [])

    autoTable(doc, {
        head: [["", "Origin", "Destination"]],
        body: array?.map((item) => [
            item.currency || "-",
            item.origin || "INR",
            item.destination || "0"
        ]),
        startY: currentY,
    });
    currentY = doc.previousAutoTable.finalY + 5;

    doc.setFontSize(12);
    doc.text(`Total: ${currencyCode} ${totalAmt || "0"}`, 165, currentY + 5);
    doc.save(quoteSelectedTenantdata?.inquiryDetailId + ".pdf");
};

export const generatePDFPreview2 = (quoteData, tenant_info) => {
    const doc = new jsPDF("p", "mm", "a4");
    let currentY = 10;

    // Header Section
    if (tenant_info?.logo) {
        doc.addImage(sitelogo, "PNG", 10, currentY, 30, 10);
    }
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 255); // Blue text for title
    doc.text("Quotation Document", 90, currentY + 7);
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // Black text for normal
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 170, currentY + 5, { align: "right" });

    currentY += 15;

    // Greeting Section
    doc.setFontSize(10);
    doc.text("Dear Lokesh James Bond,", 10, currentY);
    currentY += 5;
    doc.text(
        "Thank you for your recent enquiry. tarifftales is pleased to make you the following offer,",
        10,
        currentY
    );
    currentY += 5;
    doc.text("for which please find our rates and further details below.", 10, currentY);
    currentY += 10;
    doc.text(`Offer expires on: ${quoteData.expiryDate || "N/A"}`, 10, currentY);
    currentY += 10;
    doc.text(
        "Our quotation is valid until the above mentioned offer expiry date. We reserve the right to",
        10,
        currentY
    );
    currentY += 5;
    doc.text(
        "review and re-quote, if we do not receive any booking prior to above mentioned offer expiry date.",
        10,
        currentY
    );
    currentY += 15;

    // CommoditiesForm Table
    doc.setFillColor(220, 220, 220); // Light grey background
    doc.rect(14, currentY, 190, 8, "F");
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // Reset text color
    doc.text("COMMODITIES", 14, currentY + 6);
    currentY += 10;

    const commodities = quoteData.commodities || [["-", "-", "-", "-"]];
    autoTable(doc, {
        head: [["Description", "Package Type", "Gross Weight", "Volume (CBM)"]],
        body: commodities.map((item) => [
            item.description || "-",
            item.packageType || "-",
            item.grossWeight || "-",
            item.volume || "-",
        ]),
        startY: currentY,
        // theme: "grid",
        columnStyles: {
            0: { cellWidth: 50, halign: 'left' },
            1: { cellWidth: 50, halign: 'left' },
            2: { cellWidth: 45, halign: 'right' },
            3: { cellWidth: 45, halign: 'right' },
        }
    });
    currentY = doc.previousAutoTable.finalY + 5;

    // Tariff Details
    autoTable(doc, {
        head: [["Charge Name", "Charge Basis", "Currency", "Quantity", "Total Cost"]],
        startY: currentY,
        // theme: "grid",
        body: [[]],
        columnStyles: {
            0: { cellWidth: 60, halign: 'left' },
            1: { cellWidth: 45, halign: 'center' },
            2: { cellWidth: 30, halign: 'center' },
            3: { cellWidth: 25, halign: 'right' },
            4: { cellWidth: 30, halign: 'right' },
        },
    });
    currentY += 10;

    quoteData.tariffDetails?.forEach((tariff) => {
        doc.setFillColor(240, 240, 240); // Light grey background
        doc.rect(14, currentY, 190, 8, "F");
        doc.setFontSize(10);
        doc.text(tariff.header?.split("_").join(" ") || "Tariff Header", 14, currentY + 6);
        currentY += 10;

        autoTable(doc, {
            head: [],
            body:
                tariff.fclTariffBreakDowns?.map((breakdown) => [
                    `${breakdown.component || "-"}-${breakdown.containerDetail || "-"}`,
                    breakdown.uomCode?.split("_").join(" ") || "-",
                    breakdown.currencyCode || "INR",
                    breakdown.unit || "0",
                    breakdown.total_sale_cost || "0",
                ]) || [["As Per Carrier Tariff At Actuals.", "-", "-", "-", "-"]],
            startY: currentY,
            // theme: "grid",
            columnStyles: {
                0: { cellWidth: 60, halign: 'left' },
                1: { cellWidth: 45, halign: 'center' },
                2: { cellWidth: 30, halign: 'center' },
                3: { cellWidth: 25, halign: 'right' },
                4: { cellWidth: 30, halign: 'right' },
            },
        });
        currentY = doc.previousAutoTable.finalY + 3;
    });

    doc.setFontSize(10);
    doc.setFillColor(220, 220, 220);
    doc.rect(14, currentY, 190, 8, "F");
    doc.text("TOTAL SUMMARY", 14, currentY + 6);
    currentY += 10;

    const totals = [
        ["Total BDT", quoteData.totalBDT?.origin || "0", quoteData.totalBDT?.destination || "0"],
        ["Total INR", quoteData.totalINR?.origin || "0", quoteData.totalINR?.destination || "0"],
        ["Total USD", quoteData.totalUSD?.origin || "0", quoteData.totalUSD?.destination || "0"],
    ];

    autoTable(doc, {
        head: [["", "Origin", "Destination"]],
        body: totals,
        startY: currentY,
        // theme: "grid",
        columnStyles: {
            0: { cellWidth: 90, halign: 'left' },
            1: { cellWidth: 50, halign: 'right' },
            2: { cellWidth: 50, halign: 'right' },
        }
    });
    currentY = doc.previousAutoTable.finalY + 8;

    // Sub Total and Final Total
    doc.setFontSize(12);
    doc.text(`Sub Total: ${quoteData.subTotal || "N/A"}`, 140, currentY);
    doc.text(`Total: ${quoteData.totalCost || "N/A"}`, 140, currentY + 5);

    // Save PDF
    doc.save("quotation.pdf");
};

const mergeTariffDetails = (firstObject, secondArray) => {
    if (typeof firstObject !== "object" || firstObject === null) {
        console.error("Error: firstObject is not a valid object", firstObject);
        return {};
    }
    if (!Array.isArray(secondArray)) {
        console.error("Error: secondArray is not an array", secondArray);
        return firstObject;
    }

    // Create a map from secondArray using quote_id
    const quoteMap = secondArray.reduce((acc, quote) => {
        acc[quote.id] = quote.tariffDetails;
        return acc;
    }, {});

    // Clone the first object to avoid mutations
    let updatedObject = { ...firstObject };

    // Check if quote_id exists in quoteMap
    if (quoteMap[firstObject.quote_id]) {
        updatedObject.tariffDetails = updatedObject.tariffDetails.map(firstTariff => {
            const matchingTariff = quoteMap[firstObject.quote_id]?.find(
                secondTariff => secondTariff.header === firstTariff.header
            );

            if (matchingTariff) {
                return {
                    ...firstTariff,
                    fclTariffBreakDowns: [
                        ...firstTariff.fclTariffBreakDowns,
                        ...matchingTariff.fclTariffBreakDowns
                    ]
                };
            }
            return firstTariff;
        });
    }

    return updatedObject;
};

export const GeneratePDFRender = ({quoteData, tenant_info, customerInfo, quoteSelectedTenantdata, totalAmt ,currencyCode, mainChargeObj}) => {
    quoteData = mergeTariffDetails(quoteData, mainChargeObj);
    const totalAmountBreakdown = totalAmtArry(quoteData, [])
    const commodities = quoteData.commodities || [["-", "-", "-", "-"]];
    //console.log(quoteData, "quoteData");
    //console.log(mainChargeObj, "quoteData.tariffDetails.mainChargeObj");
    return(
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <View wrap={false} style={styles.section} >
                    <div style={{flexDirection: "row"}}>
                        <div style={{flexDirection: "column", flex: 1}}>
                            <Text style={{fontSize: '10'}}>Issue
                                Date: {quoteSelectedTenantdata?.createdDate ? formatDate(quoteSelectedTenantdata?.createdDate) : new Date().toLocaleDateString()}</Text>
                            <Text style={{
                                fontSize: '10',
                                marginTop: '5px'
                            }}>Quotation: {quoteSelectedTenantdata?.sequenceId || "-"}</Text>
                        </div>
                        <div style={{
                            flexDirection: "column",
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "flex-end",
                        }}>
                            <Image
                                src={tenant_info?.logo || sitelogo}
                                alt="" style={{height: '44px'}}/>
                        </div>
                    </div>
                    <div style={{backgroundColor: "white", borderRadius: "5px", marginTop: "5px", marginBottom:"5px", marginLeft: '0px', border:1,
                        borderWidth: 1,
                        borderColor:"#cccccc" }}>
                        <div style={{marginLeft: '10px', marginTop: '10px', marginBottom: '10px'}}>
                            <Text style={{fontSize: '10'}}>Customer Details</Text>
                            <Text style={{fontSize: '10'}}>{quoteData.tenantCustomerName || customerInfo?.name || "-"}</Text>
                            <Text style={{fontSize: '10'}}>{ quoteData?.tenantCustomerAddress ||customerInfo?.address || '-'}</Text>
                        </div>
                    </div>
                    <div style={{flexDirection: "row", width: "100%"}}>
                        <div style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            marginTop: "5px",
                            marginRight: '5px',
                            flex: 1,
                            border:1,
                            borderWidth: 1,
                            borderColor:"#cccccc"
                        }}>
                            <div style={{marginLeft: '10px', marginTop: '10px'}}>
                                <Text style={{fontSize: '10'}}>Origin:</Text>
                                <Text style={{fontSize: '10', fontWeight: "light", fontStyle: 'normal'}}>Port</Text>
                                <Text style={{fontSize: '10'}}>{quoteData.originName || "-"}</Text>
                                <Text style={{
                                    fontSize: '10',
                                    marginTop: '5px',
                                    marginBottom: '5px'
                                }}>Detention Free(days)</Text>
                                <Text style={{marginBottom: '5px', fontSize: 10}}>{quoteData.detentionFreeOrg || "-"}</Text>
                            </div>
                        </div>
                        <div style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            marginTop: "5px",
                            marginLeft: '5px',
                            flex: 1,
                            border:1,
                            borderWidth: 1,
                            borderColor:"#cccccc"
                        }}>
                            <div style={{marginLeft: '10px', marginTop: '10px'}}>
                                <Text style={{fontSize: '10'}}>Destination:</Text>
                                <Text style={{fontSize: '10', fontWeight: "light", fontStyle: 'normal'}}>Port</Text>
                                <Text style={{fontSize: '10'}}>{quoteData.destinationName || "-"}</Text>
                                <Text style={{
                                    fontSize: '10',
                                    marginTop: '5px',
                                    marginBottom: '5px'
                                }}>Detention Free(days)</Text>
                                <Text style={{marginBottom: '5px', fontSize:'10'}}>{quoteData.detentionFreeDest || "-"}</Text>
                            </div>
                        </div>
                    </div>
                    <div style={{backgroundColor: "#F0F2F4", width: "100%", marginTop: '10px', borderRadius: "5px", border:1, borderWidth: 1, borderColor:"#cccccc"}}>
                        <Text style={{padding: '5px', fontSize: '10', fontWeight:"bold"}}>COMMODITIES</Text>
                        <div style={{backgroundColor: "white", padding: '5px'}}>
                            <div style={{padding: '0px', flexDirection: "row", margin: '5px'}}>
                                <Text style={{fontSize: '10', flex: 1}}>Package Type</Text>
                                <Text style={{fontSize: '10', flex: 1}}>Gross Weight</Text>
                                <Text style={{fontSize: '10', flex: 1}}>Volume(CBM)</Text>
                            </div>
                        </div>
                        <div style={{
                            backgroundColor: "white",
                            padding: '5px',
                            borderBottomRightRadius: "5px",
                            borderBottomLeftRadius: "5px",
                        }}>
                            <div style={{padding: '0px', flexDirection: "row", margin: '5px'}}>
                                {
                                    commodities.map((item, index) => [
                                        <Text style={{fontSize: '10', flex: 1,}}
                                              key={index + 1}>{item.description || "-"}</Text>,
                                        <Text style={{fontSize: '10', flex: 1,}}
                                              key={index + 2}>{item.packageType || "-"}</Text>,
                                        <Text style={{fontSize: '10', flex: 1,}}
                                              key={index + 3}>{item.grossWeight || "-"}</Text>,
                                        <Text style={{fontSize: '10', flex: 1,}}
                                              key={index + 4}>{item.volume || "-"}</Text>,
                                    ])
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{flexDirection: "column", marginTop: '10px'}}>
                        <div style={{flexDirection: "row"}}>
                            <div style={{flex: 0.5, flexDirection: "row"}}>
                                <Text style={{fontSize: '10'}}>Carrier :</Text>
                                <Text style={{fontSize: '10'}}>{quoteData.carrierName || "-"}</Text>
                            </div>
                            <div style={{flex: 1, flexDirection: "row", justifyContent: "flex-end"}}>
                                <Text style={{fontSize: '10'}}>Valid From
                                    : {quoteData.validFrom || "-"} To: {quoteData.validTo || "-"}</Text>
                            </div>
                        </div>
                        <div style={{
                            border: 1,
                            borderWidth: 1,
                            borderRadius: "5px",
                            borderColor: "#cccccc",
                            width:"100%",
                            marginTop:"5px",}}>
                            <div style={{backgroundColor: "#F0F2F4", width: "100%", marginTop: '10px',}}>
                                <div style={{flexDirection: "row", backgroundColor: "white", padding: '5px'}}>
                                    <Text style={{fontSize: '10', flex: 1}}>Charge Name</Text>
                                    <Text style={{fontSize: '10', flex: 1}}>Charge Basis</Text>
                                    <Text style={{fontSize: '10', flex: 1}}>Currency</Text>
                                    <Text style={{fontSize: '10', flex: 1}}>Quantity</Text>
                                    <Text style={{fontSize: '10', flex: 1, width:"100%", textAlign:"center"}}>Total Cost</Text>
                                </div>
                            </div>
                            {
                                quoteData.tariffDetails?.map((tariff, index) => {
                                    if (tariff?.selected) {
                                        return (
                                            <>
                                                <div
                                                    style={{backgroundColor: "#F0F2F4", width: "100%", marginTop: '10px',}}>
                                                    <Text style={{fontSize: 10, margin: '5px'}}
                                                          key={index + 1}>{tariff.header?.split("_").join(" ") || "Tariff Header"}</Text>

                                                    {tariff.fclTariffBreakDowns?.lenght !== 0 && tariff.fclTariffBreakDowns?.map((breakdown, index) => [
                                                        <div
                                                            style={{flexDirection: "row", backgroundColor: "white", padding: '5px'}}
                                                            key={index + 2}>
                                                            <Text style={{fontSize: '10', flex: 1}}
                                                                  key={index + 3}>
                                                                {typeof breakdown.component === "object" ? breakdown.component.value || "-" : breakdown.component || breakdown.containerDetail || "-"}
                                                            </Text>,
                                                            <Text style={{fontSize: '10', flex: 1}}
                                                                  key={index + 4}>
                                                                {typeof breakdown.uomCode === "object" ? breakdown.uomCode.description || "-" : breakdown.uomCode?.split("_").join(" ") || "-"}
                                                            </Text>,
                                                            <Text style={{fontSize: '10', flex: 1}}
                                                                  key={index + 5}>{breakdown.currencyCode || "INR"}</Text>,
                                                            <Text style={{fontSize: '10', flex: 1}}
                                                                  key={index + 6}>{breakdown.unit || "0"}</Text>,
                                                            <Text style={{fontSize: '10', flex: 1, width:"100%", textAlign:"right"}}
                                                                  key={index + 7}>{breakdown.total_sale_cost || "0"}</Text>,
                                                        </div>
                                                    ])}
                                                    {
                                                        tariff.fclTariffBreakDowns?.length === 0 &&
                                                        <>
                                                            <div style={{flexDirection: "row", backgroundColor: "white", padding: '5px'}}
                                                                 key={index + 2}>
                                                                <Text style={{fontSize: '10', flex: 1}}
                                                                      key={index + 3}>As Per Carrier Tariff At Actuals.</Text>,
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div style={{backgroundColor: "#F0F2F4", width: "100%", marginTop: '10px', border: 1, borderWidth: 1, borderColor:"#cccccc", borderRadius:"5px"}}>
                            <Text style={{fontSize: 13, margin: '5px'}}>Remarks</Text>
                            <div style={{flexDirection: "row", backgroundColor: "white", padding: '5px'}}>
                                <Text style={{fontSize: '10', flex: 1}}>-</Text>
                            </div>
                        </div>
                    </div>
                </View>
                <View wrap={false} style={styles.section}>
                    <div style={{flexDirection: "row"}}>
                        <div style={{flexDirection: "column", flex: 1, border:1, borderRadius: '5px'}}>
                            <div style={{backgroundColor: "#F0F2F4", width: "100%", marginTop: '0px', borderRadius: "5px"}}>
                                <div style={{
                                    flexDirection: "row",
                                    backgroundColor: "#F0F2F4",
                                    width: "100%",
                                    padding: '2px'
                                }}>
                                    <Text style={{fontSize: '10', flex: 1}}>TOTAL</Text>
                                    <Text style={{fontSize: '10', flex: 1, width:"100%", textAlign:"center"}}>ORIGIN</Text>
                                    <Text style={{fontSize: '10', flex: 1, width:"100%", textAlign:"center"}}>DESTINATION</Text>
                                </div>
                                {
                                    totalAmountBreakdown.map((item, index) => {
                                        return (
                                            <div style={{
                                                flexDirection: "row",
                                                backgroundColor: "white",
                                                width: "100%",
                                                padding: '5px'
                                            }} key={'divindex-'+index}>
                                                <Text style={{fontSize: '10', flex: 1}} key={'index-'+index}>TOTAL {item.currency} :</Text>
                                                <Text style={{fontSize: '10', flex: 1, width:"100%", textAlign:"right"}} key={'index-'+index+item.origin}>{item.origin.toFixed(2)}</Text>
                                                <Text style={{fontSize: '10', flex: 1, width:"100%", textAlign:"right"}} key={'index-'+index+item.destination}>{item.destination.toFixed(2)}</Text>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div style={{
                            flexDirection: "column",
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "flex-end",
                        }}>
                            <Text style={{fontSize: '10', justifyContent: "flex-end", fontWeight: "bold"}}>Sub Total
                                : {currencyCode} {totalAmt || "0"}</Text>
                            <Text style={{
                                fontSize: '10',
                                justifyContent: "flex-end",
                                fontWeight: "bold",
                                marginTop: '5px',
                                color: "blue"
                            }}>Total : {currencyCode} {totalAmt || "0"}</Text>
                        </div>
                    </div>
                </View>
                <Image
                    src="https://demo-app.tarifftales.com/static/media/preview_shape.1ce25261d403af688a23.png"
                    style={styles.pageBackground}
                />
            </Page>
        </Document>
    );
}

export const GeneratePDFRenderOne = ({quoteData, tenant_info, customerInfo, quoteSelectedTenantdata, totalAmt , currencyCode, mainChargeObj}) => {
    quoteData = mergeTariffDetails(quoteData, mainChargeObj);
    const totalAmountBreakdown = totalAmtArry(quoteData, [])
    const commodities = quoteData.commodities || [["-", "-", "-", "-"]];
    //console.log(tenant_info, "tenantInfo");
    //console.log(quoteData, "quoteData");
    return(
        <Document>
            <Page size="A4" style={pdfStyles.page} wrap>
                <View style={pdfStyles.section}>
                    <div style={{flexDirection: "row", flex: 1}}>
                        <div style={pdfStyles.leftSection}>
                            <div style={pdfStyles.leftBorder}>
                                <div style={{flexDirection: "column", marginBottom: 10}}>
                                    <Text style={{fontSize: 10}}>Quotation number</Text>
                                    <Text
                                        style={{
                                            fontSize: 10,
                                            marginTop: 5,
                                            fontFamily: 'Times-Bold', fontWeight: 700, color: "#0047ff"
                                        }}>{quoteSelectedTenantdata?.sequenceId || "-"}</Text>
                                </div>
                                <div style={{flexDirection: "column", marginTop: 5, marginBottom: 10}}>
                                    <Text style={{fontSize: 10}}>Service</Text>
                                    <Text style={{fontSize: 10, marginTop: 5, fontFamily: 'Times-Bold', fontWeight: 700, color: "#000000"}}>1 of 1</Text>
                                </div>
                                <div style={{flexDirection: "column", marginTop: 5, marginBottom: 10}}>
                                    <Text style={{fontSize: 10}}>COMMODITIES</Text>
                                    <Text style={{fontSize: 10, marginTop: 5}}>-</Text>
                                </div>
                                <div style={{flexDirection: "row", marginTop: 5, marginBottom: 10}}>
                                    <div style={{flex: 1, flexDirection: "column"}}>
                                        <Text style={{fontSize: 10}}>Valid From</Text>
                                        <Text style={{
                                            fontSize: 10,
                                            fontFamily: 'Times-Bold', fontWeight: 700, color: "#000000",
                                            marginTop: 5
                                        }}>{quoteData.validFrom ? formatDate(quoteData.validFrom) : "-"}</Text>
                                    </div>
                                    <div style={{flex: 1, flexDirection: "column"}}>
                                        <Text style={{fontSize: 10}}>Valid To</Text>
                                        <Text style={{
                                            fontSize: 10,
                                            fontFamily: 'Times-Bold', fontWeight: 700, color: "#000000",
                                            marginTop: 5
                                        }}>{quoteData.validTo ? formatDate(quoteData.validTo) : "-"}</Text>
                                    </div>
                                </div>
                                <div style={{border: 0.5, borderColor: "#000", width: '100%'}}/>
                                <div style={{flexDirection: "column", marginTop: 5, marginBottom: 10}}>
                                    <Text style={{fontSize: 10, marginTop: 5}}>From</Text>
                                    <Text style={{
                                        fontSize: 10,
                                        marginTop: 5,
                                        color: "blue",
                                        fontFamily: 'Times-Bold', fontWeight: 700
                                    }}>{quoteData.originName || "-"}</Text>
                                    <Text style={{fontSize: 10, marginTop: 5}}>PORT</Text>
                                </div>
                                <div style={{flexDirection: "column", marginTop: 5, marginBottom: 10}}>
                                    <Text style={{fontSize: 10, marginTop: 5}}>To</Text>
                                    <Text style={{
                                        fontSize: 10,
                                        marginTop: 5,
                                        color: "blue",
                                        fontFamily: 'Times-Bold', fontWeight: 700
                                    }}>{quoteData.destinationName || "-"}</Text>
                                    <Text style={{fontSize: 10, marginTop: 5}}>PORT</Text>
                                </div>
                                <div style={{flexDirection: "column", marginTop: 5, marginBottom: 20}}>
                                    <Text style={{fontSize: 10, marginTop: 2}}>Estimated Transportation Days</Text>
                                    <Text
                                        style={{fontSize: 10, marginTop: 2, fontFamily: 'Times-Bold', fontWeight: 700, color: "#000000"}}>{quoteData.oceanTransitTime || "0"}</Text>
                                </div>
                            </div>
                        </div>
                        <div style={pdfStyles.rightSection}>
                            <div style={{flexDirection: "row", width: "100%"}}>
                                <div style={{flexDirection: "column", flex: 2, width: "100%"}}>
                                    <Text style={{fontSize: 17, width: "100%", fontFamily: 'Times-Bold', fontWeight: 1000, color: "#000000",}}>Quotation Document</Text>
                                    <Text style={{
                                        fontSize: 10,
                                        marginTop: '5px'
                                    }}>{quoteData?.tenantCustomerAddress || customerInfo?.address || '-'}</Text>
                                </div>
                                <div style={{
                                    flexDirection: "column",
                                    flex: 1,
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                }}>
                                    <Image
                                        src={tenant_info?.logo || sitelogo}
                                        alt="" style={{height: '44px'}}/>
                                    <Text style={{
                                        fontSize: 10,
                                        marginTop: '5px',
                                        marginLeft: '15px'
                                    }}>{quoteSelectedTenantdata?.createdDate ? formatDate(quoteSelectedTenantdata?.createdDate) : new Date().toLocaleDateString()}</Text>
                                </div>
                            </div>
                            <div style={{borderRadius: "5px", marginTop: "5px", marginLeft: '0px'}}>
                                <div style={{marginLeft: '10px', marginTop: '10px', flexDirection: "column"}}>
                                    <div style={pdfStyles.row}>
                                        <Text style={{
                                            fontSize: 10,
                                            fontWeight: 500,
                                        }}>Dear </Text>
                                        <Text style={{fontSize: 11, fontFamily: 'Times-Bold', fontWeight: 800, color: "#000000"}} >{quoteData.tenantCustomerName || customerInfo?.name || "-"},</Text>
                                    </div>
                                    <div style={pdfStyles.row}>
                                        <Text style={{fontSize: 10, fontWeight: 500, marginTop: 15}}>Thank you for your recent enquiry.
                                            {tenant_info?.name &&
                                                <Text style={{fontSize: 11, marginTop: 15, fontFamily: 'Times-Bold', fontWeight: "ultrabold", color: "#000000", marginLeft:5}}>{tenant_info?.name || "-"}</Text>
                                            } is pleased to make you the following offer, for which please find
                                            our rates and further details below.</Text>
                                    </div>

                                    <div style={pdfStyles.row}>
                                        <Text style={{fontSize: 10, fontWeight: 500, marginTop: 15}}>Offer expires on: </Text>
                                        <Text style={{fontSize: 10, fontFamily: 'Times-Bold', fontWeight: 800, color: "#000000", marginTop: 15}}>{quoteData.validTo ? formatDate(quoteData.validTo) : "-"}</Text>
                                    </div>

                                    <Text style={{fontSize: 10, fontWeight: 500, marginTop: 15, marginBottom: 15}}>Our
                                        quotation is valid until the above mentioned offer expiry date. We reserve the
                                        right to
                                        review and re-quote, if we do not receive any booking prior to above mentioned
                                        offer
                                        expiry date.
                                    </Text>
                                </div>
                            </div>
                            <div style={{backgroundColor: "#F0F2F4",
                                width: "100%",
                                marginTop: '10px',
                                borderRadius: "5px",
                                border: 1, borderWidth:1, borderColor: "#cccccc",}}>
                                <Text style={{padding: '5px', fontSize: '10'}}>COMMODITIES</Text>
                                <div style={{backgroundColor: "white", padding: '5px'}}>
                                    <div style={{padding: '0px', flexDirection: "row", margin: '5px'}}>
                                        <Text style={{fontSize: 10, flex: 1}}>Description</Text>
                                        <Text style={{fontSize: 10, flex: 1}}>Package Type</Text>
                                        <Text style={{fontSize: 10, flex: 1}}>Gross Weight</Text>
                                        <Text style={{fontSize: 10, flex: 1}}>Volume(CBM)</Text>
                                    </div>
                                </div>
                                <div style={{
                                    backgroundColor: "white",
                                    padding: '5px',
                                    borderBottomRightRadius: "5px",
                                    borderBottomLeftRadius: "5px",
                                }}>
                                    <div style={{padding: '0px', flexDirection: "row", margin: '5px'}}>
                                        {
                                            commodities.map((item, index) => [
                                                <Text style={{fontSize: 10, flex: 1,}}
                                                      key={index + 1}>{item.description || "-"}</Text>,
                                                <Text style={{fontSize:10, flex: 1,}}
                                                      key={index + 2}>{item.packageType || "-"}</Text>,
                                                <Text style={{fontSize: 10, flex: 1,}}
                                                      key={index + 3}>{item.grossWeight || "-"}</Text>,
                                                <Text style={{fontSize: 10, flex: 1,}}
                                                      key={index + 4}>{item.volume || "-"}</Text>,
                                            ])
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{flexDirection: "column", marginTop: '5px'}}>
                                <div style={{flexDirection: "row"}}>
                                    <div style={{flex: 0.5, flexDirection: "row"}}>
                                        <Text style={{fontSize: 10}}>Carrier :</Text>
                                        <Text style={{fontSize: 10}}>{quoteData.carrierName || "-"}</Text>
                                    </div>
                                </div>
                                <div style={{border: 1, borderWidth: 1, borderColor: "#cccccc", borderRadius: "5px", marginTop: '10px',}}>
                                    <div style={{backgroundColor: "#F0F2F4", width: "100%",}}>
                                        <div style={{flexDirection: "row", backgroundColor: "white", padding: '5px'}}>
                                            <Text style={{fontSize: '10', flex: 1}}>Charge Name</Text>
                                            <Text style={{fontSize: '10', flex: 1}}>Charge Basis</Text>
                                            <Text style={{fontSize: '10', flex: 1}}>Currency</Text>
                                            <Text style={{fontSize: '10', flex: 1}}>Quantity</Text>
                                            <Text style={{fontSize: '10', flex: 1, width: "100%", textAlign: "center"}}>Total
                                                Cost</Text>
                                        </div>
                                    </div>
                                    <div style={{backgroundColor: "#F0F2F4", width: "100%", marginTop: '0px',}}>
                                        {
                                            quoteData.tariffDetails?.map((tariff, index) => {
                                                if (tariff?.selected) {
                                                    return (
                                                        <>
                                                            <div
                                                                style={{
                                                                    backgroundColor: "#F0F2F4",
                                                                    width: "100%",
                                                                    marginTop: '5px',
                                                                }}>
                                                                <Text style={{fontSize: 10, margin: '5px'}}
                                                                      key={index + 1}>{tariff.header?.split("_").join(" ") || "Tariff Header"}</Text>
                                                                {tariff?.fclTariffBreakDowns?.lenght !== 0 && tariff?.fclTariffBreakDowns?.map((breakdown, index) => [
                                                                    <div
                                                                        style={{
                                                                            flexDirection: "row",
                                                                            backgroundColor: "white",
                                                                            padding: '5px'
                                                                        }}
                                                                        key={index + 2}>
                                                                        <Text style={{fontSize: '10', flex: 1}}
                                                                              key={index + 3}>
                                                                            {typeof breakdown.component === "object" ? breakdown.component.value || "-" : breakdown.component || breakdown.containerDetail || "-"}
                                                                        </Text>,
                                                                        <Text style={{fontSize: '10', flex: 1}}
                                                                              key={index + 4}>
                                                                            {typeof breakdown.uomCode === "object" ? breakdown.uomCode.description || "-" : breakdown.uomCode?.split("_").join(" ") || "-"}
                                                                        </Text>,
                                                                        <Text style={{fontSize: 10, flex: 1}}
                                                                              key={index + 5}>{breakdown.currencyCode || "INR"}</Text>,
                                                                        <Text style={{fontSize: 10, flex: 1}}
                                                                              key={index + 6}>{breakdown.unit || "0"}</Text>,
                                                                        <Text style={{
                                                                            fontSize: 10,
                                                                            flex: 1,
                                                                            width: '100%',
                                                                            textAlign: "right"
                                                                        }}
                                                                              key={index + 7}>{breakdown.total_sale_cost || "0"}</Text>,
                                                                    </div>
                                                                ])}
                                                                {
                                                                    tariff.fclTariffBreakDowns?.length === 0 &&
                                                                    <>
                                                                        <div style={{
                                                                            flexDirection: "row",
                                                                            backgroundColor: "white",
                                                                            padding: '5px'
                                                                        }}
                                                                             key={index + 2}>
                                                                            <Text style={{fontSize: 10, flex: 1}}
                                                                                  key={index + 3}>As Per Carrier Tariff
                                                                                At
                                                                                Actuals.</Text>,
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>

                                <View break>
                                    <div style={{backgroundColor: "#F0F2F4", width: "100%", marginTop: '10px',}}>
                                        <Text style={{fontSize: 10, margin: '5px'}}>Remarks</Text>
                                        <div style={{flexDirection: "row", backgroundColor: "#F0F2F4", padding: '5px'}}>
                                            <Text style={{fontSize: 10, flex: 1}}>-</Text>
                                        </div>
                                    </div>
                                </View>
                            </div>
                            <View break>
                                <div style={{flexDirection: "row", marginTop: 5}}>
                                <div style={{flexDirection: "column", flex: 1}}>
                                        <div style={{
                                            backgroundColor: "#F0F2F4",
                                            width: "100%",
                                            marginTop: '5px',
                                            borderRadius: "5px"
                                        }}>
                                            <div style={{
                                                flexDirection: "row",
                                                backgroundColor: "#F0F2F4",
                                                width: "250",
                                                padding: '5px'
                                            }}>
                                                <Text style={{fontSize: 10, flex: 1}}>TOTAL</Text>
                                                <Text style={{
                                                    fontSize: 10,
                                                    flex: 1,
                                                    width: "100%",
                                                    textAlign: "center"
                                                }}>ORIGIN</Text>
                                                <Text style={{
                                                    fontSize: 10,
                                                    flex: 1,
                                                    width: "100%",
                                                    textAlign: "center"
                                                }}>DESTINATION</Text>
                                            </div>
                                            {
                                                totalAmountBreakdown.map((item, index) => {
                                                    return (
                                                        <div style={{
                                                            flexDirection: "row",
                                                            backgroundColor: "white",
                                                            width: "300",
                                                            padding: '5px'
                                                        }} key={'divindex-' + index}>
                                                            <Text style={{fontSize: 10, flex: 1}}
                                                                  key={'index-' + index}>TOTAL {item.currency} :</Text>
                                                            <Text style={{
                                                                fontSize: 10,
                                                                flex: 1,
                                                                width: "100%",
                                                                textAlign: "left"
                                                            }}
                                                                  key={'index-' + index + item.origin}>{item.origin.toFixed(2)}</Text>
                                                            <Text style={{
                                                                fontSize: 10,
                                                                flex: 1,
                                                                width: "100%",
                                                                textAlign: "left"
                                                            }}
                                                                  key={'index-' + index + item.destination}>{item.destination.toFixed(2)}</Text>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div style={{
                                        flexDirection: "column",
                                        flex: 1,
                                        justifyContent: "center",
                                        alignItems: "flex-end",
                                    }}>
                                        <Text style={{fontSize: 10, justifyContent: "flex-end", fontWeight: "bold"}}>Sub
                                            Total
                                            : {currencyCode} {totalAmt || "0"}</Text>
                                        <Text style={{
                                            fontSize: 10,
                                            justifyContent: "flex-end",
                                            fontWeight: "bold",
                                            marginTop: '5px',
                                            color: 'blue'
                                        }}>Total : {currencyCode} {totalAmt || "0"}</Text>
                                    </div>
                                </div>
                            </View>
                        </div>
                    </div>
                </View>
            </Page>
        </Document>
    );
}


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        position: 'relative',
    },
    pageBackground: {
        position: 'absolute',
        zIndex: -1,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    pdfLogo: {
        width: '100%',
        height: 'auto'
    },
});


const pdfStyles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff'
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    leftSection: {
        margin: 1,
        padding: 5,
        flex: 1,
        width: "100%",
        backgroundColor: "#fff"
    },
    rightSection: {
        margin: 5,
        padding: 5,
        flex: 2,
        width: "100%",
        backgroundColor: "#fff"
    },
    leftBorder: {
        padding: 5,
        border: 1,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: "#cccccc",
        width: '100%',
        height: 'auto',
    }
});
