import { get, post, postFormData, put } from "../api_helper";
import * as url from "../url_helper";
import {POSTMARK_VERIFIED_DATA_URL} from "../url_helper";

// Customer
export const getMarketingDataSer = (data) => get(url.MARKETING_DATA_URL + data);
export const postMarketingDataSer = (data) => post(url.MARKETING_DATA_URL, data);

export const getRuleHistoryDataSer = (data) => get(url.MARKETING_HISTORY_DATA_URL + data);

export const getPostmarkVerifiedEmails = () => get(url.POSTMARK_VERIFIED_DATA_URL);
