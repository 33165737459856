import React, {useEffect, useState} from "react";
import TopBreadcrumbs from "../Settings/Surcharge/TopBreadcrumbs";
import {editCompanyBreadcrumb} from "../../common/data/parties";
import {Card, CardBody, Container, FormFeedback, Input, Row} from "reactstrap";
import SimpleBar from "simplebar-react";
import Select from "react-select";
import {country_list, entityType, industryType, state_list} from "../../common/data/settings";
import {isObjectEmpty} from "../../components/Common/CommonLogic";
import {useDispatch, useSelector} from "react-redux";
import {
    getCompanyCountryData, getCompanyPincodeData, getCompanyStateData,
} from "../../store/Settings/actions";
import {useFormik} from "formik";

import {updateCompanySchema} from "../Settings/schema/companySchema";
import FileUpload from "../Settings/FileUpload";
import {GET_SUBSCRIPTION_SELECT_DATA} from "../../store/Subscriptions/actiontype";
import {
    getTenantsDetailsData,
    postSubscriberData
} from "../../store/Subscribers/actions";
import {useLocation, useNavigate} from "react-router-dom";
import {DomesticsTabName, FclTabName, InternationTabName, LclTabName} from "../Dashboard/Partials/initialData";
import {buyersLocationsList} from "../../common/data/common";
import { useLoadingBar } from "../../components/Common/ProgressBar";
import { POST_SUBSCRIBER_DATA_LOADER_START, SUBSCRIBER_INITIALIZE } from "../../store/Subscribers/actionType";

const EditSubscriber = () => {
    const { start, complete, decrease, increase } = useLoadingBar({ height: 3, });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateState = useLocation();
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState("comapanyDetails");
    const { subscriptionSelectDataList } = useSelector((state) => state.subscriptionsReducer);
    const { subscriberInfo, subscriberInfoError, progressBarLoader } = useSelector((state) => state.subscriberReducer);
    const { settings_companyCity_data, settings_companyState_data,tenant_info, settings_companyCountry_data, settings_companyPincode_data, } = useSelector((state) => state?.settings);
    const [subscriptionsValue, setSubscriptionsValue] = useState({});
    const [formData, setFormData] = useState({});
    const [fclChecked, setFclChecked] = useState(false);
    const [lclChecked, setLclChecked] = useState(false);
    const [iAFChecked, setIafChecked] = useState(false);
    const [dAFChecked, setDAFChecked] = useState(false);
    const [tenantMarketPlaces, setTenantMarketPlaces] = useState([]);
    const [buyersLocations, setBuyersLocations] = useState({value: "", label: "--Select Location--"});
    const [selectedCountryOptions, setSelectedCountryOptions] = useState([]);


    useEffect(() => {
        if (settings_companyState_data && settings_companyState_data?.content?.length > 0) {
            subscriberUpdateFormik.setFieldValue("state", settings_companyState_data?.content[0]?.stateName || "")
        }
        if (settings_companyCountry_data && settings_companyCountry_data?.content?.length > 0) {
            subscriberUpdateFormik.setFieldValue("country", settings_companyCountry_data?.content[0]?.countryName || "")
        }

        //dispatch(getSubscriberProfileImageData(navigateState?.state?.data.logoPath));

        dispatch(getTenantsDetailsData(navigateState?.state?.data.id))

        setSubscriptionsValue({
            value: "Select Subscriptions",
            label: "Select Subscriptions"
        });

    }, [settings_companyState_data, settings_companyCountry_data]);

    useEffect(() => {
        //console.log(navigateState?.state?.data);
        if (!isObjectEmpty(formData)) {
            if (subscriptionSelectDataList != null) {
                subscriptionSelectDataList.map((item) => {
                    if(item.value === formData?.subscriptionId) {
                        subscriberUpdateFormik.setFieldValue("subscriptionId", item.value);
                        setSubscriptionsValue({
                            value: item.value,
                            label: item.label,
                        });
                    }
                })
            }
        }
    }, [subscriptionSelectDataList]);

    useEffect(() => {
        if (!isObjectEmpty(subscriberInfoError)) {
            //console.log(subscriberInfoError);
        }
        if (!isObjectEmpty(subscriberInfo)) {
            setFormData(subscriberInfo);
        }
        dispatch({ type:  GET_SUBSCRIPTION_SELECT_DATA});
        //console.log("subscriberInfo" , subscriberInfo);
        //console.log("subscriberInfoError", subscriberInfoError);
    }, [subscriberInfo, subscriberInfoError]);


    useEffect(() => {
        if (progressBarLoader === false) {
            dispatch({ type: SUBSCRIBER_INITIALIZE });
            navigate(-1);
            setTimeout(() => {
                complete();
            }, 1500);
        }
    }, [progressBarLoader]);


    // Company Profile Section Start
    const onUploadChange = (file) => {
        subscriberUpdateFormik.setFieldValue("image", file);
    };

    const subscriberUpdateFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            image: formData?.logoPath,
            companyName: formData?.name || "",
            firstName: formData?.firstName || "",
            lastName:  formData?.lastName || "",
            contactNumber: formData?.contactNumber || "",
            email:  formData?.email || "",
            industryType: industryType.find((item) => item.value === 'AGENT').value,
            entityType: entityType.find((item) => item.value === 'PRIVATE_LTD').value,
            subscriptionId : "",
            city: formData?.city?.cityName || "",
            state: formData?.state?.stateName || "",
            zipcode: formData?.pinCode?.pin || "",
            country: formData?.state?.country?.countryName || "",
        },
        validationSchema: updateCompanySchema,
        onSubmit: async ({ image, ...value }) => {
            start();
            const cityData = settings_companyCity_data?.content?.find((city) => city.cityName === value.city) || [];
            const stateData = settings_companyState_data?.content?.find((state) => state.stateName === value.state) || [];
            const countryData = settings_companyCountry_data?.content?.find((con) => con.countryName === value.country) || [];
            const pinCodeData = settings_companyPincode_data?.content?.find((pinCodeEntry) => pinCodeEntry.pin === value.zipcode) || [];
            const tenant_data = {
                ...(pinCodeData?.length !== 0 && {
                    pinCode: {
                        version: pinCodeData.version,
                        id: pinCodeData.id,
                    },
                }),
                ...(cityData?.length !== 0 && {
                    city: {
                        version: cityData.version,
                        id: cityData.id,
                    },
                }),
                ...(stateData?.length !== 0 && {
                    state: {
                        version: stateData.version,
                        id: stateData.id,
                    },
                }),
                ...(countryData?.length !== 0 && {
                    country: {
                        version: countryData.version,
                        id: countryData.id,
                    },
                }),
                firstName: value.firstName || null,
                lastName: value.lastName || null,
                email: value.email || null,
                id: formData?.id || "",
                version: formData?.version || "0",
                name: value.companyName || "",
                logo: "",
                logoPath: image?.path || '',
                contactName: value.companyName || null,
                contactNumber: value.contactNumber || null,
                industryType: value.industryType || null,
                entityType: value.entityType || null,
                subscriptionId: value.subscriptionId || null,
            }

            const tenantDTO = {
                firstName: value.firstName || null,
                lastName: value.lastName || null,
                email: value.email || null,
                tenant: tenant_data,
                id: formData?.id || "",
                version: formData?.version || "0",
            };

            //console.log("Update finally Company  payload:-", image, tenantDTO);
            const updateFormData = new FormData();
            updateFormData.append("file", image);
            const jsonBlob = new Blob([JSON.stringify(tenantDTO)], {
                type: "application/json",
            });
            updateFormData.append("tenant", jsonBlob);
            dispatch(postSubscriberData(updateFormData));

        },
    });

    const addOrUpdateItem = (newItem) => {
        setTenantMarketPlaces(prevItems => {
            const index = prevItems.findIndex(item => item.serviceType === newItem.serviceType);
            if (index === -1) {
                // Item doesn't exist, add it to the array
                return [...prevItems, newItem];
            } else {
                // Item exists, update it
                const updatedItems = [...prevItems];
                updatedItems[index] = newItem;
                return updatedItems;
            }
        });
    };

    return(
        <>
            <div className="page-content settings_wrapper">
                <TopBreadcrumbs breadcrumbs={editCompanyBreadcrumb}/>
                <Container fluid>
                    <button type="button" className="btn border mb-3" onClick={() => {
                        navigate(-1);
                    }}> Back
                    </button>
                    <Row>
                        <div className="col-12 col-md-2">
                            <Card className="h-100">
                                <SimpleBar style={{maxHeight: "100%"}}>
                                    <div id="sidebar-menu" className="settings_sidebar">
                                        <ul className="metismenu list-unstyled" id="side-menu">
                                            <li>
                                                <span>
                                                    <a
                                                        href="#comapanyDetails"
                                                        onClick={() => {
                                                            setActive("comapanyDetails");
                                                        }}
                                                        className={active === "comapanyDetails" ? "active" : ""}
                                                    >
                                                        Company Details
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <a
                                                        href="#bussinessType"
                                                        onClick={() => {
                                                            setActive("bussinessType");
                                                        }}
                                                        className={active === "bussinessType" ? "active" : ""}
                                                    >
                                                        Bussiness Type
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <a
                                                        href="#subscription"
                                                        onClick={() => {
                                                            setActive("subscription");
                                                        }}
                                                        className={active === "subscription" ? "active" : ""}
                                                    >
                                                        Subscription
                                                    </a>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <a
                                                        href="#marketplace"
                                                        onClick={() => {
                                                            setActive("marketplace");
                                                        }}
                                                        className={active === "marketplace" ? "active" : ""}
                                                    >
                                                        Market Place
                                                    </a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </SimpleBar>
                            </Card>
                        </div>

                        {/* ------------------- */}
                        <div className="col-12 col-md-10">
                            <div style={{height: "auto"}}>
                                {/* Comapany details  */}
                                <Card id="comapanyDetails" className="mb-4">
                                    <CardBody>
                                        <div>
                                            <h5>Company Details</h5>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12 col-md-4 mb-4">
                                                <label className="form-label">Image</label>
                                                <FileUpload
                                                    iconName="img"
                                                    name="image"
                                                    onUpload={onUploadChange}
                                                    src={formData?.logo || ""}
                                                    className="form-control"
                                                />
                                            </div>

                                            <div className="col-12 col-md-8 mb-4">
                                                <label className="form-label">Company Name<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="companyName"
                                                    value={subscriberUpdateFormik?.values?.companyName}
                                                    onChange={subscriberUpdateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                {subscriberUpdateFormik.touched.name &&
                                                subscriberUpdateFormik.errors.companyName ? (
                                                    <FormFeedback type="invalid">
                                                        {subscriberUpdateFormik.errors.companyName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">First Name<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="firstName"
                                                    value={subscriberUpdateFormik?.values?.firstName || ""}
                                                    onChange={subscriberUpdateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                {subscriberUpdateFormik.touched.firstName &&
                                                subscriberUpdateFormik.errors.firstName ? (
                                                    <FormFeedback type="invalid">
                                                        {subscriberUpdateFormik.errors.firstName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Last Name<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="lastName"
                                                    value={subscriberUpdateFormik?.values?.lastName || ""}
                                                    onChange={subscriberUpdateFormik.handleChange}
                                                    onBlur={subscriberUpdateFormik.handleBlur}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                {subscriberUpdateFormik.touched.lastName &&
                                                subscriberUpdateFormik.errors.lastName ? (
                                                    <FormFeedback type="invalid">
                                                        {subscriberUpdateFormik.errors.lastName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Contact Number<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="contactNumber"
                                                    value={subscriberUpdateFormik?.values?.contactNumber || ""}
                                                    onChange={subscriberUpdateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                {subscriberUpdateFormik.touched.contactNumber &&
                                                subscriberUpdateFormik.errors.contactNumber ? (
                                                    <FormFeedback type="invalid">
                                                        {subscriberUpdateFormik.errors.contactNumber}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Email id<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="email"
                                                    value={subscriberUpdateFormik?.values?.email || ""}
                                                    onChange={subscriberUpdateFormik.handleChange}
                                                    onBlur={subscriberUpdateFormik.handleBlur}
                                                    className="form-control"
                                                    invalid={
                                                        subscriberUpdateFormik.touched.email &&
                                                        subscriberUpdateFormik.errors.email
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder=""
                                                />
                                                {subscriberUpdateFormik.touched.email &&
                                                subscriberUpdateFormik.errors.email ? (
                                                    <FormFeedback type="invalid">
                                                        {subscriberUpdateFormik.errors.email}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">City<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="city"
                                                    list="cityList"
                                                    value={subscriberUpdateFormik?.values?.city || ""}
                                                    onChange={(e) => {
                                                        subscriberUpdateFormik.handleChange(e);
                                                        subscriberUpdateFormik.setFieldValue('state', '');
                                                        subscriberUpdateFormik.setFieldValue('country', '');
                                                        subscriberUpdateFormik.setFieldValue('zipcode', '');
                                                        const cityData = settings_companyCity_data?.content?.find((city) => city.cityName === e.target.value);
                                                        if (cityData) {
                                                            dispatch(getCompanyStateData({cityId: cityData.id}));
                                                            dispatch(getCompanyCountryData({cityId: cityData.id}));
                                                            dispatch(getCompanyPincodeData({cityId: cityData.id}));
                                                        }
                                                    }}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <datalist id="cityList">
                                                    {settings_companyCity_data &&
                                                        settings_companyCity_data?.content?.map((item, i) => (
                                                            <option key={i} value={item.cityName}/>
                                                        ))}
                                                </datalist>
                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">State</label>
                                                <Input
                                                    type="text"
                                                    name="state"
                                                    list="stateList"
                                                    value={subscriberUpdateFormik?.values?.state || ''}
                                                    onChange={subscriberUpdateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <datalist id="stateList">
                                                    {state_list &&
                                                        state_list.map((item, i) => <option key={i} value={item}/>)}
                                                </datalist>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Zipcode</label>
                                                <Input
                                                    type="text"
                                                    name="zipcode"
                                                    list="zipcodeList"
                                                    value={subscriberUpdateFormik?.values?.zipcode || ""}
                                                    onChange={subscriberUpdateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <datalist id="zipcodeList">
                                                    {settings_companyPincode_data &&
                                                        settings_companyPincode_data?.content?.map((item, i) => (
                                                            <option key={i} value={item.pin}/>
                                                        ))}
                                                </datalist>
                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Country<span
                                                    className='required_star'>*</span></label>
                                                <Input
                                                    type="text"
                                                    name="country"
                                                    list="countryList"
                                                    value={subscriberUpdateFormik?.values?.country || ""}
                                                    onChange={subscriberUpdateFormik.handleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <datalist id="countryList">
                                                    {country_list &&
                                                        country_list.map((item, i) => (
                                                            <option key={i} value={item}/>
                                                        ))}
                                                </datalist>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                {/* Bussiness Type */}
                                <Card id="bussinessType" className="my-4 mb-auto">
                                    <CardBody>
                                        <div>
                                            <h5>Bussiness Type</h5>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Industry Type<span
                                                    className='required_star'>*</span></label>
                                                <Select
                                                    value={industryType ? industryType.find((option) => option.value === subscriberUpdateFormik?.values?.industryType) : ""}
                                                    name="industryType"
                                                    onChange={(e) => {
                                                        subscriberUpdateFormik.setFieldValue(industryType, e.value);
                                                    }}
                                                    options={industryType}
                                                    placeholder={"Enter Industry Type"}
                                                    classNamePrefix="select2-selection form-select"
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <label className="form-label">Entity Type<span
                                                    className='required_star'>*</span></label>
                                                <Select
                                                    value={entityType ? entityType.find((option) => option.value === subscriberUpdateFormik?.values?.entityType) : ""}
                                                    name="entityType"
                                                    onChange={(e) => {
                                                        subscriberUpdateFormik.setFieldValue(entityType, e.value);
                                                    }}
                                                    options={entityType}
                                                    placeholder={"Enter Entity Type"}
                                                    classNamePrefix="select2-selection form-select"
                                                />
                                            </div>
                                        </div>

                                        {/*<div className="row">
                                                <div className="d-flex justify-content-center">
                                                    <div className="mb-3 mx-3 d-flex justify-content-end">
                                                        <button
                                                            onClick={bussinessTypeFormik.handleSubmit}
                                                            className=" btn btn-primary"
                                                            disabled={isAnyValueEmpty(
                                                                bussinessTypeFormik.values
                                                            )}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                    <div className="mb-3 mx-3 d-flex justify-content-end">
                                                        <button
                                                            onClick={() => bussinessTypeFormik.resetForm()}
                                                            className=" btn btn-primary"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>*/}
                                    </CardBody>
                                </Card>

                                {/* Subscription Type */}
                                <Card id="subscription" className="my-4 mb-auto">
                                    <CardBody>
                                        <div>
                                            <h5>Subscription</h5>
                                        </div>
                                        <div className="col-12 col-md-12 mt-4">
                                            <label className="form-label">Subscriptions Type<span
                                                className='required_star'>*</span></label>
                                            <Select
                                                value={subscriptionsValue}
                                                options={subscriptionSelectDataList}
                                                name="subscriptionId"
                                                onChange={(e) => {
                                                    setSubscriptionsValue(e);
                                                    subscriberUpdateFormik.setFieldValue("subscriptionId", e.value);
                                                }}
                                                placeholder={"Select Subscription Type"}
                                                classNamePrefix="select2-selection form-select"
                                            />
                                            {subscriberUpdateFormik.touched.subscriptionId &&
                                            subscriberUpdateFormik.errors.subscriptionId ? (
                                                <FormFeedback type="invalid">
                                                    {subscriberUpdateFormik.errors.subscriptionId}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card id="marketplace" className="my-4 mb-auto">
                                    <CardBody>
                                        <div>
                                            <h5>Market Place</h5>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="card p-4">
                                                <h6 className="mb-3">Services Offered in Marketplace</h6>

                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            Full Container Load (FCL)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="fclCheckbox"
                                                                defaultChecked={fclChecked}
                                                                onChange={(e) => {
                                                                    setFclChecked(e.target.checked);
                                                                    addOrUpdateItem({
                                                                        serviceType: FclTabName,
                                                                        access: e.target.checked
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            Less Then Container (LCL)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="lclCheckbox"
                                                                defaultChecked={lclChecked}
                                                                onChange={(e) => {
                                                                    setLclChecked(e.target.checked);
                                                                    addOrUpdateItem({
                                                                        serviceType: LclTabName,
                                                                        access: e.target.checked
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            AIR Freight (International)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="iafCheckbox"
                                                                defaultChecked={iAFChecked}
                                                                onChange={(e) => {
                                                                    setIafChecked(e.target.checked);
                                                                    addOrUpdateItem({
                                                                        serviceType: InternationTabName,
                                                                        access: e.target.checked
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-2 mb-4 align-self-center">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizelg">
                                                            AIR Freight (Domestics)
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3">
                                                        <div className="form-check form-switch form-switch-md mb-3"
                                                             dir="ltr">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="dafCheckbox"
                                                                defaultChecked={dAFChecked}
                                                                onChange={(e) => {
                                                                    setDAFChecked(e.target.checked);
                                                                    addOrUpdateItem({
                                                                        serviceType: DomesticsTabName,
                                                                        access: e.target.checked
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="card p-4 shadow-sm">
                                                <h6 className="mb-3">Who Can See Your Rates in Marketplace</h6>


                                                <div className="form-check form-switch mb-3">
                                                    <div className="form-check form-switch form-switch-md mb-3"
                                                         dir="ltr">
                                                        <label className="form-check-label" htmlFor="displayNameToggle">Display
                                                            My
                                                            Name Against My Rates</label>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customSwitchsizelg"
                                                            defaultChecked={subscriberUpdateFormik.values.isNameVisible || false}
                                                            onChange={(e) => {
                                                                subscriberUpdateFormik.setFieldValue("isNameVisible", e.target.checked);
                                                            }}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label className="form-label">Buyers Locations</label>
                                                        <Select
                                                            value={buyersLocations}
                                                            name={"buyersLocations"}
                                                            placeholder="AnyWhere"
                                                            onChange={(e) => {
                                                                setBuyersLocations(e);
                                                                subscriberUpdateFormik.setFieldValue("buyersLocations", e.value);
                                                            }}
                                                            options={buyersLocationsList || []}
                                                            classNamePrefix="select2-selection form-select"
                                                        />
                                                    </div>
                                                    {
                                                        buyersLocations.value !== "anywhere" && buyersLocations.value !== "" &&
                                                        <>
                                                            <div className="col-md-4">
                                                                <label className="form-label">Country</label>
                                                                <Select
                                                                    isMulti
                                                                    value={selectedCountryOptions}
                                                                    onChange={(e) => {
                                                                        setSelectedCountryOptions(e);
                                                                    }}
                                                                    options={country_master}
                                                                    placeholder="Select Country"
                                                                />
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div style={{height: "50px"}}></div>
                                <div className="row">
                                    <div className="d-flex justify-content-center">
                                        <div className="mb-3 mx-3 d-flex justify-content-end">
                                            <button
                                                onClick={subscriberUpdateFormik.handleSubmit}
                                                type="submit"
                                                className=" btn btn-primary">
                                                Update
                                            </button>
                                        </div>
                                        <div className="mb-3 mx-3 d-flex justify-content-end">
                                            <button
                                                onClick={() => subscriberUpdateFormik.resetForm()}
                                                className=" btn btn-primary">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div style={{height: "200px"}}></div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
}


export default EditSubscriber;
