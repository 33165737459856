import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import TopBreadcrumbs from "../Settings/Surcharge/TopBreadcrumbs";
import {addCompanyBreadcrumb, instantRateBookingBreadcrumb} from "../../common/data/parties";
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Card,
    CardBody,
    Container,
    Input, Label,
    Row
} from "reactstrap";
import SimpleBar from "simplebar-react";
import {convertToINR, isObjectEmpty, useMobileView} from "../../components/Common/CommonLogic";
import CompanyForm from "./partials/CompanyForm";
import AirConsigneeForm from "./AirFreightResult/AirConsigneeForm";
import ShipmentForm from "./partials/ShipmentForm";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useDispatch, useSelector} from "react-redux";
import {BLANK_MODAL_CHARGE} from "../../store/Sales/Quotation/actiontype";
import {QUOTATION_RESULT_SELECTED_BLANK} from "../../store/InstantRate/actionType";
import {useNavigate} from "react-router-dom";
import {cube_filled, oocl_logo, zim_logo} from "../../assets/images";
import ShipmentInformationForm from "./partials/ShipmentInformationForm";
import PickupAddressForm from "./partials/PickupAddressForm";
import DeliveryAddressForm from "./partials/DeliveryAddressForm";
import DocumentUpload from "./AirFreightResult/DocumentUpload";
import CommoditiesForm from "./AirFreightResult/CommoditiesForm";
import GoodsConsentForm from "./AirFreightResult/GoodsConsentForm";
import {getCustomersCityData} from "../../store/Parties/actions";
import {useFormik} from "formik";
import * as Yup from "yup";


const BookingForm = () => {

    const [isInsuranceChecked, setIsInsuranceChecked] = useState(false);
    const [activeAccordionKey, setActiveAccordionKey] = useState("flightDetails");
    const [height, setHeight] = useState(window.innerHeight);
    const isMobile = useMobileView();
    const [open, setOpen] = useState('flightDetails');
    const [openInner, setOpenInner] = useState('');
    const quoteData = useSelector((state) => state.instantRate.quote_selected_data);
    const { $instantActiveTab } = useSelector((state) => state.instantRate);
    const mainChargeObj = useSelector((state) => state?.quotation?.mainChargeObj);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useRef(null); // Ref to measure height dynamically

    useEffect(() => {
        dispatch(getCustomersCityData());
        if (contentRef.current) {
            contentRef.current.style.height = isExpanded
                ? `${contentRef.current.scrollHeight}px`
                : "0px";
        }
    }, [isExpanded]);

    useLayoutEffect(() => {
        const handleResize = () => setHeight(window.innerHeight);
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggle = (id) => {
        if (open !== id) {
            setOpen(id);
        }
    };

    const blankFieldHandle = () => {
        dispatch({ type: BLANK_MODAL_CHARGE, payload: {} });
        dispatch({ type: QUOTATION_RESULT_SELECTED_BLANK, payload: {} });
    }

    // ---------------- SubTotal / Total and Tax ------------------------------
    const innerTotalHandler = (array, newArray) => {
        return array?.reduce((total, charge) => total + convertToINR(Number(charge?.total_sale_cost) || (Number(charge.amount + (charge?.taxDetail?.value || 0))), charge.currencyCode), 0) + (newArray !== undefined ? newArray?.reduce((total, charge) => total + convertToINR(Number(charge?.total_sale_cost), charge.currencyCode), 0) : 0);
    }

    const subTotalHandler = (quoteObject) => {
        //console.log(quoteObject, "quoteObject");
        //console.log(mainChargeObj, "mainChargeObj");

        let mainChargeCurr = mainChargeObj?.find(obj => obj.id === quoteObject.quote_id) || [];

        const totalSum = quoteObject?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = 0;
            if (currentOuter?.selected) {
                innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                    return accInner + convertToINR(Number(currentInner.totalSaleCost), currentInner.currencyCode);
                }, 0);
            }
            return accOuter + innerSum;
        }, 0);

        const totalMarginSum = quoteObject?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = 0;
            if (currentOuter?.selected) {
                innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                    return accInner + (convertToINR(currentInner?.margin_value ? Number(currentInner.margin_value) : 0, currentInner.currencyCode) || 0);
                }, 0);
            }
            return accOuter + innerSum;
        }, 0);
        /*
        const newSubTotal = mainChargeCurr?.tariffDetails !== undefined ? mainChargeCurr?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                let totalAmt = Number(currentInner.unitPerPrice || 0) * Number(currentInner.unit || 1);
                return accInner + convertToINR(Number(totalAmt), currentInner.currencyCode);
            }, 0);
            return accOuter + innerSum;
        }, 0) : 0;

        const totalNewMarginSum = mainChargeCurr?.tariffDetails !== undefined ? mainChargeCurr?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                return accInner + (convertToINR(currentInner?.margin_value ? Number(currentInner.margin_value) : 0, currentInner.currencyCode) || 0);
            }, 0);
            return accOuter + innerSum;
        }, 0) : 0;
        */
        //return totalSum + newSubTotal + totalMarginSum + totalNewMarginSum;
        //console.log(totalSum, "totalSum" , totalMarginSum, 'totalMarginSum');
        return totalSum + totalMarginSum;
    }

    // ----------------- preview quotation -------------------
    const previewQuotationHandler = () => {
        //console.log(quoteData, "quoteData");
        //console.log(mainChargeObj, "mainChargeObj");
        // setPreviewModal(true);
        //QuoteModalHandler();
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollableHeight =
                document.documentElement.scrollHeight - window.innerHeight;
            const scrolled = window.scrollY;

            // Track if user has scrolled to bottom
            if (scrolled >= scrollableHeight - 50) {
                setIsAtBottom(true);
            } else {
                setIsAtBottom(false);
            }

            // Save scroll position
            setScrollPos(scrolled);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleToggle = (key) => {
        // Prevent closing the last open tab
        setActiveAccordionKey(activeAccordionKey === key ? activeAccordionKey : key);
    };

    const proceedToNext = (stepKey) => {
        toggle(stepKey);
    }

    const pickupAddressFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            pickupAddressNeeded: false,
            pickupAddress: "",
            state: "",
            zipcode: "",
            city: "",
        },
        validationSchema: Yup.object({
            pickupAddress: Yup.string().required("Required"),
            state: Yup.string().required("Required"),
            zipcode: Yup.string().required("Required"),
            city: Yup.string().required("Required"),
        })
    });

    const deliveryAddressFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            deliveryAddressNeeded: false,
            deliveryAddress: "",
            state: "",
            zipcode: "",
            city: "",
        },
        validationSchema: Yup.object({
            deliveryAddress: Yup.string().required("Required"),
            state: Yup.string().required("Required"),
            zipcode: Yup.string().required("Required"),
            city: Yup.string().required("Required"),
        })
    });

    const additionalServiceValidationCheck = async () => {
        const isPickupNeeded = pickupAddressFormik?.values.pickupAddressNeeded;
        const isDeliveryNeeded = deliveryAddressFormik?.values.deliveryAddressNeeded;

        let isPickupValid = true;
        let isDeliveryValid = true;

        if (isPickupNeeded) {
            const pickupErrors = await pickupAddressFormik.validateForm();
            isPickupValid = isObjectEmpty(pickupErrors);
            if (!isPickupValid) {
                await pickupAddressFormik.submitForm();
            }
        }

        if (isDeliveryNeeded) {
            const deliveryErrors = await deliveryAddressFormik.validateForm();
            isDeliveryValid = isObjectEmpty(deliveryErrors);
            if (!isDeliveryValid) {
                await deliveryAddressFormik.submitForm();
            }
        }

        // Proceed only if all required validations pass
        if ((!isPickupNeeded || isPickupValid) && (!isDeliveryNeeded || isDeliveryValid)) {
            proceedToNext("customerdetails");
        }
    }

    const yourGoodsFormik = useFormik({
        enableReinitialize: true,
        initialValues: {

        }
    })

    return (
        <>
            <div className="page-content settings_wrapper">
                <TopBreadcrumbs breadcrumbs={instantRateBookingBreadcrumb}/>
                <Container fluid>
                    <button type="button" className="btn border mb-3" onClick={() => {
                        blankFieldHandle();
                        navigate(-1);
                    }}> Back
                    </button>
                    <Row>
                        {/* ------------------- */}
                        <div className="col-12 col-md-8">
                            <PerfectScrollbar className="p-1" style={{ height: `${height}px` }}>
                                <div className="modal-body">
                                    {quoteData?.length !== 0 ? (
                                        <Accordion flush open={open} toggle={toggle} className='main_accordion'>
                                            <AccordionItem>
                                                <AccordionHeader className="instant-rate-booking-accordion-header" targetId={`flightDetails`}>
                                                    <span className={`fs-7 fw-700 ba h-8 br-100 justify-content-center d-sm-flex align-items-center ${open === 'flightDetails' ? 'c-neutral-900' : 'c-neutral-grey'}`} style={{width: "32px"}}>1</span>
                                                    <span className={`fs-7 px-4 fw-600 ${open === 'flightDetails' ? "c-neutral-900" : "c-neutral-grey"}`}>Shipment Details</span>
                                                </AccordionHeader>
                                                <AccordionBody accordionId={`flightDetails`}>
                                                    <ShipmentInformationForm proceedToNext={proceedToNext} />
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader className="instant-rate-booking-accordion-header" targetId={`addon`}>
                                                    <span className={`fs-7 fw-700 ba h-8 br-100 justify-content-center d-sm-flex align-items-center ${open === 'addon' ? 'c-neutral-900' : 'c-neutral-grey'}`} style={{width: "32px"}}>2</span>
                                                    <span className={`fs-7 px-4 fw-600 ${open === 'addon' ? "c-neutral-900" : "c-neutral-grey"}`}>Additional Services</span>
                                                </AccordionHeader>
                                                <AccordionBody className="p-2" accordionId={`addon`}>
                                                    <div className="customer_form_details">
                                                        <Card>
                                                            <CardBody>
                                                                <div className="row">
                                                                    <div className="col-12 col-md-6">
                                                                        <PickupAddressForm pickupAddressFormik={pickupAddressFormik} />
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                        <DeliveryAddressForm deliveryAddressFormik={deliveryAddressFormik} />
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                        <div className="container mt-4">
                                                                            <div className="d-flex w-100 justify-content-between">
                                                                                <div className="d-flex align-items-center">
                                                                                    <h6 className="mb-3" style={{flex: 1}}>Insurance</h6>
                                                                                    <div className="square-switch" style={{marginLeft: "40px"}}>
                                                                                        <Input type="checkbox"
                                                                                               id="square-switch4"
                                                                                               switch="none"
                                                                                               role="switch"
                                                                                               checked={isInsuranceChecked}
                                                                                               onClick={() => setIsInsuranceChecked(!isInsuranceChecked)}
                                                                                        />
                                                                                        <Label htmlFor="square-switch4" data-on-label="Yes"
                                                                                               data-off-label="No"></Label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-end">
                                                                    <button className="btn btn-primary" onClick={additionalServiceValidationCheck}>Continue</button>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader className="instant-rate-booking-accordion-header" targetId={`customerdetails`}>
                                                    <span className={`fs-7 fw-700 ba h-8 br-100 justify-content-center d-sm-flex align-items-center ${open === 'customerdetails' ? 'c-neutral-900' : 'c-neutral-grey'}`} style={{width: "32px"}}>3</span>
                                                    <span className={`fs-7 px-4 fw-600 ${open === 'customerdetails' ? "c-neutral-900" : "c-neutral-grey"}`}>Shipper Details</span>
                                                </AccordionHeader>
                                                <AccordionBody accordionId={`customerdetails`}>
                                                    <CompanyForm proceedToNext={proceedToNext}/>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader className="instant-rate-booking-accordion-header" targetId={`consigneedetails`}>
                                                    <span className={`fs-7 fw-700 ba h-8 br-100 justify-content-center d-sm-flex align-items-center ${open === 'consigneedetails' ? 'c-neutral-900' : 'c-neutral-grey'}`} style={{width: "32px"}}>4</span>
                                                    <span className={`fs-7 px-4 fw-600 ${open === 'consigneedetails' ? "c-neutral-900" : "c-neutral-grey"}`}>Consignee Details</span>
                                                </AccordionHeader>
                                                <AccordionBody accordionId={`consigneedetails`}>
                                                    <AirConsigneeForm proceedToNext={proceedToNext}/>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader className="instant-rate-booking-accordion-header" targetId={`yourGoods`}>
                                                    <span className={`fs-7 fw-700 ba h-8 br-100 justify-content-center d-sm-flex align-items-center ${open === 'yourGoods' ? 'c-neutral-900' : 'c-neutral-grey'}`} style={{width: "32px"}}>4</span>
                                                    <span className={`fs-7 px-4 fw-600 ${open === 'yourGoods' ? "c-neutral-900" : "c-neutral-grey"}`}>Your Goods</span>
                                                </AccordionHeader>
                                                <AccordionBody accordionId={`yourGoods`}>
                                                    <GoodsConsentForm yourGoodsFormik={yourGoodsFormik} />
                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    ) : <p>No data found</p>}
                                </div>
                            </PerfectScrollbar>
                        </div>
                        <div className="col-12 col-md-4">
                            <div id="sidebar-booking-menu" className="settings_sidebar">
                                <div className="card p-3 shadow-sm">
                                    {/* Total Price Section */}
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h6 className="mb-0">Total price</h6>
                                        </div>
                                        <div className="text-end">
                                            {quoteData.map((item, mainindex) => (
                                                <h5 key={mainindex} className="text-primary mb-0">₹{(subTotalHandler(item))}</h5>
                                            ))}
                                            <a href="#"
                                                className="text-primary text-decoration-none"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setIsExpanded(!isExpanded);
                                                }}>
                                                {isExpanded ? "Hide Breakup" : "View Breakup"}
                                            </a>
                                        </div>
                                    </div>

                                    {/* Fare Breakdown */}
                                    <div ref={contentRef}
                                        className="expandable-content"
                                        style={{ overflow: "hidden", transition: "height 0.3s ease" }}>
                                        <div className="mt-3">
                                            {quoteData.map((item, itemIndex) => (
                                                item.tariffDetails.map((details, detailsIndex) =>
                                                    details.selected && (
                                                        <React.Fragment key={detailsIndex}>
                                                            <div className="d-flex justify-content-between">
                                                                <span className="fw-bold">{details.header.replaceAll("_", " ")}</span>
                                                            </div>
                                                            {details.fclTariffBreakDowns?.length === 0 && (
                                                                <div key={`${detailsIndex}-blank-lst`}
                                                                     className="d-flex justify-content-between mb-1">
                                                                    <span style={{ fontSize: "small", color: "rgb(120 112 112)" }}>
                                                                        -
                                                                    </span>
                                                                    <span className="fw-bold">₹0</span>
                                                                </div>
                                                            )}
                                                            {details.fclTariffBreakDowns.map((tariffBreakDown, index) => (
                                                                <div key={`${itemIndex}-${detailsIndex}-${index}-lst`}
                                                                     className="d-flex justify-content-between mb-1">
                                                                    <span style={{ fontSize: "small", color: "rgb(120 112 112)" }}>
                                                                        {tariffBreakDown.chargeName}
                                                                    </span>
                                                                    <span className="fw-bold">₹{tariffBreakDown.totalSaleCost || 0}</span>
                                                                </div>
                                                            ))}
                                                        </React.Fragment>
                                                    )
                                                )
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <div style={{height: "50px;"}} />
                    <div className={`sticky-box ${isAtBottom ? "footer-fixed" : "move-bottom"}`}>
                        <div className="modal-footer">
                            <div className="btn_wrap">
                                <button type="button" className='btn border_btn' onClick={() => {
                                    blankFieldHandle();
                                    setBookModal(false);
                                }}>Cancel</button>
                                <button type="button" className='btn btn-primary ms-2' onClick={() => { previewQuotationHandler() }}>Book Now</button>
                                {/* <button type="button" className='btn btn-primary ms-2' onClick={() => { previewQuotationHandler() }}>Preview Quotation</button> */}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default BookingForm;