import {showErrorToast, showSuccessToast} from "../../components/Common/CustomToast";
import {
    getSubscriptionAllData,
    getSubscriptionSummaryData,
    postSubscriptionData
} from "../../helpers/services/NetworkService";
import {
    all,
    call,
    fork,
    put,
    takeLatest,
    takeEvery
} from "redux-saga/effects";
import {
    GET_SUBSCRIPTION_DATA,
    GET_SUBSCRIPTION_DATA_FAIL,
    GET_SUBSCRIPTION_DATA_SUCCESS,
    GET_SUBSCRIPTION_SELECT_DATA,
    GET_SUBSCRIPTION_SELECT_DATA_SUCCESS,
    POST_SUBSCRIPTION_DATA,
    POST_SUBSCRIPTION_DATA_FAIL,
    POST_SUBSCRIPTION_DATA_SUCCESS, SUBSCRIPTION_LOADER_STOP
} from "./actiontype";


function* fetchSubscriptionsSummary({ payload }) {
    try{
        const response = yield call(getSubscriptionSummaryData, payload);
        yield put({ type: GET_SUBSCRIPTION_DATA_SUCCESS, payload: response });
        yield put({ type: GET_SUBSCRIPTION_SELECT_DATA_SUCCESS, payload: response });
    } catch (error) {
        yield put({ type: GET_SUBSCRIPTION_DATA_FAIL, payload: error });
        showErrorToast(error.message);
        console.error(error);
    }
}

function* fetchSubscriptions() {
    try{
        const response = yield call(getSubscriptionAllData);
        yield put({ type: GET_SUBSCRIPTION_DATA_SUCCESS, payload: response });
        yield put({ type: GET_SUBSCRIPTION_SELECT_DATA_SUCCESS, payload: response });
    } catch (error) {
        yield put({ type: GET_SUBSCRIPTION_DATA_FAIL, payload: error });
        showErrorToast(error.message);
        console.error(error);
    }
}

function* postSubscriptions(dataObj) {
    try{
        const response = yield call(postSubscriptionData, dataObj.payload);
        yield put({ type: POST_SUBSCRIPTION_DATA_SUCCESS, payload: response });
        yield put({ type: SUBSCRIPTION_LOADER_STOP });
        showSuccessToast("Subscriptions added successfully.");
    } catch (error) {
        yield put({ type: SUBSCRIPTION_LOADER_STOP });
        yield put({ type: POST_SUBSCRIPTION_DATA_FAIL, payload: error });
        console.error(error);
    }
}


export function* watchGetSubscriptions() {
    yield takeEvery(GET_SUBSCRIPTION_DATA, fetchSubscriptionsSummary);
    yield takeEvery(POST_SUBSCRIPTION_DATA, postSubscriptions);
    yield takeEvery(GET_SUBSCRIPTION_SELECT_DATA, fetchSubscriptions);
}

function* subscriptionSaga() {
    yield all([fork(watchGetSubscriptions)]);
}

export default subscriptionSaga;